import { useSelector } from "react-redux";
import { authSelector } from "../../redux/selectors";
import { API_SERVER } from "../../variables_config";
import styles from "./BankLogo.module.css";

const BankLogo = () => {
  const { user } = useSelector(authSelector);

  return (
    <>
      {user?.bankBIC && (
        <div className={styles.bankLogo}>
          <img
            alt="logo"
            src={`${API_SERVER}storage/assets/images/${user.bankBIC}.png`}
          />
        </div>
      )}
    </>
  );
};

export default BankLogo;

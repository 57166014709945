import { useEffect } from "react";
import { isIOS } from ".";

export const useDisableBodyScroll = (open: boolean) => {
  const body = document.body;

  useEffect(() => {
    let scrollPosition = 0;

    if (open) {
      const documentWidth = document.documentElement.clientWidth;
      const windowWidth = window.innerWidth;
      const scrollBarWidth = windowWidth - documentWidth;

      if (isIOS()) {
        scrollPosition = window.pageYOffset;

        body.style.position = "fixed";
        body.style.top = `-${scrollPosition}px`;
      }

      body.style.paddingRight = `${scrollBarWidth}px`;
      body.style.overflow = "hidden";
    } else {
      if (isIOS()) {
        body.style.removeProperty("position");
        body.style.removeProperty("top");

        window.scrollTo(0, scrollPosition);
      }

      body.style.removeProperty("padding-right");
      body.style.removeProperty("overflow");
    }
  }, [body, open]);
};

export default useDisableBodyScroll;

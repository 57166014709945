import { isValid } from "iban";

export const validateRequiredField = (value: string) => {
  let error = "";

  if (value === "") {
    error = "Erforderlich";
  }

  return error;
};

export const validatePassword = (value: string) => {
  /* ^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,16}$ */
  let error = "";
  const passwordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])([^\s]){8,24}$/;
  if (!value) {
    error = "Pflichtangabe";
  } else if (!passwordRegex.test(value)) {
    error = "Passwort entspricht nicht den Vorgaben";
  }

  return error;
};

export const validateEmail = (value: string) => {
  let error;

  if (!value) {
    error = "Pflichtangabe";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value)) {
    error = "Ungültige E-Mail-Adresse";
  }

  return error;
};

export const validateIBANField = (value: string, originalValue?: string) => {
  let error = "";

  if (
    (originalValue && value.includes("*") && value !== originalValue) ||
    ((!originalValue || !value.includes("*")) && !isValid(value)) ||
    value.includes(" ")
  ) {
    error = "Ungültige IBAN";
  }

  return error;
};

export const validateConfirmPassword = (pass: string, value: string) => {
  let error = "";
  if (!value) {
    error = "Pflichtangabe";
  } else if (pass !== value) {
    error = "Passwort stimmt nicht überein";
  }

  return error;
};

export const validatePhoneNumber = (value: string) => {
  let error;
  //value &&
  if (
    !/^(0049|[+]\d{2}|\([0]\d{2}\)|[0]\d{2})[-|\s]?(\(\d{2,3}\)|\d{2,3})([-|\s]?\d{2,3})([-|\s]?\d{2,3})([-|\s]?\d{2,4})?$/g.test(
      value
    )
  ) {
    error = "Ungültige Telefonnummer";
  }

  return error;
};

export const validatePLZ = (value: string) => {
  let error;

  if (value.length !== 5 || !/[0-9]{5}$/i.test(value)) {
    error = "Ungültige PLZ";
  }

  return error;
};

export const hideIBAN = (value: string) => {
  return `${value.substring(0, 4)}${Array(value.length - 9)
    .fill("*")
    .join("")}${value.substring(value.length - 5)}`;
};

export const addStarsIBAN = (ibanValue: string) => {
  if (validateIBANField(ibanValue) === "") {
    return hideIBAN(ibanValue);
  }

  return ibanValue;
};

export const convertFileToBase64 = async (file: any) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    // Onload of file read the file content
    fileReader.onload = fileLoadedEvent => {
      const base64 = fileLoadedEvent?.target?.result;
      resolve(base64);
    };

    // Error handling
    fileReader.onerror = error => {
      reject(error);
    };

    // Convert data to base64
    fileReader.readAsDataURL(file);
  });
};

import Select, { components, DropdownIndicatorProps } from "react-select";
import "./FormikReactSelect.css";
import { ReactComponent as ArrowIcon } from "../assets/images/arrow.svg";

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowIcon />
    </components.DropdownIndicator>
  );
};

const FormikReactSelect = ({
  field,
  form,
  onChange,
  options,
  ...props
}: {
  field: any;
  form: any;
  options: any;
  onChange: any;
}) => {
  return (
    <Select
      {...field}
      {...props}
      className={`
        reactSelectContainer
        ${
          form.errors.hasOwnProperty(field.name) &&
          form.touched.hasOwnProperty(field.name)
            ? "withError"
            : ""
        }
      `}
      classNamePrefix="reactSelect"
      options={options}
      formatGroupLabel={(data: any) => (
        <div className="reactSelect__label-container">
          <span>{data.label}</span>
        </div>
      )}
      value={field.value}
      onChange={(value: any) => {
        if (onChange) {
          onChange(value.value);
        } else {
          form.setFieldValue(field.name, value);
        }
      }}
      components={{ DropdownIndicator }}
    />
  );
};

export default FormikReactSelect;

import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import DefaultModal from "../../components/DefaultModal/DefaultModal";
import ProductModalContent from "../../components/ProductModalContent/ProductModalContent";
import { ReactComponent as RemoveIcon } from "../../images/close-round-icon.svg";
import { fetchRecalcLetter } from "../../redux/reducers/calculatorSlice";
import {
  Product,
  ServiceToCalc,
  TariffDataItem,
} from "../../redux/reducers/calculatorSlice.d";
import { calcSelector, useAppDispatch } from "../../redux/selectors";
import { numberToDECurrency } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimentions";
import {
  InitModalState,
  MandatoryDataRecalcProps,
} from "../Calculator/Calculator.d";
import {
  getFormulaParamById,
  sortProductsByOrder,
} from "../Calculator/calculatorHelper";
import RichTextEditorHtml from "../RichTextEditorHtml/RichTextEditorHtml";
import { LetterServicesProps } from "./CheckoutLetterServices.d";
import styles from "./CheckoutLetterServices.module.css";
import ProductItem from "./ProductItem";

const initModalState: InitModalState = {
  idService: "",
  name: "",
  popupTitle: "",
  popupBody: "",
  rightImg: "",
  bottomText: "",
};

const mobFrom = 1044;

const CheckoutLetterServices: React.FC<LetterServicesProps> = ({
  createDraft,
  //clearConsultProtocolAnswers,
}) => {
  const dispatch = useAppDispatch();
  const { width } = useWindowDimensions();

  const {
    letterData: {
      idPackage,
      products: productsState,
      premiumFreeTerm,
      defaultValue,
      paymentModalityTitle,
    },
  } = useSelector(calcSelector);

  const [modalContent, setModalContent] = useState(initModalState);

  const changeServiceValueToCalc = useCallback(
    (productId: string) => (value: number) => {
      const products: Array<ServiceToCalc> = [
        {
          idProduct: productId,
          valueToCalculate: value,
        },
      ];

      dispatch(
        fetchRecalcLetter({
          idPackage,
          products,
          createDraft,
          //clearConsultProtocolAnswers,
        })
      );
    },
    [createDraft, dispatch, idPackage]
  );

  const closeModalHandler = () => setModalContent(initModalState);

  const changeServiceIsActive = useCallback(
    (productId: string, isActive: boolean) => {
      dispatch(
        fetchRecalcLetter({
          idPackage: idPackage,
          products: [
            {
              idProduct: productId,
              isActive,
            },
          ],
          createDraft,
          //clearConsultProtocolAnswers,
        })
      );
    },
    [createDraft, dispatch, idPackage]
  );

  const saveMandatoryDataRecalc = useCallback(
    ({
      idProduct,
      isActive,
      valueToCalculate,
      mandatoryDataRequest,
      values,
    }: MandatoryDataRecalcProps) => {
      const tariffData: Array<TariffDataItem> = Object.keys(values).map(
        (idParam: string) => {
          const formulaParam = getFormulaParamById(
            idParam,
            mandatoryDataRequest
          );

          return {
            idParam,
            dataType: formulaParam?.type,
            ...values[idParam],
          } as TariffDataItem;
        }
      );

      const products: Array<ServiceToCalc> = [
        {
          idProduct,
          isActive,
          valueToCalculate,
          tariffData,
        },
      ];

      dispatch(
        fetchRecalcLetter({
          idPackage,
          products,
          createDraft,
        })
      );
    },
    [createDraft, dispatch, idPackage]
  );

  const actionStatusIcon = (product: Product) =>
    !product.isCore && (
      <div
        onClick={() =>
          changeServiceIsActive(product.idProduct, !product.isActive)
        }
        className={`${styles.iconContainer} ${styles.productActionContainer}`}
      >
        <RemoveIcon
          className={`${styles.icon} ${
            !product.isActive ? styles.addIcon : ""
          }`}
        />
      </div>
    );

  const portalAccessProduct = useMemo(() => {
    if (productsState) {
      return productsState?.find(
        (product: Product) => product.calcMethod === 9
      );
    }

    return undefined;
  }, [productsState]);

  const isShowLeistungTitle = useMemo(() => {
    if (productsState) {
      return productsState?.some(
        (product: Product) =>
          product.calcMethod !== 1 &&
          product.calcMethod !== 8 &&
          product.calcMethod !== 9
      );
    }

    return true;
  }, [productsState]);

  return (
    <div
      className={styles.configContainer}
      data-testid="checkoutLetterServices"
    >
      {width > mobFrom && (
        <div className={styles.configParamsHead}>
          {isShowLeistungTitle && (
            <div className={styles.headItem}>Leistung</div>
          )}
          <div className={styles.headItem}>Kosten {paymentModalityTitle}</div>
        </div>
      )}

      <div className={styles.configParamsContainer}>
        {portalAccessProduct && (
          <ProductItem
            product={portalAccessProduct}
            styles={styles}
            paymentTitle={`Kosten ${paymentModalityTitle}`}
            changeServiceValueToCalc={changeServiceValueToCalc}
            actionStatusIcon={actionStatusIcon}
            setModalContent={setModalContent}
          />
        )}

        {sortProductsByOrder(productsState).map(
          (product: Product) =>
            product.calcMethod !== 9 && (
              <ProductItem
                key={product.idProduct}
                product={product}
                styles={styles}
                paymentTitle={`Kosten ${paymentModalityTitle}`}
                changeServiceValueToCalc={changeServiceValueToCalc}
                actionStatusIcon={actionStatusIcon}
                setModalContent={setModalContent}
                saveMandatoryDataRecalc={saveMandatoryDataRecalc}
              />
            )
        )}
      </div>

      {premiumFreeTerm ? (
        <div className={styles.freeTrial}>
          <span>Die ersten {premiumFreeTerm} Monate kostenfrei</span>
        </div>
      ) : null}

      <div className={styles.totalPriceContainer}>
        <span>Gesamtkosten {paymentModalityTitle} nur:</span>

        <div className={styles.totalContainer}>
          <span>{numberToDECurrency(defaultValue)}</span>
        </div>
      </div>

      <DefaultModal
        showModal={modalContent.idService !== ""}
        title={modalContent.name}
        closeHandler={closeModalHandler}
      >
        <ProductModalContent
          text={<RichTextEditorHtml editorValue={modalContent.popupBody} />}
          bottomImg={modalContent.bottomImg}
          bottomText={modalContent.bottomText}
          rightImg={modalContent.rightImg}
          rightAlt={modalContent.name}
        />
      </DefaultModal>
    </div>
  );
};

export default CheckoutLetterServices;

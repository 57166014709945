import React from "react";
import { useSelector } from "react-redux";
import { utcToZonedTime, format } from "date-fns-tz";
import BlockContainer from "../../../components/BlockContainer/BlockContainer";
import ContentBox from "../../../components/ContentBox/ContentBox";
import LongButton from "../../../components/LongButton/LongButton";
import { authSelector, packagesSelector } from "../../../redux/selectors";
import { BlockProps } from "../Home.d";

import { ReactComponent as OrderIcon } from "../../../images/order-icon.svg";
import dateRange from "../../../images/date-range-icon.svg";
import { IS_CASHBACK, IS_RVN_BRAND } from "../../../variables_config";

const BlockOne: React.FC<BlockProps> = ({ styles }) => {
  const { user } = useSelector(authSelector);
  const { date } = useSelector(packagesSelector);
  const cashbackText = IS_CASHBACK ? " und die Cashback-Vorteilswelt" : "";
  const standardText = !IS_RVN_BRAND
    ? ",  weitere Mehrwert-Angebote deiner Bank"
    : "";
  return (
    <BlockContainer>
      <div className={styles.blockOneContainer}>
        <ContentBox className={styles.blockOne}>
          <h1 className={styles.h1}>
            Herzlich Willkommen im
            <br />
            {IS_RVN_BRAND
              ? "R+V-CashProtect Portal"
              : "Cash & Protect-Portal"}, {user?.firstName}.
          </h1>
          <p className={styles.textBlock}>
            Hier findest du alles rund um deine gebuchten{" "}
            {IS_RVN_BRAND ? "R+V-CashProtect" : "Mehrwert-Pakete"}
            {`${standardText}${cashbackText}.`}
          </p>
          <div className={styles.btnContainer}>
            <LongButton
              text="Vertragsunterlagen einsehen"
              icon={OrderIcon}
              href="/downloads"
            />
            {IS_CASHBACK && (
              <LongButton
                text="Zur Cashback Vorteilswelt"
                icon={OrderIcon}
                href="/cashback"
              />
            )}
          </div>

          {date && (
            <p className={styles.smallTextBlock}>
              <img src={dateRange} alt="calendar icon" />
              <span>
                letzte Vertragsänderungen: am{" "}
                {format(utcToZonedTime(date, "GMT"), "dd.MM.yyyy - HH:mm")} Uhr
              </span>
            </p>
          )}
        </ContentBox>
      </div>
    </BlockContainer>
  );
};

export default BlockOne;

import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import BlockContainer from "../../components/BlockContainer/BlockContainer";
import Button from "../../components/Button/Button";
import ContentBox from "../../components/ContentBox/ContentBox";
import InputField from "../../components/InputField/InputField";
import ConfirmationCodeWrapper from "../../containers/ConfirmationCodeWrapper/ConfirmationCodeWrapper";
import ConfirmationLoginWrapper from "../../containers/ConfirmationLoginWrapper/ConfirmationLoginWrapper";
import MainWrapper from "../../containers/MainWrapper/MainWrapper";
import { ReactComponent as EmailIcon } from "../../images/email-icon.svg";
import { ReactComponent as PasswordIcon } from "../../images/password-icon.svg";
import {
  clearError,
  fetchCheckOrRefreshToken,
  fetchUserLogin,
} from "../../redux/reducers/authenticationSlice";
import { authSelector, useAppDispatch } from "../../redux/selectors";
import { validateRequiredField } from "../../utils/formHelper";
import styles from "./Login.module.css";
import { LoginFormValues } from "./LoginPage";

interface LocationState {
  from: { pathname: string };
}

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { from } = (location.state || {
    from: { pathname: "/home" },
  }) as LocationState;

  const { isAuthorized, user } = useSelector(authSelector);
  const [username, setUsername] = useState("");

  const initialValues: LoginFormValues = {
    username: "",
    password: "",
  };

  const onSubmit = (values: LoginFormValues) => {
    setUsername(values.username);
    dispatch(clearError(null));
    dispatch(fetchUserLogin(values));
  };

  useEffect(() => {
    dispatch(fetchCheckOrRefreshToken());
  }, []);

  useEffect(() => {
    if (isAuthorized && user?.isTempPassword) {
      navigate("/set-password");

      return;
    }
    if (isAuthorized && user?.confirmed) {
      //history.push("/home");
      //  history.replace(from);

      navigate(from, { replace: true });
    }
  }, [
    isAuthorized,
    navigate,
    from,
    user?.confirmed,
    user?.isTempPassword,
    dispatch,
  ]);

  return (
    <MainWrapper hideFooter={true}>
      <BlockContainer>
        <div className={styles.loginBlock}>
          <ContentBox className={styles.contentBox}>
            <div className={styles.loginContent}>
              <h1>Dein Login</h1>

              <Formik {...{ initialValues, onSubmit }}>
                {({ errors, touched }) => (
                  <Form className={styles.formContainer} noValidate>
                    <InputField
                      key="username"
                      id="username"
                      name="username"
                      type="text"
                      placeholder="E-mail eingeben"
                      labelText="E-mail"
                      hideRequiredInLabel={true}
                      hasErrors={
                        errors.hasOwnProperty("username") &&
                        touched.hasOwnProperty("username")
                      }
                      validateHandler={validateRequiredField}
                      icon={EmailIcon}
                    />

                    <InputField
                      key="password"
                      id="password"
                      name="password"
                      type="password"
                      placeholder="Passwort"
                      labelText="Passwort"
                      hideRequiredInLabel={true}
                      hasErrors={
                        errors.hasOwnProperty("password") &&
                        touched.hasOwnProperty("password")
                      }
                      validateHandler={validateRequiredField}
                      icon={PasswordIcon}
                    />

                    <div className={styles.btnContainer}>
                      <Button type="submit" variant="secondary" text="Login" />

                      <div className={styles.resetPassword}>
                        <Link to="/forgot-password">Passwort vergessen?</Link>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </ContentBox>
        </div>
      </BlockContainer>

      <ConfirmationLoginWrapper username={username} />
      <ConfirmationCodeWrapper />
    </MainWrapper>
  );
};

export default Login;

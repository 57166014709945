import React from "react";
import { ReactComponent as CloseIcon } from "../assets/images/close-round-icon.svg";
import { ReactComponent as HelpIcon } from "../assets/images/info-icon.svg";
import Button from "../Button/Button";
import { ModalHeaderProps } from "./ModalHeader.d";
import styles from "./ModalHeader.module.css";

const ModalHeader: React.FC<ModalHeaderProps> = ({ title, closeHandler }) => {
  return (
    <div className={styles.modalHeader}>
      <HelpIcon className={styles.helpIcon} />

      <div className={`h3 ${styles.title}`}>{title}</div>

      {closeHandler && (
        <Button
          type="icon"
          icon={CloseIcon}
          className={styles.closeBtn}
          clickHandler={closeHandler}
        />
      )}
    </div>
  );
};

export default ModalHeader;

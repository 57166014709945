import React, { useState } from "react";
import useDisableBodyScroll from "../../../utils/useDisableBodyScroll";
import { ReactComponent as MenuIcon } from "../../assets/images/menu-icon.svg";
import { ReactComponent as SignIcon } from "../../assets/images/sign-icon.svg";
import LongButton from "../../LongButton/LongButton";
import { LinkItem, MobileNavProps } from "../MainHeader.d";

const MobileNav: React.FC<MobileNavProps> = ({
  links,
  userName,
  isAuthorized,
  styles,
  logoutHandler,
}) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  useDisableBodyScroll(isMenuOpened);

  return (
    <>
      {isMenuOpened ? (
        <div className={styles.navContainer}>
          <div className={styles.navText}>Einstellungen</div>
          {isAuthorized ? (
            <div className={styles.navBoldText}>
              Was möchtest du tun, {userName}?
            </div>
          ) : null}

          <nav className={styles.nav}>
            <ul className={styles.ul}>
              {isAuthorized && links
                ? links.map((link: LinkItem, i: number) => (
                    <li key={i + 1} className={styles.li}>
                      <LongButton
                        text={link.title}
                        href={link.href}
                        icon={link.icon}
                        leftIconClassName={styles.icon}
                      />
                    </li>
                  ))
                : null}

              <li className={styles.li}>
                {isAuthorized ? (
                  <LongButton
                    text="Logout"
                    icon={SignIcon}
                    leftIconClassName={`${styles.icon} ${styles.logoutIcon}`}
                    clickHandler={logoutHandler}
                  />
                ) : (
                  <LongButton
                    text="Login"
                    icon={SignIcon}
                    href="/login"
                    leftIconClassName={styles.icon}
                  />
                )}
              </li>
            </ul>
          </nav>
          <div
            className={styles.closeBtn}
            onClick={() => setIsMenuOpened(false)}
          >
            schließen
          </div>
        </div>
      ) : null}

      <div
        data-testid="menu-icon-container"
        className={styles.menuIconContainer}
        onClick={() => setIsMenuOpened(prev => !prev)}
      >
        <MenuIcon className={styles.menuIcon} />
      </div>
    </>
  );
};

export default MobileNav;

import styles from "./LoaderComponent.module.css";

const LoaderComponent = () => {
  return (
    <div data-testid="loader" className={styles.loaderContainer}>
      <div className={styles.loader}></div>
    </div>
  );
};

export default LoaderComponent;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APICall } from "../../utils/api";
import { CashbackState, SearchFilter } from "../reducers/cashbackSlice.d";

const apiVersion = "1.0.0";

const initialState: CashbackState = {
  auth_id: "",
  categories: [],
  status: "idle",
  accountBalance: 0,

  search: {
    merchantLocations: [],
    filter: {
      textSearch: "",
      idCategory: 0,
      itemsOnPage: 6,
      //sortBy: 'asc'
    },
  },
  user_id: null,
};

export const fetchCashbackLoginUser = createAsyncThunk(
  "cashback/LoginUser",
  async (_, { rejectWithValue }) => {
    return await APICall(
      "paymentservice.cashback",
      "customer-login",
      rejectWithValue
    );
  }
);

export const fetchCashbackCreateUser = createAsyncThunk(
  "cashback/CreateUser",
  async (_, { rejectWithValue }) => {
    return await APICall(
      "paymentservice.cashback",
      "customer-create",
      rejectWithValue,
      {
        ver: apiVersion,
      }
    );
  }
);

// export const fetchCashbackMerchantLocations = createAsyncThunk(
//   'cashback/MerchantLocations',
//   async (merchant_locations: Array<number>, { rejectWithValue }) => {
//     return await APICall('paymentservice.cashback', 'merchant-locations', rejectWithValue, merchant_locations);
//   }
// );

export const fetchCashbackPartnersCategoies = createAsyncThunk(
  "cashback/PartnersCategoies",
  async (_, { rejectWithValue }) => {
    return await APICall(
      "paymentservice.cashback",
      "get-categories",
      rejectWithValue,
      {
        ver: apiVersion,
      }
    );
  }
);

export const fetchCashbackMerchantLocations = createAsyncThunk(
  "cashback/MerchantLocations",
  async (filter: SearchFilter, { rejectWithValue }) => {
    return await APICall(
      "paymentservice.cashback",
      "merchant-locations",
      rejectWithValue,
      {
        ...filter,
        ver: apiVersion,
      }
    );
  }
);

// export const fetchCashbackMerchantShops = createAsyncThunk(
//   'cashback/MerchantShops',
//   async ({ filter }: { filter: SearchFilter }, { rejectWithValue }) => {

//     const params = {} as SearchParams;

//     if (filter.textSearch !== '') {
//       params.name = filter.textSearch;
//     }

//     if (filter.sortBy.includes('category')) {
//       //location_type: 1
//       //category: filter.categoryId
//     }
//     else {
//       params.sort_by_name = filter.sortBy;
//     }

//     console.log('fetchCashbackMerchantShops', params);

//     return await APICall('paymentservice.cashback', 'proxy', rejectWithValue, {
//       params: params,
//       method: 'GET',
//       apiPostfix: 'search.json',
//       idApi: 'api'
//     });
//   }
// );

export const fetchCashbackLogout = createAsyncThunk(
  "cashback/Logout",
  async (_, { rejectWithValue }) => {
    return await APICall(
      "paymentservice.cashback",
      "customer-logout",
      rejectWithValue
    );
  }
);

export const fetchCashbackTotalAmount = createAsyncThunk(
  "cashback/CashbackTotalAmount",
  async (_, { rejectWithValue }) => {
    return await APICall(
      "paymentservice.cashback",
      "get-cashback-balance",
      rejectWithValue,
      {
        ver: apiVersion,
      }
    );
  }
);

export const cashbackSlice = createSlice({
  name: "cashback",
  initialState,
  reducers: {
    itemsOnPageUpdate: (state, action) => {
      state.search.filter.itemsOnPage = action.payload;
    },
    searchTextUpdate: (state, action) => {
      state.search.filter.textSearch = action.payload;
    },
    searchCategoryUpdate: (state, action) => {
      state.search.filter.idCategory = action.payload;
    },
    sortByUpdate: (state, action) => {
      //state.search.filter.sortBy = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(
      fetchCashbackLoginUser.fulfilled as any,
      (state, action) => {
        state.user_id = action.payload.token.user_id;
        state.auth_id = action.payload.id;
        state.status = "succeded";
      }
    );

    builder.addCase(fetchCashbackLoginUser.rejected as any, (state, action) => {
      console.log(action.payload ? action.payload : action.error);
      state.status = "error";
    });

    builder.addCase(
      fetchCashbackCreateUser.fulfilled as any,
      (state, action) => {
        console.log(action);
        state.auth_id = action.payload.id;
        state.status = "succeded";
      }
    );

    builder.addCase(
      fetchCashbackCreateUser.rejected as any,
      (state, action) => {
        console.log(action.payload ? action.payload : action.error);
        state.status = "error";
      }
    );

    builder.addCase(fetchCashbackLogout.fulfilled as any, (state, action) => {
      state.status = "logouted";
      state.user_id = null;
      state.auth_id = "";
    });

    builder.addCase(
      fetchCashbackPartnersCategoies.fulfilled as any,
      (state, action) => {
        state.categories = action.payload;
      }
    );

    builder.addCase(
      fetchCashbackMerchantLocations.fulfilled as any,
      (state, action) => {
        state.search.merchantLocations = action.payload;
      }
    );

    builder.addCase(
      fetchCashbackMerchantLocations.rejected as any,
      (state, action) => {
        if (action?.payload?.message)
          console.error(
            "fetchCashbackMerchantLocations",
            action.payload.message
          );
        else
          console.error(
            "fetchCashbackMerchantLocations",
            "Etwas schiefgegangen"
          );
      }
    );

    builder.addCase(
      fetchCashbackTotalAmount.fulfilled as any,
      (state, action) => {
        console.log("fetchCashbackTotalAmount", action);
        state.accountBalance = action.payload.account_balance;
      }
    );

    builder.addCase(
      fetchCashbackTotalAmount.rejected as any,
      (state, action) => {
        if (action?.payload?.message)
          console.error("fetchCashbackTotalAmount", action.payload.message);
        else console.error("fetchCashbackTotalAmount", "Etwas schiefgegangen");
      }
    );
  },
});

export const {
  itemsOnPageUpdate,
  searchTextUpdate,
  sortByUpdate,
  searchCategoryUpdate,
} = cashbackSlice.actions;

export default cashbackSlice.reducer;

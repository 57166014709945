import styles from "./CookiesModal.module.css";
import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import { getModalRootElement } from "../../utils";

const modalRoot = getModalRootElement();

const CookiesModal: React.FC<{ show: boolean; children: React.ReactNode }> = ({
  show,
  children,
}) => {
  const el = useRef(document.createElement("div"));
  const nodeRef = React.useRef(null);

  useEffect(() => {
    // Use this in case CRA throws an error about react-hooks/exhaustive-deps
    const current = el.current;

    // We assume `modalRoot` exists with '!'
    modalRoot!.appendChild(current);
    return () => void modalRoot!.removeChild(current);
  }, []);

  return ReactDOM.createPortal(
    <CSSTransition
      in={show}
      unmountOnExit
      timeout={{ enter: 0, exit: 300 }}
      nodeRef={nodeRef}
      classNames={{
        //enterActive: styles.MyClassEnterActive,
        enterDone: styles.enterDone,
        //exitActive: styles.MyClassExit,
        exitDone: styles.exit,
      }}
    >
      <div className={styles.modal} ref={nodeRef} data-testid={"modalWrapper"}>
        <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
          <div className={styles.modalBody}>{children}</div>

          <div className={styles.modalFooter}></div>
        </div>
      </div>
    </CSSTransition>,
    el.current
  );
};

export default CookiesModal;

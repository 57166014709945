import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import MainWrapper from "../../containers/MainWrapper/MainWrapper";
import BlockContainer from "../../components/BlockContainer/BlockContainer";
import styles from "./NutzungsbedingungenPage.module.css";

const NutzungsbedingungenPage = () => {
  return (
    <MainWrapper>
      <BlockContainer>
        <div className={styles.block}>
          <p>Allgemeine Schutzbrief Service GmbH</p>

          <h1 className={styles.h1}>Allgemeine Geschäftsbedingungen</h1>

          <h2 className={styles.h2}>Inhaltsübersicht</h2>

          <Accordion allowZeroExpanded={true}>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  1. Allgemeines, Vertragsschluss, Vertragsbeziehungen,
                  Vertragspartner
                </AccordionItemButton>
              </AccordionItemHeading>

              <AccordionItemPanel>
                <p>
                  1.1. Die Allgemeine Schutzbrief Service GmbH (nachfolgend
                  „ASSG“) ist Betreiberin des Online-Portals „Cash & Protect
                  Portal“ sowie Kooperationspartner diverser Webseiten Dritter
                  (nachfolgend „Kooperationspartner“), die die
                  White-Label-Technologie oder vorgegebene Inhalte der ASSG
                  verwenden. Auf dem Cash & Protect Portal oder auf den
                  Internetauftritten Dritter können Privatpersonen, die an dem
                  Abschluss von Schutzbriefen, Serviceleistungen oder sonstigen
                  online angebotenen Finanzdienstleistungen interessiert sind
                  (nachfolgend „Nutzer“), solche Schutzbriefe oder
                  Serviceleistungen verschiedener Anbieter nach ihrem Bedarf
                  konfigurieren und bestellen. Dabei werden dem Nutzer zu den
                  ausgewählten Schutzbrief- und Serviceleistungen Konditionen-
                  und Vertragsdetails zur Verfügung gestellt.
                </p>
                <p>
                  1.2. Die Nutzer können sich über die ASSG oder über ihre
                  Kooperationspartner Angebote für den Beitritt zu zwischen der
                  ASSG mit den jeweiligen Versicherungsgesellschaften
                  abgeschlossenen Gruppenversicherungsverträgen anzeigen lassen,
                  konfigurieren und online annehmen (bestellen). Ein
                  Vertragsverhältnis mit der ASSG kommt erst mit der
                  Übermittlung der konkreten Bestellung auf Grundlage der
                  tatsächlich übermittelten Daten des Nutzers zustande. Über die
                  Vermittlung von Gruppenversicherungs-Beitritten oder
                  Serviceleistungen hinausgehende Verpflichtungen übernimmt die
                  ASSG nicht, insbesondere bietet die ASSG selbst keine eigenen
                  Versicherungs- und/oder Finanzprodukte an.
                </p>
                <p>
                  1.3. Die Bestellung eines Schutzbriefs oder einer
                  Serviceleistung verpflichtet die ASSG noch nicht zu einer
                  Aufnahme in den jeweiligen Gruppenversicherungs- oder
                  Rahmenvertrag. ASSG verpflichtet sich lediglich die Bestellung
                  des Nutzers unverzüglich an die Servicepartner weiterzuleiten
                  und den Nutzer unverzüglich über die Annahme oder die
                  Ablehnung des Beitritts durch den Servicepartner zu
                  informieren. Eine weitere Tätigkeitsverpflichtung der ASSG
                  entsteht erst nach Erstellung und Übermittlung einer
                  Vertragsbestätigung bzw. die Übersendung von entsprechenden
                  Gruppenversicherungs-/ Rahmenvertrags-Beitrittsdokumenten
                  durch die ASSG.
                </p>
                <p>
                  1.4. Bei der Bearbeitung der Bestellung können nur die vom
                  Nutzer übermittelten Daten und Informationen zugrunde gelegt
                  werden. Deshalb ist der Nutzer verpflichtet, die Daten und
                  Informationen wahrheitsgemäß, fehlerfrei und vollständig zu
                  geben. Sollte sich im Nachhinein herausstellen, dass dies
                  nicht der Fall war, ist der Nutzer verpflichtet der ASSG den
                  daraus möglicherweise entstehenden Schaden zu ersetzen.
                </p>
                <p>
                  1.5. Die ASSG ist nicht verpflichtet, sich fortlaufend über
                  eventuelle Änderungen der Verhältnisse des Nutzers zu
                  informieren. Entsprechendes gilt für die Unterrichtung über
                  alle Vorgänge und Umstände, die für die Aufrechterhaltung des
                  Schutzbriefes oder der Serviceleistungen von Bedeutung sein
                  können. Vielmehr ist der Nutzer verpflichtet, alle Änderungen
                  der im Sinne von Ziffer 1.4. mitgeteilten Daten und
                  Informationen unverzüglich an ASSG über das Portal
                  mitzuteilen.
                </p>
                <p>
                  1.6. Ein Versicherungs-/Service-/Finanzdienstleistungsvertrag
                  kommt ausschließlich auf Basis des zwischen der ASSG und dem
                  mit dem von ihr ausgewählten Anbieter (z.B. Versicherungs-
                  oder Dienstleistungsunternehmen) abgeschlossenen
                  Gruppenversicherungs- bzw. Rahmenvertrag zustande. Der Inhalt
                  dieses Vertragsverhältnisses richtet sich allein nach den
                  Bedingungen des jeweiligen Gruppenversicherungs- oder
                  Dienstleistungs-Vertragspartners der ASSG und wird dem Nutzer
                  bei Vertragsabschluss in geeigneter und gesetzeskonformer Art
                  und Weise mitgeteilt.
                </p>
                <p>
                  1.7. Die Nutzung des Cash & Protect Portals ist für den Nutzer
                  kostenlos. Der Nutzer hat der ASSG lediglich die abgefragten
                  Daten und Informationen für die Vermittlung zur Verfügung
                  gestellt.
                </p>
                <p>
                  1.8. Von diesen Nutzungsbedingungen abweichende oder diese
                  Nutzungsbedingungen ergänzende Geschäftsbedingungen der Nutzer
                  finden keine Anwendung.
                </p>
                <p>
                  1.9. Versicherungsschutz oder die Erbringung der
                  Finanzdienstleistung entstehen nicht mit Abschluss der
                  Bestellung, sondern erst dann, wenn ein Beitritt zu einem
                  Gruppenversicherungs- oder Dienstleistungsvertrag
                  rechtswirksam zustande gekommen ist.
                </p>
                <p>
                  1.10. Der Vertragstext wird von der ASSG gespeichert. Die
                  Bestelldaten werden dem Nutzer gesondert per E-Mail
                  zugesendet. Die für den Vertragsschluss zur Verfügung stehende
                  Sprache ist Deutsch.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  2. Stellung der Allgemeinen Schutzbrief Service
                </AccordionItemButton>
              </AccordionItemHeading>

              <AccordionItemPanel>
                <p>
                  Die ASSG ist Gruppenversicherungsnehmer für verschiedene
                  Schutzbriefprodukte und Vertragspartner von
                  Service-Rahmenverträgen, die sie mit unterschiedlichen
                  Versicherungs-, Dienstleistungs- und
                  Finanzdienstleistungsanbieter abgeschlossen hat. Sie
                  verschafft ihren Nutzern Versicherungsschutz oder Anspruch auf
                  Serviceleistungen im Rahmen der von ihr selbst abgeschlossenen
                  Gruppenversicherungs- und Rahmenverträge. Die
                  Schutzbriefpakete, die von der ASSG entweder unter eigenem
                  Namen oder unter der Marke des Kooperationspartners angeboten
                  werden, stellen variabel zusammenstellbare Bündel von
                  Schutzbrief- oder Serviceleistungen eines oder mehrerer
                  Vertragspartner dar. Die ASSG fungiert bei der Vermittlung von
                  Versicherungen als Versicherungsvertreter nach §34d GewO und
                  verfügt über eine entsprechende Vermittlererlaubnis
                  [www.vermittlerregister.info D-YFRX-SB48N-34].
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  3. Nutzung nur durch Verbraucher
                </AccordionItemButton>
              </AccordionItemHeading>

              <AccordionItemPanel>
                <p>
                  3.1. Berechtigt zur Nutzung des Cash & Protect Portals und die
                  durch ASSG zur Verfügung gestellten Technologien und Inhalte
                  sind ausschließlich Verbraucher.
                </p>
                <p>
                  3.2. Die kostenfreie Nutzung der Leistungen des Cash & Protect
                  Portals ist nur zur Deckung des eigenen Bedarfs des Nutzers
                  gestattet. Eine Nutzung zu gewerblichen Zwecken oder im Rahmen
                  einer selbstständigen beruflichen Tätigkeit im Rahmen der
                  Vermittlung für Dritte ist untersagt.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  4. Allgemeine Leistungen der Allgemeinen Schutzbrief Service
                </AccordionItemButton>
              </AccordionItemHeading>

              <AccordionItemPanel>
                <p>
                  4.1. Die ASSG stellt mit dem Cash & Protect Portal und mit
                  Hilfe von Kooperationspartnern Webseiten zur Anfrage von
                  Versicherungs- und Finanzdienstleistungsprodukten zur
                  Verfügung. Der Nutzer hat die Möglichkeit, über die
                  entsprechenden, von der ASSG zur Verfügung gestellten
                  Eingabemasken, durch Eingabe der erforderlichen Daten online
                  geeigneten Versicherungs- und Finanzdienstleistungsprodukten
                  zu suchen. Die gefundenen Produkte werden dem Nutzer nach
                  Eingabe der Daten auf einer Ergebnisseite angezeigt. Diese
                  Ergebnisseite beinhaltet insbesondere die Eckdaten der
                  angezeigten Produkte, sowie einen Link zu den weiteren
                  Detailinformationen, die für einen Vergleich der Angebote
                  erforderlich sind.
                </p>
                <p>
                  4.2. Diese Suche erstreckt sich nicht auf alle am Markt
                  existierenden Produkte, sondern nur auf ausgewählte Produkte
                  der mit der ASSG kooperierenden Anbieter. Das Suchergebnis
                  stellt auch kein verbindliches Angebot des jeweiligen
                  Anbieters und/oder der ASSG dar; es handelt sich stattdessen
                  stets um unverbindliche Informationen im Hinblick auf
                  existierende Versicherungs-, Dienstleistungs- und sonstige
                  Finanzdienstleistungsprodukte der mit der ASSG kooperierenden
                  Anbieter.
                </p>
                <p>
                  4.3. Bestellt der Nutzer ein bestimmtes von ihm selbst
                  ausgewähltes und zusammengestelltes Schutzbrief- oder
                  Serviceprodukt, übermittelt die ASSG dem entsprechenden
                  Anbieter die Beitrittserklärung des Nutzers samt aller für den
                  jeweiligen Versicherungs- oder Serviceumfang erforderlichen
                  Daten des Nutzers.
                </p>
                <p>
                  4.4. Die ASSG stellt dem Nutzer vor der Bestellung eines
                  Schutzbrief- oder Serviceprodukts die erforderlichen
                  Informationen (insbesondere die Vertragsbedingungen und
                  sonstige Pflichtinformationen) zur Verfügung.
                </p>
                <p>
                  4.5. Dem Nutzer entstehen für die Vermittlungstätigkeit der
                  ASSG keine Kosten. Die Vergütung für die Vermittlungs- und
                  Verwaltungstätigkeit der ASSG sind im Preis des jeweiligen
                  Schutzbriefs oder Serviceprodukts inkludiert.
                </p>
                <p>
                  4.6. Der Anspruch auf Nutzung des Cash & Protect Portals oder
                  der Webseiten von Kooperationspartnern der ASSG besteht nur im
                  Rahmen des aktuellen Stands der Technik. Die ASSG behält sich
                  vor, den Zugang zum Cash & Protect Portal oder Webseiten von
                  Kooperationspartnern der ASSG zeitweilig zu beschränken, wenn
                  dies im Hinblick auf Kapazitätsgrenzen, die Sicherheit oder
                  Integrität der Server oder zur Durchführung technischer
                  Maßnahmen erforderlich ist und dies der ordnungsgemäßen oder
                  verbesserten Erbringung der Leistungen dient (z.B. bei
                  Wartungsarbeiten).
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  5. Besondere Regelungen für Versicherungen
                </AccordionItemButton>
              </AccordionItemHeading>

              <AccordionItemPanel>
                <p>
                  5.1. Die ASSG übernimmt nach §61 VVG die Dokumentation der
                  Beratung und lässt diese Beratungsdokumentation dem Nutzer vor
                  Bestellung zukommen. Mit der Bestellung bestätigt der Nutzer
                  den Erhalt sowie Vollständigkeit und Richtigkeit der
                  Beratungsdokumentation. Die Beratungsdokumentation ist
                  Grundlage des Beitrittswunschs des Nutzers.
                </p>
                <p>
                  5.2. Die ASSG vermittelt nicht alle am Markt existierenden
                  Versicherungsprodukte, sondern nur ausgewählte
                  Versicherungsprodukte von mit der ASSG kooperierenden
                  Versicherungsunternehmen. Die ASSG berücksichtigt im Rahmen
                  ihrer Tätigkeit nur die unter Rechtsaufsicht der Bundesanstalt
                  für Finanzdienstleistungsaufsicht (BaFin) fallenden
                  Versicherer, die eine Niederlassung in der Bundesrepublik
                  Deutschland unterhalten und die gemäß § 7 VVG i.V.m. der VVG
                  InfoV gesetzlich vorgeschriebene Informationen in deutscher
                  Sprache anbieten.
                </p>
                <p>
                  5.3. Die ASSG bietet an, Nutzer bei Meldungen von Schäden zu
                  unterstützen. Alternativ können Schadensmeldungen durch den
                  Nutzer, auch ohne die Unterstützung durch die ASSG, direkt an
                  das jeweilige Versicherungsunternehmen gerichtet werden.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  6. Pflichten der Nutzer, Haftung
                </AccordionItemButton>
              </AccordionItemHeading>

              <AccordionItemPanel>
                <p>
                  6.1. Für die Vollständigkeit und Richtigkeit sämtlicher vom
                  Nutzer in die Eingabemaske eingegebenen oder sonst
                  übermittelten Daten ist ausschließlich der Nutzer selbst
                  verantwortlich. Der Nutzer ist zur vollständigen Erteilung
                  wahrheitsgemäßer Angaben verpflichtet, soweit es zur
                  ordnungsgemäßen Erledigung der Beauftragung erforderlich ist.
                  Dies gilt auch für Änderungen seiner Risiko- oder
                  Rechtsverhältnisse oder der zugrunde liegenden Tatsachen nach
                  Vertragsschluss, die für den jeweiligen Versicherungsschutz
                  relevant sein könnten. Unterlässt der Nutzer die unverzügliche
                  Information, besteht eventuell kein oder kein vollständiger
                  Anspruch aus dem Versicherungsvertrag. Verletzt der Nutzer
                  diese Mitwirkungs- und Mitteilungspflicht, so ist die ASSG von
                  jedweder Haftung befreit; gleiches gilt für den Fall, dass der
                  Nutzer mit dem Versicherer direkt korrespondiert und/oder
                  verhandelt.
                </p>
                <p>
                  6.2. Übermittelt die ASSG dem Nutzer nach Bestellung die
                  Beitritts- und Vertragsunterlagen, ist der Nutzer
                  verpflichtet, diese auf sachliche Richtig- und Vollständigkeit
                  zu überprüfen und auf etwaige Fehler, Abweichungen von seiner
                  Bestellung oder Unrichtigkeiten unverzüglich hinzuweisen.
                  Sollte der Nutzer die ASSG nicht auf einen Mangel der
                  sachlichen Richtig- oder Vollständigkeit hinweisen, können
                  sich daraus rechtliche Nachteile für den Kunden ergeben.
                </p>
                <p>
                  6.3. Die aus den Verträgen unmittelbar erwachsenden
                  Verpflichtungen, wie die Prämienzahlungen, Anzeigepflichten
                  und die Einhaltung vertraglicher Obliegenheiten etc. sind vom
                  Nutzer eigenverantwortlich zu erfüllen.
                </p>
                <p>
                  6.4. Der Nutzer verpflichtet sich, elektronische Angriffe
                  jeglicher Art auf das Cash & Protect Portal oder die Webseiten
                  der Kooperationspartner der ASSG zu unterlassen. Als
                  elektronische Angriffe gelten insbesondere Versuche, die
                  Sicherheitsmechanismen des Cash & Protect Portals oder der
                  Webseiten der Kooperationspartner der ASSG zu überwinden, zu
                  umgehen, oder auf sonstige Art außer Kraft zu setzen, der
                  Einsatz von Computerprogrammen zum automatischen Auslesen von
                  Daten, das Anwenden und/oder Verbreiten von Viren, Würmern,
                  Trojanern, Brute Force Attacken, Spam oder die Verwendung von
                  sonstigen Links, Programmen oder Verfahren, die die ASSG, das
                  Cash & Protect Portal oder die Webseiten der
                  Kooperationspartner der ASSG schädigen können.
                </p>
                <p>
                  6.5. Nutzer dürfen Suchanfragen in den Datenbanken der ASSG
                  oder den Webseiten von Kooperationspartnern der ASSG
                  ausschließlich mittels der auf dem Cash & Protect Portal oder
                  den auf den Webseiten der Kooperationspartner der ASSG
                  angebotenen Suchmasken stellen. Nicht statthaft ist die Suche
                  unter Umgehung der Suchmasken, insbesondere durch Verwendung
                  von Suchsoftware, die auf die Daten des Cash & Protect Portals
                  oder der Webseiten der Kooperationspartner der ASSG zugreift.
                  Zuwiderhandlungen werden unter anderem unter dem Gesichtspunkt
                  des Eingriffs in den eingerichteten und ausgeübten
                  Gewerbebetrieb zivilrechtlich verfolgt und können unter dem
                  Gesichtspunkt des unerlaubten Eingriffs in verwandte
                  Schutzrechte nach den §§ 108 ff. des Urhebergesetzes
                  strafrechtliche Konsequenzen haben.
                </p>
                <p>
                  6.6. Bei erheblichen Verstößen gegen die dem Nutzer
                  obliegenden Pflichten sowie bei begründeten erheblichen
                  Verdachtsmomenten für eine erhebliche Pflichtverletzung ist
                  die ASSG berechtigt, das Cash & Protect Portal oder die
                  Webseiten der Kooperationspartner für die Inanspruchnahme
                  durch den Nutzer zu sperren, insbesondere bei Übermittlung von
                  vorsätzlich falschen oder unwahren persönlichen Daten durch
                  den Nutzer. Die ASSG informiert den Nutzer über den Grund der
                  Sperrung per E-Mail. Die Sperre besteht fort, bis die
                  Pflichtverletzung behoben ist und/oder der Nutzer die
                  Unterlassung zukünftiger Pflichtverletzungen glaubhaft gemacht
                  hat.
                </p>
                <p>
                  6.7. Der Nutzer haftet bei der von ihm zu vertretenden
                  Verletzung von Rechten Dritter gegenüber diesen selbst und
                  unmittelbar. Bei begründeten Ansprüchen Dritter aufgrund einer
                  solchen Verletzung ist der Nutzer verpflichtet, die ASSG
                  vollumfänglich freizustellen, sofern er nicht nachweist, dass
                  er die schadensursächliche Pflichtverletzung nicht zu
                  vertreten hat.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>7. Zustellung</AccordionItemButton>
              </AccordionItemHeading>

              <AccordionItemPanel>
                <p>
                  7.1. Als Ort der Zustellung des gesamten Schriftverkehrs mit
                  dem Nutzer gilt die Postanschrift, die bei der Bestellung
                  angegeben wurde. Teilt der Nutzer einen Wechsel seiner
                  Anschrift nicht unverzüglich mit, haftet der Nutzer der ASSG
                  für den daraus ggf. entstehenden Schaden oder Mehraufwand.
                </p>
                <p>
                  7.2. Die E-Mail-Adresse des Nutzers, die bei der Registrierung
                  im bzw. Nutzung des Onlineportals „Cash & Protect“ angegeben
                  wurde, wird zur Zustellung des Online-Schriftverkehrs
                  verwendet. Der Nutzer verpflichtet sich diese E-Mail-Adresse
                  zu erhalten oder einen Wechsel unverzüglich mitzuteilen. Teilt
                  der Nutzer einen Wechsel seiner E-Mail-Adresse nicht
                  unverzüglich mit, haftet der Nutzer der ASSG für den daraus
                  ggf. entstehenden Schaden oder Mehraufwand.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  8. Haftung der Allgemeinen Schutzbrief Service
                </AccordionItemButton>
              </AccordionItemHeading>

              <AccordionItemPanel>
                <p>
                  8.1. Die Haftung der ASSG, gleich aus welchem Rechtsgrund,
                  einschließlich Verzug und unerlaubte Handlung, ist wie folgt
                  beschränkt oder – soweit gesetzlich zulässig - ausgeschlossen:
                  8.1.1. Die ASSG haftet für etwaige Schäden nur, wenn a) die
                  ASSG schuldhaft (d.h. mindestens fahrlässig) eine wesentliche
                  Vertragspflicht verletzt, wobei der Begriff der „wesentlichen
                  Vertragspflichten“ solche Pflichten bezeichnet, deren
                  Erfüllung die ordnungsgemäße Durchführung des Vertrags
                  überhaupt erst ermöglicht, auf deren Einhaltung der
                  Vertragspartner regelmäßig vertrauen darf und deren Verletzung
                  die Erreichung des Vertragszwecks gefährdet, b) der Schaden
                  durch grobe Fahrlässigkeit oder Vorsatz der ASSG verursacht
                  wurde, oder c) die ASSG eine Garantie übernommen hat.
                </p>
                <p>
                  8.1.2. Die Haftung der ASSG ist auf den typischen,
                  vorhersehbaren Schaden beschränkt, wenn a) die ASSG
                  wesentliche Vertragspflichten schuldhaft, aber nicht grob
                  fahrlässig oder vorsätzlich verletzt hat, oder b) Mitarbeiter
                  oder Beauftragte der ASSG, die nicht Organe oder leitende
                  Angestellte sind, sonstige Pflichten grob fahrlässig oder
                  vorsätzlich verletzt haben.
                </p>
                <p>
                  8.2. Im Falle einer Haftung für einfache Fahrlässigkeit, d.h.
                  bei einfach fahrlässiger Verletzung einer wesentlichen
                  Vertragspflicht, ist die Ersatzpflicht der ASSG auf einen
                  Betrag von 500.000 EUR je Schadensfall beschränkt.
                </p>
                <p>
                  8.3. Die vorstehenden Haftungsausschlüsse und -beschränkungen
                  gelten in gleichem Umfang zugunsten der Organe, gesetzlichen
                  Vertreter, Angestellten und sonstigen Erfüllungsgehilfen der
                  ASSG.
                </p>
                <p>
                  8.4. Die Haftung der ASSG nach dem deutschen
                  Produkthaftungsgesetz, für vorsätzliches Handeln, für die
                  Verletzung von Leben, Körper und Gesundheit, für arglistiges
                  Verschweigen sowie die Haftung aus § 63 VVG wegen der
                  Verletzung einer Pflicht nach den §§ 60, 61 VVG bleiben von
                  den vorstehenden Haftungsausschlüssen und -beschränkungen
                  unberührt.
                </p>
                <p>
                  8.5. Schadensersatzansprüche des Nutzers aus diesem Vertrag
                  verjähren spätestens nach drei Jahren. Die Verjährung beginnt
                  zum Schluss des Jahres, in welchem der Nutzer Kenntnis von dem
                  Schaden und der Person des Ersatzpflichtigen erlangt hat oder
                  ohne grobe Fahrlässigkeit hätte erlangen müssen.
                </p>
                <p>
                  8.6. Für Fehlberatungen oder nicht geeignete
                  Beratungsergebnisse wegen nicht vollständiger, unverzüglicher
                  oder wahrheitsgemäßer Information des Nutzers ist die Haftung
                  für Vermögensschäden ausgeschlossen, es sei denn, der Nutzer
                  weist ASSG nach, dass ASSG vorsätzlich oder grob fahrlässig
                  gehandelt hat.
                </p>
                <p>
                  8.7. Für die Richtigkeit von EDV-Berechnungen, für
                  Produktangaben oder Versicherungsbedingungen der Versicherer
                  oder sonstiger für den Nutzer tätiger Dritter haftet die ASSG
                  nicht.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  9. Abtretungsausschluss sowie sonstige Ausschlüsse
                </AccordionItemButton>
              </AccordionItemHeading>

              <AccordionItemPanel>
                <p>
                  9.1. Die Abtretung und sonstige Übertragung oder Belastung von
                  sich aus dem Vertragsverhältnis ergebenden Forderungen des
                  Nutzers gegen die ASSG, wie auch deren Pfändung ist
                  ausgeschlossen.
                </p>
                <p>
                  9.2. Die Aufrechnung und die Geltendmachung eines
                  Zurückbehaltungsrechts, sowie die Geltendmachung sonstiger
                  Einreden durch den Nutzer gegen eine Forderung der ASSG ist
                  ausgeschlossen, es sei denn, die Forderung des Nutzers ist
                  unbestritten oder rechtskräftig festgestellt
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  10. Änderungsvorbehalt der allgemeinen Geschäftsbedingungen
                </AccordionItemButton>
              </AccordionItemHeading>

              <AccordionItemPanel>
                <p>
                  Die ASSG behält sich vor, seine Allgemeinen
                  Geschäftsbedingungen unter Bezug auf geänderte politische,
                  rechtliche und marktwirtschaftliche Rahmenbedingungen zu
                  ändern. Dazu übersendet die ASSG dem Nutzer an seine bekannte
                  Adresse ein Exemplar der neuen AGB unter Hervorhebung der
                  Änderungen gegenüber den bisherigen AGB verbunden mit dem
                  Hinweis, dass der Nutzer die Möglichkeit hat, innerhalb von
                  vier Wochen nach Zugang der neuen AGB deren Wirksamwerden zu
                  widersprechen. Widerspricht der Nutzer nicht innerhalb der
                  Frist, so gelten die neuen AGB für das Vertragsverhältnis mit
                  der ASSG.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>11. Datenschutz</AccordionItemButton>
              </AccordionItemHeading>

              <AccordionItemPanel>
                <p>
                  Bei der Nutzung des Cash & Protect Portals oder der Webseiten
                  der Kooperationspartner der ASSG werden von der ASSG
                  personenbezogene Daten der Nutzer erhoben und verarbeitet. Die
                  Datenverarbeitung erfolgt nach Maßgabe der
                  Datenschutzbestimmungen der ASSG oder der Kooperationspartner
                  von der ASSG, die der Nutzer mit den entsprechenden Angeboten
                  gesondert erhalten hat.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  12. Schlussbestimmungen
                </AccordionItemButton>
              </AccordionItemHeading>

              <AccordionItemPanel>
                <p>
                  12.1. Auf Verträge zwischen ASSG und dem Nutzer findet das
                  Recht der Bundesrepublik Deutschland unter Ausschluss des
                  UN-Kaufrechts Anwendung. Erfüllungsort ist Essen.
                </p>
                <p>
                  12.2. Zum Zwecke der Vertragserfüllung und Ausübung der aus
                  diesem Vertrag erwachsenden Rechte kann sich die ASSG
                  verbundener Unternehmen bedienen.
                </p>
                <p>
                  12.3. Sollten einzelne Bestimmungen dieser Nutzungsbedingungen
                  ganz oder teilweise nichtig oder unwirksam sein oder werden,
                  so wird dadurch die Wirksamkeit der übrigen Bestimmungen nicht
                  berührt. Die Parteien verpflichten sich, anstelle der
                  nichtigen oder unwirksamen Bestimmung eine dieser Bestimmung
                  rechtlich und wirtschaftlich am nächsten kommende gültige und
                  wirksame Regelung zu treffen, die sie vernünftigerweise
                  vereinbart hätten, wenn sie beim Abschluss dieser
                  Nutzungsbedingungen die Nichtigkeit oder Unwirksamkeit der
                  betreffenden Regelung bedacht hätten.
                </p>
                <p>
                  12.4. Änderungen und Ergänzungen dieser Nutzungsbedingungen
                  bedürfen zu ihrer Wirksamkeit der Schriftform. Dies gilt auch
                  für eine Änderung dieser Schriftformklausel. Die vorstehenden
                  Bedingungen geben den Vertragsinhalt vollständig wieder.
                  Sonstige Nebenabreden bestehen nicht, es sei denn, sie wurden
                  schriftlich bestätigt.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>

          <p className={styles.textRight}>Stand: 11/2021</p>
        </div>
      </BlockContainer>
    </MainWrapper>
  );
};

export default NutzungsbedingungenPage;

import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as CloseIcon } from "../../images/close-round-icon.svg";
import { ReactComponent as SearchIcon } from "../../images/search-icon.svg";
import { FaqItem } from "../../redux/reducers/faqSlice.d";
import RichTextEditorHtml from "../RichTextEditorHtml/RichTextEditorHtml";
import { SearchFaqBarProps } from "./SearchFaqBar.d";
import styles from "./SearchFaqBar.module.css";

const SearchFaqBar: React.FC<SearchFaqBarProps> = ({
  results,
  keyword,
  changeHandler,
}) => {
  const cancelSearch = () => {
    changeHandler("");
  };

  const renderResults =
    results.length > 0 &&
    results.map(
      (faqItem: FaqItem) =>
        faqItem.articles.length > 0 &&
        faqItem.articles.map((article: any, i: number) => (
          <SearchPreview
            key={faqItem._id + i}
            category={faqItem.category}
            article={article}
          />
        ))
    );

  return (
    <div className={styles.auto}>
      <div className={styles.searchBarContainer}>
        <input
          className={styles.searchBar}
          placeholder="Bitte Stichwort eingeben"
          value={keyword}
          onChange={(e: any) => changeHandler(e.target.value)}
        />
        <span className={styles.rightIconContainer}>
          {keyword.length > 0 ? (
            <button
              data-testid="closeSearchBtn"
              onClick={() => cancelSearch()}
              className={styles.cancelBtn}
            >
              <CloseIcon className={styles.rightIcon} />
            </button>
          ) : (
            <SearchIcon className={styles.rightIcon} />
          )}
        </span>
      </div>

      {keyword && results.length > 0 ? (
        <div className={styles.searchResultsWrapper}>
          <div className={styles.searchResults}>{renderResults}</div>
        </div>
      ) : keyword.trim().length > 2 ? (
        <div className={styles.searchResultsWrapper}>
          <div className={styles.searchResults}>
            <div className={styles.searchPreview}>
              <div className={styles.question}>Keine Ergebnisse</div>
              <div className={styles.answer}>
                Bitte prufe deine Rechtschreibung oder verwende ein anderes
                Stichwort.
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

const SearchPreview = ({
  category,
  article,
}: {
  category: string;
  article: any;
}) => {
  return (
    <Link className={styles.aContainer} to={`/faq/question/${article._id}`}>
      <div className={styles.searchPreview}>
        <div className={styles.category}>{category}</div>
        <div className={styles.question}>{article.question}</div>
        <div className={styles.answer}>
          <RichTextEditorHtml
            removeTags={true}
            maxStrLength={100}
            editorValue={article.answer}
          />
        </div>
      </div>
    </Link>
  );
};

export default SearchFaqBar;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  FORMULA_MONTH_TAB,
  getFormulaItem,
  getTarifItemById,
  sortFormulaMonthes,
} from "../../containers/Calculator/calculatorHelper";
import { numberToDECurrency } from "../../utils";
import { API_SERVER } from "../../variables_config";
import { ReactComponent as HelpIcon } from "../assets/images/info-icon.svg";
import Button from "../Button/Button";
import ContentBox from "../ContentBox/ContentBox";
import Radiobox from "../Radiobox/Radiobox";
import RangeNumbersSimple from "../RangeNumbersSimple/RangeNumbersSimple";
import RulerSlider from "../RulerSlider/RulerSlider";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import { CalcProductDefaultProps } from "./CalcProductDefault.d";
import styles from "./CalcProductDefault.module.css";
import { calcSelector } from "../../redux/selectors";
import { useSelector } from "react-redux";

const CalcProductDefault: React.FC<CalcProductDefaultProps> = ({
  product,
  paymentTitle,
  setModalContent,
  changeServiceIsActive,
  changeServiceValueToCalc,
  saveMandatoryDataRecalc,
  setMandatoryModal,
}) => {
  const formulaItemMonthTab = useMemo(
    () => getFormulaItem(product.mandatoryDataRequest || [], FORMULA_MONTH_TAB),
    [product.mandatoryDataRequest]
  );
  const [scrollStyle, setScrollStyle] = useState("");

  const { isCalculating } = useSelector(calcSelector);
  useEffect(() => {
    if (!isCalculating) {
      setScrollStyle("");
    }
  }, [isCalculating]);

  const tarifDataItem = useMemo(
    () => getTarifItemById(product.mandatoryData, formulaItemMonthTab?.idParam),
    [product.mandatoryData, formulaItemMonthTab]
  );

  const onRadioMonthChange = useCallback(
    (radioValue: string) => {
      if (formulaItemMonthTab?.idParam) {
        saveMandatoryDataRecalc({
          idProduct: product.idProduct,
          isActive: product.isActive,
          valueToCalculate: product.valueToCalculate,
          mandatoryDataRequest: product.mandatoryDataRequest,
          values: {
            [formulaItemMonthTab.idParam]: {
              value: tarifDataItem?.value,
              months: radioValue,
            },
          },
        });
      }
    },
    [
      product.idProduct,
      product.isActive,
      product.mandatoryDataRequest,
      product.valueToCalculate,
      tarifDataItem,
      formulaItemMonthTab,
      saveMandatoryDataRecalc,
    ]
  );

  const radioMonthes = useMemo(
    () =>
      formulaItemMonthTab &&
      sortFormulaMonthes(formulaItemMonthTab?.options).map((opt: string) => {
        const radioId = `monthes_${opt}_${formulaItemMonthTab?.idParam}`;
        const radioName = `monthes_${formulaItemMonthTab?.idParam}`;
        const isRadioChecked = !!(
          getTarifItemById(product.mandatoryData, formulaItemMonthTab?.idParam)
            ?.months === opt
        );

        return (
          <Radiobox
            //key={radioId}
            id={radioId}
            name={radioName}
            checked={isRadioChecked}
            className={styles.radioLabel}
            textClassName={styles.radioText}
            disabled={!product.isActive}
            toggleCheck={() => {
              onRadioMonthChange(opt);
            }}
          >
            <div className={styles.radioContent}>
              <span>
                <b>{opt.replace("m", "")}</b>&nbsp;Monate
              </span>
            </div>
          </Radiobox>
        );
      }),
    [formulaItemMonthTab, onRadioMonthChange, product]
  );

  return (
    <div
      key={`product_${JSON.stringify(product)}`}
      className={`${styles.productContainer} ${
        !product.isActive ? styles.productNotActive : ""
      }`}
      data-testid={"calcProductDefault"}
    >
      <ContentBox className={styles.contentBox}>
        <div className={styles.productTop}>
          <div className={styles.statusContainer}>
            {!product.isCore && (
              <>
                <div
                  className={`${styles.status} ${
                    !product.isCore && !product.isActive ? styles.active : ""
                  }`}
                >
                  pausiert
                </div>
                <ToggleSwitch
                  disabled={product.isCore}
                  isToggleOn={product.isActive}
                  clickHandler={changeServiceIsActive(product.idProduct)}
                />
                <div
                  className={`${styles.status} ${
                    !product.isCore && product.isActive ? styles.active : ""
                  }`}
                >
                  aktiviert
                </div>
              </>
            )}
          </div>

          <div className={styles.titleContainer}>
            <h3 className={styles.title}>
              {(product.additionalName || product.name).length > 80
                ? `${(product.additionalName || product.name).slice(0, 80)}...`
                : product.additionalName || product.name}
            </h3>
            <div
              data-testid="open-modal-button"
              className={styles.productReadMore}
              onClick={() =>
                setModalContent({
                  idService: product.idProduct,
                  name: product.additionalName || product.name,
                  popupTitle: product.popupTitle,
                  popupBody: product.popupBody,
                  bottomText: "Unser Partner",
                  bottomImg: product?.insuranceLogo
                    ? `${API_SERVER}${product?.insuranceLogo}`
                    : "",
                  rightImg: `${API_SERVER}${product.image}`,
                })
              }
            >
              <HelpIcon className={styles.helpIcon} />
            </div>
          </div>
        </div>

        <div className={styles.productContent}>
          {!(
            (product.calcMethod === 7 || product.calcMethod === 17) &&
            product.mandatoryDataRequest &&
            !formulaItemMonthTab
          ) &&
            product.minValue !== 0 &&
            product.maxValue !== 0 &&
            product.valueToCalculate !== 0 && (
              <div className={styles.rangeContainer}>
                <div className={styles.pricePeriod}>
                  {product.additionalNameDesc || "Wählen Sie den Schutz bei"}
                </div>

                <RangeNumbersSimple
                  min={product.minValue}
                  max={product.maxValue}
                  current={product.valueToCalculate || 0}
                  step={product.step}
                  active={product.isActive}
                  changeHandler={changeServiceValueToCalc(product.idProduct)}
                  setScrollStyle={setScrollStyle}
                />

                <div
                  className={`${styles.rangeLinesContainer}${
                    scrollStyle ? " " + styles[scrollStyle] : ""
                  }`}
                >
                  <RulerSlider
                    min={product.minValue}
                    max={product.maxValue}
                    current={product.valueToCalculate || 0}
                    step={product.step}
                    setScrollStyle={setScrollStyle}
                    changeHandler={changeServiceValueToCalc(product.idProduct)}
                  />
                </div>
              </div>
            )}

          {!formulaItemMonthTab &&
            product.mandatoryDataRequest &&
            product.isActive && (
              <div className={styles.mandatoryBtnContainer}>
                <Button
                  type="button"
                  variant="primary"
                  size="small"
                  text="Zusatzinfo erforderlich"
                  clickHandler={() =>
                    setMandatoryModal({
                      idProduct: product.idProduct,
                      productName: product.additionalName || product.name,
                      isActive: product.isActive,
                      valueToCalculate: product.valueToCalculate,
                      mandatoryData: product.mandatoryDataRequest,
                    })
                  }
                />
              </div>
            )}

          {formulaItemMonthTab &&
            (product.calcMethod === 13 || product.calcMethod === 17) && (
              <div className={styles.radioMonthesContainer}>
                <div className={styles.perMnth}>
                  Wie lange soll die Leistung gezahlt werden?
                </div>
                <div className={styles.radioMonthes}>{radioMonthes}</div>
              </div>
            )}

          <div className={styles.priceContainer}>
            <div className={styles.productPrice}>
              {product.ab && "ab "}
              {numberToDECurrency(product.total)}
            </div>
            {!product.ab && (
              <div className={styles.pricePeriod}>
                {product.paymentPeriodTitle || paymentTitle}
              </div>
            )}
          </div>
        </div>
      </ContentBox>
    </div>
  );
};

export default CalcProductDefault;

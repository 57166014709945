import styles from "./LandingPage.module.css";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authSelector } from "../../redux/selectors";
import MainWrapper from "../../containers/MainWrapper/MainWrapper";
import BlockOne from "./components/BlockOne";
import BlockTwo from "./components/BlockTwo";
import BlockThree from "./components/BlockThree";
import BlockFour from "./components/BlockFour";
import BlockFive from "./components/BlockFive";
import { IS_RVN_BRAND } from "../../variables_config";

const LandingPage = () => {
  const navigate = useNavigate();
  const { isAuthorized } = useSelector(authSelector);

  useEffect(() => {
    if (isAuthorized) {
      navigate("/home");
    }
  }, [isAuthorized, navigate]);

  return (
    <MainWrapper>
      <BlockOne styles={styles} />
      {!IS_RVN_BRAND ? <BlockTwo styles={styles} /> : null}
      {!IS_RVN_BRAND ? <BlockThree styles={styles} /> : null}
      <BlockFour styles={styles} />
      {!IS_RVN_BRAND ? <BlockFive styles={styles} /> : null}
    </MainWrapper>
  );
};

export default LandingPage;

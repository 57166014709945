import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APICall } from "../../utils/api";

export interface FinapiState {
  isCreated: boolean;
  isLogined: boolean;
  isExist: boolean;
  status: string;
  webFormToken: any;
}

const initialState: FinapiState = {
  isCreated: false,
  isLogined: false,
  isExist: false,
  status: "idle",
  webFormToken: null,
};

export const fetchFinpiExistsCustomer = createAsyncThunk(
  "finapi/ExistsCustomer",
  async (_, { rejectWithValue }) => {
    return await APICall(
      "paymentservice.finapi",
      "customer-exists",
      rejectWithValue
    );
  }
);

export const fetchFinpiCreateCustomer = createAsyncThunk(
  "finapi/CreateCustomer",
  async (_, { rejectWithValue }) => {
    return await APICall(
      "paymentservice.finapi",
      "customer-create",
      rejectWithValue
    );
  }
);

export const fetchFinpiLoginCustomer = createAsyncThunk(
  "finapi/LoginCustomer",
  async (_, { rejectWithValue }) => {
    return await APICall(
      "paymentservice.finapi",
      "customer-login",
      rejectWithValue
    );
  }
);

export const fetchFinpiWebFormToken = createAsyncThunk(
  "finapi/WebFormToken",
  async ({ iban }: { iban: string }, { rejectWithValue }) => {
    return await APICall(
      "paymentservice.finapi",
      "customer-import-bank-connection",
      rejectWithValue,
      {
        bank: { search: iban },
        callbacks: {
          finalised: "https://dev.finapi.io/callback?state=42",
        },
      }
    );
  }
);

export const finapiSlice = createSlice({
  name: "finapi",
  initialState,
  reducers: {
    clearStatus: (state, action) => {
      state.status = initialState.status;
    },
  },
  extraReducers: builder => {
    builder.addCase(
      fetchFinpiExistsCustomer.fulfilled as any,
      (state, action) => {
        if (action?.payload?.exists) {
          state.status = "exists";
          state.isExist = true;
        } else {
          state.isExist = initialState.isExist;
          state.status = initialState.status;
        }
      }
    );

    builder.addCase(
      fetchFinpiExistsCustomer.rejected as any,
      (state, action) => {
        console.log(action);
        state.status = "error";
      }
    );

    builder.addCase(
      fetchFinpiCreateCustomer.fulfilled as any,
      (state, action) => {
        state.isCreated = true;
        state.status = "created";
      }
    );

    builder.addCase(
      fetchFinpiCreateCustomer.rejected as any,
      (state, action) => {
        console.log(action);
        state.isCreated = initialState.isCreated;
        state.status = "error";
      }
    );

    builder.addCase(
      fetchFinpiLoginCustomer.fulfilled as any,
      (state, action) => {
        state.isLogined = true;
        state.status = "logined";
      }
    );

    builder.addCase(
      fetchFinpiLoginCustomer.rejected as any,
      (state, action) => {
        console.log(action);
        state.isLogined = initialState.isLogined;
        state.status = "error";
      }
    );

    builder.addCase(
      fetchFinpiWebFormToken.fulfilled as any,
      (state, action) => {
        console.log(action);
        state.webFormToken = action.payload;
        state.status = "succeded";
      }
    );

    builder.addCase(fetchFinpiWebFormToken.rejected as any, (state, action) => {
      console.log(action);
      state.webFormToken = initialState.webFormToken;
      state.status = "error";
    });
  },
});

export const { clearStatus } = finapiSlice.actions;

export default finapiSlice.reducer;

import React, { useCallback } from "react";
import Axios from "axios";
import { useSelector } from "react-redux";
import { API_SERVER } from "../../variables_config";
import { RootState } from "../../redux/store";
import styles from "./Attachment.module.css";

interface AttachementProps {
  link: string;
  fileName: string;
  className?: string;
  children?: React.ReactNode;
}

const hiddenDownloadLink = (file: any, fileName: string, className = "") => {
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement("a");

  link.href = url;
  link.setAttribute("download", fileName);

  link.click();
  window.URL.revokeObjectURL(url);
};

const Attachment: React.FC<AttachementProps> = ({
  link,
  fileName,
  className,
  children,
}) => {
  const { token } = useSelector((state: RootState) => state.authentication);
  const axiosGetFile = useCallback(async () => {
    try {
      const response = await Axios.get(link, {
        baseURL: API_SERVER,
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      });

      hiddenDownloadLink(response.data, fileName);
    } catch (e) {
      console.log("--error--", e);
    }
  }, [token, link, fileName]);

  return (
    <button
      type="button"
      className={`${styles.link} ${className}`}
      onClick={() => axiosGetFile()}
    >
      {children}
    </button>
  );
};

export default Attachment;

import React from "react";
import { Form, Formik } from "formik";
import Button from "../../../components/Button/Button";
import PasswordInputField from "../../../components/PasswordInputField/PasswordInputField";
import {
  validatePassword,
  validateConfirmPassword,
  validateRequiredField,
} from "../../../utils/formHelper";
import {
  clearError,
  fetchUserChangePassword,
} from "../../../redux/reducers/authenticationSlice";
import { PasswordFormValues } from "../../../redux/reducers/profileSlice.d";
import { ReactComponent as InfoIcon } from "../../../images/info-icon.svg";
import { PasswordFormComponentProps } from "../ProfilePage.d";
import { useAppDispatch } from "../../../redux/selectors";

const initialPasswordValues: PasswordFormValues = {
  currPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const PasswordFormComponent: React.FC<PasswordFormComponentProps> = ({
  styles,
}) => {
  const dispatch = useAppDispatch();

  const onSubmit = (
    values: PasswordFormValues,
    { resetForm }: { resetForm: () => void }
  ) => {
    resetForm();
    dispatch(clearError(null));
    dispatch(fetchUserChangePassword(values));
  };

  return (
    <>
      <Formik
        {...{
          initialValues: initialPasswordValues,
          onSubmit,
        }}
        enableReinitialize
      >
        {({ values, errors, touched }) => (
          <Form className={styles.form} noValidate>
            <div className={styles.requiredInfo}>
              Die mit * gekennzeichneten Daten sind Pflichtfelder
            </div>

            <PasswordInputField
              id="currPassword"
              name="currPassword"
              placeholder="* * * * * * *"
              labelText="Aktuelles Passwort"
              hasErrors={
                errors.hasOwnProperty("currPassword") &&
                touched.hasOwnProperty("currPassword")
              }
              validateHandler={validateRequiredField}
              containerClass={styles.formGroupInline}
              labelClass={styles.label}
              inputClass={styles.input}
            />

            <PasswordInputField
              id="newPassword"
              name="newPassword"
              placeholder="* * * * * * *"
              labelText="Neues Passwort eingeben"
              hasErrors={
                errors.hasOwnProperty("newPassword") &&
                touched.hasOwnProperty("newPassword")
              }
              validateHandler={validatePassword}
              containerClass={styles.formGroupInline}
              labelClass={styles.label}
              inputClass={styles.input}
            />

            <PasswordInputField
              key="confirmPassword"
              id="confirmPassword"
              name="confirmPassword"
              placeholder="* * * * * * *"
              labelText="Neues Passwort wiederholen"
              hasErrors={
                errors.hasOwnProperty("confirmPassword") &&
                touched.hasOwnProperty("confirmPassword")
              }
              validateHandler={(value: string) =>
                validateConfirmPassword(values.newPassword, value)
              }
              containerClass={styles.formGroupInline}
              labelClass={styles.label}
              inputClass={styles.input}
            />

            <div className={styles.passwordHintContainer}>
              <div className={styles.passwordHint}>
                Bitte beachte die Regeln für dein Passwort.{" "}
                <InfoIcon
                  className={styles.infoIcon}
                  {...{
                    "data-tip": true,
                    id: "password_hint",
                    "data-tip-disable": false,
                  }}
                />
              </div>
            </div>

            <div className={styles.btnContainer}>
              <Button type="submit" variant="secondary" text="Speichern" />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PasswordFormComponent;

import React from "react";
import styles from "./ProfileDataContent.module.css";
import BlockContainer from "../BlockContainer/BlockContainer";
import { ProfileDataContentProps } from "./ProfileDataContent.d";
import ContentBox from "../ContentBox/ContentBox";
import TitleLine from "../TitleLine/TitleLine";
import { ReactComponent as EditPencil } from "../assets/images/edit-pencil-icon.svg";
import { getFormattedDate } from "../../utils";

const ProfileDataContent: React.FC<ProfileDataContentProps> = ({
  values,
  onChangeProfileHandler,
  onChangeAccessHandler,
  onChangeAccountHandler,
}) => {
  return (
    <BlockContainer>
      <div className={styles.blockContainer}>
        <ContentBox className={styles.block}>
          <div className={styles.configHeader}>
            <TitleLine>
              Mein Konto - <br /> Meine persönlichen Daten
            </TitleLine>

            <div className={styles.btnsColumn}>
              <button
                type="button"
                className={styles.changeBtn}
                onClick={onChangeProfileHandler}
              >
                <EditPencil className={styles.btnIcon} />
                <span>Persönliche Daten verwalten</span>
              </button>
              <button
                type="button"
                className={styles.changeBtn}
                onClick={() => onChangeAccountHandler(true)}
              >
                <EditPencil className={styles.btnIcon} />
                <span>Zahlungsdaten verwalten</span>
              </button>
            </div>
          </div>

          <div className={styles.dataContainer}>
            <div className={styles.dataItemsHalf}>
              <div className={styles.dataContent}>
                <div className={styles.dataItem}>
                  <span>Anrede:</span>
                </div>
                <div className={`${styles.dataItem} ${styles.capitalLetter}`}>
                  {values.salutation}
                </div>
              </div>

              <div className={styles.dataContent}>
                <div className={styles.dataItem}>
                  <span>Vorname:</span>
                </div>
                <div className={styles.dataItem}>{values.firstName}</div>
              </div>

              <div className={styles.dataContent}>
                <div className={styles.dataItem}>
                  <span>Nachname:</span>
                </div>
                <div className={styles.dataItem}>{values.lastName}</div>
              </div>

              <div className={styles.dataContent}>
                <div className={styles.dataItem}>
                  <span>Geburtsdatum:</span>
                </div>
                <div className={styles.dataItem}>
                  {getFormattedDate(values.dateBirth)}
                </div>
              </div>
            </div>

            <div className={styles.dataItemsHalf}>
              <div className={styles.dataContent}>
                <div className={styles.dataItem}>
                  <span>Straße, Nr.:</span>
                </div>
                <div className={styles.dataItem}>
                  {values.street}, {values.house}
                </div>
              </div>

              <div className={styles.dataContent}>
                <div className={styles.dataItem}>
                  <span>PLZ, Ort:</span>
                </div>
                <div className={styles.dataItem}>
                  {values.postalCode}, {values.city}
                </div>
              </div>

              <div className={styles.dataContent}>
                <div className={styles.dataItem}>
                  <span>Telefonnummer:</span>
                </div>
                <div className={styles.dataItem}>
                  {values.phone ? (
                    values.phone
                  ) : (
                    <span className={styles.error}>Erforderlich</span>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles.configHeader} ${styles.bottomHeader}`}>
            <TitleLine>Deine Zugangsdaten</TitleLine>

            <div className={styles.btnsColumn}>
              <button
                type="button"
                className={styles.changeBtn}
                onClick={onChangeAccessHandler}
              >
                <EditPencil className={styles.btnIcon} />
                <span>Bearbeiten</span>
              </button>
            </div>
          </div>

          <div className={styles.dataContainer}>
            <div className={styles.dataItemsHalf}>
              <div className={styles.dataContent}>
                <div className={styles.dataItem}>
                  <span>Email Adresse:</span>
                </div>
                <div className={styles.dataItem}>{values.username}</div>
              </div>

              <div className={styles.dataContent}>
                <div className={styles.dataItem}>
                  <span>Passwort:</span>
                </div>
                <div className={styles.dataItem}>* * * * * *</div>
              </div>
            </div>
          </div>
        </ContentBox>
      </div>
    </BlockContainer>
  );
};

export default ProfileDataContent;

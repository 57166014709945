import React from "react";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import { API_SERVER } from "../../variables_config";
import ContentBox from "../ContentBox/ContentBox";
import RichTextEditorHtml from "../../containers/RichTextEditorHtml/RichTextEditorHtml";
import { CalcProductDefaultFixedProps } from "./CalcProductDefaultFixed.d";
import styles from "./CalcProductDefaultFixed.module.css";
import { ReactComponent as HelpIcon } from "../assets/images/info-icon.svg";
import { numberToDECurrency } from "../../utils";

const CalcProductDefaultFixed: React.FC<CalcProductDefaultFixedProps> = ({
  product,
  paymentTitle,
  setModalContent,
  changeServiceIsActive,
}) => {
  return (
    <div
      className={`${styles.productContainer} ${
        !product.isActive ? styles.productNotActive : ""
      }`}
      data-testid={"calcProductDefaultFixed"}
    >
      <ContentBox>
        <div className={styles.productTop}>
          <div className={styles.statusContainer}>
            {!product.isCore && (
              <>
                <div
                  className={`${styles.status} ${
                    !product.isCore && !product.isActive ? styles.active : ""
                  }`}
                >
                  pausiert
                </div>
                <ToggleSwitch
                  disabled={product.isCore}
                  isToggleOn={product.isActive}
                  clickHandler={changeServiceIsActive(product.idProduct)}
                />
                <div
                  className={`${styles.status} ${
                    !product.isCore && product.isActive ? styles.active : ""
                  }`}
                >
                  aktiviert
                </div>
              </>
            )}
          </div>

          <div className={styles.titleContainer}>
            <h3 className={styles.title}>
              {(product.additionalName || product.name).length > 80
                ? `${(product.additionalName || product.name).slice(0, 80)}...`
                : product.additionalName || product.name}
            </h3>
            <div
              className={styles.productReadMore}
              onClick={() =>
                setModalContent({
                  idService: product.idProduct,
                  name: product.additionalName || product.name,
                  popupTitle: product.popupTitle,
                  popupBody: product.popupBody,
                  bottomText: "Unser Partner",
                  bottomImg: product?.insuranceLogo
                    ? `${API_SERVER}${product?.insuranceLogo}`
                    : "",
                  rightImg: `${API_SERVER}${product.image}`,
                })
              }
            >
              <HelpIcon className={styles.helpIcon} />
            </div>
          </div>
        </div>

        <div className={styles.productContent}>
          {/* <h3 className={styles.subTitle}>Für nur 1,20€ monatlich mehr</h3> */}

          <div className={styles.productDesc}>
            <RichTextEditorHtml editorValue={product.descriptionBody} />
          </div>

          <div className={styles.priceContainer}>
            <div className={styles.productPrice}>
              {product.ab && "ab "}
              {numberToDECurrency(product.total)}
            </div>
            {!product.ab && (
              <div className={styles.pricePeriod}>
                {product.paymentPeriodTitle || paymentTitle}
              </div>
            )}
          </div>
        </div>
      </ContentBox>
    </div>
  );
};

export default CalcProductDefaultFixed;

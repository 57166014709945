import React, { useMemo } from "react";
import { numberToDECurrency } from "../../../utils";
import BlockContainer from "../../../components/BlockContainer/BlockContainer";
import ContentBox from "../../../components/ContentBox/ContentBox";
import LongButton from "../../../components/LongButton/LongButton";
import { BlockThreeProps } from "../PackageTemplateDefaultPage.d";
import { ReactComponent as CreditCardIcon } from "../../../images/credit-card-icon.svg";
import {
  FORMULA_MONTH_TAB,
  getFormulaItem,
} from "../../../containers/Calculator/calculatorHelper";
import { Product } from "../../../redux/reducers/calculatorSlice.d";

const BlockThree: React.FC<BlockThreeProps> = ({
  letterData,
  isShowSubmitBtn,
  styles,
  toggleReturnConfirmModal,
}) => {
  const isMandatoryDataFilled = useMemo(() => {
    if (letterData && !letterData.hasOwnProperty("products")) {
      return true;
    }

    return !letterData?.products.some(
      (product: Product) =>
        product.calcMethod !== 8 &&
        product.mandatoryDataRequest &&
        !getFormulaItem(
          product.mandatoryDataRequest || [],
          FORMULA_MONTH_TAB
        ) &&
        product.isActive
    );
  }, [letterData]);

  if (!letterData) {
    return null;
  }

  return (
    <BlockContainer>
      <div className={styles.blockThreeContainer}>
        <ContentBox className={styles.blockThree}>
          <h2 className={styles.blockThreeTitle}>
            Die oben angezeigte Konfiguration deines Mehrwert-Paketes kostet{" "}
            {letterData.paymentModalityTitle}:
          </h2>

          <div className={`h1 ${styles.priceContainer}`}>
            {numberToDECurrency(letterData?.defaultValue)}
          </div>
          <div className={styles.priceRequiredText}>
            *Einschließlich Servicepauschale
          </div>
        </ContentBox>
        <div className={styles.btnContainer}>
          {letterData.isBought && !isShowSubmitBtn && (
            <LongButton
              text="Kundigen"
              icon={false}
              clickHandler={toggleReturnConfirmModal}
              disabled={letterData?.bank?.bdcPaymentMethod === "standard"}
            />
          )}

          {isShowSubmitBtn && (
            <LongButton
              text="Jetzt buchen"
              icon={CreditCardIcon}
              href={`/checkout/${letterData?.idPackage}`}
              {...(!isMandatoryDataFilled ? { disabled: true } : {})}
            />
          )}
        </div>
      </div>
    </BlockContainer>
  );
};

export default BlockThree;

import React, { useEffect, useState } from "react";
import { truncToDigit } from "../../utils";
import { ReactComponent as RangeLines } from "../assets/images/range-lines.svg";
import FormattedNumber from "../FormattedNumber/FormattedNumber";
import { RulerSliderProps } from "./RulerSlider.d";
import styles from "./RulerSlider.module.css";
import { useSelector } from "react-redux";
import { calcSelector } from "../../redux/selectors";

const vibrate = () => {
  if (window?.navigator?.hasOwnProperty("vibrate")) {
    window.navigator.vibrate([200, 10, 300]);
  }
};

const RulerSlider: React.FC<RulerSliderProps> = ({
  min,
  max,
  current,
  step,
  changeHandler,
  setScrollStyle,
}) => {
  const [touchPosition, setTouchPosition] = useState(null);
  const [touchCurrent, setTouchCurrent] = useState(current);

  const { isCalculating } = useSelector(calcSelector);
  const prevHandler = () => {
    const prevNumber = touchCurrent - step >= min ? touchCurrent - step : 0;

    if (prevNumber !== 0 && touchCurrent >= min) {
      setScrollStyle && setScrollStyle("transformToLeft");
      setTouchCurrent(prevNumber);
    }
  };

  const nextHandler = () => {
    const nextNumber = touchCurrent + step <= max ? touchCurrent + step : 0;

    if (nextNumber !== 0 && touchCurrent <= max) {
      setScrollStyle && setScrollStyle("transformToRight");

      setTouchCurrent(nextNumber);
    }
  };

  const handleTouchStart = (e: any) => {
    setTouchPosition(e.touches[0].clientX);
  };

  const handleTouchMove = (e: any) => {
    if (touchPosition === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchPosition - currentTouch;

    if (diff > 10) {
      prevHandler();

      vibrate();
      setTouchPosition(currentTouch);
    }

    if (diff < -10) {
      nextHandler();
      vibrate();
      setTouchPosition(currentTouch);
    }
  };

  const handleTouchEnd = () => {
    if (
      touchCurrent !== current &&
      (touchCurrent > min || touchCurrent < max) &&
      !isCalculating
    ) {
      changeHandler(touchCurrent);
    }
    setScrollStyle && setScrollStyle("");
    setTouchPosition(null);
  };

  useEffect(() => {
    setTouchCurrent(current);
  }, [current]);

  return (
    <div
      className={styles.rangeLinesContainer}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div className={styles.stepsContainer} data-testid="ruler-slider">
        {/* <h1>{touchPosition}</h1> */}
        <div className={styles.step}>
          {touchCurrent - step * 3 >= 0 ? (
            <FormattedNumber value={truncToDigit(touchCurrent - step * 3)} />
          ) : (
            ""
          )}
        </div>
        <div className={styles.step}>
          {touchCurrent - step * 2 >= 0 ? (
            <FormattedNumber value={truncToDigit(touchCurrent - step * 2)} />
          ) : (
            ""
          )}
        </div>
        <div className={styles.step}>
          {touchCurrent - step >= 0 ? (
            <FormattedNumber value={truncToDigit(touchCurrent - step)} />
          ) : (
            ""
          )}
        </div>

        <div className={`${styles.step} ${styles.currentStep} `}>
          <FormattedNumber value={touchCurrent} />
        </div>

        <div className={styles.step}>
          <FormattedNumber value={truncToDigit(touchCurrent + step)} />
        </div>
        <div className={styles.step}>
          <FormattedNumber value={truncToDigit(touchCurrent + step * 2)} />
        </div>
        <div className={styles.step}>
          <FormattedNumber value={truncToDigit(touchCurrent + step * 3)} />
        </div>
      </div>

      <RangeLines className={styles.lineImg} />
    </div>
  );
};

export default RulerSlider;

import styles from "./Checkbox.module.css";
import { CheckboxProps } from "./Checkbox.d";

const Checkbox: React.FC<CheckboxProps> = ({
  id,
  checked,
  children,
  toggleCheck,
  disabled,
  waitCheck,
  className = "",
}) => {
  return (
    <label
      className={`${styles.label} ${
        disabled ? styles.disabled : ""
      } ${className}`}
      htmlFor={id}
    >
      <div className={styles.checkbox}>
        <input
          id={id}
          type="checkbox"
          defaultChecked={checked}
          {...(disabled ? { disabled } : null)}
          onChange={e => toggleCheck(e.target.checked)}
        />
        <span
          className={`${styles.knob} ${waitCheck ? styles.waitCheck : ""} ${
            disabled ? styles.disabled : ""
          }`}
          data-testid="checkboxSpan"
        />
      </div>

      {children && <div className={styles.text}>{children}</div>}
    </label>
  );
};

export default Checkbox;

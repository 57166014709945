import React, { useEffect } from "react";
import { BlockFourProps } from "../Home.d";
import TitleLine from "../../../components/TitleLine/TitleLine";
import { useSelector } from "react-redux";
import { packagesSelector, useAppDispatch } from "../../../redux/selectors";
import { fetchCategoriesWithPackages } from "../../../redux/reducers/packagesSlice";
import { API_SERVER } from "../../../variables_config";
import CategoriesPackagesSlider from "../../../components/CategoriesPackagesSlider/CategoriesPackagesSlider";

const BlockFour: React.FC<BlockFourProps> = ({ styles, scrollRef }) => {
  const dispatch = useAppDispatch();

  const { categoriesPackages } = useSelector(packagesSelector);

  useEffect(() => {
    dispatch(fetchCategoriesWithPackages({ perPage: 999 }));
  }, [dispatch]);

  return (
    <div className={styles.blockFourContainer}>
      <div className={styles.blockFour} ref={scrollRef}>
        <div className={styles.twoDivs}>
          <div className={styles.half}>
            <TitleLine>
              Hier findest du noch weitere spannende Angebote!
            </TitleLine>
          </div>
          <div className={styles.half}></div>
        </div>

        <p className={styles.textBlock}>
          Sieh dir unterschiedliche Mehrwertpakete der Allgemeine Schutzbrief
          Servicegesellschaft an, die dich interessieren könnten.
        </p>
      </div>

      <div className={styles.blockSlider}>
        {categoriesPackages && categoriesPackages.length ? (
          <CategoriesPackagesSlider
            packages={categoriesPackages.map((item, i) => ({
              idPackage: item.idPackage || "",
              name: item.name,
              image: `${API_SERVER}${item.icon}`,
              description: item.description,
            }))}
          />
        ) : null}
      </div>
    </div>
  );
};

export default BlockFour;

import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BlockContainer from "../../components/BlockContainer/BlockContainer";
import TitleLine from "../../components/TitleLine/TitleLine";
import MainWrapper from "../../containers/MainWrapper/MainWrapper";
import { resetStatus } from "../../redux/reducers/buyPackageSlice";
import { fetchGetBank } from "../../redux/reducers/bankSlice";
import {
  authSelector,
  //bankSelector,
  calcSelector,
  useAppDispatch,
} from "../../redux/selectors";
import { ReactComponent as EmailIcon } from "../../images/email-icon.svg";
import { ReactComponent as ConfigIcon } from "../../images/config-icon.svg";
import { ReactComponent as OrderIcon } from "../../images/order-icon.svg";
//import { ReactComponent as GiftIcon } from "../../images/gift-icon.svg";
import styles from "./Thankyou.module.css";
import Button from "../../components/Button/Button";
import CircleIcon from "../../components/CircleIcon/CircleIcon";
import useDisableBodyScroll from "../../utils/useDisableBodyScroll";
import { IS_RVN_BRAND } from "../../variables_config";

const Thankyou = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { letterData } = useSelector(calcSelector);
  const { user } = useSelector(authSelector);
  //const { bankData } = useSelector(bankSelector);

  useDisableBodyScroll(false);

  useEffect(() => {
    dispatch(fetchGetBank({ idBank: user?.idBank }));
  }, [dispatch, user?.idBank]);

  useEffect(() => {
    dispatch(resetStatus("idle"));
  }, [dispatch]);

  if (!letterData.idPackage) {
    navigate("/home");
  }

  return (
    <MainWrapper>
      <BlockContainer>
        <div className={styles.blockContainer}>
          <div className={styles.block}>
            <TitleLine>
              Herzlichen Glückwunsch zur Buchung deines persönlichen{" "}
              {!IS_RVN_BRAND ? "Mehrwert-Paketes" : letterData?.name}
              {", "}
              {user?.firstName} {user?.lastName}!
            </TitleLine>

            <h3 className={styles.title}>
              Dein{" "}
              {!IS_RVN_BRAND
                ? `Mehrwert-Paket "${letterData?.name}"`
                : letterData?.name}{" "}
              hat die Nummer <b>{letterData?.packageNum}</b>. Eine E-Mail mit
              den Vertragsunterlagen ist an deine E-Mail-Adresse unterwegs.
            </h3>

            {/* <div className={styles.usernameContainer}>
              <div className={styles.usernameContent}>
                <div className={styles.usernameTitle}>Deine aktuelle E-Mail</div>

                <div className={styles.username}>
                  <span>{user?.username}</span>
                  <span className={styles.leftIconContainer}>
                    <EmailIcon className={styles.leftIcon} />
                  </span>
                </div>
              </div>
            </div> */}

            <ul className={styles.ulBlock}>
              <li>
                <div className={styles.liIconContainer}>
                  <CircleIcon icon={EmailIcon} />
                </div>
                <div className={styles.liContent}>
                  <p className={styles.liTitle}>
                    Bitte überprüfe auch deinen Spam-Ordner, solltest du die
                    Mail im Posteingang nicht gleich finden.
                  </p>
                </div>
              </li>

              <li>
                <div className={styles.liIconContainer}>
                  <CircleIcon icon={ConfigIcon} />
                </div>
                <div className={styles.liContent}>
                  <p className={styles.liTitle}>
                    Du kannst dein{" "}
                    {!IS_RVN_BRAND ? "Mehrwert-Paket" : letterData?.name} im
                    Cash & Protect Portal jederzeit überprüfen und nach deinen
                    Wünschen weiter konfigurieren.
                  </p>
                </div>
              </li>

              <li>
                <div className={styles.liIconContainer}>
                  <CircleIcon icon={OrderIcon} />
                </div>
                <div className={styles.liContent}>
                  <p className={styles.liTitle}>
                    Vergiss bitte nicht, deine persönlichen Daten in deinem Cash
                    & Protect Kundenkonto aktuell zu halten, so dass wir dir
                    unsere Services bestmöglich anbieten können.
                  </p>
                </div>
              </li>

              {/* <li>
                <div className={styles.liIconContainer}>
                  <CircleIcon icon={GiftIcon} />
                </div>
                <div className={styles.liContent}>
                  <p className={styles.liTitle}>
                    Unser Willkommens-Angebot an dich: Als Kunde der{" "}
                    {bankData?.bankName} kannst du dein Mehrwert-Paket 3 Monate
                    kostenlos testen.
                  </p>
                </div>
              </li> */}
            </ul>

            <div className={styles.btnContainer}>
              <Button variant="secondary" href="/home" text="Home" />
            </div>
          </div>
        </div>
      </BlockContainer>
    </MainWrapper>
  );
};

export default Thankyou;

import React from "react";
import Button from "../Button/Button";
import styles from "./ConfirmActionModalContent.module.css";
import { ConfirmActionModalContentProps } from "./ConfirmActionModalContent.d";
import ModalBody from "../ModalBody/ModalBody";

const ConfirmActionModalContent: React.FC<ConfirmActionModalContentProps> = ({
  confirmBtnText,
  rejectBtnText,
  confirmHandler,
  closeHandler,
  children,
}) => {
  return (
    <>
      <ModalBody>{children}</ModalBody>

      <div className={styles.modalFooter}>
        <Button
          variant="secondary"
          clickHandler={closeHandler}
          text={rejectBtnText}
          className={styles.actionBtn}
        />

        <Button
          clickHandler={confirmHandler}
          text={confirmBtnText}
          className={styles.actionBtn}
        />
      </div>
    </>
  );
};

export default ConfirmActionModalContent;

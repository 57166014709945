import React, { memo } from "react";
import BlockContainer from "../../../components/BlockContainer/BlockContainer";
import ContentBox from "../../../components/ContentBox/ContentBox";
import LongButton from "../../../components/LongButton/LongButton";
import BankLogo from "../../../components/BankLogo/BankLogo";
import { BlockProps } from "../PackageTemplateDefaultPage.d";
import { ReactComponent as OrderIcon } from "../../../images/order-icon.svg";
import { ReactComponent as SchutzIcon } from "../../../images/schutz-icon.svg";

const BlockOne: React.FC<BlockProps> = ({ letterData, styles }) => {
  if (!letterData) {
    return null;
  }

  return (
    <BlockContainer>
      <div className={styles.blockOneContainer}>
        <ContentBox className={styles.blockOne}>
          <div className={styles.bankLogoContainer}>
            <BankLogo />
          </div>

          <h1>{letterData?.name}</h1>

          <div className={styles.texted} style={{ width: "80%" }} />
          <div className={styles.texted} style={{ width: "70%" }} />
          <div className={styles.texted} style={{ width: "85%" }} />
          <div className={styles.texted} style={{ width: "80%" }} />
          <div className={styles.texted} style={{ width: "75%" }} />

          <div className={styles.btnContainer}>
            <LongButton
              text="Vertragsunterlagen einsehen"
              icon={OrderIcon}
              href="/downloads"
            />
          </div>
          <div className={styles.schutzIconContainer}>
            <SchutzIcon className={styles.schutzIcon} />
          </div>
        </ContentBox>
      </div>
    </BlockContainer>
  );
};

export default memo(BlockOne);

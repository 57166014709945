import ModalBody from "../ModalBody/ModalBody";
import { ProductModalContentProps } from "./ProductModalContent.d";
import styles from "./ProductModalContent.module.css";

const ProductModalContent: React.FC<ProductModalContentProps> = ({
  text,
  bottomText,
  bottomImg,
  bottomAlt,
  rightImg,
  rightAlt,
}) => {
  return (
    <ModalBody>
      <div className={styles.container}>
        {rightImg && (
          <div className={styles.imgContainer}>
            <img className={styles.topImg} src={rightImg} alt={rightAlt} />
          </div>
        )}
        <div className={styles.content}>
          <div className={styles.p}>{text}</div>

          <div className={styles.bottom}>
            <p className={styles.bottomP}>{bottomText}</p>
            {bottomImg && (
              <img className={styles.img} src={bottomImg} alt={bottomAlt} />
            )}
          </div>
        </div>
      </div>
    </ModalBody>
  );
};

export default ProductModalContent;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APICall } from "../../utils/api";
import { SYSTEM_ID } from "../../variables_config";
import {
  RegisterFormValues,
  ValuesToSend,
  AuthenticationState,
  PasswordSetFormValues,
  PasswordResetFormValues,
} from "./authenticationSlice.d";

const apiVersion = "1.1.0";

const initialState: AuthenticationState = {
  user: null,
  rolesMap: null,
  token: "",
  expireAt: null,
  error: null,
  status: "idle",
  isAuthorizing: false,
  isAuthorized: false,
  waitCheck: false,
  waitConfirmEmailChange: false,
  emailChangeConfirmed: false,
  twoWayMethods: [],
  gdprChecks: {
    dataProtectCheck: false,
    offersServicesCheck: false,
    contractDataCheck: false,
    ready: false,
    accrvidn: undefined,
  },
};

export const fetchUserLogin = createAsyncThunk(
  "users/Login",
  async (
    {
      username,
      password,
    }: {
      username: string;
      password: string;
    },
    { rejectWithValue }
  ) => {
    return await APICall("authservice.user", "login", rejectWithValue, {
      username,
      password,
      system: SYSTEM_ID,
    });
  }
);

export const fetchUserRegistration = createAsyncThunk(
  "users/Registration",
  async (regValues: RegisterFormValues, { rejectWithValue }) => {
    const valuesToSend: ValuesToSend = {
      username: regValues.username,
      password: regValues.password,
      firstName: regValues.firstname,
      lastName: regValues.lastname,
      city: regValues.city,
      postalCode: regValues.postcode,
      role: "customer",
      salutation: regValues.salutation,
      dateBirth: regValues.dateBirth,
      extras: {
        street: regValues.street,
        house: regValues.house,
      },
      accrvidn: regValues.accrvidn,
    };

    return await APICall("authservice.user", "signup", rejectWithValue, {
      ...valuesToSend,
      system: SYSTEM_ID,
    });
  }
);

export const fetchCheckOrRefreshToken = createAsyncThunk(
  "users/fetchCheckOrRefreshToken",
  async (_, { rejectWithValue }) => {
    return await APICall(
      "authservice.user",
      "refreshorcheck",
      rejectWithValue,
      {
        system: SYSTEM_ID,
      }
    );
  }
);

export const fetchCheckEmail = createAsyncThunk(
  "users/fetchCheckEmail",
  async (
    {
      confirmCode,
    }: {
      confirmCode: string;
    },
    { rejectWithValue, getState }
  ) => {
    const state = getState() as any;

    const token = state.authentication.token;
    const config = { headers: {} } as { headers: any };

    if (token) {
      config.headers = { Authorization: `Bearer ${token}` };
    }

    return await APICall(
      "authservice.user",
      "confirm-email",
      rejectWithValue,
      {
        confirmCode,
        system: SYSTEM_ID,
      },
      config
    );
  }
);

export const fetchConfirmLogin = createAsyncThunk(
  "users/fetchConfirmLogin",
  async (
    {
      username,
      code,
      authTypeEmail,
      authTypeSMS,
      authTypeGoogle,
      isDefault,
    }: {
      username: string;
      code: string;
      authTypeEmail?: boolean;
      authTypeSMS?: boolean;
      authTypeGoogle?: boolean;
      isDefault?: boolean;
    },
    { rejectWithValue, getState }
  ) => {
    const state = getState() as any;

    const token = state.authentication.token;
    const config = { headers: {} } as { headers: any };

    if (token) {
      config.headers = { Authorization: `Bearer ${token}` };
    }

    return await APICall(
      "authservice.user",
      "login-confirm",
      rejectWithValue,
      {
        username,
        code,
        authTypeEmail,
        authTypeSMS,
        authTypeGoogle,
        isDefault,
        system: SYSTEM_ID,
      },
      config
    );
  }
);

export const fetchSendConfirmLogin = createAsyncThunk(
  "users/fetchSendConfirmLogin",
  async (
    {
      username,
      authTypeEmail,
      authTypeSMS,
      authTypeGoogle,
      isDefault,
    }: {
      username: string;
      authTypeEmail?: boolean;
      authTypeSMS?: boolean;
      authTypeGoogle?: boolean;
      isDefault?: boolean;
    },
    { rejectWithValue, getState }
  ) => {
    const state = getState() as any;

    const token = state.authentication.token;
    const config = { headers: {} } as { headers: any };

    if (token) {
      config.headers = { Authorization: `Bearer ${token}` };
    }

    return await APICall(
      "authservice.user",
      "login-send-confirm",
      rejectWithValue,
      {
        username,
        authTypeEmail,
        authTypeSMS,
        authTypeGoogle,
        isDefault,
      },
      config
    );
  }
);

export const fetchUserDataProtectCheck = createAsyncThunk(
  "users/RegistrationDataProtectCheck",
  async (
    {
      value,
      accrvidn,
    }: {
      value: boolean;
      accrvidn: string | undefined;
    },
    { rejectWithValue }
  ) => {
    return await APICall(
      "authservice.acceptions",
      "set-accept",
      rejectWithValue,
      {
        service: "signup_user",
        idElem: "dataProtectCheck",
        value,
        accrvidn,
      }
    );
  }
);

export const fetchUserOffersServicesCheck = createAsyncThunk(
  "users/RegistrationOffersServicesCheck",
  async (
    {
      value,
      accrvidn,
    }: {
      value: boolean;
      accrvidn: string | undefined;
    },
    { rejectWithValue }
  ) => {
    return await APICall(
      "authservice.acceptions",
      "set-accept",
      rejectWithValue,
      {
        service: "signup_user",
        idElem: "offersServicesCheck",
        value,
        accrvidn,
      }
    );
  }
);

export const fetchUserContractDataCheck = createAsyncThunk(
  "users/RegistrationContractDataCheck",
  async (
    {
      value,
      accrvidn,
    }: {
      value: boolean;
      accrvidn: string | undefined;
    },
    { rejectWithValue }
  ) => {
    return await APICall(
      "authservice.acceptions",
      "set-accept",
      rejectWithValue,
      {
        service: "signup_user",
        idElem: "contractDataCheck",
        value,
        accrvidn,
      }
    );
  }
);

export const fetchUserResetPassword = createAsyncThunk(
  "users/ResetPassword",
  async (
    {
      email,
      link,
    }: {
      email: string;
      link: string;
    },
    { rejectWithValue }
  ) => {
    return await APICall(
      "authservice.user",
      "forgot-password-send-mail",
      rejectWithValue,
      {
        email,
        link,
      }
    );
  }
);

export const fetchUserRestorePassword = createAsyncThunk(
  "users/RestorePassword",
  async (values: PasswordResetFormValues, { rejectWithValue }) => {
    return await APICall(
      "authservice.user",
      "forgot-password-change",
      rejectWithValue,
      { ...values }
    );
  }
);

export const fetchUserSetPassword = createAsyncThunk(
  "users/setPassword",
  async (values: PasswordSetFormValues, { rejectWithValue }) => {
    return await APICall("authservice.user", "set-password", rejectWithValue, {
      ...values,
      ver: apiVersion,
      system: SYSTEM_ID,
    });
  }
);

export const fetchUserChangePassword = createAsyncThunk(
  "users/ChangePassword",
  async (
    {
      currPassword,
      newPassword,
      confirmPassword,
    }: {
      currPassword: string;
      newPassword: string;
      confirmPassword: string;
    },
    { rejectWithValue }
  ) => {
    return await APICall(
      "authservice.user",
      "change-password",
      rejectWithValue,
      {
        currPassword,
        newPassword,
        confirmPassword,
      }
    );
  }
);

export const fetchUserEmailChangeSendCode = createAsyncThunk(
  "users/EmailChangeSendCode",
  async (
    {
      email,
    }: {
      email: string;
    },
    { rejectWithValue }
  ) => {
    return await APICall(
      "authservice.user",
      "email-change-send-code",
      rejectWithValue,
      {
        email,
      }
    );
  }
);

export const fetchConfirmChangeEmail = createAsyncThunk(
  "users/ConfirmChangeEmail",
  async (
    {
      confirmCode,
    }: {
      confirmCode: string;
    },
    { rejectWithValue }
  ) => {
    return await APICall(
      "authservice.user",
      "email-change-confirm-code",
      rejectWithValue,
      {
        confirmCode,
        ver: apiVersion,
      }
    );
  }
);

export const fetchCashbackCreateUser = createAsyncThunk(
  "users/CreateCashbackUser",
  async (_, { rejectWithValue }) => {
    return await APICall(
      "paymentservice.cashback",
      "customer-create",
      rejectWithValue,
      {
        ver: apiVersion,
      }
    );
  }
);

export const fetchUserLogout = createAsyncThunk(
  "users/Logout",
  async (_, { rejectWithValue }) => {
    return await APICall("authservice.user", "logout", rejectWithValue);
  }
);

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    clearError: (state, action) => {
      state.error = null;
    },
    clearStatus: (state, action) => {
      state.status = action.payload;
    },
    clearEmailChangedStatus: (state, action) => {
      state.emailChangeConfirmed = false;
    },
    clearWaitCheck: (state, action) => {
      state.waitCheck = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchUserLogin.fulfilled as any, (state, action) => {
      if (action.payload.success) {
        state.waitCheck = action.payload.waitCheck;
        state.twoWayMethods = action.payload.twoWayMethods;
      } else {
        state.user = action.payload.user;
        state.rolesMap = action.payload.rolesMap;
        state.token = action.payload.token;
        state.expireAt = action.payload.expireAt;
        state.isAuthorized = true;
      }
      state.error = null;
      state.isAuthorizing = false;
    });

    builder.addCase(fetchUserLogin.pending as any, (state, action) => {
      state.status = "pending";
      state.isAuthorizing = true;
    });

    builder.addCase(fetchUserLogin.rejected as any, (state, action) => {
      if (action.payload?.message) {
        state.error = action.payload.message;
      } else {
        state.error = "Etwas schiefgegangen";
      }

      state.user = initialState.user;
      state.rolesMap = initialState.rolesMap;
      state.token = initialState.token;
      state.expireAt = initialState.expireAt;
      state.isAuthorizing = false;
      state.isAuthorized = false;
      state.status = "error";
    });

    builder.addCase(fetchUserRegistration.fulfilled as any, (state, action) => {
      state.user = action.payload.user;
      state.rolesMap = action.payload.rolesMap;
      state.token = action.payload.token;
      state.expireAt = action.payload.expireAt;

      state.isAuthorizing = false;
      state.isAuthorized = true;
      state.error = null;
    });

    builder.addCase(fetchUserRegistration.pending as any, (state, action) => {
      state.status = "pending";
      state.isAuthorizing = true;
    });

    builder.addCase(fetchUserRegistration.rejected as any, (state, action) => {
      state.user = initialState.user;
      state.rolesMap = initialState.rolesMap;
      state.token = initialState.token;
      state.gdprChecks = initialState.gdprChecks;

      state.isAuthorizing = false;
      state.isAuthorized = false;
      state.status = "error";

      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    });

    builder.addCase(
      fetchCheckOrRefreshToken.fulfilled as any,
      (state, action) => {
        state.user = action.payload.user;
        state.rolesMap = action.payload.rolesMap;
        state.token = action.payload.token;
        state.expireAt = action.payload.expireAt;
        state.error = null;
        state.status = "succeded";
        state.isAuthorized = true;
        state.isAuthorizing = false;
      }
    );

    builder.addCase(
      fetchCheckOrRefreshToken.pending as any,
      (state, action) => {
        state.status = "pending";
        state.isAuthorizing = true;
      }
    );

    builder.addCase(
      fetchCheckOrRefreshToken.rejected as any,
      (state, action) => {
        state.user = initialState.user;
        state.rolesMap = initialState.rolesMap;
        state.token = initialState.token;
        state.expireAt = initialState.expireAt;
        state.isAuthorizing = false;
        state.isAuthorized = false;
        state.status = "error";
      }
    );

    builder.addCase(fetchCheckEmail.fulfilled as any, (state, action) => {
      state.user = action.payload.user;
      state.rolesMap = action.payload.rolesMap;
      state.token = action.payload.token;
      state.expireAt = action.payload.expireAt;
      state.error = null;
      state.status = "succeded";
      state.isAuthorized = true;
      state.isAuthorizing = false;
    });

    builder.addCase(fetchCheckEmail.pending as any, (state, action) => {
      state.status = "pending";
      state.isAuthorizing = true;
    });

    builder.addCase(fetchCheckEmail.rejected as any, (state, action) => {
      state.user = initialState.user;
      state.rolesMap = initialState.rolesMap;
      state.token = initialState.token;
      state.expireAt = initialState.expireAt;
      state.isAuthorizing = false;
      state.isAuthorized = false;
      state.status = "error";

      if (action.payload?.message) {
        state.error = action.payload.message;
      } else {
        state.error = "Etwas schiefgegangen";
      }
    });

    builder.addCase(
      fetchConfirmChangeEmail.fulfilled as any,
      (state, action) => {
        if (action.payload.user) {
          state.user = action.payload.user;
          state.token = action.payload.token;
          state.expireAt = action.payload.expireAt;
          state.error = null;
          state.status = "succeded";
          state.emailChangeConfirmed = true;
        } else {
          state.status = "error";
          state.error = "Etwas schiefgegangen";
        }
        state.waitConfirmEmailChange = false;
      }
    );

    builder.addCase(
      fetchConfirmChangeEmail.rejected as any,
      (state, action) => {
        state.status = "error";
        state.emailChangeConfirmed = false;

        if (action.payload?.message) {
          state.error = action.payload.message;
        } else {
          state.error = "Etwas schiefgegangen";
        }
      }
    );

    builder.addCase(fetchConfirmLogin.fulfilled as any, (state, action) => {
      if (action.payload.success === false) {
        state.isAuthorizing = false;
        state.isAuthorized = false;
        state.status = "error";
        state.error = `Wrong code. You have ${action.payload.remainToBlock} attempts left.`;
      } else {
        state.user = action.payload.user;
        state.rolesMap = action.payload.rolesMap;
        state.token = action.payload.token;
        state.expireAt = action.payload.expireAt;
        state.error = null;
        state.status = "succeded";
        state.isAuthorized = true;
        state.isAuthorizing = false;
        state.waitCheck = false;
      }
    });

    builder.addCase(fetchConfirmLogin.pending as any, (state, action) => {
      state.status = "pending";
      state.isAuthorizing = true;
    });

    builder.addCase(fetchConfirmLogin.rejected as any, (state, action) => {
      state.user = initialState.user;
      state.rolesMap = initialState.rolesMap;
      state.token = initialState.token;
      state.expireAt = initialState.expireAt;
      state.isAuthorizing = false;
      state.isAuthorized = false;
      state.status = "error";

      if (action.payload?.message) {
        state.error = action.payload.message;
      } else {
        state.error = "Etwas schiefgegangen";
      }
    });

    builder.addCase(fetchSendConfirmLogin.fulfilled as any, (state, action) => {
      if (action.payload.success) {
        state.error = null;
        state.status = "succeded";
        state.isAuthorizing = false;
      } else {
        state.error = "Etwas schiefgegangen";
        state.status = "error";
      }

      state.isAuthorized = !action.payload.waitCheck;
      state.waitCheck = action.payload.waitCheck;
    });

    builder.addCase(fetchSendConfirmLogin.pending as any, (state, action) => {
      state.status = "pending";
      state.isAuthorizing = true;
    });

    builder.addCase(fetchSendConfirmLogin.rejected as any, (state, action) => {
      state.user = initialState.user;
      state.rolesMap = initialState.rolesMap;
      state.token = initialState.token;
      state.expireAt = initialState.expireAt;
      state.isAuthorizing = false;
      state.isAuthorized = false;
      state.status = "error";

      if (action.payload?.message) {
        state.error = action.payload.message;
      } else {
        state.error = "Etwas schiefgegangen";
      }
    });

    builder.addCase(
      fetchUserDataProtectCheck.fulfilled as any,
      (state, action) => {
        state.gdprChecks.dataProtectCheck = action.payload.success;
        state.gdprChecks.ready = action.payload.ready;
        state.gdprChecks.accrvidn = action.payload.accrvidn;
      }
    );

    builder.addCase(
      fetchUserOffersServicesCheck.fulfilled as any,
      (state, action) => {
        state.gdprChecks.offersServicesCheck = action.payload.success;
        state.gdprChecks.ready = action.payload.ready;
        state.gdprChecks.accrvidn = action.payload.accrvidn;
      }
    );

    builder.addCase(
      fetchUserContractDataCheck.fulfilled as any,
      (state, action) => {
        state.gdprChecks.contractDataCheck = action.payload.success;
        state.gdprChecks.ready = action.payload.ready;
        state.gdprChecks.accrvidn = action.payload.accrvidn;
      }
    );

    builder.addCase(
      fetchUserResetPassword.fulfilled as any,
      (state, action) => {
        if (action.payload.success) {
          state.error = null;
          state.status = "succeded";
        } else {
          state.error = "Etwas schiefgegangen";
          state.status = "error";
        }
      }
    );

    builder.addCase(fetchUserResetPassword.rejected as any, (state, action) => {
      state.status = "error";

      if (action.payload?.message) {
        state.error = action.payload.message;
      } else {
        state.error = "Etwas schiefgegangen";
      }
    });

    builder.addCase(
      fetchUserRestorePassword.fulfilled as any,
      (state, action) => {
        if (action.payload.success) {
          state.error = null;
          state.status = "succeded";
        } else {
          state.error = "Etwas schiefgegangen";
          state.status = "error";
        }
      }
    );

    builder.addCase(
      fetchUserRestorePassword.rejected as any,
      (state, action) => {
        state.status = "error";

        if (action.payload?.message) {
          state.error = action.payload.message;
        } else {
          state.error = "Etwas schiefgegangen";
        }
      }
    );

    builder.addCase(fetchUserSetPassword.fulfilled as any, (state, action) => {
      state.user = action.payload.user;
      state.rolesMap = action.payload.rolesMap;
      state.token = action.payload.token;
      state.expireAt = action.payload.expireAt;
      state.error = null;
      state.status = "succeded";
      state.isAuthorized = true;
      state.isAuthorizing = false;
    });

    builder.addCase(fetchUserSetPassword.rejected as any, (state, action) => {
      state.status = "error";

      if (action.payload?.message) {
        state.error = action.payload.message;
      } else {
        state.error = "Etwas schiefgegangen";
      }
    });

    builder.addCase(
      fetchUserChangePassword.fulfilled as any,
      (state, action) => {
        if (action.payload.success) {
          state.error = null;
          state.status = "passwordchanged";
        } else {
          state.error = "Etwas schiefgegangen";
          state.status = "error";
        }
      }
    );

    builder.addCase(
      fetchUserChangePassword.rejected as any,
      (state, action) => {
        state.status = "error";

        if (action.payload?.message) {
          state.error = action.payload.message;
        } else {
          state.error = "Etwas schiefgegangen";
        }
      }
    );

    builder.addCase(
      fetchUserEmailChangeSendCode.fulfilled as any,
      (state, action) => {
        if (action.payload.success) {
          state.error = null;
          state.status = "succeded";
          state.waitConfirmEmailChange = true;
        } else {
          state.error = "Etwas schiefgegangen";
          state.status = "error";
          state.waitConfirmEmailChange = false;
        }
      }
    );

    builder.addCase(
      fetchUserEmailChangeSendCode.rejected as any,
      (state, action) => {
        state.status = "error";
        state.waitConfirmEmailChange = false;

        if (action.payload?.message) {
          state.error = action.payload.message;
        } else {
          state.error = "Etwas schiefgegangen";
        }
      }
    );

    builder.addCase(
      fetchCashbackCreateUser.fulfilled as any,
      (state, action) => {
        if (action.payload.user) {
          state.error = null;
          state.status = "cashbackCreated";
          if (state.user) {
            state.user.isOnCashback = action.payload.user.isOnCashback;
          }
        } else {
          state.error = "Etwas schiefgegangen";
          state.status = "error";
        }
      }
    );

    builder.addCase(
      fetchCashbackCreateUser.rejected as any,
      (state, action) => {
        state.status = "error";

        if (action.payload?.message) {
          state.error = action.payload.message;
        } else {
          state.error = "Etwas schiefgegangen";
        }
      }
    );

    builder.addCase(fetchUserLogout.fulfilled as any, (state, action) => {
      state.user = initialState.user;
      state.rolesMap = initialState.rolesMap;
      state.token = initialState.token;
      state.gdprChecks = initialState.gdprChecks;
      state.error = null;
      state.status = "logouted";
      state.isAuthorized = false;
      state.isAuthorizing = false;
      state.waitCheck = false;
    });
  },
});

export const {
  clearError,
  clearStatus,
  clearEmailChangedStatus,
  clearWaitCheck,
} = authenticationSlice.actions;

export default authenticationSlice.reducer;

import React, { useMemo, useRef, useState } from "react";
import useWindowDimensions from "../../utils/useWindowDimentions";
import ContentBox from "../ContentBox/ContentBox";
import LongButton from "../LongButton/LongButton";
import { ReactComponent as AddIcon } from "../assets/images/add-ring-icon.svg";
import { ReactComponent as ArrowFilledIcon } from "../assets/images/arrow-filled-right.svg";
import { ReactComponent as Ellipse } from "../assets/images/ellipse.svg";
import { SlidePackage, SliderProps } from "./CategoriesPackagesSlider.d";
import styles from "./CategoriesPackagesSlider.module.css";

const visibleSlidesQty = 3;
const defaultPosition = 3;
const mobVisibleSlidesQty = 1;
const mobDefaultPosition = 1;
const defaultSlide = 0;

const movedTo = (items: Array<any>, moveTo: number) => {
  let newItems = [...items];
  let length = newItems.length;

  for (let i = 0; i < length - moveTo; i++) {
    newItems[i + moveTo] = items[i];

    if (i < moveTo) {
      newItems[i] = items[length - moveTo + i];
    }
  }

  return newItems;
};

const moveBackward = (items: Array<any>) => {
  let newItems = [...items];

  const length = newItems.length;
  const last = newItems[length - 1];

  for (let i = length - 1; i > 0; i--) {
    newItems[i] = newItems[i - 1];
  }

  newItems[0] = last;

  return newItems;
};

const moveForward = (items: Array<any>) => {
  let newItems = [...items];

  const length = newItems.length;
  const first = newItems[0];

  for (let i = 0; i < length - 1; i++) {
    newItems[i] = newItems[i + 1];
  }

  newItems[length - 1] = first;

  return newItems;
};

const CategoriesPackagesSlider: React.FC<SliderProps> = ({
  packages,
  showLoginBtn,
  loginBtnIcon,
}) => {
  const { width } = useWindowDimensions();
  const [active, setActive] = useState(defaultSlide);
  const childsLength = useMemo(() => packages.length, [packages]);
  const lineRef = useRef<HTMLDivElement>(null);

  const [slides, setSlides] = useState(
    movedTo(
      packages.map((item: SlidePackage, i: number) => (
        <img
          key={(item.idPackage || `pack_${i}`) + i}
          src={item.image}
          alt={item.name}
        />
      )),
      width > 768 ? defaultPosition : mobDefaultPosition
    )
  );

  const prevHandler = () => {
    if (active > 0) {
      setActive(prev => prev - 1);
    } else {
      setActive(childsLength - 1);
    }

    setSlides(moveBackward(slides));
  };

  const nextHandler = () => {
    if (active < childsLength - 1) {
      setActive(prev => prev + 1);
    } else {
      setActive(0);
    }

    setSlides(moveForward(slides));
  };

  return (
    <div className={styles.sliderContainer} data-testid="categories-slider">
      <div className={styles.slidesContainer}>
        <div className={styles.slides} style={{}}>
          {slides.map((item: any, i: number) => (
            <div
              key={i}
              className={`${styles.slide} ${
                (width > 768
                  ? defaultPosition + visibleSlidesQty
                  : mobDefaultPosition + mobVisibleSlidesQty) < i
                  ? styles.hidden
                  : ""
              }`}
            >
              {item}
            </div>
          ))}
        </div>

        <div className={styles.lineContainer} ref={lineRef}>
          <Ellipse className={styles.line} />
        </div>
      </div>

      <div className={styles.sliderContent}>
        <h3 className={styles.slideTitleContainer}>
          <div className={styles.sliderArrows}>
            <div
              className={styles.prev}
              onClick={prevHandler}
              data-testid="prev-category-button"
            >
              <ArrowFilledIcon className={styles.arrow} />
            </div>
          </div>

          <span data-testid="active-package-name">{packages[active].name}</span>

          <div className={`${styles.sliderArrows} ${styles.sliderArrowsNext}`}>
            <div
              className={styles.next}
              onClick={nextHandler}
              data-testid="next-category-button"
            >
              <ArrowFilledIcon className={styles.arrow} />
            </div>
          </div>
        </h3>

        <div className={styles.slideDescription}>
          <ContentBox className={styles.descBox}>
            <div className={styles.descContent}>
              <div className={styles.descTitle}>{packages[active].name}</div>

              <div className={styles.descText}>
                {packages[active].description}
              </div>
            </div>

            {packages[active].idPackage ? (
              <div className={styles.btnContainer}>
                <LongButton
                  variant="secondary"
                  text="Jetzt konfigurieren"
                  icon={AddIcon}
                  href={`/packages/${packages[active].idPackage}`}
                  className={styles.btn}
                />
              </div>
            ) : showLoginBtn ? (
              <div className={styles.btnContainer}>
                <LongButton
                  text="Jetzt zum Mitgliederbereich"
                  icon={loginBtnIcon || AddIcon}
                  href={`/login`}
                  className={styles.btn}
                />
              </div>
            ) : null}
          </ContentBox>
        </div>
      </div>
    </div>
  );
};

export default CategoriesPackagesSlider;

import React from "react";
import styles from "./InputField.module.css";
import { Field, ErrorMessage } from "formik";
import { InputFieldProps } from "./InputField.d";

const InputField: React.FC<InputFieldProps> = ({
  id,
  name,
  type,
  placeholder,
  hasErrors,
  errorText,
  labelText,
  component,
  containerClass = "",
  labelClass = "",
  inputClass = "",
  value,
  children,
  icon,
  rightIcon,
  options,
  rows,
  hideRequiredInLabel,
  maxDate,
  openToDate,
  disabled,
  rightIconClick,
  validateHandler,
  onChange,
  onBlur,
}) => {
  const IconComponent: any = icon;
  const RightIconComponent: any = rightIcon;
  return (
    <div className={`${styles.formGroup} ${containerClass}`}>
      {labelText ? (
        <label className={`${styles.inputLabel} ${labelClass}`} htmlFor={name}>
          {labelText}
          {validateHandler && !hideRequiredInLabel ? (
            <>
              &nbsp;<span>*</span>
            </>
          ) : (
            ""
          )}
        </label>
      ) : null}

      <div className={`${styles.inputContainer} ${inputClass}`}>
        <Field
          id={id}
          name={name}
          placeholder={placeholder}
          validate={validateHandler}
          {...(type === "select" || type === "textarea"
            ? { as: type }
            : { type })}
          className={`${name} ${styles.formControl} ${
            icon ? styles.withLeftIcon : ""
          } ${rightIcon || type === "select" ? styles.withRightIcon : ""} ${
            hasErrors || errorText ? styles.formControlError : ""
          }`}
          {...(hasErrors || errorText
            ? {
                "aria-errormessage": `${name}-errorMessage`,
                "aria-invalid": true,
              }
            : { "aria-invalid": false })}
          {...(value ? { value } : null)}
          {...(component ? { component } : null)}
          {...(onChange ? { onChange } : null)}
          {...(options ? { options } : null)}
          {...(rows ? { rows } : null)}
          {...(maxDate ? { maxDate } : null)}
          {...(openToDate ? { openToDate } : null)}
          {...(disabled ? { disabled } : null)}
          {...(onBlur ? { onBlur } : null)}
        >
          {children}
        </Field>

        {icon && typeof icon !== "string" ? (
          <label htmlFor={name} className={styles.leftIconContainer}>
            <IconComponent className={styles.leftIcon} />
          </label>
        ) : null}

        {rightIcon ? (
          <label
            className={`${styles.rightIconContainer} ${
              rightIconClick ? styles.rightIconClick : ""
            }`}
            {...(rightIconClick
              ? { onClick: rightIconClick }
              : { htmlFor: name })}
            data-testid="right-icon"
          >
            <RightIconComponent className={styles.rightIcon} />
          </label>
        ) : null}

        {errorText ? (
          <span className={styles.fieldError} id={`${name}-errorMessage`}>
            {errorText}
          </span>
        ) : (
          <ErrorMessage name={name}>
            {errMsg => (
              <span className={styles.fieldError} id={`${name}-errorMessage`}>
                {errMsg}
              </span>
            )}
          </ErrorMessage>
        )}
      </div>
    </div>
  );
};

export default InputField;

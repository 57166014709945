import { Helmet } from "react-helmet-async";

const SettingsFront = () => {
  return (
    <>
      <Helmet>
        <title>Cash & Protect Portal</title>
        <meta
          name="description"
          content="Allgemeine Schutzbrief Servicegesellschaft"
        />
      </Helmet>
    </>
  );
};

export default SettingsFront;

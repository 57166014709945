import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APICall } from "../../utils/api";
import { FaqArticleState } from "./faqSlice.d";

const initialState: FaqArticleState = {
  faqArticle: {
    _id: "",
    question: "",
    answer: "",
  },
  status: "idle",
  error: null,
};

export const fetchFaqArticle = createAsyncThunk(
  "faq/fetchFaqArticle",
  async (questionId: string, { rejectWithValue }) => {
    return await APICall("infoservice.faq", "getone-article", rejectWithValue, {
      idArticle: questionId,
    });
  }
);

export const faqArticleSlice = createSlice({
  name: "faqArticle",
  initialState,
  reducers: {
    changeStatus: (state, action) => {
      state.status = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchFaqArticle.fulfilled as any, (state, action) => {
      state.faqArticle = action.payload;
      state.error = null;
      state.status = "succeded";
    });

    builder.addCase(fetchFaqArticle.rejected as any, (state, action) => {
      if (action?.payload?.message) state.error = action.payload.message;
      else state.error = "Etwas schiefgegangen";

      state.status = "error";
    });
  },
});

export const { changeStatus } = faqArticleSlice.actions;

export default faqArticleSlice.reducer;

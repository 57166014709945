import React from "react";
import FormattedNumber from "../../components/FormattedNumber/FormattedNumber";
import RangeNumbersSimple from "../../components/RangeNumbersSimple/RangeNumbersSimple";
import { ReactComponent as HelpIcon } from "../../images/info-icon.svg";
import { Product } from "../../redux/reducers/calculatorSlice.d";
import { numberToDECurrency } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimentions";
import { API_SERVER } from "../../variables_config";
import { MandatoryDataRecalcProps } from "../Calculator/Calculator.d";
import ProductItemMonthes from "./ProductItemMonthes";

interface ProductItemProps {
  product: Product;
  styles: any;
  paymentTitle: string;
  changeServiceValueToCalc: (productId: string) => any;
  actionStatusIcon: (product: Product) => any;
  setModalContent: (v: any) => void;
  saveMandatoryDataRecalc?: (props: MandatoryDataRecalcProps) => void;
}

const mobFrom = 1044;
const productNameMaxLength = 26;

const ProductItem: React.FC<ProductItemProps> = ({
  product,
  styles,
  paymentTitle,
  changeServiceValueToCalc,
  actionStatusIcon,
  setModalContent,
  saveMandatoryDataRecalc,
}) => {
  const { width } = useWindowDimensions();

  return (
    <div
      key={product.idProduct}
      className={`${styles.configParamsItem} ${
        !product.isActive ? styles.configParamsItemNotActive : ""
      }`}
    >
      <h3 className={styles.configParamsItemTitle}>
        <div className={styles.titleContent}>
          <span>
            {width >= mobFrom &&
            (product.additionalName || product.name).length >
              productNameMaxLength
              ? `${(product.additionalName || product.name).slice(
                  0,
                  productNameMaxLength
                )}...`
              : product.additionalName || product.name}
          </span>
          <div
            className={`${styles.iconContainer} ${styles.productReadMore}`}
            onClick={() =>
              setModalContent({
                idService: product.idProduct,
                name: product.additionalName || product.name,
                popupTitle: product.popupTitle,
                popupBody: product.popupBody,
                bottomText: "Unser Partner",
                bottomImg: product?.insuranceLogo
                  ? `${API_SERVER}${product?.insuranceLogo}`
                  : "",
                rightImg: `${API_SERVER}${product.image}`,
              })
            }
          >
            <HelpIcon className={styles.icon} />
          </div>
        </div>

        {width < mobFrom && actionStatusIcon(product)}
      </h3>

      <div className={styles.configParamsItemValues}>
        <div className={styles.rangeContainer}>
          {product.calcMethod !== 1 &&
            product.calcMethod !== 8 &&
            product.calcMethod !== 9 && (
              <>
                {product.minValue !== 0 &&
                product.maxValue !== 0 &&
                product.valueToCalculate !== 0 ? (
                  <>
                    {width < mobFrom && (
                      <div className={styles.priceHint}>Leistung</div>
                    )}

                    <div className={styles.rangeItemsContainer}>
                      {(product.calcMethod === 13 ||
                        product.calcMethod === 17) && (
                        <ProductItemMonthes
                          product={product}
                          styles={styles}
                          saveMandatoryDataRecalc={saveMandatoryDataRecalc}
                        />
                      )}
                      <RangeNumbersSimple
                        min={product.minValue}
                        max={product.maxValue}
                        current={product.valueToCalculate || 0}
                        step={product.step}
                        active={product.isActive}
                        arrowClass={styles.arrowsClass}
                        changeHandler={changeServiceValueToCalc(
                          product.idProduct
                        )}
                      />
                    </div>
                  </>
                ) : (
                  product.valueToCalculate !== 0 && (
                    <div className={styles.totalContainer}>
                      {product.valueToCalculate > 0 ? (
                        <>
                          <FormattedNumber value={product.valueToCalculate} /> €
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  )
                )}
              </>
            )}
        </div>

        <div
          className={`${styles.priceContainer} ${
            product.isCore ? styles.paddingRight : ""
          }`}
        >
          {product.calcMethod !== 9 && (
            <>
              {width < mobFrom && (
                <div className={styles.priceHint}>{paymentTitle}</div>
              )}

              <div className={styles.totalContainer}>
                <span>{numberToDECurrency(product.total)}</span>
              </div>

              {width >= mobFrom && actionStatusIcon(product)}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductItem;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APICall } from "../../utils/api";
import {
  ServiceToCalc,
  CalculatorState,
  AdditionalInfoAnswer,
} from "./calculatorSlice.d";

const apiVersion = "1.0.0";

const initialState: CalculatorState = {
  error: null,
  status: "idle",
  letterData: {
    idPackage: "",
    active: false,
    name: "",
    minimum: 0,
    maximum: 0,
    defaultValue: 0,
    defaultVat: 0,
    defaultIPT: 0,
    image: "",
    logo: "",
    yearValue: 0,
    AGB: "",
    claim: "",
    packageNum: "",
    paymentModality: "",
    paymentModalityTitle: "",
    dateInFuture: "",
    products: [],
  },
  servicesPackagesValues: [],
  isCalculating: false,
  isRecalculated: false,
};

export const fetchCreateAnounLetter = createAsyncThunk(
  "letters/CreateAnounLetter",
  // Declare the type your function argument here:
  async (
    {
      BIC,
      idPage,
      idBank,
    }: {
      BIC?: string;
      idPage?: string;
      idBank?: string;
    },
    { rejectWithValue }
  ) => {
    return await APICall("backend.packages", "create-anon", rejectWithValue, {
      ...(BIC ? { BIC } : {}),
      ...(idPage ? { idPage } : {}),
      ...(idBank ? { idBank } : {}),
      ver: apiVersion,
    });
  }
);

export const fetchRecalcLetter = createAsyncThunk(
  "letters/RecalcLetter",
  // Declare the type your function argument here:
  async (
    {
      idPackage,
      products,
      createDraft,
      additionalInfoAns,
    }: {
      idPackage: string;
      products?: Array<ServiceToCalc>;
      createDraft: boolean;
      additionalInfoAns?: Array<AdditionalInfoAnswer>;
    },
    { rejectWithValue }
  ) => {
    return await APICall("backend.packages", "recalc", rejectWithValue, {
      idPackage,
      createDraft,
      ...(products ? { products } : {}),
      ...(additionalInfoAns ? { additionalInfoAns } : {}),
      ver: apiVersion,
    });
  }
);

export const fetchAdditionalRecalcLetter = createAsyncThunk(
  "letters/fetchAdditionalRecalcLetter",
  // Declare the type your function argument here:
  async (
    {
      idPackage,
      products,
      createDraft,
      additionalInfoAns,
    }: {
      idPackage: string;
      products?: Array<ServiceToCalc>;
      createDraft: boolean;
      additionalInfoAns?: Array<AdditionalInfoAnswer>;
    },
    { rejectWithValue }
  ) => {
    return await APICall("backend.packages", "recalc", rejectWithValue, {
      idPackage,
      createDraft,
      ...(products ? { products } : {}),
      ...(additionalInfoAns ? { additionalInfoAns } : {}),
      ver: apiVersion,
    });
  }
);

export const fetchCalcLetterData = createAsyncThunk(
  "letters/fetchCalcLetterData",
  async ({ letterId }: { letterId: string }, { rejectWithValue }) => {
    return await APICall("backend.commerce", "getone", rejectWithValue, {
      idPackage: letterId,
      populate: true,
      ver: apiVersion,
    });
  }
);

export const fetchSetUserLetter = createAsyncThunk(
  "letters/fetchSetUserLetter",
  async (
    {
      idPackage,
      idUser,
      isActive,
    }: { idPackage: string; idUser: string; isActive: boolean },
    { rejectWithValue }
  ) => {
    return await APICall("backend.letter", "set-user", rejectWithValue, {
      idPackage,
      idUser,
      isActive,
    });
  }
);

export const calculatorSlice = createSlice({
  name: "calculator",
  initialState,
  reducers: {
    changeLetterActiveStatus: (state, action) => {
      state.letterData.active = action.payload;
    },
    clearStatus: (state, action) => {
      state.status = action.payload;
    },
    resetState: (state, action) => {
      state.error = initialState.error;
      state.status = initialState.status;
      state.letterData = initialState.letterData;
    },
  },
  extraReducers: builder => {
    builder.addCase(
      fetchCreateAnounLetter.fulfilled as any,
      (state, action) => {
        state.letterData = action.payload;
        state.status = "succeded";
        state.isRecalculated = false;
      }
    );

    builder.addCase(fetchCreateAnounLetter.rejected as any, (state, action) => {
      if (action?.payload?.message) state.error = action.payload.message;
      else state.error = "Etwas schiefgegangen";
    });

    builder.addCase(fetchRecalcLetter.fulfilled as any, (state, action) => {
      state.letterData = action.payload;
      //state.status = "";
      state.isRecalculated = true;
      state.isCalculating = false;
    });

    builder.addCase(fetchRecalcLetter.pending as any, (state, action) => {
      state.isCalculating = true;
    });

    builder.addCase(fetchRecalcLetter.rejected as any, (state, action) => {
      if (action?.payload?.message) {
        state.error = action.payload.message;
      } else {
        state.error = "Etwas schiefgegangen";
      }
      state.isCalculating = false;

      state.isRecalculated = false;
    });

    builder.addCase(
      fetchAdditionalRecalcLetter.fulfilled as any,
      (state, action) => {
        state.letterData = action.payload;
        state.status = "recalc";
        state.isRecalculated = true;
        //state.isCalculating = false;
      }
    );

    builder.addCase(
      fetchAdditionalRecalcLetter.pending as any,
      (state, action) => {
        //state.isCalculating = true;
      }
    );

    builder.addCase(
      fetchAdditionalRecalcLetter.rejected as any,
      (state, action) => {
        if (action?.payload?.message) {
          state.error = action.payload.message;
        } else {
          state.error = "Etwas schiefgegangen";
        }

        state.status = "error";
        //state.isCalculating = false;
        state.isRecalculated = false;
      }
    );

    builder.addCase(fetchCalcLetterData.fulfilled as any, (state, action) => {
      state.letterData = action.payload;
      state.status = "succeded";

      state.error = null;
      state.isRecalculated = false;
    });

    builder.addCase(fetchCalcLetterData.rejected as any, (state, action) => {
      if (action?.payload?.message) {
        state.error = action.payload.message;
      } else {
        state.error = "Etwas schiefgegangen";
      }

      state.status = "error";
      state.isRecalculated = false;
    });

    builder.addCase(fetchSetUserLetter.fulfilled as any, (state, action) => {
      if (action.payload.success)
        state.status = action.payload.success ? "succeded" : "error";
    });

    builder.addCase(fetchSetUserLetter.rejected as any, (state, action) => {
      state.status = "error";
    });
  },
});

// Action creators are generated for each case reducer function
export const { changeLetterActiveStatus, clearStatus, resetState } =
  calculatorSlice.actions;

export default calculatorSlice.reducer;

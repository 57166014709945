import { Form, Formik } from "formik";
import React from "react";
import Button from "../../components/Button/Button";
import FormikDatePicker from "../../components/FormikDatePicker/FormikDatePicker";
import InputField from "../../components/InputField/InputField";
import { validateRequiredField } from "../../utils/formHelper";
import { MandatoryModalContainerProps } from "./MandatoryModalContainer.d";
import styles from "./MandatoryModalContainer.module.css";

const MandatoryModalContainer: React.FC<MandatoryModalContainerProps> = ({
  mandatoryData,
  saveMandatoryData,
}) => {
  const getInitValues = () => {
    let initValues = {};

    if (mandatoryData) {
      initValues = mandatoryData.reduce<{ [key: string]: any }>((acc, item) => {
        acc[item.idParam] = "";
        return acc;
      }, {});
    }

    return initValues;
  };

  const onSubmit = (
    values: { [key: string]: any },
    { resetForm }: { resetForm: () => void }
  ) => {
    saveMandatoryData(values);
  };

  return (
    <div className={styles.mandatoryContainer} data-testid="mandatoryContainer">
      <Formik {...{ initialValues: getInitValues(), onSubmit }}>
        {({ errors, touched }) => (
          <Form className={styles.formContainer} noValidate>
            {mandatoryData &&
              mandatoryData.map((item, i: number) => {
                if (item.type === 1) {
                  return (
                    <InputField
                      key={item.idParam}
                      id={item.idParam}
                      name={item.idParam}
                      type="text"
                      placeholder=""
                      labelText={item.title}
                      hasErrors={
                        errors.hasOwnProperty(item.idParam) &&
                        touched.hasOwnProperty(item.idParam)
                      }
                      validateHandler={validateRequiredField}
                      component={FormikDatePicker}
                      icon={"calendar"}
                    />
                  );
                }

                if (item.type === 2 || item.type === 3) {
                  return (
                    <InputField
                      key={item.idParam}
                      id={item.idParam}
                      name={item.idParam}
                      type={item.type === 2 ? "number" : "text"}
                      placeholder=""
                      labelText={item.title}
                      hasErrors={
                        errors.hasOwnProperty(item.idParam) &&
                        touched.hasOwnProperty(item.idParam)
                      }
                      validateHandler={validateRequiredField}
                    />
                  );
                }

                if (item.type === 4) {
                  return (
                    <InputField
                      key={item.idParam}
                      id={item.idParam}
                      name={item.idParam}
                      type="select"
                      placeholder=""
                      labelText={item.title}
                      hasErrors={
                        errors.hasOwnProperty(item.idParam) &&
                        touched.hasOwnProperty(item.idParam)
                      }
                      validateHandler={validateRequiredField}
                    >
                      <option value="" label="Auswählen..." />
                      {item.options &&
                        item.options.map((option: any, j: number) => (
                          <option key={j} value={option} label={option} />
                        ))}
                    </InputField>
                  );
                }

                return null;
              })}

            <div className={styles.btnContainer}>
              <Button type="submit" variant="main" text="Save" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MandatoryModalContainer;

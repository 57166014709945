import { Form, Formik } from "formik";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BlockContainer from "../../components/BlockContainer/BlockContainer";
import Button from "../../components/Button/Button";
import ContentBox from "../../components/ContentBox/ContentBox";
import FormikReactSelect from "../../components/FormikReactSelect/FormikReactSelect";
import InputField from "../../components/InputField/InputField";
import TitleLine from "../../components/TitleLine/TitleLine";
import MainWrapper from "../../containers/MainWrapper/MainWrapper";
import { ReactComponent as CloseIcon } from "../../images/close-round-icon.svg";
import { ReactComponent as PhoneIcon } from "../../images/phone-icon.svg";
import {
  changeStatus,
  clearError,
  fetchSendMessageSupport,
} from "../../redux/reducers/mailSupportSlice";
import { MailSupportValues } from "../../redux/reducers/mailSupportSlice.d";
import {
  changeStatus as changeRequestCategoriesStatus,
  fetchRequestCategoriesList,
} from "../../redux/reducers/requestCategoriesSlice";
import {
  authSelector,
  mailSupportSelector,
  requestCategoriesSelector,
} from "../../redux/selectors";
import { successMessage } from "../../utils";
import { validateRequiredField } from "../../utils/formHelper";
import styles from "./ServiceCenterPage.module.css";
import { useAppDispatch } from "../../redux/selectors";

const ServiceCenterPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { user } = useSelector(authSelector);
  const { requestCategoriesList, status: requestCategoriesStatus } =
    useSelector(requestCategoriesSelector);
  const { status } = useSelector(mailSupportSelector);

  const initialValues: MailSupportValues = {
    firstName: user?.firstName,
    lastName: user?.lastName,
    from: user ? user?.username : "",
    cc: "",
    requestCategory: "",
    subject: "",
    body: "",
  };

  const getSelectedCategory = (categories: any, categoryId: string) => {
    return categories.find((category: any) => category._id === categoryId);
  };

  const onSubmit = (
    values: MailSupportValues,
    { resetForm }: { resetForm: () => void }
  ) => {
    let sendValues = { ...values };
    const categoryId = sendValues.requestCategory.value;
    let selectedCategory = getSelectedCategory(
      requestCategoriesList,
      categoryId
    );

    if (!selectedCategory) {
      requestCategoriesList.some(category => {
        selectedCategory = getSelectedCategory(
          category.subcategories,
          categoryId
        );

        if (selectedCategory) {
          return true;
        }

        return false;
      });
    }

    if (selectedCategory) {
      sendValues.requestCategory = selectedCategory?.name;
      sendValues.cc = selectedCategory?.email;
    }

    dispatch(clearError(null));
    resetForm();
    dispatch(fetchSendMessageSupport({ values: sendValues }));
  };

  const requestCategoriesOptions = () => {
    if (requestCategoriesList.length === 0) {
      return [];
    }

    return requestCategoriesList.map(category => ({
      label: category.name,
      options: category.subcategories
        ? category.subcategories.map(subcategory => ({
            value: subcategory._id,
            label: subcategory.name,
          }))
        : [],
    }));
  };

  useEffect(() => {
    if (requestCategoriesStatus === "idle") {
      dispatch(fetchRequestCategoriesList({ perPage: 999 }));
    }
  }, [dispatch, requestCategoriesStatus]);

  useEffect(() => {
    if (status === "succeded") {
      successMessage(`Nachricht gesendet`);

      dispatch(changeStatus("idle"));
    }
  }, [status, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(changeRequestCategoriesStatus("idle"));
    };
  }, [dispatch]);

  return (
    <MainWrapper>
      <BlockContainer>
        <div className={styles.blockContainer}>
          <ContentBox className={styles.block}>
            <TitleLine className={styles.title}>
              Unser Kontakt - Dein Anliegen
            </TitleLine>

            <Button
              type="icon"
              icon={CloseIcon}
              className={styles.closeBtn}
              clickHandler={() => navigate("/home")}
            />

            <div className={styles.phoneBlock}>
              <h3>Du möchtest uns direkt erreichen?</h3>
              <h3 className={styles.phoneContainer}>
                <span>Wir sind für dich da.</span>
                <a href="tel:+4920549899965" className={styles.phoneA}>
                  <PhoneIcon className={styles.phoneIcon} />
                  +49 2054 989 99 65
                </a>
              </h3>
            </div>

            <div className={styles.requiredInfo}>
              Die mit * gekennzeichneten Daten sind Pflichtfelder
            </div>

            <Formik {...{ initialValues, onSubmit }} enableReinitialize>
              {({ errors, touched }) => (
                <Form className={styles.form} noValidate>
                  <InputField
                    id="firstName"
                    name="firstName"
                    type="text"
                    placeholder=""
                    labelText="Vorname"
                    hasErrors={
                      errors.hasOwnProperty("firstName") &&
                      touched.hasOwnProperty("firstName")
                    }
                    containerClass={styles.formGroupInline}
                    labelClass={styles.label}
                    inputClass={styles.input}
                  />

                  <InputField
                    id="lastName"
                    name="lastName"
                    type="text"
                    placeholder=""
                    labelText="Nachname"
                    hasErrors={
                      errors.hasOwnProperty("lastName") &&
                      touched.hasOwnProperty("lastName")
                    }
                    containerClass={styles.formGroupInline}
                    labelClass={styles.label}
                    inputClass={styles.input}
                  />

                  <InputField
                    id="from"
                    name="from"
                    type="text"
                    placeholder=""
                    labelText="Deine E-Mail-Adresse, an die wir Dir unsere Antwort schicken können"
                    hasErrors={
                      errors.hasOwnProperty("from") &&
                      touched.hasOwnProperty("from")
                    }
                    validateHandler={validateRequiredField}
                    containerClass={`${styles.formGroupInline} ${styles.inputAlignTop}`}
                    labelClass={styles.label}
                    inputClass={styles.input}
                  />

                  <InputField
                    id="requestCategory"
                    name="requestCategory"
                    type="text"
                    placeholder="Auswählen..."
                    labelText="Bitte wähle eine Rubrik, die zu Deinem Anliegen passt"
                    component={FormikReactSelect}
                    options={requestCategoriesOptions()}
                    hasErrors={
                      errors.hasOwnProperty("requestCategory") &&
                      touched.hasOwnProperty("requestCategory")
                    }
                    validateHandler={validateRequiredField}
                    containerClass={`${styles.formGroupInline} ${styles.inputAlignTop}`}
                    labelClass={styles.label}
                    inputClass={styles.input}
                  />

                  <InputField
                    id="subject"
                    name="subject"
                    type="text"
                    placeholder=""
                    labelText="Deine Mehrwert-Paket Nummer (falls zur Hand)"
                    hasErrors={
                      errors.hasOwnProperty("subject") &&
                      touched.hasOwnProperty("subject")
                    }
                    //validateHandler={validateRequiredField}
                    containerClass={`${styles.formGroupInline} ${styles.inputAlignTop}`}
                    labelClass={styles.label}
                    inputClass={styles.input}
                  />

                  <InputField
                    id="body"
                    name="body"
                    type="textarea"
                    placeholder="Deine Eingabe"
                    labelText="Bitte beschreibe hier Dein Anliegen oder Deine Frage"
                    rows="6"
                    hasErrors={
                      errors.hasOwnProperty("body") &&
                      touched.hasOwnProperty("body")
                    }
                    validateHandler={validateRequiredField}
                    containerClass={`${styles.formGroupInline} ${styles.inputAlignTop}`}
                    labelClass={styles.label}
                    inputClass={styles.input}
                  />

                  <div className={styles.btnContainer}>
                    <Button type="submit" variant="secondary" text="Absenden" />
                  </div>
                </Form>
              )}
            </Formik>
          </ContentBox>
        </div>
      </BlockContainer>
    </MainWrapper>
  );
};

export default ServiceCenterPage;

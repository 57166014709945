import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import DefaultModal from "../../components/DefaultModal/DefaultModal";
import ModalBody from "../../components/ModalBody/ModalBody";
import {
  fetchDiscontinuePackage,
  resetStatus,
} from "../../redux/reducers/buyPackageSlice";
import {
  authSelector,
  buyPackageSelector,
  calcSelector,
  useAppDispatch,
} from "../../redux/selectors";
import useModal from "../../utils/useModal";
import styles from "./PackagePage.module.css";
import BlockFour from "./components/BlockFour";
import BlockOne from "./components/BlockOne";
import BlockThree from "./components/BlockThree";
import BlockTwo from "./components/BlockTwo";

const PackagePage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useSelector(authSelector);
  const { letterData, isRecalculated } = useSelector(calcSelector);
  const { status: buypackageStatus } = useSelector(buyPackageSelector);
  const { isShowing: isShowingReturnConfirm, toggle: toggleReturnConfirm } =
    useModal();

  const clickReturnPackage = useCallback(() => {
    dispatch(fetchDiscontinuePackage(letterData?.idPackage));
  }, [letterData?.idPackage, dispatch]);

  const clickCloseReturnModal = useCallback(() => {
    toggleReturnConfirm();
    navigate("/home");
  }, [toggleReturnConfirm, navigate]);
  useEffect(() => {
    if (buypackageStatus === "returned") {
      toggleReturnConfirm();
      dispatch(resetStatus("idle"));
    }
  }, [buypackageStatus, toggleReturnConfirm, dispatch]);

  return (
    <>
      <BlockOne letterData={letterData} styles={styles} />
      <BlockTwo letterData={letterData} styles={styles} />

      <BlockThree
        letterData={letterData}
        isShowSubmitBtn={letterData.isBought ? isRecalculated : true}
        styles={styles}
        returnConfirm={clickReturnPackage}
      />
      <BlockFour letterData={letterData} styles={styles} />

      <DefaultModal
        showModal={isShowingReturnConfirm}
        title={"Vielen Dank, Ihre Kündigung ist bei uns eingegangen."}
        closeHandler={clickCloseReturnModal}
      >
        <ModalBody>
          <>
            <p>
              Wir haben Ihnen eine Bestätigung an <b>{user?.username}</b>{" "}
              gesendet.
            </p>
            <p>
              Antrags- oder Vertragsnummer: <b>{letterData.packageNum}</b>
            </p>
            <h3>So geht es weiter:</h3>
            <ol>
              <li>
                <p>
                  <b>Ihre Kündigung wird weitergeleitet</b>
                </p>
                <p>
                  Wir leiten Ihre Kündigung in wenigen Minuten direkt an die R+V
                  Versicherung weiter. Außerdem senden wir Ihnen noch eine
                  Bestätigungsemail zu Ihrer Kündigung.
                </p>
              </li>
              <li>
                <p>
                  <b>Die Versicherung prüft Ihre Kündigung</b>
                </p>
                <p>
                  Nachdem die Versicherung Ihre Kündigung geprüft hat, erhalten
                  Sie eine entsprechende Rückmeldung von uns oder von der
                  Versicherung selbst.
                </p>
              </li>
            </ol>
          </>
        </ModalBody>

        <div className={styles.modalFooter}>
          <Button
            variant="secondary"
            clickHandler={clickCloseReturnModal}
            text={"Bestätigung"}
            className={styles.actionBtn}
          />
        </div>
      </DefaultModal>
    </>
  );
};

export default PackagePage;

import React, { memo } from "react";
import BlockContainer from "../../../components/BlockContainer/BlockContainer";
import ContentBox from "../../../components/ContentBox/ContentBox";
import LongButton from "../../../components/LongButton/LongButton";
import { BlockProps } from "../PackageTemplateDefaultPage.d";
import { ReactComponent as BagIcon } from "../../../images/bag-icon.svg";
import TitleLine from "../../../components/TitleLine/TitleLine";
import Li from "../../../components/Li/Li";
import { IS_CASHBACK } from "../../../variables_config";

const BlockFour: React.FC<BlockProps> = ({ letterData, styles }) => {
  return (
    <BlockContainer>
      <div className={styles.blockFourContainer}>
        <div className={styles.twoDivs}>
          <div className={styles.half}>
            <TitleLine>
              <div className={`${styles.texted} ${styles.titledText}`} />
              <div
                className={`${styles.texted} ${styles.titledText}`}
                style={{ width: "80%" }}
              />
            </TitleLine>
          </div>
          <div className={styles.half}></div>
        </div>

        <ContentBox className={styles.blockFour}>
          <ul className={styles.ulBlock}>
            <Li className={styles.li}>
              <div className={styles.texted} />
            </Li>
            <Li className={styles.li}>
              <div className={styles.texted} />
            </Li>
            <Li className={styles.li}>
              <div className={styles.texted} />
            </Li>
            <Li className={styles.li}>
              <div className={styles.texted} />
            </Li>
          </ul>

          <div className={styles.videoContainer}>
            <div className={styles.crossed} />
          </div>
          {IS_CASHBACK && (
            <div className={styles.btnContainer}>
              <LongButton
                text="Weitere Angebote ansehen"
                icon={BagIcon}
                href="/cashback"
              />
            </div>
          )}
        </ContentBox>
      </div>
    </BlockContainer>
  );
};

export default memo(BlockFour);

import React from "react";
import BlockContainer from "../../../components/BlockContainer/BlockContainer";
import ContentBox from "../../../components/ContentBox/ContentBox";
import LongButton from "../../../components/LongButton/LongButton";
import CircleIcon from "../../../components/CircleIcon/CircleIcon";
import { ReactComponent as UserIcon } from "../../../images/user-icon.svg";

import { ReactComponent as EditIcon } from "../../../images/edit-pencil-icon.svg";
import { ReactComponent as ConfigIcon } from "../../../images/config-icon.svg";
import { ReactComponent as OrderIcon } from "../../../images/order-icon.svg";
import { ReactComponent as BoxIcon } from "../../../images/box-alt-icon.svg";
import TitleLine from "../../../components/TitleLine/TitleLine";
import { BlockProps } from "../LandingPage.d";

const BlockTwo: React.FC<BlockProps> = ({ styles }) => {
  return (
    <BlockContainer>
      <div className={styles.blockTwoContainer}>
        <h3>Deine Vorteile</h3>
        <ContentBox className={styles.blockTwo}>
          <TitleLine>Dein Mehrwert in unseren Mehrwert-Paketen</TitleLine>

          <div className={styles.itemsContainer}>
            <ul className={styles.ulBlock}>
              <li>
                <div className={styles.liIconContainer}>
                  <CircleIcon icon={EditIcon} />
                </div>
                <div className={styles.liContent}>
                  <p className={styles.liTitle}>Maßgeschneideter Schutz:</p>
                  <p>Du bestimmst wie viel Absicherung du haben möchtest.</p>
                </div>
              </li>
              <li>
                <div className={styles.liIconContainer}>
                  <CircleIcon icon={ConfigIcon} />
                </div>
                <div className={styles.liContent}>
                  <p className={styles.liTitle}>
                    Jederzeit monatlich anpassbar (oder kündbar):
                  </p>
                  <p>
                    Denn faire Verträge sind nicht an lange Laufzeiten oder
                    starre policen geknüpft.
                  </p>
                </div>
              </li>
              <li>
                <div className={styles.liIconContainer}>
                  <CircleIcon icon={OrderIcon} />
                </div>
                <div className={styles.liContent}>
                  <p className={styles.liTitle}>Absolut unbürokratisch:</p>
                  <p>
                    Du hast sofort Zugriff auf alle Dokumente und kannst die
                    Anpassungen vornehmen, die du gerade brauchst.
                  </p>
                </div>
              </li>
              <li>
                <div className={styles.liIconContainer}>
                  <CircleIcon icon={BoxIcon} />
                </div>
                <div className={styles.liContent}>
                  <p className={styles.liTitle}>
                    Zu unschlagbaren Konditionen:
                  </p>
                  <p>
                    Denn wir sind konzern-unabhängig und können jeweils beste
                    Angebote für dich verhandeln.
                  </p>
                </div>
              </li>
            </ul>
          </div>

          <div className={styles.btnContainer}>
            <LongButton
              text="Jetzt zum Mitgliederbereich"
              icon={UserIcon}
              href="/login"
            />
          </div>
        </ContentBox>
      </div>
    </BlockContainer>
  );
};

export default BlockTwo;

import { createSlice, AsyncThunk } from "@reduxjs/toolkit";

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
type RejectedAction = ReturnType<GenericAsyncThunk["rejected"]>;

interface InitialState {
  error: string | null;
}

const initialState: InitialState = {
  error: "",
};

export const errorCatchingSlice = createSlice({
  name: "errorCatching",
  initialState,
  reducers: {
    clearError: (state, action) => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder.addMatcher<RejectedAction>(
      action =>
        action.type.endsWith("/rejected") &&
        !action.type.includes("fetchCheckOrRefreshToken"),
      (state, action: any) => {
        //console.log('addMatcher RejectedAction', { state, action });
        if (action && action?.payload?.message) {
          state.error = action.payload.message;
        } else {
          state.error = action.error;
        }
      }
    );
  },
});

export const { clearError } = errorCatchingSlice.actions;

export default errorCatchingSlice.reducer;

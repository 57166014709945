import styles from "./BlockContainer.module.css";
import { Props } from "./BlockContainer.d";

const BlockContainer: React.FC<Props> = ({ bgClass, fixed, children }) => {
  return (
    <div
      className={`${styles.wrapperContainer} ${bgClass ? bgClass : ""}`}
      data-testid="blockContainer"
    >
      <div className={`${styles.container} ${fixed ? styles.fixedWidth : ""}`}>
        {children}
      </div>
    </div>
  );
};

export default BlockContainer;

import React from "react";
import styles from "./Li.module.css";
import { Props } from "./Li.d";

const Li: React.FC<Props> = ({ icon = true, className = "", children }) => {
  return (
    <li className={`${styles.li} ${className} ${icon ? styles.icon : ""}`}>
      {children}
    </li>
  );
};

export default Li;

import React, { useState } from "react";
import InputField from "../InputField/InputField";
//import styles from "./PasswordInputField.module.css";
import { PasswordInputFieldProps } from "./PasswordInputField.d";
import { ReactComponent as EyeClosedIcon } from "../assets/images/eye-closed-icon.svg";
import { ReactComponent as EyeIcon } from "../assets/images/eye-icon.svg";

const types = ["password", "text"];

const PasswordInputField: React.FC<PasswordInputFieldProps> = ({
  id,
  name,
  placeholder,
  hasErrors,
  labelText,
  containerClass = "",
  labelClass = "",
  inputClass = "",
  icon,
  hideRequiredInLabel,
  validateHandler,
}) => {
  const [fieldType, setFieldType] = useState(0);
  return (
    <div>
      <InputField
        id={id}
        name={name}
        type={types[fieldType]}
        placeholder={placeholder}
        labelText={labelText}
        hasErrors={hasErrors}
        validateHandler={validateHandler}
        containerClass={containerClass}
        labelClass={labelClass}
        inputClass={inputClass}
        rightIcon={fieldType ? EyeIcon : EyeClosedIcon}
        rightIconClick={() => setFieldType(prev => +!prev)}
        {...(icon ? { icon } : null)}
        {...(hideRequiredInLabel ? { hideRequiredInLabel } : null)}
      />
    </div>
  );
};

export default PasswordInputField;

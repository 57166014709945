import React from "react";
import Button from "../Button/Button";
import DefaultModal from "../DefaultModal/DefaultModal";
import styles from "./ConfirmationCodeModal.module.css";
import { ConfirmationCodeModalProps } from "./ConfirmationCodeModal.d";

const ConfirmationCodeModal: React.FC<ConfirmationCodeModalProps> = ({
  title,
  showModal,
  submitDisabled,
  twoWayOptions,
  secondsToResendCode,
  onInputChange,
  closeModal,
  onSubmit,
  onResend,
  onChangeTwoWayOptions,
}) => {
  return (
    <DefaultModal
      showModal={showModal}
      closeHandler={() => closeModal()}
      title={title}
      className={styles.confimationModalContent}
    >
      <div
        className={styles.confimationContainer}
        data-testid={"confirmationCodeModal"}
      >
        <div className={styles.confimationContent}>
          <h3>Bitte hier den Bestätigungscode eingeben</h3>

          <div className={styles.confirmationInput}>
            <input
              type="text"
              className={styles.formControl}
              placeholder="Code"
              onChange={(e: any) => onInputChange(e.target.value)}
              data-testid={"confirmationInput"}
            />
          </div>

          <div className={styles.resendContainer}>
            {secondsToResendCode ? (
              <p className={styles.resendCountdown}>
                Resend code in {secondsToResendCode} sec
              </p>
            ) : onResend ? (
              <>
                {twoWayOptions && twoWayOptions.length > 0 ? (
                  <div className={styles.resendAlternativeContainer}>
                    <p className={styles.resendAlternativeP}>
                      Have troubles receiving code?
                    </p>
                    <select
                      className={styles.formControl}
                      onChange={e => {
                        onChangeTwoWayOptions &&
                          onChangeTwoWayOptions(e.target.value);
                      }}
                      data-testid={"dropdown"}
                    >
                      <option value="" label="Alternative method" />
                      {twoWayOptions.map((item: string) => (
                        <option key={item} value={item} label={item} />
                      ))}
                    </select>
                  </div>
                ) : null}

                <Button
                  variant="link"
                  text="Resend code"
                  clickHandler={onResend}
                />
              </>
            ) : null}
          </div>

          <div className={styles.confirmationBtn}>
            <Button
              type="button"
              variant="primary-office"
              text="Bestätigen"
              disabled={submitDisabled}
              clickHandler={() => onSubmit()}
            />
          </div>
        </div>
      </div>
    </DefaultModal>
  );
};

export default ConfirmationCodeModal;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APICall } from "../../utils/api";
import { ThemeState } from "./themeStylesSlice.d";
import { INSTANCE } from "../../variables_config";

const initialState: ThemeState = {
  colors: [],
  status: "idle",
  isPending: false,
};

export const fetchThemeStyles = createAsyncThunk(
  "themeStyles/fetchThemeStyles",
  async (_, { rejectWithValue }) => {
    return await APICall("themeservice.themes", "getone", rejectWithValue, {
      instance: INSTANCE,
      defaultTheme: true,
    });
  }
);

export const themeStylesSlice = createSlice({
  name: "themeStyles",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchThemeStyles.fulfilled as any, (state, action) => {
      state.colors = action.payload.colors;
      state.status = "succeded";
      state.isPending = false;
    });

    builder.addCase(fetchThemeStyles.pending as any, (state, action) => {
      state.isPending = true;
    });

    builder.addCase(fetchThemeStyles.rejected as any, (state, action) => {
      state.isPending = false;
      state.status = "error";
    });
  },
});

export default themeStylesSlice.reducer;

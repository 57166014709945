import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchUserLogout } from "../../redux/reducers/authenticationSlice";
import { authSelector, useAppDispatch } from "../../redux/selectors";
import useWindowDimensions from "../../utils/useWindowDimentions";
import logo from "../assets/images/header-logo.svg";
import logoMob from "../assets/images/header-mob-logo.svg";
import rnvLogo from "../assets/images/ruv/logo_DBNA.png";
import rnvlogoMob from "../assets/images/ruv/logo.png";
import { ReactComponent as SignIcon } from "../assets/images/sign-icon.svg";
import BlockContainer from "../BlockContainer/BlockContainer";
import HeaderLinkItem from "../HeaderLinkItem/HeaderLinkItem";
import MobileNav from "./components/MobileNav";
import { HeaderProps, LinkItem } from "./MainHeader.d";
import styles from "./MainHeader.module.css";
import { IS_RVN_BRAND } from "../../variables_config";

const mobFrom = 1044;

const MainHeader: React.FC<HeaderProps> = ({ links }) => {
  const dispatch = useAppDispatch();
  const { width } = useWindowDimensions();
  const { isAuthorized, user } = useSelector(authSelector);

  const logoutHandler = () => {
    dispatch(fetchUserLogout());
    //dispatch(fetchCashbackLogout());
  };

  return (
    <header className={`${styles.header} `} data-testid="header">
      <BlockContainer>
        <div className={styles.headerContent}>
          <div className={styles.logoContainer}>
            <Link to={isAuthorized ? "/home" : "/"}>
              <img
                src={
                  IS_RVN_BRAND
                    ? width > mobFrom
                      ? rnvLogo
                      : rnvlogoMob
                    : width > mobFrom
                    ? logo
                    : logoMob
                }
                className={styles.logo}
                alt="logo"
              />
            </Link>
          </div>

          {isAuthorized ? (
            width <= mobFrom ? (
              <div className={styles.titleContainer}>Mitgliederbereich</div>
            ) : null
          ) : (
            <div className={styles.titleContainer}>
              {" "}
              {IS_RVN_BRAND
                ? "R+V-CashProtect Portal"
                : "Cash & Protect Portal"}
            </div>
          )}

          {width > mobFrom ? (
            <div className={styles.navContainer}>
              <nav className={styles.nav}>
                <ul className={styles.ul}>
                  {isAuthorized && links
                    ? links.map((link: LinkItem, i: number) => (
                        <li key={i + 1} className={styles.li}>
                          <HeaderLinkItem
                            text={link.title}
                            href={link.href}
                            icon={link.icon}
                          />
                        </li>
                      ))
                    : null}

                  <li className={styles.li}>
                    {isAuthorized ? (
                      <button
                        className={styles.logoutBtn}
                        onClick={logoutHandler}
                      >
                        <span className={styles.signIconContainer}>
                          <SignIcon className={styles.signIcon} />
                        </span>
                        <span>Logout</span>
                      </button>
                    ) : (
                      <Link to="/login" className={styles.loginBtn}>
                        <span className={styles.signIconContainer}>
                          <SignIcon className={styles.signIcon} />
                        </span>
                        <span>Login</span>
                      </Link>
                    )}
                  </li>
                </ul>
              </nav>
            </div>
          ) : (
            <MobileNav
              links={links}
              userName={user?.firstName}
              isAuthorized={isAuthorized}
              styles={styles}
              logoutHandler={logoutHandler}
            />
          )}
        </div>
      </BlockContainer>
    </header>
  );
};

export default MainHeader;

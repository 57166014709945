import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APICall } from "../../utils/api";
import { AddressState } from "./addressSlice.d";

export const initialState: AddressState = {
  suggestions: null,
  status: "idle",
  error: null,
};

export const fetchFindAddress = createAsyncThunk(
  "address/fetchFindAddress",
  async (query: string, { rejectWithValue }) => {
    return await APICall("infoservice.address", "find", rejectWithValue, {
      query,
    });
  }
);

export const addressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {
    clearSuggestions: (state, action) => {
      state.suggestions = action.payload;
    },
    clearStatus: (state, action) => {
      state.status = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchFindAddress.fulfilled as any, (state, action) => {
      state.suggestions = action.payload;
      state.status = "succeded";
      state.error = null;
    });

    builder.addCase(fetchFindAddress.rejected as any, (state, action) => {
      if (action?.payload?.message) {
        state.error = action.payload.message;
      } else {
        state.error = "Etwas schiefgegangen";
      }

      state.status = "error";
    });
  },
});

export const { clearSuggestions, clearStatus } = addressSlice.actions;

export default addressSlice.reducer;

import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import LoaderComponent from "../../components/LoaderComponent/LoaderComponent";
import { fetchCheckOrRefreshToken } from "../../redux/reducers/authenticationSlice";
import { RootState } from "../../redux/store";
import { ROUTE } from "../../variables_config";
import styles from "./CheckAuth.module.css";
import { useAppDispatch } from "../../redux/selectors";

const CheckAuth: React.FC<any> = ({ children }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { status, expireAt, isAuthorizing, isAuthorized, user } = useSelector(
    (state: RootState) => state.authentication
  );

  useEffect(() => {
    const startDate = Date.now();
    const expiredDate = expireAt ? new Date(expireAt) : 0;
    const howlong = expiredDate
      ? expiredDate.getTime() - startDate - 10 * 1000
      : 0;

    const verifyTokenTimer = setTimeout(() => {
      dispatch(fetchCheckOrRefreshToken());
    }, howlong);

    return () => {
      clearTimeout(verifyTokenTimer);
    };
  }, [dispatch, expireAt]);

  if (status === "idle" || isAuthorizing) {
    return (
      <div className={styles.wrapper}>
        <LoaderComponent />
      </div>
    );
  }
  // console.log("checkauth", {
  //   pathname: window.location.pathname,
  //   newHref: `${window.location.origin}${ROUTE}${
  //     ROUTE === "/" ? "" : "/"
  //   }login`,
  // });

  if (
    !isAuthorizing &&
    !isAuthorized &&
    !user?.confirmed &&
    window.location.pathname !== `${ROUTE}${ROUTE === "/" ? "" : "/"}` &&
    window.location.pathname !== `${ROUTE}${ROUTE === "/" ? "" : "/"}login`
  ) {
    // window.location.href = `${window.location.origin}${ROUTE}${
    //   ROUTE === "/" ? "" : "/"
    //   }login`;

    return (
      // <Redirect
      //   to={{
      //     pathname: "/login",
      //     state: { from: location },
      //   }}
      // />

      <Navigate to={"/login"} replace state={{ from: location }} />
    );
  }

  return children;
};

export default CheckAuth;

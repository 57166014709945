import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { NumericFormat } from "react-number-format";
import { MandatoryDataRequestItem } from "../../redux/reducers/calculatorSlice.d";
import useTimeout from "../../utils/useTimeout";
import { API_SERVER } from "../../variables_config";
import { ReactComponent as HelpIcon } from "../assets/images/info-icon.svg";
import ContentBox from "../ContentBox/ContentBox";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import { CalcProductElectronicProps } from "./CalcProductElectronic.d";
import styles from "./CalcProductElectronic.module.css";
import { getFormulaItem } from "./helper";

const delay = 500;
const FORMULA_ONE_Y = "VK1D0";
const FORMULA_ONE_Y_D = "VK1D1";
const FORMULA_TWO_Y = "VK2D0";
const FORMULA_TWO_Y_D = "VK2D1";
const FORMULA_THREE_LEFT = "GL2";
const FORMULA_THREE_RIGHT = "GL3";

const CalcProductElectronic: React.FC<CalcProductElectronicProps> = ({
  product,
  setModalContent,
  changeServiceIsActive,
  saveMandatoryDataRecalc,
}) => {
  const [timerDelay, setTimerDelay] = useState<number | null>(null);
  const [inputValue, setInputValue] = useState(
    product.valueToCalculate || product.minValue
  );
  const [isDSelected, setIsDSelected] = useState<boolean>(false);
  const [isTwoYearsSelected, setIsTwoYearsSelected] = useState<boolean>(false);

  const vkValue = useRef<MandatoryDataRequestItem | undefined>(undefined);
  const glValue = useRef<string | undefined>(undefined);

  const formulaItemVK1D0 = useMemo(
    () => getFormulaItem(product.mandatoryDataRequest, FORMULA_ONE_Y),
    [product.mandatoryDataRequest]
  );

  const formulaItemVK1D1 = useMemo(
    () => getFormulaItem(product.mandatoryDataRequest, FORMULA_ONE_Y_D),
    [product.mandatoryDataRequest]
  );

  const formulaItemVK2D0 = useMemo(
    () => getFormulaItem(product.mandatoryDataRequest, FORMULA_TWO_Y),
    [product.mandatoryDataRequest]
  );

  const formulaItemVK2D1 = useMemo(
    () => getFormulaItem(product.mandatoryDataRequest, FORMULA_TWO_Y_D),
    [product.mandatoryDataRequest]
  );

  const formulaItemGL2 = useMemo(
    () => getFormulaItem(product.mandatoryDataRequest, FORMULA_THREE_LEFT),
    [product.mandatoryDataRequest]
  );

  const formulaItemGL3 = useMemo(
    () => getFormulaItem(product.mandatoryDataRequest, FORMULA_THREE_RIGHT),
    [product.mandatoryDataRequest]
  );

  const findSelectedFormulaItem = useCallback(
    (checkboxValues: Array<MandatoryDataRequestItem>) => {
      return checkboxValues.find((item: MandatoryDataRequestItem) =>
        product.mandatoryData.some(
          (cItem: MandatoryDataRequestItem) => cItem.idParam === item.idParam
        )
      );
    },
    [product.mandatoryData]
  );

  const onToggleChange = useCallback(
    ({
      valueToCalculate,
      vkValue,
      glValue,
    }: {
      valueToCalculate: number;
      vkValue?: string;
      glValue?: string;
    }) => {
      if (vkValue || glValue) {
        saveMandatoryDataRecalc({
          idProduct: product.idProduct,
          isActive: product.isActive,
          valueToCalculate,
          mandatoryDataRequest: product.mandatoryDataRequest,
          values: {
            ...(vkValue ? { [vkValue]: valueToCalculate } : {}),
            ...(glValue ? { [glValue]: valueToCalculate } : {}),
          },
        });
      }
    },
    [
      product.idProduct,
      product.isActive,
      product.mandatoryDataRequest,
      saveMandatoryDataRecalc,
    ]
  );

  const changeInput = useCallback(
    (targetValue: number) => {
      let value = targetValue;

      if (value < product.minValue) {
        value = product.minValue;
      } else if (value > product.maxValue) {
        value = product.maxValue;
      }

      setTimerDelay(null);
      setInputValue(value);

      onToggleChange({
        valueToCalculate: value,
        vkValue: vkValue.current?.idParam,
      });
    },
    [vkValue, product.maxValue, product.minValue, onToggleChange]
  );

  const getCurrentVKSelected = useCallback(
    (defaultValue: MandatoryDataRequestItem | undefined) => {
      if (
        formulaItemVK1D0 &&
        formulaItemVK1D1 &&
        formulaItemVK2D0 &&
        formulaItemVK2D1
      ) {
        return findSelectedFormulaItem([
          formulaItemVK1D0,
          formulaItemVK1D1,
          formulaItemVK2D0,
          formulaItemVK2D1,
        ]);
      }

      return defaultValue;
    },
    [
      formulaItemVK1D0,
      formulaItemVK1D1,
      formulaItemVK2D0,
      formulaItemVK2D1,
      findSelectedFormulaItem,
    ]
  );

  const setCurrentSelected = useCallback(
    (isTwoYearsSelected: boolean, isDSelected: boolean) => {
      if (
        formulaItemVK1D0 &&
        formulaItemVK1D1 &&
        formulaItemVK2D0 &&
        formulaItemVK2D1
      ) {
        return isTwoYearsSelected
          ? isDSelected
            ? formulaItemVK2D1
            : formulaItemVK2D0
          : isDSelected
          ? formulaItemVK1D1
          : formulaItemVK1D0;
      }

      return undefined;
    },
    [formulaItemVK1D0, formulaItemVK1D1, formulaItemVK2D0, formulaItemVK2D1]
  );

  const getCurrentGLSelected = useCallback(
    (defaultValue: MandatoryDataRequestItem | undefined) => {
      if (formulaItemGL2 && formulaItemGL3) {
        return findSelectedFormulaItem([formulaItemGL2, formulaItemGL3]);
      }

      return defaultValue;
    },
    [formulaItemGL2, formulaItemGL3, findSelectedFormulaItem]
  );

  const getCurrentTwoYearsState = (
    mItem: MandatoryDataRequestItem | undefined
  ) => {
    return mItem?.title?.includes("VK2") || false;
  };

  const getCurrentDState = (mItem: MandatoryDataRequestItem | undefined) => {
    return mItem?.title?.includes("D1") || false;
  };

  useEffect(() => {
    const fromBack = getCurrentVKSelected(undefined);

    if (fromBack) {
      vkValue.current = fromBack;
      setIsDSelected(getCurrentDState(vkValue.current));
      setIsTwoYearsSelected(getCurrentTwoYearsState(vkValue.current));
    }
  }, [
    getCurrentVKSelected,
    formulaItemVK1D0,
    formulaItemVK1D1,
    formulaItemVK2D0,
    formulaItemVK2D1,
  ]);

  useEffect(() => {
    const fromBack = getCurrentGLSelected(formulaItemGL2);

    if (fromBack) {
      glValue.current = fromBack?.idParam;
    }
  }, [getCurrentGLSelected, formulaItemGL2]);

  useTimeout(() => changeInput(inputValue), timerDelay);

  if (!product) {
    return null;
  }

  return (
    <div
      className={`${styles.productContainer} ${
        !product.isActive ? styles.productNotActive : ""
      }`}
      data-testid={"CalcProductElectronic"}
    >
      <ContentBox className={styles.contentBox}>
        <div className={styles.productTop}>
          <div className={styles.statusContainer}>
            {!product.isCore && (
              <>
                <div
                  className={`${styles.status} ${
                    !product.isCore && !product.isActive ? styles.active : ""
                  }`}
                >
                  pausiert
                </div>
                <ToggleSwitch
                  disabled={product.isCore}
                  isToggleOn={product.isActive}
                  clickHandler={changeServiceIsActive(product.idProduct)}
                />
                <div
                  className={`${styles.status} ${
                    !product.isCore && product.isActive ? styles.active : ""
                  }`}
                >
                  aktiviert
                </div>
              </>
            )}
          </div>

          <div className={styles.titleContainer}>
            <h3 className={styles.title}>
              {(product.additionalName || product.name).length > 80
                ? `${(product.additionalName || product.name).slice(0, 80)}...`
                : product.additionalName || product.name}
            </h3>
            <div
              data-testid="open-modal-button"
              className={styles.productReadMore}
              onClick={() =>
                setModalContent({
                  idService: product.idProduct,
                  name: product.additionalName || product.name,
                  popupTitle: product.popupTitle,
                  popupBody: product.popupBody,
                  bottomText: "Unser Partner",
                  bottomImg: product?.insuranceLogo
                    ? `${API_SERVER}${product?.insuranceLogo}`
                    : "",
                  rightImg: `${API_SERVER}${product.image}`,
                })
              }
            >
              <HelpIcon className={styles.helpIcon} />
            </div>
          </div>

          <span>
            {product.additionalNameDesc ||
              (formulaItemGL2
                ? "Wähle hier die Dauer Deiner Garantieverlängerung"
                : "Wähle hier Umfang und Dauer Deiner Vollkaskoversicherung")}
          </span>
        </div>

        <div className={styles.productContent}>
          {!product.idParent ? (
            <div className={styles.wdMob}>
              <span className={styles.perMnth}>
                {product.paymentPeriodTitle || (
                  <>
                    Preis Deines
                    <br />
                    Smartphones:
                  </>
                )}
              </span>
              <div className={styles.inputField}>
                {true /*product.maxValue !== 0*/ ? (
                  <NumericFormat
                    value={inputValue}
                    disabled={!product.isActive}
                    className={styles.rangeValue}
                    displayType={"input"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    onValueChange={values => {
                      const { value } = values;

                      setTimerDelay(delay);
                      setInputValue(value !== "" ? +value : 0);
                    }}
                  />
                ) : (
                  <div className={styles.rangeABValue}>
                    <span>{product.ab && "ab "}0</span>
                  </div>
                )}
              </div>
            </div>
          ) : null}

          {product.mandatoryDataRequest && (
            <>
              {formulaItemVK1D0 &&
                formulaItemVK1D1 &&
                formulaItemVK2D0 &&
                formulaItemVK2D1 && (
                  <>
                    <div className={styles.statusContainer}>
                      <div
                        className={`${styles.status} ${
                          !isDSelected ? styles.active : ""
                        }`}
                      >
                        ohne Diebstahl
                      </div>
                      <ToggleSwitch
                        isToggleOn={isDSelected}
                        clickHandler={state => {
                          setIsDSelected(state);

                          vkValue.current = setCurrentSelected(
                            isTwoYearsSelected,
                            state
                          );

                          onToggleChange({
                            valueToCalculate: inputValue,
                            vkValue: vkValue.current?.idParam,
                          });
                        }}
                      />
                      <div
                        className={`${styles.status} ${
                          isDSelected ? styles.active : ""
                        }`}
                      >
                        inklusive Diebstahl
                      </div>
                    </div>

                    <div className={styles.checkboxLabel}>
                      Versicherungsdauer
                    </div>

                    <div className={styles.statusContainer}>
                      <div
                        className={`${styles.status} ${
                          !isTwoYearsSelected ? styles.active : ""
                        }`}
                      >
                        1 Jahr
                      </div>
                      <ToggleSwitch
                        isToggleOn={isTwoYearsSelected}
                        clickHandler={state => {
                          setIsTwoYearsSelected(state);

                          vkValue.current = setCurrentSelected(
                            state,
                            isDSelected
                          );

                          onToggleChange({
                            valueToCalculate: inputValue,
                            vkValue: vkValue.current?.idParam,
                          });
                        }}
                      />
                      <div
                        className={`${styles.status} ${
                          isTwoYearsSelected ? styles.active : ""
                        }`}
                      >
                        2 Jahre
                      </div>
                    </div>
                  </>
                )}

              {formulaItemGL2 && formulaItemGL3 && (
                <div className={styles.statusContainer}>
                  <div
                    className={`${styles.status} ${
                      !(
                        getCurrentGLSelected(formulaItemGL2)?.idParam ===
                        formulaItemGL3?.idParam
                      )
                        ? styles.active
                        : ""
                    }`}
                  >
                    2 Jahre
                  </div>
                  <ToggleSwitch
                    isToggleOn={
                      getCurrentGLSelected(formulaItemGL2)?.idParam ===
                      formulaItemGL3?.idParam
                    }
                    {...(!product.isActive ? { disabled: true } : {})}
                    clickHandler={state => {
                      glValue.current = state
                        ? formulaItemGL3?.idParam
                        : formulaItemGL2?.idParam;

                      onToggleChange({
                        valueToCalculate:
                          product.valueToCalculate || product.minValue,
                        glValue: glValue.current,
                      });
                    }}
                  />
                  <div
                    className={`${styles.status} ${
                      getCurrentGLSelected(formulaItemGL2)?.idParam ===
                      formulaItemGL3?.idParam
                        ? styles.active
                        : ""
                    }`}
                  >
                    3 Jahre
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </ContentBox>
    </div>
  );
};

export default CalcProductElectronic;

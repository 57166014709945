import { Link } from "react-router-dom";
import MainWrapper from "../../containers/MainWrapper/MainWrapper";
import BlockContainer from "../../components/BlockContainer/BlockContainer";
import styles from "./ImpressumPage.module.css";

const ImpressumPage = () => {
  return (
    <MainWrapper>
      <BlockContainer fixed={true}>
        <div className={styles.block}>
          <h1 className={styles.h1}>Impressum und Datenschutz</h1>

          <h3 className={styles.h2}>
            Anbieterkennzeichnung nach § 5 Telemediengesetz (TMG) und DL-InfoV:
            Name der verantwortlichen Stelle
          </h3>

          <p>
            Allgemeine Schutzbrief Service GmbH
            <br />
            Kemmannsweg 13, 45219 Essen
          </p>

          <p>
            Die Allgemeine Schutzbrief Service GmbH ist ein Dienstleister und
            Vertriebspartner für Banken und Sparkassen und setzt innovative
            Schutzbrief-Konzepte für deren Kunden um.
          </p>

          <div className={styles.halfs}>
            <div className={styles.halfsItem}>
              <div className={styles.halfLeft}>
                <p>
                  <b>Handelsregister</b>
                </p>
              </div>
              <div className={styles.halfRight}>
                <p>Nr.: HRB 32714, Amtsgericht Essen</p>
              </div>
            </div>

            <div className={styles.halfsItem}>
              <div className={styles.halfLeft}>
                <p>
                  <b>Umsatzsteueridentifikationsnummer</b>
                </p>
              </div>
              <div className={styles.halfRight}>
                <p>DE347140990</p>
              </div>
            </div>

            <div className={styles.halfsItem}>
              <div className={styles.halfLeft}>
                <p>
                  <b>Geschäftsführer:</b>
                </p>
              </div>
              <div className={styles.halfRight}>
                <p>David Furtwängler</p>
              </div>
            </div>

            <div className={styles.halfsItem}>
              <div className={styles.halfLeft}>
                <p>
                  <b>Anschrift und Sitz der Gesellschaft:</b>
                </p>
              </div>
              <div className={styles.halfRight}>
                <p>Kemmannsweg 13, 45219 Essen</p>
                <p>E-Mail: info@allgemeine-schutzbrief.de</p>
              </div>
            </div>

            <div className={styles.halfsItem}>
              <div className={styles.halfLeft}>
                <p>
                  <b>Redaktioneller Inhalt:</b>
                </p>
              </div>
              <div className={styles.halfRight}>
                <p>Verantwortung gem. § 55(2) RStV: David Furtwängler</p>
              </div>
            </div>
          </div>

          <h3 className={styles.h2}>
            Allgemeine Geschäftsbedingungen für Kaufleute:
          </h3>

          <p>
            Für alle Verträge mit der Allgemeine Schutzbrief Service GmbH gilt
            nach den Allgemeinen Geschäftsbedingungen das Recht der
            Bundesrepublik Deutschland unter Ausschluss des einheitlichen
            UN-Kaufrechts (CISG). Regelmäßig ist Gerichtsstand für alle
            Streitigkeiten aus oder im Zusammenhang mit Verträgen der
            Geschäftssitz der Allgemeine Schutzbrief Service GmbH, sofern der
            Auftraggeber Kaufmann oder eine juristische Person des öffentlichen
            Rechts oder öffentlich-rechtlichen Sondervermögens ist. Die
            Allgemeine Schutzbrief Service GmbH bleibt berechtigt, am
            allgemeinen Gerichtsstand des Auftraggebers Klage oder andere
            gerichtliche Verfahren zu erheben oder einzuleiten.
          </p>

          <h3 className={styles.h2}>
            Allgemeine Geschäftsbedingungen für Verbraucher:
          </h3>

          <p>
            Plattform der Europäischen Kommission zur Online-Streitbeilegung
            (OS) für Verbraucher:{" "}
            <a
              href="https://ec.europa.eu/consumers/odr"
              target="_blank"
              rel="nofollow noreferrer"
            >
              ec.europa.eu/consumers/odr
            </a>
          </p>

          <p>
            Die Allgemeine Schutzbrief Service GmbH ist nicht bereit, an einem
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teilzunehmen.
          </p>

          <p>
            Zu den <Link to="/agb">AGB</Link>
          </p>

          <h3 className={styles.h2}>Datenschutzerklärung</h3>

          <p>
            Siehe dazu unsere <Link to="/datenschutz">Datenschutzhinweise</Link>
          </p>

          <h3 className={styles.h2}>
            Erstinformationen gem. der VersVermVO Versicherungsvermittler:
          </h3>

          <div className={styles.halfs}>
            <div className={styles.halfsItem}>
              <div className={styles.halfLeft}>
                <p>Zuständige Aufsichtsbehörde:</p>
              </div>
              <div className={styles.halfRight}>
                <p>Industrie- und Handelskammer zu Essen</p>
                <p>Postanschrift: IHK zu Essen, 45117 Essen</p>
              </div>
            </div>
            <div className={styles.halfsItem}>
              <div className={styles.halfLeft}>
                <p>Tätigkeitsart:</p>
              </div>
              <div className={styles.halfRight}>
                <p>
                  Versicherungsvertreter mit Erlaubnis nach § 34d Abs. 1 GewO
                </p>
              </div>
            </div>
            <div className={styles.halfsItem}>
              <div className={styles.halfLeft}>
                <p>Versicherungsvermittler-Register:</p>
              </div>
              <div className={styles.halfRight}>
                <p>Registernummer: D-YFRX-SB48N-34</p>
              </div>
            </div>
            <div className={styles.halfsItem}>
              <div className={styles.halfLeft}>
                <p>Registerabruf unter:</p>
              </div>
              <div className={styles.halfRight}>
                <p>
                  <a
                    href="https://www.vermittlerregister.info"
                    target="_blank"
                    rel="nofollow noreferrer"
                  >
                    www.vermittlerregister.info
                  </a>
                </p>
              </div>
            </div>
          </div>

          <p>
            Bei Streitigkeiten zwischen Versicherungsvermittlern und
            Versicherungsunternehmern kann folgende Schlichtungsstelle angerufen
            werden:
            <br />
            Versicherungsombudsmann e.V., Postfach 080632, 10006 Berlin
            <br />(
            <a
              href="https://www.versicherungsombudsmann.de"
              target="_blank"
              rel="nofollow noreferrer"
            >
              www.versicherungsombudsmann.de
            </a>
            )
          </p>
        </div>
      </BlockContainer>
    </MainWrapper>
  );
};

export default ImpressumPage;

import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { Formik, Form } from "formik";
import InputField from "../../components/InputField/InputField";
import BlockContainer from "../../components/BlockContainer/BlockContainer";
import Button from "../../components/Button/Button";
import ContentBox from "../../components/ContentBox/ContentBox";
import MainWrapper from "../../containers/MainWrapper/MainWrapper";
import { authSelector, useAppDispatch } from "../../redux/selectors";
import {
  clearError,
  fetchUserRestorePassword,
} from "../../redux/reducers/authenticationSlice";
import {
  validateConfirmPassword,
  validatePassword,
} from "../../utils/formHelper";
import { getQueryStringParams, successMessage } from "../../utils";
import { ReactComponent as PasswordIcon } from "../../images/password-icon.svg";
import styles from "./PasswordResetPage.module.css";
import { PasswordResetFormValues } from "./PasswordResetPage.d";

const PasswordResetPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { status } = useSelector(authSelector);
  const { idRestore } = getQueryStringParams(window.location.search);

  const initialValues: PasswordResetFormValues = {
    password: "",
    confirmPassword: "",
    idRestore,
  };

  const onSubmit = (values: PasswordResetFormValues) => {
    dispatch(clearError(null));
    dispatch(fetchUserRestorePassword(values));
  };

  useEffect(() => {
    if (status === "succeded") {
      successMessage(`Passwort aktualisiert`);

      setTimeout(() => navigate("/login"), 2000);
    }
  }, [status, navigate]);

  return (
    <MainWrapper hideFooter={true}>
      <BlockContainer>
        <div className={styles.loginBlock}>
          <ContentBox className={styles.contentBox}>
            <div className={styles.loginContent}>
              <h1>Passwort reset</h1>

              <Formik {...{ initialValues, onSubmit }}>
                {({ errors, touched, values }) => (
                  <Form className={styles.formContainer} noValidate>
                    <InputField
                      key="password"
                      id="password"
                      name="password"
                      type="password"
                      placeholder="Passwort"
                      labelText="Passwort"
                      hasErrors={
                        errors.hasOwnProperty("password") &&
                        touched.hasOwnProperty("password")
                      }
                      validateHandler={validatePassword}
                      icon={PasswordIcon}
                    />

                    <InputField
                      key="confirmPassword"
                      id="confirmPassword"
                      name="confirmPassword"
                      type="password"
                      placeholder="Passwort wiederholen"
                      labelText="Passwort wiederholen"
                      hasErrors={
                        errors.hasOwnProperty("confirmPassword") &&
                        touched.hasOwnProperty("confirmPassword")
                      }
                      validateHandler={(value: string) =>
                        validateConfirmPassword(values.password, value)
                      }
                      icon={PasswordIcon}
                    />

                    <div className={styles.btnContainer}>
                      <Button
                        type="submit"
                        variant="secondary"
                        text="Update pasword"
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </ContentBox>
        </div>
      </BlockContainer>
    </MainWrapper>
  );
};

export default PasswordResetPage;

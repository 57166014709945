import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  clearStatus,
  clearSuggestions,
  fetchFindAddress,
} from "../../redux/reducers/addressSlice";
import { SuggestionItem } from "../../redux/reducers/addressSlice.d";
import { addressSelector, useAppDispatch } from "../../redux/selectors";
import useTimeout from "../../utils/useTimeout";
import { InputAdressAutocompleteProps } from "./InputAdressAutocomplete.d";
import styles from "./InputAdressAutocomplete.module.css";

const delay = 300;
const searchLength = 3;

const InputAdressAutocomplete: React.FC<InputAdressAutocompleteProps> = ({
  className,
  value,
  name,
  placeholder = "",
  disabled,
  onInputChange,
  onSuggestionChange,
  onBlur,
}) => {
  const dispatch = useAppDispatch();
  const [timerDelay, setTimerDelay] = useState<number | null>(null);
  const { suggestions, status } = useSelector(addressSelector);

  const [showSuggestions, setShowSuggestions] = useState(false);
  const [searchInput, setSearchInput] = useState(value);

  const onChange = (e: any) => {
    const currValue = e.currentTarget.value;
    onInputChange(currValue);
    setSearchInput(currValue);

    if (currValue.trim().length >= searchLength) {
      setTimerDelay(delay);
    }
  };

  const onSuggestionClick = (suggestion: SuggestionItem) => {
    setSearchInput(suggestion.street);
    onInputChange(suggestion.street);
    onSuggestionChange(suggestion);

    setShowSuggestions(false);
    dispatch(clearSuggestions([]));
  };

  useEffect(() => {
    setSearchInput(value);
  }, [value]);

  useEffect(() => {
    if (status === "succeded") {
      setShowSuggestions(true);
      dispatch(clearStatus("idle"));
    }
  }, [status, suggestions, dispatch]);

  useTimeout(() => {
    dispatch(fetchFindAddress(searchInput.trim()));
    setTimerDelay(null);
  }, timerDelay);

  return (
    <div
      className={styles.searchContainer}
      onBlur={() => setShowSuggestions(false)}
      data-testid={"searchContainer"}
    >
      <input
        type="text"
        name={name}
        value={searchInput}
        placeholder={placeholder}
        className={className}
        onChange={onChange}
        onBlur={onBlur}
        {...(disabled ? { disabled: true } : {})}
      />

      {showSuggestions && searchInput ? (
        suggestions && suggestions.length ? (
          <ul className={styles.suggestions}>
            {suggestions.map((suggestion: SuggestionItem) => (
              <li
                key={suggestion._id}
                className={styles.suggestion}
                onMouseDown={(e: any) => {
                  e.preventDefault();
                  onSuggestionClick(suggestion);
                }}
              >
                {suggestion.street}, {suggestion.postalCode}, {suggestion.city}
              </li>
            ))}
          </ul>
        ) : null
      ) : null}
    </div>
  );
};

export default InputAdressAutocomplete;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APICall } from "../../utils/api";
import { PackageState } from "./packageSlice.d";

const apiVersion = "1.0.0";

const initialState: PackageState = {
  packageData: {
    idPackage: "",
    name: "",
    active: false,
    bought: false,
    dateStart: "",
    dateEnd: "",
    defaultValue: 0,
    idBank: "",
    image: "",
    logo: "",
    tag: "",
    idInsurance: "",
    claim: "",
    bookingText: "",
    triggers: "",
    AGB: "",
    paymentModality: "",
    premiumFreeTerm: 0,
    minimalContractualDuration: 0,
    minAge: 0,
    maxAge: 0,
    endAge: 0,
    distributionPerson: "",
    anbieterPerson: "",
    comment: "",
    products: [],
  },
  walletPass: null,
  status: "idle",
  error: null,
};

export const fetchPackageData = createAsyncThunk(
  "package/PackageData",
  async ({ packageId }: { packageId: string }, { rejectWithValue }) => {
    return await APICall("backend.commerce", "getone", rejectWithValue, {
      idPackage: packageId,
      populate: true,
      ver: apiVersion,
    });
  }
);

export const fetchPackageWalletPass = createAsyncThunk(
  "package/fetchPackageWalletPass",
  async (packageId: string, { rejectWithValue }) => {
    return await APICall(
      "backend.commerce",
      "get-wallet-pass",
      rejectWithValue,
      {
        idPackage: packageId,
      }
    );
  }
);

export const packageSlice = createSlice({
  name: "package",
  initialState,
  reducers: {
    changePackageActiveStatus: (state, action) => {
      state.packageData.active = action.payload;
    },
    clearPass: (state, action) => {
      state.walletPass = initialState.walletPass;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchPackageData.fulfilled as any, (state, action) => {
      console.log("fetchPackageData", action);
      state.packageData = action.payload;
      state.error = null;
    });

    builder.addCase(fetchPackageData.rejected as any, (state, action) => {
      if (action?.payload?.message) state.error = action.payload.message;
      else state.error = "Etwas schiefgegangen";
    });

    builder.addCase(
      fetchPackageWalletPass.fulfilled as any,
      (state, action) => {
        console.log("fetchPackageWalletPass", action);
        state.walletPass = action.payload.file;
        state.error = null;
      }
    );

    builder.addCase(fetchPackageWalletPass.rejected as any, (state, action) => {
      if (action?.payload?.message) {
        state.error = action.payload.message;
      } else {
        state.error = "Etwas schiefgegangen";
      }
    });
  },
});

export const { changePackageActiveStatus, clearPass } = packageSlice.actions;

export default packageSlice.reducer;

import {
  MandatoryDataRequestItem,
  Product,
  TariffDataItem,
} from "../../redux/reducers/calculatorSlice.d";

export const FORMULA_MONTH_TAB = "monthTab";

export const returnPackageValue = (
  index: number,
  min: number | boolean,
  curr: number | boolean,
  max: number | boolean
) => (index === 0 ? min : index === 2 ? max : curr);

export const returnPackageBooleanValue = (
  index: number,
  min: boolean,
  curr: boolean,
  max: boolean
) => (index === 0 ? min : index === 2 ? max : curr);

export const getFormulaParamById = (
  id: string,
  params: Array<MandatoryDataRequestItem> | undefined
) => {
  return params?.find((item: MandatoryDataRequestItem) => item.idParam === id);
};

export const getFormulaItem = (
  mandatoryData: Array<MandatoryDataRequestItem>,
  title: string
) =>
  mandatoryData && mandatoryData.length
    ? mandatoryData.find(item => item.title === title)
    : undefined;

export const getFormulaItemByType = (
  mandatoryData: Array<MandatoryDataRequestItem>,
  type: number
) =>
  mandatoryData && mandatoryData.length
    ? mandatoryData.find(item => item.type === type)
    : undefined;

export const getTarifItemById = (
  tarifData: Array<TariffDataItem> | undefined,
  idParam: string | undefined
): TariffDataItem | undefined => {
  if (!idParam) {
    return undefined;
  }
  return tarifData?.find((item: TariffDataItem) => item.idParam === idParam);
};

export const sortFormulaMonthes = (options: string[]) => {
  return [...options].sort(
    (a: string, b: string) => +a.replace("m", "") - +b.replace("m", "")
  );
};

export const sortProductsByOrder = (products: Array<Product>) => {
  return [...products].sort((a: Product, b: Product) => {
    if (a.showOrder === -1 && b.showOrder === -1) {
      return -1;
    }

    if (a.showOrder < b.showOrder) {
      return -1;
    }

    if (a.showOrder > b.showOrder) {
      return 1;
    }
    return 0;
  });
};

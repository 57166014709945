import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import BlockContainer from "../../../components/BlockContainer/BlockContainer";
import TitleLine from "../../../components/TitleLine/TitleLine";
import BankLogo from "../../../components/BankLogo/BankLogo";
import BoughtPackagesSlider from "../../../components/BoughtPackagesSlider/PackagesSlider";
import {
  authSelector,
  bankSelector,
  packageSelector,
  packagesSelector,
} from "../../../redux/selectors";
import { fetchBoughtPackagesData } from "../../../redux/reducers/packagesSlice";
import { API_SERVER, IS_RVN_BRAND } from "../../../variables_config";
import { BlockProps } from "../Home.d";
import {
  clearPass,
  fetchPackageWalletPass,
} from "../../../redux/reducers/packageSlice";
import { fetchGetBank } from "../../../redux/reducers/bankSlice";
import { useAppDispatch } from "../../../redux/selectors";

const BlockThree: React.FC<BlockProps> = ({ styles }) => {
  const dispatch = useAppDispatch();

  const { user } = useSelector(authSelector);
  const { bankData } = useSelector(bankSelector);
  const { boughtPackagesStatus, boughtPackages } =
    useSelector(packagesSelector);

  const { walletPass } = useSelector(packageSelector);

  const onClickWalletPass = (packageId: string) => {
    dispatch(fetchPackageWalletPass(packageId));
  };

  useEffect(() => {
    if (boughtPackagesStatus === "idle") {
      dispatch(fetchBoughtPackagesData());
    }
  }, [dispatch, boughtPackagesStatus]);

  useEffect(() => {
    if (!bankData) {
      dispatch(fetchGetBank({ idBank: user?.idBank }));
    }
  }, [dispatch, bankData, user?.idBank]);

  useEffect(() => {
    if (walletPass) {
      const data = Uint8Array.from(walletPass.fileContent.data);
      console.log("wallet file", walletPass);
      const content = new Blob([data.buffer], {
        type: "application/vnd.apple.pkpass",
      });

      const encodedUri = window.URL.createObjectURL(content);
      const link = document.createElement("a");

      link.setAttribute("href", encodedUri);
      link.setAttribute("download", walletPass.fileName);
      link.setAttribute("target", "_blank");
      link.click();
      dispatch(clearPass(null));
    }
  }, [walletPass, dispatch]);

  return (
    <BlockContainer bgClass={styles.withoutPadding}>
      <div
        className={`${styles.blockThreeContainer} ${
          IS_RVN_BRAND ? styles.widthBottomPadding : ""
        }`}
      >
        <div className={styles.twoDivs}>
          <div className={styles.half}>
            <TitleLine>
              <div>
                {!IS_RVN_BRAND
                  ? "Deine Mehrwert-Paket Übersicht in Kooperation mit:"
                  : "Deine R+V-CashProtect Übersicht"}
              </div>
              {!IS_RVN_BRAND && (
                <div className={styles.bankLogoContainer}>
                  <BankLogo />
                </div>
              )}
            </TitleLine>
          </div>
          <div className={styles.half}></div>
        </div>

        <div className={styles.blockSlider}>
          {boughtPackages && boughtPackages.length ? (
            <BoughtPackagesSlider
              packages={boughtPackages.map((item, i) => ({
                idPackage: item.idPackage,
                name: item.name,
                image: `${API_SERVER}${item.logo || item.icon}`,
              }))}
              hideWalletBtn={bankData?.bdcPaymentMethod === "sellerside"}
              disableWalletBtn={!!bankData?.createPkPass}
              getWalletPassHandler={onClickWalletPass}
            />
          ) : null}
        </div>
      </div>
    </BlockContainer>
  );
};

export default BlockThree;

import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Params, useParams } from "react-router-dom";
import PackagePage from "../../pages/PackagePage/PackagePage";
import PackageTemplateDefaultPage from "../../pages/PackageTemplateDefaultPage/PackageTemplateDefaultPage";
import {
  fetchCalcLetterData,
  resetState,
} from "../../redux/reducers/calculatorSlice";
import { calcSelector, useAppDispatch } from "../../redux/selectors";
import {
  PACKAGE_LAYOUT_SEVEN,
  PACKAGE_LAYOUT_WIREFRAME,
} from "../../variables_config";
import MainWrapper from "../MainWrapper/MainWrapper";

const PackageLayoutContainer = () => {
  const dispatch = useAppDispatch();
  const { letterData } = useSelector(calcSelector);
  const { packageId }: Readonly<Params<string>> = useParams();

  const packageTemplatePage = useMemo(() => {
    let component = null;

    switch (letterData?.idCategory?.designCode || PACKAGE_LAYOUT_WIREFRAME) {
      case PACKAGE_LAYOUT_SEVEN:
        component = <PackagePage />;
        break;

      default:
        component = <PackageTemplateDefaultPage />;
    }

    return component;
  }, [letterData?.idCategory]);

  useEffect(() => {
    if (packageId) {
      dispatch(fetchCalcLetterData({ letterId: packageId }));
    }
  }, [dispatch, packageId]);

  useEffect(() => {
    return () => {
      dispatch(resetState(null));
    };
  }, [dispatch]);

  return (
    <MainWrapper showLoader={!letterData.idPackage}>
      {packageTemplatePage}
    </MainWrapper>
  );
};

export default PackageLayoutContainer;

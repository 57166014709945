import React from "react";
import { useSelector } from "react-redux";
import { Form, Formik } from "formik";
import { subYears } from "date-fns";
import Button from "../../../components/Button/Button";
import FormikDatePicker from "../../../components/FormikDatePicker/FormikDatePicker";
import InputField from "../../../components/InputField/InputField";
import {
  validatePhoneNumber,
  validatePLZ,
  validateRequiredField,
} from "../../../utils/formHelper";
import { profileSelector } from "../../../redux/selectors";
import { ProfileDataFormComponentProps } from "../ProfilePage.d";
import TwoWayAuthSettingsComponent from "./TwoWayAuthSettingsComponent";

const ProfileDataFormComponent: React.FC<ProfileDataFormComponentProps> = ({
  initialValues,
  authTypes,
  styles,
  onSubmit,
  setAuthTypes,
}) => {
  const { gQRcode } = useSelector(profileSelector);

  return (
    <Formik {...{ initialValues, onSubmit }} enableReinitialize>
      {({ values, errors, touched, isValid }) => (
        <Form className={styles.form} noValidate>
          <div className={styles.requiredInfo}>
            Die mit * gekennzeichneten Daten sind Pflichtfelder
          </div>

          <InputField
            id="salutation"
            name="salutation"
            type="select"
            placeholder=""
            labelText="Anrede"
            hasErrors={
              errors.hasOwnProperty("salutation") &&
              touched.hasOwnProperty("salutation")
            }
            validateHandler={validateRequiredField}
            containerClass={styles.formGroupInline}
            labelClass={styles.label}
            inputClass={styles.input}
            hideRequiredInLabel={true}
          >
            <option value="" label="Auswählen...">
              Auswählen...
            </option>
            <option value="herr" label="Herr">
              Herr
            </option>
            <option value="frau" label="Frau">
              Frau
            </option>
          </InputField>

          <InputField
            id="firstName"
            name="firstName"
            type="text"
            placeholder="Vorname"
            labelText="Vorname"
            hasErrors={
              errors.hasOwnProperty("firstName") &&
              touched.hasOwnProperty("firstName")
            }
            validateHandler={validateRequiredField}
            containerClass={styles.formGroupInline}
            labelClass={styles.label}
            inputClass={styles.input}
            hideRequiredInLabel={true}
          />

          <InputField
            id="lastName"
            name="lastName"
            type="text"
            placeholder="Nachname"
            labelText="Nachname"
            hasErrors={
              errors.hasOwnProperty("lastName") &&
              touched.hasOwnProperty("lastName")
            }
            validateHandler={validateRequiredField}
            containerClass={styles.formGroupInline}
            labelClass={styles.label}
            inputClass={styles.input}
            hideRequiredInLabel={true}
          />

          <InputField
            id="dateBirth"
            name="dateBirth"
            type="text"
            placeholder="Geburtsdatum"
            labelText="Geburtsdatum"
            component={FormikDatePicker}
            hasErrors={
              errors.hasOwnProperty("dateBirth") &&
              touched.hasOwnProperty("dateBirth")
            }
            validateHandler={validateRequiredField}
            maxDate={subYears(new Date(), 18)}
            openToDate={new Date().setFullYear(1980)}
            containerClass={styles.formGroupInline}
            labelClass={styles.label}
            inputClass={styles.input}
            hideRequiredInLabel={true}
          />

          <InputField
            id="phone"
            name="phone"
            type="text"
            placeholder=""
            labelText="Telefonnummer"
            hasErrors={
              errors.hasOwnProperty("phone") && touched.hasOwnProperty("phone")
            }
            validateHandler={validatePhoneNumber}
            containerClass={styles.formGroupInline}
            labelClass={styles.label}
            inputClass={styles.input}
            hideRequiredInLabel={true}
          />

          <div className={styles.formGroupInline}>
            <InputField
              id="street"
              name="street"
              type="text"
              placeholder="Musterstraße"
              labelText="Straße, Nr."
              hasErrors={
                errors.hasOwnProperty("street") &&
                touched.hasOwnProperty("street")
              }
              validateHandler={validateRequiredField}
              containerClass={styles.formGroupInline}
              labelClass={`${styles.label} ${styles.fixedLabel}`}
              inputClass={`${styles.input} ${styles.fixedInput}`}
              hideRequiredInLabel={true}
            />

            <InputField
              id="house"
              name="house"
              type="text"
              placeholder="Nr."
              // labelText="Hausnummer"
              containerClass={`${styles.formGroupInline} ${styles.smallInput} ${styles.formGroupInlineLeftMargin}`}
              hasErrors={
                errors.hasOwnProperty("house") &&
                touched.hasOwnProperty("house")
              }
              validateHandler={validateRequiredField}
              labelClass={styles.label}
              inputClass={`${styles.input} ${styles.textCenter}`}
              hideRequiredInLabel={true}
            />
          </div>

          <div className={styles.formGroupInline}>
            <InputField
              id="postalCode"
              name="postalCode"
              type="text"
              placeholder="123456"
              labelText="PLZ, Ort"
              containerClass={`${styles.formGroupInline} `}
              hasErrors={
                errors.hasOwnProperty("postalCode") &&
                touched.hasOwnProperty("postalCode")
              }
              validateHandler={validatePLZ}
              labelClass={`${styles.label} ${styles.fixedLabel}`}
              inputClass={`${styles.input} ${styles.smallInput} ${styles.textCenter}`}
              hideRequiredInLabel={true}
            />

            <InputField
              id="city"
              name="city"
              type="text"
              placeholder="Musterstadt"
              //labelText="Ort"
              hasErrors={
                errors.hasOwnProperty("city") && touched.hasOwnProperty("city")
              }
              validateHandler={validateRequiredField}
              containerClass={`${styles.formGroupInline} ${styles.fixedInput} ${styles.formGroupInlineLeftMargin}`}
              labelClass={styles.label}
              inputClass={`${styles.input} ${styles.fixedInput}`}
              hideRequiredInLabel={true}
            />
          </div>

          <TwoWayAuthSettingsComponent
            key={JSON.stringify(authTypes)}
            authTypes={authTypes}
            styles={styles}
            isDisableSMS={!values.phone}
            setAuthTypes={setAuthTypes}
          />

          <div className={styles.btnContainer}>
            <Button
              type="submit"
              variant="secondary"
              text="Speichern"
              disabled={!isValid || gQRcode !== ""}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ProfileDataFormComponent;

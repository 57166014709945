import styles from "./MainFooter.module.css";
import BlockContainer from "../BlockContainer/BlockContainer";
import { Link } from "react-router-dom";
import logo from "../assets/images/footer-logo.svg";
import secureData from "../assets/images/secure-data-center.svg";
import secureDsgvo from "../assets/images/secure-dsgvo.svg";
import insurLab from "../assets/images/insur-lab.svg";
import useWindowDimensions from "../../utils/useWindowDimentions";
import { IS_RVN_BRAND } from "../../variables_config";
import rnvLogo from "../assets/images/ruv/logo.png";

const MainFooter = () => {
  const { width } = useWindowDimensions();

  const nav = (
    <nav className={styles.nav}>
      <ul className={styles.navUl}>
        {/* <li>
          <Link to="/agb">AGB</Link>
        </li> */}
        <li>
          {IS_RVN_BRAND ? (
            <a
              href="https://www.ruv.de/impressum"
              target="_blank"
              rel="nofollow noreferrer"
            >
              Impressum
            </a>
          ) : (
            <Link to="/impressum">Impressum</Link>
          )}
        </li>
        <li>
          {IS_RVN_BRAND ? (
            <a
              href="https://www.ruv.de/datenschutz"
              target="_blank"
              rel="nofollow noreferrer"
            >
              Datenschutz
            </a>
          ) : (
            <Link to="/datenschutz">Datenschutz</Link>
          )}
        </li>
      </ul>
    </nav>
  );

  return (
    <footer className={styles.footer}>
      <BlockContainer>
        <div className={styles.footerContent}>
          <div className={styles.logosContainer}>
            <div className={styles.logo}>
              <img src={IS_RVN_BRAND ? rnvLogo : logo} alt="footer logo" />
            </div>
            <div className={styles.logo}>
              <img src={secureData} alt="secureData" />
            </div>

            <div className={styles.logo}>
              <img src={secureDsgvo} alt="secureDsgvo" />
            </div>

            {!IS_RVN_BRAND && (
              <div className={styles.logo}>
                <img src={insurLab} alt="insurLab" />
              </div>
            )}

            {width > 768 ? nav : null}
          </div>

          {width <= 768 ? nav : null}
        </div>
      </BlockContainer>
    </footer>
  );
};

export default MainFooter;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ConfirmationCodeModal from "../../components/ConfirmationCodeModal/ConfirmationCodeModal";
import { fetchCheckEmail } from "../../redux/reducers/authenticationSlice";
import { RootState } from "../../redux/store";
import { useAppDispatch } from "../../redux/selectors";

const ConfirmationCodeWrapper = () => {
  const dispatch = useAppDispatch();

  const { isAuthorized, user, waitCheck } = useSelector(
    (state: RootState) => state.authentication
  );

  const [confirmCode, setConfirmCode] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const onClickConfirmEmail = () => {
    dispatch(fetchCheckEmail({ confirmCode }));
  };

  useEffect(() => {
    if (isAuthorized && !waitCheck) {
      if (user && !user.confirmed) {
        setShowConfirmModal(true);
      }
    }
  }, [isAuthorized, dispatch, user, waitCheck]);

  return (
    <ConfirmationCodeModal
      title={"Email confirmation"}
      showModal={showConfirmModal}
      submitDisabled={!confirmCode}
      onInputChange={setConfirmCode}
      closeModal={() => setShowConfirmModal(false)}
      onSubmit={onClickConfirmEmail}
    />
  );
};

export default ConfirmationCodeWrapper;

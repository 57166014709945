import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { clearError } from "../../redux/reducers/errorCatchingSlice";
import { errorMessage } from "../../utils";
import { useAppDispatch } from "../../redux/selectors";

const ErrorsBounding = () => {
  const dispatch = useAppDispatch();

  const error = useSelector((state: RootState) => state.errorCatching.error);
  const [errors, setErrors] = useState<Array<string | null>>([]);

  useEffect(() => {
    setErrors([error]);
  }, [error]);

  useEffect(() => {
    errors.forEach(err => {
      if (err) {
        errorMessage(err);
        dispatch(clearError(null));
      }
    });
  }, [errors, dispatch]);

  return <ToastContainer theme="colored" />;
};

export default ErrorsBounding;

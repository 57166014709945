import {
  createAlignPlugin,
  createListPlugin,
  ELEMENT_PARAGRAPH,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
  createPlateUI,
  createBasicElementsPlugin,
  createBasicMarksPlugin,
} from "@udecode/plate";
import { createMyPlugins, MyValue } from "./types.d";

export const defaultInitValue: MyValue = [
  {
    type: "p",
    children: [{ text: "" }],
  },
];

export const plateUI = createPlateUI();

export const editorPlugins = createMyPlugins(
  [
    createBasicElementsPlugin(),
    createAlignPlugin({
      inject: {
        props: {
          validTypes: [
            ELEMENT_PARAGRAPH,
            ELEMENT_H1,
            ELEMENT_H2,
            ELEMENT_H3,
            ELEMENT_H4,
            ELEMENT_H5,
            ELEMENT_H6,
          ],
        },
      },
    }),
    createListPlugin(),
    createBasicMarksPlugin(),
  ],
  {
    components: plateUI,
  }
);

export const editorProps = {
  spellCheck: false,
  autoFocus: false,
  placeholder: "Typ…",
};

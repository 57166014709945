import React from "react";
import { TdProps } from "./Td.d";
import styles from "./Td.module.css";

const Td: React.FC<TdProps> = ({
  align,
  sticky,
  customClass,
  colSpan,
  children,
}) => {
  return (
    <td
      className={`${styles.td} ${styles[align || "left"]} ${
        sticky
          ? sticky === "left"
            ? styles.stickyLeftContainer
            : sticky === "right"
            ? styles.stickyRightContainer
            : ""
          : ""
      } ${customClass || ""}`}
      {...(colSpan ? { colSpan } : {})}
    >
      <div className={styles.content}>{children}</div>
    </td>
  );
};

export default Td;

import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Formik, Form } from "formik";
import InputField from "../../components/InputField/InputField";
import BlockContainer from "../../components/BlockContainer/BlockContainer";
import Button from "../../components/Button/Button";
import ContentBox from "../../components/ContentBox/ContentBox";
import MainWrapper from "../../containers/MainWrapper/MainWrapper";
import { authSelector, useAppDispatch } from "../../redux/selectors";
import {
  clearError,
  fetchUserSetPassword,
} from "../../redux/reducers/authenticationSlice";
import { PasswordSetFormValues } from "../../redux/reducers/authenticationSlice.d";
import {
  validateConfirmPassword,
  validatePassword,
} from "../../utils/formHelper";
import { getQueryStringParams, successMessage } from "../../utils";
import { ReactComponent as PasswordIcon } from "../../images/password-icon.svg";
import { ReactComponent as InfoIcon } from "../../images/info-icon.svg";
import styles from "./PasswordSetPage.module.css";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";

const PasswordSetPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isAuthorized, user } = useSelector(authSelector);
  const { tempCode } = getQueryStringParams(window.location.search);

  const initialValues: PasswordSetFormValues = {
    newPassword: "",
    confirmPassword: "",
    confirmCode: tempCode,
  };

  const onSubmit = (values: PasswordSetFormValues) => {
    dispatch(clearError(null));

    dispatch(
      fetchUserSetPassword(
        user?.isTempPassword
          ? { ...values, confirmCode: "ChangeCode", idUser: user._id }
          : values
      )
    );
  };

  useEffect(() => {
    if (isAuthorized && !user?.isTempPassword) {
      successMessage(`Passwort Prüfung erfolgreich`);

      setTimeout(() => navigate("/home"), 2000);
    }
  }, [isAuthorized, navigate, user?.isTempPassword]);

  return (
    <MainWrapper hideFooter={true}>
      <BlockContainer>
        <div className={styles.loginBlock}>
          <ContentBox className={styles.contentBox}>
            <div className={styles.loginContent}>
              <h1>Bitte lege dein persönliches Passwort fest</h1>

              <Formik {...{ initialValues, onSubmit }}>
                {({ errors, touched, values }) => (
                  <Form className={styles.formContainer} noValidate>
                    <InputField
                      key="newPassword"
                      id="newPassword"
                      name="newPassword"
                      type="password"
                      placeholder="Passwort"
                      labelText="Passwort"
                      hasErrors={
                        errors.hasOwnProperty("newPassword") &&
                        touched.hasOwnProperty("newPassword")
                      }
                      validateHandler={validatePassword}
                      icon={PasswordIcon}
                    />

                    <InputField
                      key="confirmPassword"
                      id="confirmPassword"
                      name="confirmPassword"
                      type="password"
                      placeholder="Passwort wiederholen"
                      labelText="Passwort wiederholen"
                      hasErrors={
                        errors.hasOwnProperty("confirmPassword") &&
                        touched.hasOwnProperty("confirmPassword")
                      }
                      validateHandler={(value: string) =>
                        validateConfirmPassword(values.newPassword, value)
                      }
                      icon={PasswordIcon}
                    />

                    <div className={styles.passwordHintContainer}>
                      <div className={styles.passwordHint}>
                        Bitte beachte die Regeln für dein Passwort.{" "}
                        <InfoIcon
                          className={styles.infoIcon}
                          {...{
                            "data-tip": true,
                            id: "password_hint",
                            "data-tip-disable": false,
                          }}
                        />
                      </div>
                    </div>

                    <div className={styles.btnContainer}>
                      <Button
                        type="submit"
                        variant="secondary"
                        text="Passwort festlegen"
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </ContentBox>
        </div>

        <Tooltip
          anchorId="password_hint"
          place="top"
          variant="dark"
          offset={0}
          className="tooltip"
        >
          <span>
            Mindestens 8 Zeichen
            <br />
            Mindestens 1 Großbuchstabe
            <br />
            Mindestens 1 Zahl (0-9)
          </span>
        </Tooltip>
      </BlockContainer>
    </MainWrapper>
  );
};

export default PasswordSetPage;

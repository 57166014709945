import { useEffect } from "react";
import { useSelector } from "react-redux";
import { packagesSelector, useAppDispatch } from "../../../redux/selectors";
import { fetchCategories } from "../../../redux/reducers/packagesSlice";
import { API_SERVER } from "../../../variables_config";
import CategoriesPackagesSlider from "../../../components/CategoriesPackagesSlider/CategoriesPackagesSlider";
import { BlockProps } from "../LandingPage.d";
import { ReactComponent as UserIcon } from "../../../images/user-icon.svg";

const BlockThree: React.FC<BlockProps> = ({ styles }) => {
  const dispatch = useAppDispatch();

  const { categories } = useSelector(packagesSelector);

  useEffect(() => {
    dispatch(fetchCategories({ perPage: 999 }));
  }, [dispatch]);

  return (
    <div className={styles.blockThreeContainer}>
      <div className={styles.blockThree}>
        <h3>Hier findest du noch weitere spannende Angebote!</h3>

        <div className={styles.twoDivs}>
          <div className={styles.half}></div>
          <div className={styles.half}></div>
        </div>
      </div>

      <div className={styles.blockSlider}>
        {categories && categories.length ? (
          <CategoriesPackagesSlider
            showLoginBtn={true}
            loginBtnIcon={UserIcon}
            packages={categories.map((item, i) => ({
              idPackage: item.idPackage || "",
              name: item.name,
              image: `${API_SERVER}${item.icon}`,
              description: item.description,
            }))}
          />
        ) : null}
      </div>
    </div>
  );
};

export default BlockThree;

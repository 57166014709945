import { useEffect, useState } from "react";
import BlockContainer from "../../components/BlockContainer/BlockContainer";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import styles from "./FAQArticlePage.module.css";
import SearchFaqBar from "../../containers/SearchFaqBar/SearchFaqBar";
import { fetchFaqSearchList } from "../../redux/reducers/faqSlice";
import {
  changeStatus,
  fetchFaqArticle,
} from "../../redux/reducers/faqArticleSlice";
import { FaqItem } from "../../redux/reducers/faqSlice.d";
import { useNavigate, useParams } from "react-router";
import RichTextEditorHtml from "../../containers/RichTextEditorHtml/RichTextEditorHtml";
import MainWrapper from "../../containers/MainWrapper/MainWrapper";
import ContentBox from "../../components/ContentBox/ContentBox";
import TitleLine from "../../components/TitleLine/TitleLine";
import { ReactComponent as CloseIcon } from "../../images/close-round-icon.svg";
import Button from "../../components/Button/Button";
import { useAppDispatch } from "../../redux/selectors";
import { Params } from "react-router-dom";

const FAQArticlePage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { questionId }: Readonly<Params<string>> = useParams();

  const { faqSearchList, searchStatus } = useSelector(
    (state: RootState) => state.faq
  );

  const { faqArticle } = useSelector((state: RootState) => state.faqArticle);

  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState<Array<FaqItem>>([]);

  const onSearchHandler = (text: string) => {
    setSearchValue(text);

    if (text.trim().length > 2) {
      dispatch(fetchFaqSearchList(text));
    } else {
      setSearchResults([]);
    }
  };

  useEffect(() => {
    if (searchStatus === "succeded") {
      setSearchResults(faqSearchList);
    }
  }, [searchStatus, faqSearchList]);

  useEffect(() => {
    if (questionId) {
      dispatch(fetchFaqArticle(questionId));
      setSearchValue("");
      setSearchResults([]);
    }
  }, [questionId, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(changeStatus("idle"));
    };
  }, [dispatch]);

  return (
    <MainWrapper>
      <BlockContainer>
        <div className={styles.blockContainer}>
          <ContentBox className={styles.block}>
            <TitleLine className={styles.title}>
              FAQ - Fragen und Antworten
            </TitleLine>

            <Button
              type="icon"
              icon={CloseIcon}
              className={styles.closeBtn}
              clickHandler={() => navigate("/faq")}
            />

            <div className={styles.content}>
              <div className={styles.searchContainer}>
                <h3>Wie können wir Dir helfen?</h3>

                <SearchFaqBar
                  results={searchResults}
                  keyword={searchValue}
                  changeHandler={onSearchHandler}
                />

                <p className={styles.description}>Hilfebereich</p>
              </div>

              <div className={styles.categoriesContainer}>
                <div className={`h3 ${styles.categorieTitle}`}>
                  {faqArticle.question}
                </div>

                <div className={styles.faqArticleAnswerContainer}>
                  <RichTextEditorHtml editorValue={faqArticle.answer} />
                </div>
              </div>
            </div>
          </ContentBox>
        </div>
      </BlockContainer>
    </MainWrapper>
  );
};

export default FAQArticlePage;

import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "./store";

export const useAppDispatch: () => AppDispatch = useDispatch;

export const authSelector = (state: RootState) => state.authentication;
export const packagesSelector = (state: RootState) => state.packages;
export const packageSelector = (state: RootState) => state.package;
export const bankSelector = (state: RootState) => state.bank;
export const calcSelector = (state: RootState) => state.calculator;
export const buyPackageSelector = (state: RootState) => state.buyPackage;
export const downloadsSelector = (state: RootState) => state.downloads;
export const profileSelector = (state: RootState) => state.profile;
export const finapiSelector = (state: RootState) => state.finapi;
export const cashbackSelector = (state: RootState) => state.cashback;
export const requestCategoriesSelector = (state: RootState) =>
  state.requestCategories;
export const mailSupportSelector = (state: RootState) => state.mailSupport;
export const addressSelector = (state: RootState) => state.address;

import styles from "./ToggleSwitch.module.css";

export interface ToggleSwitchProps {
  className?: string;
  isToggleOn?: boolean;
  disabled?: boolean;
  clickHandler: (value: boolean) => void;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  disabled,
  isToggleOn = false,
  clickHandler,
}) => {
  return (
    <div
      data-testid="toggle-switch"
      {...(!disabled
        ? {
            onClick: () => {
              clickHandler(!isToggleOn);
            },
          }
        : {})}
      className={`${styles.toggleSwitch} ${isToggleOn ? styles.active : ""} ${
        disabled ? styles.disabled : ""
      }`}
    >
      <div data-testid="toggle-switch-knob" className={styles.knob} />
    </div>
  );
};

export default ToggleSwitch;

import React from "react";
import { Form, Formik } from "formik";
import Button from "../../../components/Button/Button";
import InputField from "../../../components/InputField/InputField";
import {
  validateIBANField,
  validateRequiredField,
} from "../../../utils/formHelper";
import {
  clearError,
  fetchUpdateCustomerAccount,
} from "../../../redux/reducers/profileSlice";
import { CustomerAccount } from "../../../redux/reducers/profileSlice.d";
import { CustomerAccountProps } from "../ProfilePage.d";
import FormikIbanInput from "../../../components/FormikIbanInput/FormikIbanInput";
import { useAppDispatch } from "../../../redux/selectors";

const CustomerAccountComponent: React.FC<CustomerAccountProps> = ({
  initialValues,
  styles,
}) => {
  const dispatch = useAppDispatch();

  const onSubmit = (
    values: CustomerAccount,
    { resetForm }: { resetForm: () => void }
  ) => {
    resetForm();
    dispatch(clearError(null));
    dispatch(fetchUpdateCustomerAccount(values));
  };

  return (
    <Formik
      {...{
        initialValues,
        onSubmit,
      }}
      enableReinitialize
    >
      {({ errors, touched }) => (
        <Form className={styles.form} noValidate>
          <div className={styles.requiredInfo}>
            Die mit * gekennzeichneten Daten sind Pflichtfelder
          </div>

          <InputField
            id="accountHolderName"
            name="accountHolderName"
            type="text"
            placeholder="Gib deinen Namen ein"
            labelText="Kontoinhaber"
            hasErrors={
              errors.hasOwnProperty("accountHolderName") &&
              touched.hasOwnProperty("accountHolderName")
            }
            validateHandler={validateRequiredField}
            containerClass={styles.formGroupInline}
            labelClass={styles.label}
            inputClass={styles.input}
          />

          <InputField
            id="iban"
            name="iban"
            type="text"
            placeholder="IBAN"
            labelText="IBAN (22-stellig)"
            hasErrors={
              errors.hasOwnProperty("iban") && touched.hasOwnProperty("iban")
            }
            validateHandler={validateIBANField}
            component={FormikIbanInput}
            containerClass={styles.formGroupInline}
            labelClass={styles.label}
            inputClass={styles.input}
          />

          <div className={styles.btnContainer}>
            <Button type="submit" variant="secondary" text="Speichern" />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CustomerAccountComponent;

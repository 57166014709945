import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APICall } from "../../utils/api";
import { FaqState } from "./faqSlice.d";

const initialState: FaqState = {
  faqList: [],
  faqSearchList: [],
  perPage: 9,
  startRecord: 0,
  totalRecords: 0,
  status: "idle",
  searchStatus: "idle",
  error: null,
};

export const fetchFaqList = createAsyncThunk(
  "faq/fetchFaqList",
  async (
    {
      query,
      activePage,
      perPage,
    }: { query?: string; activePage?: number; perPage?: number },
    { rejectWithValue }
  ) => {
    return await APICall("infoservice.faq", "getall", rejectWithValue, {
      ...(activePage && perPage && activePage > 1
        ? { startRecord: (activePage - 1) * perPage }
        : {}),
      ...(perPage ? { perPage } : {}),
      ...(query ? { query } : {}),
    });
  }
);

export const fetchFaqSearchList = createAsyncThunk(
  "faq/fetchFaqSearchList",
  async (query: string, { rejectWithValue }) => {
    return await APICall("infoservice.faq", "getall", rejectWithValue, {
      perPage: 999,
      query,
    });
  }
);

export const faqSlice = createSlice({
  name: "faq",
  initialState,
  reducers: {
    changeStatus: (state, action) => {
      state.status = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchFaqList.fulfilled as any, (state, action) => {
      state.faqList = action.payload.data;
      state.startRecord = action.payload.startRecord;
      state.totalRecords = action.payload.totalRecords;
      state.error = null;
      state.status = "succeded";
    });

    builder.addCase(fetchFaqList.rejected as any, (state, action) => {
      if (action?.payload?.message) state.error = action.payload.message;
      else state.error = "Etwas schiefgegangen";
    });

    builder.addCase(fetchFaqSearchList.fulfilled as any, (state, action) => {
      state.faqSearchList = action.payload.data;
      state.error = null;
      state.searchStatus = "succeded";
    });

    builder.addCase(fetchFaqSearchList.rejected as any, (state, action) => {
      if (action?.payload?.message) state.error = action.payload.message;
      else state.error = "Etwas schiefgegangen";
    });
  },
});

export const { changeStatus } = faqSlice.actions;

export default faqSlice.reducer;

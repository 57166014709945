import React, { useState } from "react";
import Button from "../../components/Button/Button";
import { SetupGoogleAuthProps } from "./SetupGoogleAuth.d";
import styles from "./SetupGoogleAuth.module.css";

const SetupGoogleAuth: React.FC<SetupGoogleAuthProps> = ({
  QRcode,
  onSubmit,
}) => {
  const [confirmCode, setConfirmCode] = useState("");

  return (
    <div className={styles.confimationContainer}>
      <p className={styles.resendAlternativeP}>
        Scanne diesen QRCode mit der Google Authenticator App auf Deinem Handy:
      </p>
      <div className={styles.confimationImg}>
        <img src={QRcode} alt="google auth code" />

        <div className={styles.confirmationInput}>
          <input
            type="text"
            className={styles.formControl}
            placeholder="Code"
            onChange={(e: any) => setConfirmCode(e.target.value)}
          />

          <Button
            type="button"
            size={"small"}
            variant="office-edit"
            text="Code senden"
            clickHandler={() => onSubmit(confirmCode)}
          />
        </div>
      </div>
    </div>
  );
};

export default SetupGoogleAuth;

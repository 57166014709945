import { subYears } from "date-fns";
import de from "date-fns/locale/de";
import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
registerLocale("de", de);

const FormikDatePicker = ({
  field,
  form,
  onChange,
  openToDate,
  placeholder,
  onBlur,
  ...props
}: {
  field: any;
  form: any;
  placeholder: string;
  onChange: any;
  onBlur?: (e: any) => void;
  openToDate?: string | number;
}) => {
  return (
    <DatePicker
      {...field}
      {...props}
      locale="de"
      selected={(field.value && moment(field.value).toDate()) || ""}
      value={(field.value && moment(field.value).toDate()) || ""}
      onChange={(date: Date) => {
        const curValue = date || "";

        if (onChange) {
          onChange(curValue);
        } else {
          form.setFieldValue(field.name, curValue);
        }
      }}
      maxDate={subYears(new Date(), 18)}
      dateFormat="dd.MM.yyyy"
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      placeholderText={placeholder}
      {...(!field.value && openToDate
        ? {
            openToDate: new Date(openToDate),
          }
        : {})}
      {...(onBlur ? { onBlur } : {})}
    />
  );
};

export default FormikDatePicker;

import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Params, useNavigate, useParams } from "react-router-dom";
import BlockContainer from "../../components/BlockContainer/BlockContainer";
import ContentBox from "../../components/ContentBox/ContentBox";
import LoaderComponent from "../../components/LoaderComponent/LoaderComponent";
import TitleLine from "../../components/TitleLine/TitleLine";
import CheckoutLetterServices from "../../containers/CheckoutLetterServices/CheckoutLetterServices";
import MainWrapper from "../../containers/MainWrapper/MainWrapper";
import { fetchCalcLetterData } from "../../redux/reducers/calculatorSlice";
import {
  buyPackageSelector,
  calcSelector,
  useAppDispatch,
} from "../../redux/selectors";
import styles from "./Checkout.module.css";
import CheckoutForm from "./components/CheckoutForm";
import { resetState } from "../../redux/reducers/buyPackageSlice";
//import { ReactComponent as UserIcon } from "../../images/user-icon.svg";

const Checkout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { letterData, status: packageStatus } = useSelector(calcSelector);
  const { status, waitingPayment } = useSelector(buyPackageSelector);

  const { packageId }: Readonly<Params<string>> = useParams();

  useEffect(() => {
    if (packageId) {
      dispatch(fetchCalcLetterData({ letterId: packageId }));
    }
  }, [dispatch, packageId]);

  useEffect(() => {
    if (status === "succeded") {
      dispatch(resetState());
      navigate("/thankyou");
    }
  }, [dispatch, navigate, status]);

  useEffect(() => {
    if (!packageId) {
      navigate("/home");
    }
  }, [dispatch, navigate, packageId]);

  return (
    <MainWrapper>
      <BlockContainer>
        <div className={styles.blockOneContainer}>
          {packageStatus === "idle" && !letterData.idPackage ? (
            <LoaderComponent />
          ) : (
            <ContentBox className={styles.block}>
              <TitleLine>
                Überprüfe deine {letterData.name} Konfiguration:
              </TitleLine>

              <CheckoutLetterServices createDraft={false} />
            </ContentBox>
          )}
        </div>
      </BlockContainer>

      <BlockContainer>
        <div className={styles.blockTwoContainer}>
          <CheckoutForm
            //key={`form_${formValues.idPackage}`}
            styles={styles}
            packageId={packageId}
          />
        </div>
      </BlockContainer>

      {waitingPayment ? (
        <div className={styles.waitingLoaderContainer}>
          <LoaderComponent />
        </div>
      ) : null}
    </MainWrapper>
  );
};

export default Checkout;

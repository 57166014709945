import React from "react";

interface ErrorsState {
  error: any;
  errorInfo: any;
}

export class ErrorBoundaryWrapper extends React.Component<any, ErrorsState> {
  constructor(props: any) {
    super(props);
    this.state = { error: null, errorInfo: null } as ErrorsState;
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: "pre-wrap" }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }

    return this.props.children;
  }
}

import React from "react";
import BlockContainer from "../../../components/BlockContainer/BlockContainer";
import ContentBox from "../../../components/ContentBox/ContentBox";
import LongButton from "../../../components/LongButton/LongButton";
import { BlockTwoProps } from "../Home";
import { ReactComponent as BagIcon } from "../../../images/bag-icon.svg";
import ebene from "../../../images/ebene.svg";
import TitleLine from "../../../components/TitleLine/TitleLine";
import useWindowDimensions from "../../../utils/useWindowDimentions";

const mobFrom = 1044;

const BlockTwo: React.FC<BlockTwoProps> = ({ styles, scrollTo }) => {
  const { width } = useWindowDimensions();

  return (
    <BlockContainer>
      <div className={styles.blockTwoContainer}>
        <div className={styles.twoDivs}>
          <div className={styles.half}>
            <TitleLine>
              Du möchtest noch mehr passende Angebote finden?
            </TitleLine>

            <ContentBox className={styles.blockTwo}>
              <p className={styles.textBlock}>
                Sichere jetzt deinen Lifestyle ab
              </p>

              <div className={styles.btnContainer}>
                <LongButton
                  variant="secondary"
                  text="Zu den Angeboten"
                  icon={BagIcon}
                  clickHandler={scrollTo}
                />
              </div>
            </ContentBox>
          </div>

          {width > mobFrom ? (
            <div className={styles.half}>
              <img src={ebene} className={styles.blockTwoImg} alt="Ebene" />
            </div>
          ) : null}
        </div>
      </div>
    </BlockContainer>
  );
};

export default BlockTwo;

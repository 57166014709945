import LoaderComponent from "../LoaderComponent/LoaderComponent";
import { Props } from "./Main.d";
import styles from "./Main.module.css";

const Main: React.FC<Props> = ({ children, className, showLoader }) => {
  return (
    <div
      className={`${styles.main} ${className ? styles[className] : ""}`}
      data-testid="main"
    >
      {showLoader ? (
        <div className={styles.loaderWrapper}>
          <LoaderComponent />
        </div>
      ) : (
        children
      )}
    </div>
  );
};

export default Main;

import React from "react";
import { QuestionsListProps } from "./AdditionalFormProtocol.d";
import styles from "./AdditionalFormProtocol.module.css";
import AnswersList from "./AnswersList";

const QuestionsList: React.FC<QuestionsListProps> = ({
  questions,
  answers,
  updateAnswer,
}) => {
  return (
    <>
      {Object.keys(questions).map((key: string, i: number) => (
        <div key={`question_${i}`} className={styles.questionItem}>
          <div className={`h3 ${styles.h3}`}>{questions[key].text}</div>
          <div className={styles.answersContainer}>
            <AnswersList
              questionKey={key}
              answersList={questions[key].answers}
              selectedAnswers={answers}
              updateAnswer={updateAnswer}
              thenComponent={QuestionsList}
            />
          </div>
        </div>
      ))}
    </>
  );
};

export default QuestionsList;

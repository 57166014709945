import React from "react";
import { API_SERVER } from "../../variables_config";
import { ReactComponent as HelpIcon } from "../assets/images/info-icon.svg";
import ContentBox from "../ContentBox/ContentBox";
import { CalcProductProps } from "./CalcProduct.d";
import styles from "./CalcProduct.module.css";

const CalcProduct: React.FC<CalcProductProps> = ({
  product,
  setModalContent,
}) => {
  return (
    <div
      className={`${styles.productContainer} ${
        !product.isActive ? styles.productNotActive : ""
      }`}
      data-testid={"calcProductPortalAccess"}
    >
      <ContentBox className={styles.contentBox}>
        <div className={styles.productTop}>
          <div className={styles.titleContainer}>
            <h3 className={styles.title}>
              {product.name.length > 80
                ? `${product.name.slice(0, 80)}...`
                : product.name}
            </h3>
            <div
              data-testid="open-modal-button"
              className={styles.productReadMore}
              onClick={() =>
                setModalContent({
                  idService: product.idProduct,
                  name: product.name,
                  popupTitle: product.popupTitle,
                  popupBody: product.popupBody,
                  bottomText: "Unser Partner",
                  bottomImg: product?.insuranceLogo
                    ? `${API_SERVER}${product?.insuranceLogo}`
                    : "",
                  rightImg: `${API_SERVER}${product.image}`,
                })
              }
            >
              <HelpIcon className={styles.helpIcon} />
            </div>
          </div>

          {product.additionalNameDesc && <h5>{product.additionalNameDesc}</h5>}
        </div>
      </ContentBox>
    </div>
  );
};

export default CalcProduct;

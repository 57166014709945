import IbanInputField from "../IbanInputField/IbanInputField";

const FormikIbanInput = ({
  field,
  form,
  onChange,
  ...props
}: {
  field: any;
  form: any;
  onChange?: any;
}) => {
  return (
    <IbanInputField
      {...field}
      {...props}
      onChange={(value: string) => {
        if (onChange) {
          onChange(value);
        } else {
          form.setFieldValue(field.name, value);
        }
      }}
    />
  );
};

export default FormikIbanInput;

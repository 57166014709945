import MainWrapper from "../../containers/MainWrapper/MainWrapper";
import BlockContainer from "../../components/BlockContainer/BlockContainer";
//import styles from "./UnserePartnerPage.module.css";

const UnserePartnerPage = () => {
  return (
    <MainWrapper>
      <BlockContainer>
        <div>UnserePartner Page</div>
      </BlockContainer>
    </MainWrapper>
  );
};

export default UnserePartnerPage;

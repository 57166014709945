import React, { useCallback, useEffect, useMemo, useState } from "react";
import { serializeHtml, createPlateEditor } from "@udecode/plate";
import { removeHtmlTags } from "../../utils";
import { plateUI, defaultInitValue, editorPlugins } from "./initData";
import { RichTextEditorHtmlProps } from "./RichTextEditor.d";

const RichTextEditorHtml: React.FC<RichTextEditorHtmlProps> = ({
  editorValue,
  removeTags,
  maxStrLength,
}): any => {
  const [showChild, setShowChild] = useState(false);

  // Wait until after client-side hydration to show
  useEffect(() => {
    setShowChild(true);
  }, []);

  if (!showChild) {
    return null;
  }

  return (
    <RichTextEditorSSRFix {...{ editorValue, removeTags, maxStrLength }} />
  );
};

const RichTextEditorSSRFix: React.FC<RichTextEditorHtmlProps> = ({
  editorValue,
  removeTags,
  maxStrLength,
}): any => {
  const editor = createPlateEditor({
    plugins: editorPlugins,
    components: plateUI,
  });

  const convertPlateToHtml = useCallback(
    (editorValueNodes: any) => {
      const nodes = [...editorValueNodes];

      const html = serializeHtml(editor, {
        nodes,
      });

      return html;
    },
    [editor]
  );

  const editorValueToPlate =
    editorValue && editorValue.includes('"children":[{"text":')
      ? JSON.parse(editorValue)
      : defaultInitValue;

  const html = useMemo(
    () => convertPlateToHtml(editorValueToPlate),
    [convertPlateToHtml, editorValueToPlate]
  );

  if (removeTags) {
    const removedStr = removeHtmlTags(html);

    if (maxStrLength && removedStr.length > maxStrLength) {
      return `${removedStr.slice(0, maxStrLength)}...`;
    }

    return removedStr;
  }

  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

export default RichTextEditorHtml;

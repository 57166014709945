import React, { useEffect, useState } from "react";
import { addStarsIBAN } from "../../utils/formHelper";
import { IbanInputFieldProps } from "./IbanInputField.d";

const IbanInputField: React.FC<IbanInputFieldProps> = ({
  id,
  name,
  value,
  placeholder,
  className,
  onChange,
  onBlur,
}) => {
  const [ibanValue, setIbanValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const ibanOnChange = (e: any) => {
    let value = e.target.value;
    value = value.replace(/\s/g, "");

    setIbanValue(value);
    onChange(value);
  };

  const ibanOnFocus = () => {
    setIbanValue(value);
    setIsFocused(true);
  };

  const ibanOnBlur = (e: any) => {
    onBlur(e);
    setIsFocused(false);
    setIbanValue(addStarsIBAN(e.target.value));
  };

  useEffect(() => {
    if (value) {
      setIbanValue(isFocused ? value : addStarsIBAN(value));
    }
  }, [value, isFocused]);

  return (
    <input
      id={id}
      name={name}
      placeholder={placeholder}
      type="text"
      className={className}
      value={ibanValue}
      onChange={ibanOnChange}
      onFocus={ibanOnFocus}
      onBlur={ibanOnBlur}
    />
  );
};

export default IbanInputField;

import React from "react";
import styles from "./CircleIcon.module.css";

interface CircleIconProps {
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
}

const CircleIcon: React.FC<CircleIconProps> = ({ icon }) => {
  const IconComponent: any = icon;
  return (
    <div className={styles.circle}>
      {<IconComponent className={styles.icon} />}
    </div>
  );
};

export default CircleIcon;

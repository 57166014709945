import styles from "./Button.module.css";
import { Link } from "react-router-dom";
import { Props } from "./Button.d";
import { ReactComponent as ArrowIcon } from "../assets/images/arrow.svg";

const Button: React.FC<Props> = ({
  className = "",
  leftIconClassName = "",
  type = "button",
  variant = "primary",
  text = "",
  href,
  disabled,
  icon,
  isArrow,
  clickHandler,
}) => {
  const IconComponent: any = icon;
  if (href && !disabled) {
    return (
      <Link
        className={`${styles.btn} ${className} ${
          styles[variant] ? styles[variant] : ""
        }`}
        to={href}
      >
        {icon ? (
          <span className={`${styles.leftIconContainer} ${leftIconClassName}`}>
            <IconComponent className={styles.leftIcon} />
          </span>
        ) : null}
        {text}
        {isArrow ? (
          <span className={styles.rightIconContainer}>
            <ArrowIcon className={styles.arrowIcon} />
          </span>
        ) : null}
      </Link>
    );
  }

  if (type === "icon") {
    return (
      <button
        type="button"
        className={`${styles.btnIcon} ${className}`}
        onClick={clickHandler}
        disabled={disabled}
        data-testid="btn-btnIcon"
      >
        {icon ? <IconComponent className={styles.icon} /> : null}
      </button>
    );
  }

  return (
    <button
      type={type}
      className={`${styles.btn} ${className} ${styles[variant]}`}
      onClick={clickHandler}
      disabled={disabled}
      data-testid={`btn-${variant}`}
    >
      {icon ? (
        <span
          className={`${styles.leftIconContainer} ${leftIconClassName}`}
          data-testid="iconContainer"
        >
          <IconComponent className={styles.leftIcon} />
        </span>
      ) : null}
      {text}
      {isArrow ? (
        <span className={styles.rightIconContainer}>
          <ArrowIcon className={styles.arrowIcon} />
        </span>
      ) : null}
    </button>
  );
};

export default Button;

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { Tooltip } from "react-tooltip";
import { successMessage } from "../../utils";
import {
  CustomerAccount,
  ProfileValuesToSave,
} from "../../redux/reducers/profileSlice.d";
import {
  clearError,
  clearStatus,
  fetchProfileData,
  saveProfileData,
} from "../../redux/reducers/profileSlice";
import {
  authSelector,
  profileSelector,
  useAppDispatch,
} from "../../redux/selectors";
import {
  clearStatus as clearAuthStatus,
  clearEmailChangedStatus,
} from "../../redux/reducers/authenticationSlice";
import Button from "../../components/Button/Button";
import ProfileDataContent from "../../components/ProfileDataContent/ProfileDataContent";
import BlockContainer from "../../components/BlockContainer/BlockContainer";
import ContentBox from "../../components/ContentBox/ContentBox";
import TitleLine from "../../components/TitleLine/TitleLine";
import CustomerAccountComponent from "./components/CustomerAccountComponent";
import EmailFormComponent from "./components/EmailFormComponent";
import ProfileDataFormComponent from "./components/ProfileDataFormComponent";
import PasswordFormComponent from "./components/PasswordFormComponent";
import EmailChangeCodeWrapper from "../../containers/EmailChangeCodeWrapper/EmailChangeCodeWrapper";
import MainWrapper from "../../containers/MainWrapper/MainWrapper";
import { ReactComponent as CloseIcon } from "../../images/close-round-icon.svg";
import styles from "./ProfilePage.module.css";
import { AuthTypes } from "./ProfilePage.d";

const defaultTwoWayAuthState = {
  twoWayAuthEmail: { active: false, isDefault: false },
  twoWayAuthSMS: { active: false, isDefault: false },
  twoWayAuthGoogle: { active: false, isDefault: false },
};

const initialValues: ProfileValuesToSave = {
  firstName: "",
  lastName: "",
  salutation: "",
  username: "",
  dateBirth: "",
  city: "",
  street: "",
  house: "",
  phone: "",
  postalCode: "",
};

const initCustomerAccount: CustomerAccount = {
  iban: "",
  accountHolderName: "",
};

const ProfilePage = () => {
  const dispatch = useAppDispatch();
  const { status, profileData } = useSelector(profileSelector);

  const {
    user,
    emailChangeConfirmed,
    status: authStatus,
  } = useSelector(authSelector);

  const [isChangeProfileData, setIsChangeProfileData] = useState(false);
  const [isChangeAccessData, setIsChangeAccessData] = useState(false);
  const [isChangeAccountData, setIsChangeAccountData] = useState(false);

  const [authTypes, setAuthTypes] = useState<AuthTypes>(defaultTwoWayAuthState);
  const [profileValues, setProfileValues] = useState(initialValues);
  const [accountValues, setAccountValues] = useState(initCustomerAccount);

  const changeProfileData = () => setIsChangeProfileData(true);
  const changeAccessData = () => setIsChangeAccessData(true);
  const changeAccountData = (value: boolean) => setIsChangeAccountData(value);

  const onProfileSubmit = (values: ProfileValuesToSave) => {
    const newValues = { ...values };

    newValues.dateBirth = format(new Date(values.dateBirth), "yyyy-MM-dd");

    dispatch(clearError(null));
    dispatch(saveProfileData({ values: { ...newValues, ...authTypes } }));
  };

  const updateTwoAuthState = () => {
    setAuthTypes({
      twoWayAuthEmail:
        profileData?.twoWayAuthEmail || defaultTwoWayAuthState.twoWayAuthEmail,
      twoWayAuthSMS:
        profileData?.twoWayAuthSMS || defaultTwoWayAuthState.twoWayAuthSMS,
      twoWayAuthGoogle:
        profileData?.twoWayAuthGoogle ||
        defaultTwoWayAuthState.twoWayAuthGoogle,
    });
  };

  useEffect(() => {
    dispatch(fetchProfileData({ woDots: true }));
  }, [dispatch]);

  useEffect(() => {
    if (profileData._id) {
      setProfileValues({
        firstName: profileData.firstName,
        lastName: profileData.lastName,
        salutation: profileData.salutation,
        username: user ? user.username : profileData.username,
        dateBirth: profileData.dateBirth,
        city: profileData.city || "",
        street: profileData.extras.street || "",
        house: profileData.extras.house || "",
        phone: profileData.phone || "",
        postalCode: profileData.postalCode || "",
      });
    }
  }, [profileData, user]);

  useEffect(() => {
    if (profileData._id) {
      setAccountValues({
        iban: profileData.iban || "",
        accountHolderName: profileData.accountHolderName || "",
      });
    }
  }, [profileData, user]);

  useEffect(() => {
    updateTwoAuthState();
  }, [profileData]);

  useEffect(() => {
    if (status === "saved") {
      dispatch(clearStatus("idle"));
      setIsChangeProfileData(false);
      successMessage("Profil geändert");
    }
  }, [dispatch, status]);

  useEffect(() => {
    if (authStatus === "passwordchanged") {
      setIsChangeAccessData(false);
      dispatch(clearAuthStatus("succeded"));
      successMessage("Passwort geändert");
    }
  }, [dispatch, authStatus]);

  useEffect(() => {
    if (emailChangeConfirmed) {
      setIsChangeAccessData(false);
      dispatch(clearEmailChangedStatus(""));
      successMessage("E-Mail-Adresse geändert");
    }
  }, [dispatch, emailChangeConfirmed]);

  useEffect(() => {
    if (status === "succeded") {
      dispatch(clearStatus("idle"));
      setIsChangeAccountData(false);
      successMessage("Bankverbindung geändert");
    }
  }, [dispatch, status]);

  return (
    <MainWrapper>
      {isChangeProfileData ? (
        <BlockContainer>
          <div className={styles.blockContainer}>
            <ContentBox className={styles.block}>
              <TitleLine className={styles.title}>
                Mein Konto - <br /> Meine persönlichen Daten
              </TitleLine>

              <Button
                type="icon"
                icon={CloseIcon}
                className={styles.closeBtn}
                clickHandler={() => {
                  updateTwoAuthState();
                  setIsChangeProfileData(false);
                }}
              />

              <ProfileDataFormComponent
                initialValues={profileValues}
                authTypes={authTypes}
                styles={styles}
                onSubmit={onProfileSubmit}
                setAuthTypes={setAuthTypes}
              />
            </ContentBox>
          </div>
        </BlockContainer>
      ) : isChangeAccessData ? (
        <>
          <BlockContainer>
            <div className={styles.blockContainer}>
              <ContentBox className={styles.block}>
                <TitleLine className={styles.title}>Passwort ändern</TitleLine>

                <Button
                  type="icon"
                  icon={CloseIcon}
                  className={styles.closeBtn}
                  clickHandler={() => setIsChangeAccessData(false)}
                />

                <PasswordFormComponent styles={styles} />
              </ContentBox>
            </div>
          </BlockContainer>

          <BlockContainer>
            <div className={styles.blockContainer}>
              <ContentBox className={styles.block}>
                <TitleLine className={styles.title}>
                  E-Mail-Adresse ändern
                </TitleLine>

                <EmailFormComponent
                  currentEmail={profileValues.username}
                  styles={styles}
                />
              </ContentBox>
            </div>
          </BlockContainer>
        </>
      ) : isChangeAccountData ? (
        <BlockContainer>
          <div className={styles.blockContainer}>
            <ContentBox className={styles.block}>
              <TitleLine className={styles.title}>Bankdaten ändern</TitleLine>

              <Button
                type="icon"
                icon={CloseIcon}
                className={styles.closeBtn}
                clickHandler={() => changeAccountData(false)}
              />

              <CustomerAccountComponent
                initialValues={accountValues}
                styles={styles}
              />
            </ContentBox>
          </div>
        </BlockContainer>
      ) : (
        <ProfileDataContent
          values={profileValues}
          onChangeProfileHandler={changeProfileData}
          onChangeAccessHandler={changeAccessData}
          onChangeAccountHandler={changeAccountData}
        />
      )}

      <EmailChangeCodeWrapper />

      {/* {isChangeProfileData ? (
        <ReactTooltip
          id="twowayauth_hint"
          place="top"
          type="dark"
          effect="solid"
          offset={{ top: 0 }}
          className="tooltip"
        >
          <div className={styles.authTooltipContent}>
            <h3 className={styles.authTooltipH3}>
              Bestätigung in zwei Schritten aktivieren
            </h3>

            <p className={styles.authTooltipP}>
              Mit der Bestätigung in zwei Schritten können Sie Ihr Konto für den
              Fall, dass Unbefugte an Ihr Passwort gelangen, zusätzlich
              absichern. Nachdem Sie die Bestätigung in zwei Schritten
              eingerichtet haben, erfolgt die Anmeldung in Ihrem Konto in zwei
              Schritten. Dabei verwenden Sie:
            </p>

            <ul className={styles.authTooltipUl}>
              <li>Etwas, das Sie wissen, zum Beispiel Ihr Passwort</li>
              <li>Etwas, das Sie haben, zum Beispiel Ihr Smartphone</li>
            </ul>

            <h3 className={styles.authTooltipH3}>
              Identität in einem zweiten Schritt bestätigen
            </h3>

            <p className={styles.authTooltipP}>
              Nachdem Sie die Bestätigung in zwei Schritten aktiviert haben,
              müssen Sie bei der Anmeldung einen zweiten Schritt ausführen, um
              Ihre Identität zu bestätigen. Zum Schutz Ihres Kontos werden Sie
              von uns aufgefordert, einen zweiten Schritt auszuführen.
            </p>
          </div>
        </ReactTooltip>
      ) : null} */}

      {isChangeAccessData ? (
        <Tooltip
          anchorId="password_hint"
          place="top"
          variant="dark"
          offset={0}
          className="tooltip"
        >
          <span>
            Mindestens 8 Zeichen
            <br />
            Mindestens 1 Großbuchstabe
            <br />
            Mindestens 1 Zahl (0-9)
          </span>
        </Tooltip>
      ) : null}
    </MainWrapper>
  );
};

export default ProfilePage;

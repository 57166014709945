import styles from "./ContentBox.module.css";
import { Props } from "./ContentBox.d";

const ContentBox: React.FC<Props> = ({ className, children }) => {
  return (
    <div
      className={`${styles.container} ${className ? className : ""}`}
      data-testid="contentBox"
    >
      {children}
    </div>
  );
};

export default ContentBox;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ConfirmationCodeModal from "../ConfirmationCodeModal/ConfirmationCodeModal";
import {
  clearError,
  fetchConfirmTanPackage,
  resetEmployerFormError,
  resetRepeatRequest,
} from "../../../../redux/reducers/buyPackageSlice";
import {
  calcSelector,
  buyPackageSelector,
  useAppDispatch,
  authSelector,
} from "../../../../redux/selectors";
import { AdditionalInfoAnswer } from "../../../../redux/reducers/calculatorSlice.d";
import { getAdditionalInfoAnsItem, hidePhoneNumber } from "../../helper";
import useTimeout from "../../../../utils/useTimeout";

const initValues = {
  confirmCode: "",
  additionalInfoEmployer: {
    firmName: "",
    street: "",
    house: "",
    postalCode: "",
    city: "",
  },
};

const repeatDelay = 500;

interface ConfirmationCodeWrapperProps {
  isShowEmployerForm: boolean;
  employerFormData?: any;
}

const ConfirmationCodeWrapper: React.FC<ConfirmationCodeWrapperProps> = ({
  isShowEmployerForm,
  employerFormData,
}) => {
  const dispatch = useAppDispatch();

  const {
    waitingPayment,
    isNeedTan,
    isAbortNeedTan,
    employerFormValues,
    employerFormErrors,
    repeatRequest,
  } = useSelector(buyPackageSelector);
  const {
    letterData: { idPackage, mergedAdditionalInfoAns },
  } = useSelector(calcSelector);
  const { user } = useSelector(authSelector);

  const [formValues, setFormValues] = useState(initValues);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const resetEmployerRequestError = (key: string) => {
    dispatch(resetEmployerFormError(key));
  };

  const closeModalHandler = () => {
    dispatch(clearError(null));
    dispatch(resetRepeatRequest());

    dispatch(
      fetchConfirmTanPackage({
        idPackage,
        clearTan: true,
        confirmCode: "",
      })
    );
    setShowConfirmModal(false);
  };

  const onClickConfirmPin = (values: any) => {
    dispatch(clearError(null));
    dispatch(resetRepeatRequest());

    const additionalInfoAns: Array<AdditionalInfoAnswer> = [];
    const employerAnswer = getAdditionalInfoAnsItem(
      mergedAdditionalInfoAns,
      "employer"
    );

    if (employerAnswer && isShowEmployerForm) {
      additionalInfoAns.push({
        infoName: employerAnswer.infoName,
        type: employerAnswer.type,
        value: {
          value: employerAnswer.value,
          deactivateProduct: employerAnswer?.deactivateProduct,
          form: values.additionalInfoEmployer,
        },
      });
    }

    dispatch(
      fetchConfirmTanPackage({
        idPackage,
        confirmCode: values.confirmCode,
        ...(additionalInfoAns.length ? { additionalInfoAns } : {}),
      })
    );
  };

  useEffect(() => {
    setFormValues(prev => ({
      ...prev,
      ...employerFormValues,
    }));
  }, [employerFormValues]);

  useEffect(() => {
    if (!isAbortNeedTan) {
      if (isNeedTan) {
        setShowConfirmModal(true);
      }
    } else {
      setShowConfirmModal(false);
    }
  }, [isNeedTan, isAbortNeedTan, dispatch]);

  useTimeout(
    () => repeatRequest && onClickConfirmPin(formValues),
    repeatDelay,
    [formValues, repeatRequest]
  );

  return (
    <ConfirmationCodeModal
      key={`confirm_modal_${showConfirmModal}`}
      title={"Mobile-TAN Bestätigung"}
      showModal={showConfirmModal}
      submitDisabled={waitingPayment}
      formValues={formValues}
      isShowEmployerForm={isShowEmployerForm}
      employerFormData={employerFormData}
      requestErrors={employerFormErrors}
      customerPhone={hidePhoneNumber(user?.phone || "")}
      resetRequestError={resetEmployerRequestError}
      setFormValues={setFormValues}
      closeModal={closeModalHandler}
      onSubmit={onClickConfirmPin}
    />
  );
};

export default ConfirmationCodeWrapper;

import { useFormikContext } from "formik";
import { useEffect } from "react";

const UpdateStateFormik = ({ onChange }: { onChange: any }) => {
  const { values } = useFormikContext();

  useEffect(() => {
    onChange(values);
  }, [onChange, values]);

  return null;
};

export default UpdateStateFormik;

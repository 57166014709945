import React from "react";
import { SuggestionItem } from "../../redux/reducers/addressSlice.d";
import { validatePLZ, validateRequiredField } from "../../utils/formHelper";
import FormikInputAddress from "../FormikInputAddress/FormikInputAddress";
import InputField from "../InputField/InputField";
import styles from "./AdditionalFormEmployer.module.css";
import { EmployerFormErrorValues } from "../../redux/reducers/checkoutSlice.d";

interface FieldType {
  type: string;
  required: boolean;
  title: string;
}

interface AdditionalFormEmployerProps {
  fields: {
    [key: string]: FieldType;
  };
  errors: any;
  touched: any;
  requestErrors?: EmployerFormErrorValues | null;
  setFieldValue: any;
  resetRequestError: (k: string) => void;
  onFieldBlur?: (e: any) => void;
}

const AdditionalFormEmployer: React.FC<AdditionalFormEmployerProps> = ({
  fields,
  errors,
  touched,
  requestErrors,
  setFieldValue,
  resetRequestError,
  onFieldBlur,
}) => {
  const isEmployerError = (errors: any, touched: any, key: string) => {
    if (!Object.keys(errors).length && !Object.keys(touched).length) {
      return false;
    }

    if (
      !errors.hasOwnProperty("additionalInfoEmployer") &&
      !touched.hasOwnProperty("additionalInfoEmployer")
    ) {
      return false;
    }

    const employerErrors = errors?.additionalInfoEmployer;
    const employerToched = touched?.additionalInfoEmployer;

    if (
      employerErrors?.hasOwnProperty(key) &&
      employerToched?.hasOwnProperty(key)
    ) {
      return true;
    }

    return false;
  };

  return (
    <div className={styles.formContainer}>
      <div className={styles.formContactContainer}>
        <div className={styles.formContent}>
          <div className={styles.formGroupInline}>
            <InputField
              key="additionalInfoEmployer.firmName"
              id="additionalInfoEmployer.firmName"
              name="additionalInfoEmployer.firmName"
              type="text"
              labelText="Name/Firma Ihres aktuellen Arbeitgebers"
              placeholder="Name/Firma"
              hideRequiredInLabel={true}
              hasErrors={isEmployerError(errors, touched, "firmName")}
              errorText={requestErrors?.firmName}
              containerClass={styles.formGroupInline}
              labelClass={styles.label}
              inputClass={styles.input}
              onChange={(e: any) => {
                setFieldValue(
                  "additionalInfoEmployer.firmName",
                  e.target.value
                );

                requestErrors?.firmName && resetRequestError("firmName");
              }}
              {...(onFieldBlur ? { onBlur: onFieldBlur } : {})}
              {...(fields.firmName.required
                ? { validateHandler: validateRequiredField }
                : {})}
            />
          </div>
          <div className={styles.formGroupInline}>
            <InputField
              key="additionalInfoEmployer.street"
              id="additionalInfoEmployer.street"
              name="additionalInfoEmployer.street"
              type="text"
              placeholder="Straße"
              labelText="Straße, Nr."
              component={FormikInputAddress}
              hideRequiredInLabel={true}
              hasErrors={isEmployerError(errors, touched, "street")}
              errorText={requestErrors?.street}
              containerClass={styles.formGroupInline}
              labelClass={`${styles.label} ${styles.fixedLabel}`}
              inputClass={`${styles.input} ${styles.fixedInput}`}
              onChange={(suggestion: SuggestionItem) => {
                setFieldValue(
                  "additionalInfoEmployer.street",
                  suggestion.street
                );
                setFieldValue("additionalInfoEmployer.city", suggestion.city);
                setFieldValue(
                  "additionalInfoEmployer.postalCode",
                  suggestion.postalCode
                );

                requestErrors?.street && resetRequestError("street");
              }}
              {...(onFieldBlur ? { onBlur: onFieldBlur } : {})}
              {...(fields.street.required
                ? { validateHandler: validateRequiredField }
                : {})}
            />

            <InputField
              key="additionalInfoEmployer.house"
              id="additionalInfoEmployer.house"
              name="additionalInfoEmployer.house"
              type="text"
              placeholder="Nr."
              hasErrors={isEmployerError(errors, touched, "house")}
              errorText={requestErrors?.house}
              containerClass={`${styles.formGroupInline} ${styles.smallInput} ${styles.textCenter} ${styles.formGroupInlineLeftMargin}`}
              labelClass={styles.label}
              inputClass={styles.input}
              onChange={(e: any) => {
                setFieldValue("additionalInfoEmployer.house", e.target.value);

                requestErrors?.house && resetRequestError("house");
              }}
              {...(onFieldBlur ? { onBlur: onFieldBlur } : {})}
              {...(fields.house.required
                ? { validateHandler: validateRequiredField }
                : {})}
            />
          </div>

          <div className={styles.formGroupInline}>
            <InputField
              id="additionalInfoEmployer.postalCode"
              name="additionalInfoEmployer.postalCode"
              type="text"
              placeholder="PLZ"
              labelText="PLZ, Ort (Firmensitz)"
              containerClass={styles.formGroupInline}
              hideRequiredInLabel={true}
              hasErrors={isEmployerError(errors, touched, "postalCode")}
              errorText={requestErrors?.postalCode}
              labelClass={`${styles.label} ${styles.fixedLabel}`}
              inputClass={`${styles.input} ${styles.smallInput} ${styles.textCenter}`}
              onChange={(e: any) => {
                setFieldValue(
                  "additionalInfoEmployer.postalCode",
                  e.target.value
                );

                requestErrors?.postalCode && resetRequestError("postalCode");
              }}
              {...(onFieldBlur ? { onBlur: onFieldBlur } : {})}
              {...(fields.postalCode.required
                ? { validateHandler: validatePLZ }
                : {})}
            />

            <InputField
              id="additionalInfoEmployer.city"
              name="additionalInfoEmployer.city"
              type="text"
              placeholder="Ort"
              hasErrors={isEmployerError(errors, touched, "city")}
              errorText={requestErrors?.city}
              containerClass={`${styles.formGroupInline} ${styles.fixedInput} ${styles.formGroupInlineLeftMargin}`}
              labelClass={styles.label}
              inputClass={`${styles.input} ${styles.fixedInput}`}
              onChange={(e: any) => {
                setFieldValue("additionalInfoEmployer.city", e.target.value);

                requestErrors?.city && resetRequestError("city");
              }}
              {...(onFieldBlur ? { onBlur: onFieldBlur } : {})}
              {...(fields.city.required
                ? { validateHandler: validateRequiredField }
                : {})}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdditionalFormEmployer;

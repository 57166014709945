import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APICall } from "../../utils/api";
import { MailSupportState, MailSupportValues } from "./mailSupportSlice.d";

const initialState: MailSupportState = {
  error: null,
  status: "idle",
};

export const fetchSendMessageSupport = createAsyncThunk(
  "mailsupport/fetchSendMessageSupport",
  async (
    {
      values,
    }: {
      values: MailSupportValues;
    },
    { rejectWithValue }
  ) => {
    return await APICall(
      "mailservice.mailer",
      "send-message-support",
      rejectWithValue,
      {
        ...values,
      }
    );
  }
);

export const fetchSendMessagePackageSupport = createAsyncThunk(
  "mailsupport/fetchSendMessageSupport",
  async (
    {
      values,
    }: {
      values: MailSupportValues;
    },
    { rejectWithValue }
  ) => {
    return await APICall(
      "mailservice.mailer",
      "send-message-support-package",
      rejectWithValue,
      {
        ...values,
      }
    );
  }
);

export const mailSupportSlice = createSlice({
  name: "mailSupport",
  initialState,
  reducers: {
    clearError: (state, action) => {
      state.error = null;
    },
    changeStatus: (state, action) => {
      state.status = "idle";
    },
  },
  extraReducers: builder => {
    builder.addCase(
      fetchSendMessageSupport.fulfilled as any,
      (state, action) => {
        state.error = null;
        state.status = "succeded";
      }
    );

    builder.addCase(
      fetchSendMessageSupport.rejected as any,
      (state, action) => {
        if (action?.payload?.message) {
          state.error = action.payload.message;
        } else {
          state.error = action.error;
        }

        state.status = "error";
      }
    );
  },
});

export const { clearError, changeStatus } = mailSupportSlice.actions;

export default mailSupportSlice.reducer;

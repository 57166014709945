import React from "react";
import Button from "../../../../components/Button/Button";
import DefaultModal from "../../../../components/DefaultModal/DefaultModal";
import styles from "./ConfirmationCodeModal.module.css";
import { ConfirmationCodeModalProps } from "./ConfirmationCodeModal.d";
import AdditionalFormEmployer from "../../../../components/AdditionalFormEmployer/AdditionalFormEmployer";
import { Form, Formik } from "formik";
import UpdateStateFormik from "../../../../components/UpdateStateFormik/UpdateStateFormik";
import InputField from "../../../../components/InputField/InputField";
import { validateRequiredField } from "../../../../utils/formHelper";
import LoaderComponent from "../../../../components/LoaderComponent/LoaderComponent";

const ConfirmationCodeModal: React.FC<ConfirmationCodeModalProps> = ({
  title,
  showModal,
  submitDisabled,
  isShowEmployerForm,
  employerFormData,
  requestErrors,
  formValues,
  customerPhone,
  setFormValues,
  resetRequestError,
  closeModal,
  onSubmit,
}) => {
  return (
    <DefaultModal
      showModal={showModal}
      {...(closeModal ? { closeHandler: () => closeModal() } : {})}
      title={title}
      className={`${styles.confimationModalContent} ${
        isShowEmployerForm && styles.modalContentWithForm
      }`}
    >
      <div
        className={styles.confimationContainer}
        data-testid={"confirmationCodeModal"}
      >
        <Formik
          {...{
            initialValues: formValues,
            onSubmit: onSubmit,
          }}
          enableReinitialize
        >
          {({ errors, touched, isValid, setFieldValue }) => (
            <Form className={styles.confimationContent} noValidate>
              {isShowEmployerForm && (
                <div className={styles.employerFormContainer}>
                  <h3 className={`h3 ${styles.pinH3}`}>
                    {employerFormData?.formTitle}
                  </h3>

                  <AdditionalFormEmployer
                    fields={employerFormData?.fields}
                    errors={errors}
                    touched={touched}
                    requestErrors={requestErrors}
                    resetRequestError={resetRequestError}
                    setFieldValue={setFieldValue}
                  />
                </div>
              )}

              <h3 className={`h3 ${styles.pinH3}`}>
                Bitte geben Sie hier Ihre Mobile-TAN ein:
              </h3>
              <p className={`p ${styles.pinP}`}>
                Ihre Mobile-TAN wurde an die Nummer
                <br />
                {customerPhone}
                <br />
                gesended.
              </p>

              <div className={styles.confirmationInput}>
                <InputField
                  key="confirmCode"
                  id="confirmCode"
                  name="confirmCode"
                  type="text"
                  labelText=""
                  placeholder="Code"
                  hideRequiredInLabel={true}
                  hasErrors={
                    errors.hasOwnProperty("confirmCode") &&
                    touched.hasOwnProperty("confirmCode")
                  }
                  //containerClass={styles.formGroupInline}
                  //labelClass={styles.label}
                  //inputClass={styles.formControl}
                  validateHandler={validateRequiredField}
                />
              </div>

              <div className={styles.confirmationBtn}>
                <Button
                  type="submit"
                  text="Bestätigen"
                  disabled={
                    !isValid ||
                    !!(requestErrors && Object.keys(requestErrors).length) ||
                    submitDisabled
                  }
                />
              </div>

              <UpdateStateFormik onChange={setFormValues} />
            </Form>
          )}
        </Formik>

        {submitDisabled ? (
          <div className={styles.waitingLoaderContainer}>
            <LoaderComponent />
          </div>
        ) : null}
      </div>
    </DefaultModal>
  );
};

export default ConfirmationCodeModal;

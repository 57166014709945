import axios from "axios";
import { toast } from "react-toastify";
import { API_SERVER } from "../variables_config";

export const clientOld = axios.create({
  baseURL: API_SERVER,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

export const numberToDECurrency = (number: number, noDecimals?: boolean) =>
  new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
    ...(noDecimals ? { minimumFractionDigits: 0 } : null),
  })
    .format(number)
    .toString();

export const getFormattedDate = (date: string) => {
  if (!date) return "";

  const padToTwo = (number: number) => {
    return number > 9 ? number : "0" + number;
  };

  const dt = new Date(date);

  const year = dt.getUTCFullYear();
  const month = dt.getUTCMonth() + 1;
  const day = dt.getUTCDate();

  return `${padToTwo(day)}.${padToTwo(month)}.${year}`;
};

export const removeHtmlTags = (str: React.ReactNode | string) => {
  if (!str) return "";

  return str.toString().replace(/(<([^>]+)>)/gi, " ");
};

export const getQueryStringParams = (query: string) => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split("&")
        .reduce<{ [k: string]: string }>((params, param) => {
          let [key, value] = param.split("=");
          params[key] = value
            ? decodeURIComponent(value.replace(/\+/g, " "))
            : "";
          return params;
        }, {})
    : {};
};

export const successMessage = (text: string) => {
  toast.success(text, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  });
};

export const errorMessage = (text: string) => {
  toast.error(text, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  });
};

export const isIOS = () => {
  const iosQuirkPresent = () => {
    const audio = new Audio();
    audio.volume = 0.5;
    return audio.volume === 1;
  };

  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const isAppleDevice = navigator.userAgent.includes("Macintosh");
  const isTouchScreen = navigator.maxTouchPoints >= 1;

  return isIOS || (isAppleDevice && (isTouchScreen || iosQuirkPresent()));
};

export const truncToDigit = (number: number, digits = 2) =>
  Math.trunc(number * Math.pow(10, digits)) / Math.pow(10, digits);

export const getModalRootElement = (): HTMLElement => {
  let modalRoot = document.querySelector("#modal-root") as HTMLElement;

  // only for tests
  if (!modalRoot) {
    modalRoot = document.createElement("div");
    modalRoot.setAttribute("id", "modal-root");
    document.body.appendChild(modalRoot);
  }

  return modalRoot;
};

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ConfirmationCodeModal from "../../components/ConfirmationCodeModal/ConfirmationCodeModal";
import { authSelector, useAppDispatch } from "../../redux/selectors";
import {
  clearWaitCheck,
  fetchConfirmLogin,
  fetchSendConfirmLogin,
} from "../../redux/reducers/authenticationSlice";
import { errorMessage } from "../../utils";

const secondsToResendCode = 60;

const ConfirmationLoginWrapper: React.FC<{ username: string }> = ({
  username,
}) => {
  const dispatch = useAppDispatch();

  const { status, error, waitCheck, twoWayMethods } = useSelector(authSelector);

  const [confirmCode, setConfirmCode] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [twoWayAlternative, setTwoWayAlternative] = useState("");
  const [seconds, setSeconds] = useState(secondsToResendCode);

  const onClickConfirmEmail = () => {
    dispatch(
      fetchConfirmLogin({
        username,
        code: confirmCode,
        ...(twoWayAlternative
          ? { [twoWayAlternative]: true }
          : { isDefault: true }),
      })
    );
  };

  const resendCode = () => {
    dispatch(
      fetchSendConfirmLogin({
        username,
        ...(twoWayAlternative
          ? { [twoWayAlternative]: true }
          : { isDefault: true }),
      })
    );

    setSeconds(secondsToResendCode);
  };

  const onChangeTwoWayOptions = (value: string) => {
    setTwoWayAlternative(value);
  };

  const onCloseModal = () => {
    setConfirmCode("");
    setShowConfirmModal(false);
    setSeconds(secondsToResendCode);
    dispatch(clearWaitCheck(false));
  };

  useEffect(() => {
    if (waitCheck) {
      setShowConfirmModal(true);
    }
  }, [waitCheck, dispatch]);

  useEffect(() => {
    if (waitCheck && status === "error" && error) {
      errorMessage(error);
    }
  }, [waitCheck, status, error]);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        clearInterval(myInterval);
      }
    }, 1000);

    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <ConfirmationCodeModal
      title={"Two-way confirmation"}
      showModal={showConfirmModal}
      submitDisabled={!confirmCode}
      onInputChange={setConfirmCode}
      closeModal={onCloseModal}
      onSubmit={onClickConfirmEmail}
      onResend={resendCode}
      twoWayOptions={twoWayMethods}
      onChangeTwoWayOptions={onChangeTwoWayOptions}
      secondsToResendCode={seconds}
    />
  );
};

export default ConfirmationLoginWrapper;

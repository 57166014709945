import React from "react";
import { ReactComponent as ArrowIcon } from "../assets/images/arrow.svg";
import ContentBox from "../ContentBox/ContentBox";
import { SlidePackageItemProps } from "./SlidePackageItem.d";
import styles from "./SlidePackageItem.module.css";

const SlidePackageItem: React.FC<SlidePackageItemProps> = ({
  testId,
  name,
  image,
  isActive,
  onClick,
}) => {
  return (
    <div
      data-testid={testId || "slide-item"}
      className={`${styles.slide} ${isActive ? styles.activeSlide : ""}`}
      {...(onClick ? { onClick } : {})}
    >
      <div className={styles.packageContainer}>
        <ContentBox className={styles.packageBox}>
          <div className={styles.text}>
            {name.length > 42 ? `${name.substring(0, 42)}...` : name}
          </div>

          <div className={styles.bottomContainer}>
            <div className={styles.imgContainer}>
              {image ? (
                <img className={styles.img} src={image} alt={name} />
              ) : null}
            </div>

            <div className={styles.line} />
            <div className={styles.arrow}>
              <ArrowIcon className={styles.arrowIcon} />
            </div>
          </div>
        </ContentBox>
      </div>
    </div>
  );
};

export default SlidePackageItem;

import styles from "./Radiobox.module.css";
import { RadioboxProps } from "./Radiobox.d";

const Radiobox: React.FC<RadioboxProps> = ({
  id,
  name,
  checked,
  children,
  disabled = false,
  className = "",
  textClassName = "",
  toggleCheck,
}) => {
  const onChangeHandler = (e: any) => {
    toggleCheck(e.target.checked);
  };

  return (
    <label
      className={`${styles.label} ${
        disabled ? styles.disabled : ""
      } ${className}`}
      htmlFor={id}
    >
      <div className={styles.radiobox}>
        <input
          id={id}
          type="radio"
          name={name}
          defaultChecked={checked}
          {...({ disabled } || null)}
          onChange={onChangeHandler}
        />
        <span
          className={`${styles.knob} ${disabled ? styles.disabled : ""}`}
          data-testid={"inputSpan"}
        />
      </div>

      {children && (
        <div className={`${styles.text} ${textClassName}`}>{children}</div>
      )}
    </label>
  );
};

export default Radiobox;

import styles from "./Modal.module.css";
import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import { ReactComponent as CloseIcon } from "../assets/images/cancelModalIcon.svg";
import { getModalRootElement } from "../../utils";

type ModalProps = {
  show: boolean;
  size?: string;
  hideClose?: boolean;
  title: React.ReactNode;
  closeHandler?: React.MouseEventHandler<HTMLElement>;
  children: React.ReactNode;
};

const modalRoot = getModalRootElement();

const Modal: React.FC<ModalProps> = ({
  show,
  title,
  size,
  hideClose,
  closeHandler,
  children,
}) => {
  const el = useRef(document.createElement("div"));
  const nodeRef = React.useRef(null);

  useEffect(() => {
    // Use this in case CRA throws an error about react-hooks/exhaustive-deps
    const current = el.current;

    // We assume `modalRoot` exists with '!'
    modalRoot && modalRoot.appendChild(current);
    return () => void modalRoot && modalRoot.removeChild(current);
  }, []);

  return ReactDOM.createPortal(
    <CSSTransition
      in={show}
      unmountOnExit
      timeout={{ enter: 0, exit: 300 }}
      nodeRef={nodeRef}
      classNames={{
        enter: styles.enterDone,
        enterActive: styles.enterDone,
        enterDone: styles.enterDone,
        exitDone: styles.exit,
      }}
    >
      <div
        className={styles.modal}
        {...(!hideClose ? { onClick: closeHandler } : {})}
        ref={nodeRef}
        data-testid={"modalWrapper"}
      >
        <div
          className={`${styles.modalContent} ${size ? styles[size] : ""}`}
          onClick={e => e.stopPropagation()}
          data-testid={"modalContent"}
        >
          <div className={styles.modalHeader}>
            <h4 className={styles.modalTitle}>{title}</h4>
            {!hideClose ? (
              <button onClick={closeHandler} className={styles.closeX}>
                <CloseIcon />
              </button>
            ) : null}
          </div>

          <div className={styles.modalBody}>{children}</div>

          <div className={styles.modalFooter}></div>
        </div>
      </div>
    </CSSTransition>,
    el.current
  );
};

export default Modal;

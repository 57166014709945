import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import ConfirmActionModal from "../../components/ConfirmActionModal/ConfirmActionModal";
import {
  fetchDiscontinuePackage,
  resetStatus,
} from "../../redux/reducers/buyPackageSlice";
import {
  buyPackageSelector,
  calcSelector,
  useAppDispatch,
} from "../../redux/selectors";
import { successMessage } from "../../utils";
import useModal from "../../utils/useModal";
import BlockFour from "./components/BlockFour";
import BlockOne from "./components/BlockOne";
import BlockThree from "./components/BlockThree";
import BlockTwo from "./components/BlockTwo";
import styles from "./PackageTemplateDefaultPage.module.css";

const PackageTemplateDefaultPage = () => {
  const dispatch = useAppDispatch();
  const { letterData, isRecalculated } = useSelector(calcSelector);
  const { status: buypackageStatus } = useSelector(buyPackageSelector);
  const { isShowing: isShowingReturnConfirm, toggle: toggleReturnConfirm } =
    useModal();

  const clickReturnPackage = useCallback(() => {
    dispatch(fetchDiscontinuePackage(letterData?.idPackage));
    toggleReturnConfirm();
  }, [letterData?.idPackage, toggleReturnConfirm, dispatch]);

  useEffect(() => {
    if (buypackageStatus === "returned") {
      successMessage("Anfrage zur Stornierung des Mehrwert-Paketes gesendet");
      dispatch(resetStatus("idle"));
    }
  }, [buypackageStatus, dispatch]);

  return (
    <>
      <BlockOne letterData={letterData} styles={styles} />

      <BlockTwo letterData={letterData} styles={styles} />

      <BlockThree
        letterData={letterData}
        isShowSubmitBtn={letterData.isBought ? isRecalculated : true}
        styles={styles}
        toggleReturnConfirmModal={toggleReturnConfirm}
      />
      <BlockFour letterData={letterData} styles={styles} />

      <ConfirmActionModal
        showModal={isShowingReturnConfirm}
        title={"Bitte bestätige Deine Aktivieren"}
        description={"Bitte bestätige Deine Eingabe"}
        confirmBtnText={"Bestätigung"}
        rejectBtnText={"Zurück"}
        confirmHandler={clickReturnPackage}
        rejectHandler={toggleReturnConfirm}
      />
    </>
  );
};

export default PackageTemplateDefaultPage;

import { useEffect } from "react";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import {
  fetchUserResetPassword,
  clearError,
} from "../../redux/reducers/authenticationSlice";
import InputField from "../../components/InputField/InputField";
import BlockContainer from "../../components/BlockContainer/BlockContainer";
import Button from "../../components/Button/Button";
import { validateRequiredField } from "../../utils/formHelper";
import { ROUTE } from "../../variables_config";
import { ForgotPasswordFormValues } from "./PasswordForgotPage.d";
import styles from "./PasswordForgotPage.module.css";
import MainWrapper from "../../containers/MainWrapper/MainWrapper";
import ContentBox from "../../components/ContentBox/ContentBox";
import { authSelector, useAppDispatch } from "../../redux/selectors";
import { ReactComponent as EmailIcon } from "../../images/email-icon.svg";
import { successMessage } from "../../utils";

const PasswordForgotPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { status } = useSelector(authSelector);

  const initialValues: ForgotPasswordFormValues = {
    email: "",
  };

  const onSubmit = (values: ForgotPasswordFormValues) => {
    let sendValues = {
      ...values,
      link: `${window.location.origin}${ROUTE}${
        ROUTE === "/" ? "" : "/"
      }reset-password`,
    };

    dispatch(clearError(null));
    dispatch(fetchUserResetPassword(sendValues));
  };

  useEffect(() => {
    if (status === "succeded") {
      successMessage(`Link zum Zurücksetzen an Ihre E-Mail-Adresse gesendet`);

      setTimeout(() => navigate("/login"), 2000);
    }
  }, [status, navigate]);

  return (
    <MainWrapper hideFooter={true}>
      <BlockContainer>
        <div className={styles.loginBlock}>
          <ContentBox className={styles.contentBox}>
            <div className={styles.loginContent}>
              <h1>Passwort restore</h1>

              <Formik {...{ initialValues, onSubmit }}>
                {({ errors, touched }) => (
                  <Form className={styles.formContainer} noValidate>
                    <InputField
                      key="email"
                      id="email"
                      name="email"
                      type="text"
                      placeholder="Email eingeben"
                      labelText="E-Mail"
                      hasErrors={
                        errors.hasOwnProperty("email") &&
                        touched.hasOwnProperty("email")
                      }
                      validateHandler={validateRequiredField}
                      icon={EmailIcon}
                    />

                    <div className={styles.btnContainer}>
                      <Button
                        type="submit"
                        variant="secondary"
                        text="Get reset link"
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </ContentBox>
        </div>
      </BlockContainer>
    </MainWrapper>
  );
};

export default PasswordForgotPage;

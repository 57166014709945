import { subYears } from "date-fns";
import { FieldArray } from "formik";
import React from "react";
import { validateRequiredField } from "../../utils/formHelper";
import FormikDatePicker from "../FormikDatePicker/FormikDatePicker";
import TrackInputField from "../InputField/InputField";
import styles from "./AdditionalFormPersonDeath.module.css";
import { ReactComponent as RemoveIcon } from "../../images/close-round-icon.svg";
import Button from "../Button/Button";
import { ReactComponent as DateRangeIcon } from "../../images/date-range-icon.svg";
import {
  OtherPerson,
  PersonDeathFormValues,
} from "../../redux/reducers/buyPackageSlice.d";

interface FieldType {
  type: string;
  required: boolean;
  title: string;
}

interface PersonOption {
  title: string;
  value: string;
}

interface AdditionalFormPersonDeathProps {
  options: Array<PersonOption>;
  fields: {
    otherPersons: { [key: string]: FieldType };
  };
  values: PersonDeathFormValues | undefined;
  errors: any;
  touched: any;
  showPersonFormOnValue: string;
  isPersonRequired: boolean;
  setFieldValue: any;
  onFieldBlur?: (e: any) => void;
  sendRecalc?: (v: PersonDeathFormValues) => void;
}

const minAge = 18;
const defaultYear = 1980;

const newPerson: OtherPerson = { firstName: "", lastName: "", dateBirth: "" };

const AdditionalFormPersonDeath: React.FC<AdditionalFormPersonDeathProps> = ({
  options,
  fields,
  values,
  errors,
  touched,
  showPersonFormOnValue,
  isPersonRequired,
  setFieldValue,
  onFieldBlur,
  sendRecalc,
}) => {
  const isOtherPersonError = (
    errors: any,
    touched: any,
    key: string,
    index: number
  ) => {
    if (
      errors &&
      touched &&
      !Object.keys(errors)?.length &&
      !Object.keys(touched)?.length
    ) {
      return false;
    }

    if (
      !errors?.hasOwnProperty("otherPersons") &&
      !touched?.hasOwnProperty("otherPersons")
    ) {
      return false;
    }

    const otherPersonErrors = errors?.otherPersons;
    const otherPersonToched = touched?.otherPersons;

    if (
      !otherPersonErrors ||
      !otherPersonErrors?.length ||
      !otherPersonToched ||
      !otherPersonToched?.length
    ) {
      return false;
    }

    if (
      otherPersonErrors[index]?.hasOwnProperty(key) &&
      otherPersonToched[index]?.hasOwnProperty(key)
    ) {
      return true;
    }

    return false;
  };

  return (
    <div className={styles.formContainer}>
      <div className={styles.formContactContainer}>
        <div className={styles.formContent}>
          <TrackInputField
            key="additionalInfoPerson.value"
            id="additionalInfoPerson.value"
            name="additionalInfoPerson.value"
            type="select"
            placeholder=""
            labelText=""
            containerClass={styles.personInput}
            hasErrors={errors?.hasOwnProperty("value")}
            {...(isPersonRequired
              ? {
                  validateHandler: (value: string) => {
                    let error = "";

                    if (
                      value === "" ||
                      (value === showPersonFormOnValue &&
                        !values?.otherPersons?.length)
                    ) {
                      error = "Erforderlich";
                    }

                    return error;
                  },
                }
              : {})}
            onChange={(e: any) => {
              const value = e.target.value;
              setFieldValue("additionalInfoPerson.value", value);

              if (value === showPersonFormOnValue) {
                setFieldValue("additionalInfoPerson.otherPersons", [newPerson]);
              }
            }}
            {...(onFieldBlur ? { onBlur: onFieldBlur } : {})}
          >
            <option value="" label="Auswählen...">
              Auswählen...
            </option>

            {options.map(option => (
              <option
                key={option.value}
                value={option.value}
                label={option.title}
              >
                {option.title}
              </option>
            ))}
          </TrackInputField>

          {values?.value === showPersonFormOnValue ? (
            <FieldArray name="additionalInfoPerson.otherPersons">
              {({ remove, push }) => (
                <div className={styles.otherPersonContainer}>
                  {values?.otherPersons &&
                    values?.otherPersons.length > 0 &&
                    values?.otherPersons?.map((otherPerson, index) => (
                      <div className={styles.otherPersonInputsRow} key={index}>
                        <div className={styles.otherPersonInput}>
                          <TrackInputField
                            key={`additionalInfoPerson.otherPersons.${index}.firstName`}
                            id={`additionalInfoPerson.otherPersons.${index}.firstName`}
                            name={`additionalInfoPerson.otherPersons.${index}.firstName`}
                            type="text"
                            labelText=""
                            placeholder={fields.otherPersons.firstName.title}
                            hideRequiredInLabel={true}
                            hasErrors={isOtherPersonError(
                              errors,
                              touched,
                              "firstName",
                              index
                            )}
                            containerClass={styles.personInput}
                            {...(fields.otherPersons.firstName.required
                              ? { validateHandler: validateRequiredField }
                              : {})}
                            {...(onFieldBlur ? { onBlur: onFieldBlur } : {})}
                          />

                          <TrackInputField
                            key={`additionalInfoPerson.otherPersons.${index}.lastName`}
                            id={`additionalInfoPerson.otherPersons.${index}.lastName`}
                            name={`additionalInfoPerson.otherPersons.${index}.lastName`}
                            type="text"
                            labelText=""
                            placeholder={fields.otherPersons.lastName.title}
                            hideRequiredInLabel={true}
                            hasErrors={isOtherPersonError(
                              errors,
                              touched,
                              "lastName",
                              index
                            )}
                            containerClass={styles.personInput}
                            {...(fields.otherPersons.lastName.required
                              ? { validateHandler: validateRequiredField }
                              : {})}
                            {...(onFieldBlur ? { onBlur: onFieldBlur } : {})}
                          />

                          <TrackInputField
                            key={`additionalInfoPerson.otherPersons.${index}.dateBirth`}
                            id={`additionalInfoPerson.otherPersons.${index}.dateBirth`}
                            name={`additionalInfoPerson.otherPersons.${index}.dateBirth`}
                            type="text"
                            placeholder="01.01.1980"
                            labelText=""
                            component={FormikDatePicker}
                            hasErrors={isOtherPersonError(
                              errors,
                              touched,
                              "dateBirth",
                              index
                            )}
                            {...(fields.otherPersons.dateBirth.required
                              ? { validateHandler: validateRequiredField }
                              : {})}
                            maxDate={subYears(new Date(), minAge)}
                            rightIcon={DateRangeIcon}
                            openToDate={new Date().setFullYear(defaultYear)}
                            containerClass={styles.personInput}
                            onChange={(date: any) => {
                              setFieldValue(
                                `additionalInfoPerson.otherPersons.${index}.dateBirth`,
                                date
                              );
                              sendRecalc && sendRecalc(values);
                            }}
                            {...(onFieldBlur ? { onBlur: onFieldBlur } : {})}
                          />
                        </div>

                        <div className={styles.otherPersonRemove}>
                          <div
                            onClick={() => remove(index)}
                            className={styles.iconContainer}
                          >
                            <RemoveIcon className={styles.icon} />
                          </div>
                        </div>
                      </div>
                    ))}
                  <Button
                    className={styles.small}
                    clickHandler={() => {
                      push({ firstName: "", lastName: "", dateBirth: "" });
                    }}
                    text="Add person"
                  />
                </div>
              )}
            </FieldArray>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AdditionalFormPersonDeath;

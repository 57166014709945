import styles from "./LongButton.module.css";
import { Props } from "./LongButton.d";
import Button from "../Button/Button";

const LongButton: React.FC<Props> = ({
  variant = "primary",
  type = "button",
  className = "",
  leftIconClassName = "",
  text,
  href,
  disabled,
  icon,
  clickHandler,
}) => {
  return (
    <Button
      type={type}
      variant={variant}
      className={`${styles.longBtn} ${className}`}
      leftIconClassName={leftIconClassName}
      text={text}
      icon={icon}
      isArrow={true}
      clickHandler={clickHandler}
      {...(href ? { href } : {})}
      {...(disabled ? { disabled } : {})}
    />
  );
};

export default LongButton;

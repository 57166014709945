import moment from "moment";
import React from "react";
import BlockContainer from "../../../components/BlockContainer/BlockContainer";
import TitleLine from "../../../components/TitleLine/TitleLine";
import Calculator from "../../../containers/Calculator/Calculator";
import { ErrorBoundaryWrapper } from "../../../containers/ErrorBoundaryWrapper/ErrorBoundaryWrapper";
import { IS_RVN_BRAND } from "../../../variables_config";
import { BlockProps } from "../PackageTemplateDefaultPage.d";

const formatDate = (date: string) => moment(date).utc().format("DD.MM.yyyy");

const BlockTwo: React.FC<BlockProps> = ({ letterData, styles }) => {
  if (!letterData) {
    return null;
  }

  const futureDate = moment(letterData.dateInFuture);
  futureDate.subtract(1, "seconds");
  const date = letterData?.dateStart
    ? letterData?.dateStart.toString()
    : new Date().toDateString();

  return (
    <BlockContainer>
      <div className={styles.blockTwoContainer}>
        <div className={styles.twoDivs}>
          <div className={styles.half}>
            <TitleLine>
              {letterData?.isBought ? (
                <>Dein Mehrwert-Paket kurz erklärt – {letterData?.name}</>
              ) : (
                <>
                  <div className={`${styles.texted} ${styles.titledText}`} />
                  <div
                    className={`${styles.texted} ${styles.titledText}`}
                    style={{ width: "70%" }}
                  />
                </>
              )}
            </TitleLine>
          </div>
          <div className={styles.half}></div>
        </div>

        <div className={styles.textBlock}>
          {letterData?.isBought ? (
            <span>
              Hier siehst du die Konfiguration deines bestehenden{" "}
              {letterData?.name}
              {!IS_RVN_BRAND ? " Mehrwert-Paketes" : null}
              {". "}
              {letterData?.parentPackageDateEnd &&
              new Date(letterData?.dateStart || "").getTime() >
                new Date().getTime() ? (
                <>
                  <span className={styles.newStartText}>
                    Der unten aufgeführte{" "}
                    {!IS_RVN_BRAND ? letterData?.name : "R+V-CashProtect"} wirkt
                    ab dem {formatDate(date)}
                    {" 00:00"}.
                  </span>{" "}
                </>
              ) : null}
              Du kannst sie nach deinen Wünschen mit wirkung zum{" "}
              {futureDate.utc().format("DD.MM.yyyy HH:mm")} anpassen.
            </span>
          ) : (
            <>
              <div className={styles.texted} style={{ width: "80%" }} />
              <div className={styles.texted} style={{ width: "90%" }} />
            </>
          )}
        </div>

        <div className={styles.calcContainer}>
          <ErrorBoundaryWrapper>
            <Calculator
              packageIdProp={letterData?.idPackage}
              createDraft={true}
            />
          </ErrorBoundaryWrapper>
        </div>
      </div>
    </BlockContainer>
  );
};

export default BlockTwo;

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { IS_CASHBACK, ROUTE } from "../variables_config";

/* Main pages */
import MainLanding from "../pages/LandingPage/LandingPage";
import MainLogin from "../pages/LoginPage/Login";
import PasswordForgotPage from "../pages/PasswordForgotPage/PasswordForgotPage";
import PasswordResetPage from "../pages/PasswordResetPage/PasswordResetPage";
import PasswordSetPage from "../pages/PasswordSetPage/PasswordSetPage";
import MainPackage from "../containers/PackageLayoutContainer/PackageLayoutContainer";
import MainProfile from "../pages/ProfilePage/ProfilePage";
import MainCheckout from "../pages/CheckoutPage/Checkout";
import MainThankyou from "../pages/ThankyouPage/Thankyou";
import MainCashback from "../pages/CashbackPage/Cashback";
import MainCashbackCheckout from "../pages/CashbackCheckoutPage/CashbackCheckout";
import MainDownloads from "../pages/DownloadsPage/Downloads";
import ErrorsBounding from "../containers/ErrorsBounding/ErrorsBounding";
import CheckAuth from "../containers/CheckAuth/CheckAuth";
import PrivateInterceptorWrapper from "../containers/PrivateInterceptorWrapper/PrivateInterceptorWrapper";
import ScrollToTop from "../containers/ScrollToTop/ScrollToTop";
import MainHome from "../pages/HomePage/HomePage";
import AufWalletPage from "../pages/AufWalletPage/AufWalletPage";
import SchadenMeldenPage from "../pages/SchadenMeldenPage/SchadenMeldenPage";
import NachrichtenPage from "../pages/NachrichtenPage/NachrichtenPage";
import ServiceCenterPage from "../pages/ServiceCenterPage/ServiceCenterPage";
import FAQsPage from "../pages/FAQsPage/FAQsPage";
import FAQPage from "../pages/FAQPage/FAQPage";
import FAQArticlePage from "../pages/FAQArticlePage/FAQArticlePage";
import UnserePartnerPage from "../pages/UnserePartnerPage/UnserePartnerPage";
import NutzungsbedingungenPage from "../pages/NutzungsbedingungenPage/NutzungsbedingungenPage";
import ImpressumPage from "../pages/ImpressumPage/ImpressumPage";
import DatenschutzPage from "../pages/DatenschutzPage/DatenschutzPage";
import SettingsFront from "../containers/SettingsFront/SettingsFront";
import CheckCookies from "../containers/CheckCookies/CheckCookies";
import InfoBuildPage from "../pages/InfoBuildPage/InfoBuildPage";

//import GlobalThemeStyles from '../containers/GlobalThemeStyles/GlobalThemeStyles';
import MainLayout from "../layouts/MainLayoutRoute";

function App() {
  return (
    <>
      <BrowserRouter basename={ROUTE}>
        {/* <GlobalThemeStyles /> */}
        <SettingsFront />
        <ScrollToTop />
        <CheckCookies />

        <Routes>
          <Route
            path="/login"
            element={
              <MainLayout>
                <MainLogin />
              </MainLayout>
            }
          />
          <Route
            path="/infobuildpage"
            element={
              <MainLayout>
                <InfoBuildPage />
              </MainLayout>
            }
          />

          <Route
            path="/forgot-password"
            element={
              <MainLayout>
                <PasswordForgotPage />
              </MainLayout>
            }
          />
          <Route
            path="/reset-password"
            element={
              <MainLayout>
                <PasswordResetPage />
              </MainLayout>
            }
          />
          <Route
            path="/set-password"
            element={
              <MainLayout>
                <PasswordSetPage />
              </MainLayout>
            }
          />
          <Route
            path="/agb"
            element={
              <MainLayout>
                <NutzungsbedingungenPage />
              </MainLayout>
            }
          />
          <Route
            path="/impressum"
            element={
              <MainLayout>
                <ImpressumPage />
              </MainLayout>
            }
          />
          <Route
            path="/datenschutz"
            element={
              <MainLayout>
                <DatenschutzPage />
              </MainLayout>
            }
          />

          <Route
            path="/"
            element={
              <CheckAuth>
                <MainLayout>
                  <MainLanding />
                </MainLayout>
              </CheckAuth>
            }
          />

          <Route
            path="*"
            element={
              <CheckAuth>
                <PrivateInterceptorWrapper>
                  <Routes>
                    <Route
                      path="/home"
                      element={
                        <MainLayout>
                          <MainHome />
                        </MainLayout>
                      }
                    />
                    <Route
                      path="/packages/:packageId"
                      element={
                        <MainLayout>
                          <MainPackage />
                        </MainLayout>
                      }
                    />

                    {IS_CASHBACK && (
                      <>
                        <Route
                          path="/cashback"
                          element={
                            <MainLayout>
                              <MainCashback />
                            </MainLayout>
                          }
                        />

                        <Route
                          path="/cashback-checkout"
                          element={
                            <MainLayout>
                              <MainCashbackCheckout />
                            </MainLayout>
                          }
                        />
                      </>
                    )}

                    <Route
                      path="/checkout/:packageId"
                      element={
                        <MainLayout>
                          <MainCheckout />
                        </MainLayout>
                      }
                    />
                    <Route
                      path="/thankyou"
                      element={
                        <MainLayout>
                          <MainThankyou />
                        </MainLayout>
                      }
                    />
                    <Route
                      path="/profile"
                      element={
                        <MainLayout>
                          <MainProfile />
                        </MainLayout>
                      }
                    />
                    <Route
                      path="/downloads"
                      element={
                        <MainLayout>
                          <MainDownloads />
                        </MainLayout>
                      }
                    />
                    <Route
                      path="/aufwallet"
                      element={
                        <MainLayout>
                          <AufWalletPage />
                        </MainLayout>
                      }
                    />
                    <Route
                      path="/nachrichten"
                      element={
                        <MainLayout>
                          <NachrichtenPage />
                        </MainLayout>
                      }
                    />
                    <Route
                      path="/service-contact"
                      element={
                        <MainLayout>
                          <ServiceCenterPage />
                        </MainLayout>
                      }
                    />
                    <Route
                      path="/service-contact/:packageId"
                      element={
                        <MainLayout>
                          <SchadenMeldenPage />
                        </MainLayout>
                      }
                    />
                    <Route
                      path="/faq"
                      element={
                        <MainLayout>
                          <FAQsPage />
                        </MainLayout>
                      }
                    />
                    <Route
                      path="/faq/:faqId"
                      element={
                        <MainLayout>
                          <FAQPage />
                        </MainLayout>
                      }
                    />
                    <Route
                      path="/faq/question/:questionId"
                      element={
                        <MainLayout>
                          <FAQArticlePage />
                        </MainLayout>
                      }
                    />
                    <Route
                      path="/unserepartner"
                      element={
                        <MainLayout>
                          <UnserePartnerPage />
                        </MainLayout>
                      }
                    />
                  </Routes>
                </PrivateInterceptorWrapper>
              </CheckAuth>
            }
          />
        </Routes>
      </BrowserRouter>
      <ErrorsBounding />
    </>
  );
}

export default App;

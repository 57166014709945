import React from "react";
import ReactPlayer from "react-player";
import BlockContainer from "../../../components/BlockContainer/BlockContainer";
import ContentBox from "../../../components/ContentBox/ContentBox";
import LongButton from "../../../components/LongButton/LongButton";
import TitleLine from "../../../components/TitleLine/TitleLine";
import { ReactComponent as BoxAltIcon } from "../../../images/box-alt-icon.svg";
import play from "../../../images/play-icon.svg";
import videoPreview from "../video-preview.jpg";
import { BlockProps } from "../LandingPage.d";

const BlockFour: React.FC<BlockProps> = ({ styles }) => {
  return (
    <BlockContainer>
      <div className={styles.blockFourContainer}>
        <h3>So funktioniert's</h3>
        <ContentBox className={styles.blockFour}>
          <TitleLine>
            Warum wir alles anders machen als das, was du von Versicherungen
            kennst?
          </TitleLine>

          <p className={styles.textBlock}>
            Weil wir der Überzeugung sind, dass sich Versicherungen den Menschen
            anpassen sollte, und nicht umgekehrt.
          </p>

          <p className={styles.smallTextBlock}>So geht Versicherung heute:</p>

          <div className={styles.videoContainer}>
            <ReactPlayer
              url="https://cdn.valu-x.com/video/vx2-h264_1080_best.mp4"
              playIcon={
                <span className={styles.playIcon}>
                  <img className={styles.playBut} src={play} alt="play" />
                </span>
              }
              light={videoPreview}
              controls
              playing
              width="100%"
              height="100%"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
              }}
            />
          </div>

          <p className={styles.smallTextBlock}>
            Individualisierung ist das, was unsere Mehrwert-Pakete auszeichnet.
          </p>

          <p className={styles.textBlock}>Wir haben dein Interesse geweckt?</p>

          <div className={styles.btnContainer}>
            <LongButton
              text="Mehrwertpakete ansehen"
              icon={BoxAltIcon}
              href="/login"
            />
          </div>
        </ContentBox>
      </div>
    </BlockContainer>
  );
};

export default BlockFour;

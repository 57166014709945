import { NavLink } from "react-router-dom";
import styles from "./HeaderLinkItem.module.css";
import { LinkItemProps } from "./HeaderLinkItem.d";

const HeaderLinkItem: React.FC<LinkItemProps> = ({
  text,
  href,
  icon,
  className = "",
}) => {
  const IconComponent: any = icon;
  return (
    <NavLink
      to={href}
      className={`${styles.link} ${(isActive: boolean) =>
        isActive ? styles.active : ""}`}
    >
      <span className={styles.iconContainer}>
        <IconComponent className={`${styles.icon} ${className}`} />
      </span>
      <span>{text}</span>
    </NavLink>
  );
};

export default HeaderLinkItem;

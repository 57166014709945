import MainWrapper from "../../containers/MainWrapper/MainWrapper";
import BlockContainer from "../../components/BlockContainer/BlockContainer";
//import styles from "./AufWalletPage.module.css";

const AufWalletPage = () => {
  return (
    <MainWrapper>
      <BlockContainer>
        <div>Auf Wallet Page</div>
      </BlockContainer>
    </MainWrapper>
  );
};

export default AufWalletPage;

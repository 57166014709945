import React from "react";
import BlockContainer from "../../../components/BlockContainer/BlockContainer";
import ContentBox from "../../../components/ContentBox/ContentBox";
import InfoBubble from "../../../components/InfoBubble/InfoBubble";
import Li from "../../../components/Li/Li";
import LongButton from "../../../components/LongButton/LongButton";
import TitleLine from "../../../components/TitleLine/TitleLine";
import { ReactComponent as UserIcon } from "../../../images/user-icon.svg";
import { ReactComponent as GeldGrafik } from "../../../images/geld-grafik.svg";
import { BlockProps } from "../LandingPage.d";
import useWindowDimensions from "../../../utils/useWindowDimentions";

const mobFrom = 1044;

const BlockFive: React.FC<BlockProps> = ({ styles }) => {
  const { width } = useWindowDimensions();

  const ulBlock = (
    <ul className={styles.ulBlock}>
      <Li>Ganz einfach.</Li>
      <Li>Ganz automatisch.</Li>
      <Li>Ganz sicher bares Geld sparen.</Li>
    </ul>
  );

  return (
    <BlockContainer>
      <div className={styles.blockFiveContainer}>
        <h3>Cashback</h3>
        <ContentBox className={styles.blockFive}>
          <TitleLine className={styles.blockFiveTitle}>
            Geld sparen beim Einkaufen?
          </TitleLine>

          <div className={styles.withImgContainer}>
            <div className={styles.itemsContainer}>
              <p className={styles.textBlock}>
                Mit jedem deiner Online-Einkäufe bei unseren vielen
                angeschlossenen Partnern bekommst du echte Euros auf dein Konto
                zurück-überwiesen anstatt Paybackpunkte zu sammeln.
              </p>

              {width > mobFrom ? ulBlock : null}
            </div>
            <div className={styles.imgContainer}>
              <GeldGrafik className={styles.blockFiveImg} />
            </div>
          </div>

          {width <= mobFrom ? ulBlock : null}

          <div className={styles.btnContainer}>
            <LongButton
              text="Jetzt zum Mitgliederbereich"
              icon={UserIcon}
              href="/login"
            />
          </div>

          <InfoBubble
            customClass={styles.bubbleTop}
            text="bis zu 25% Cashback auf deine Einkäufe"
          />
        </ContentBox>
      </div>
    </BlockContainer>
  );
};

export default BlockFive;

//import styles from "./FAQPage.module.css";
import { useEffect, useState } from "react";
import BlockContainer from "../../components/BlockContainer/BlockContainer";
//import Button from "../../../components/Button/Button";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import styles from "./FAQsPage.module.css";
import SearchFaqBar from "../../containers/SearchFaqBar/SearchFaqBar";
import {
  fetchFaqList,
  fetchFaqSearchList,
} from "../../redux/reducers/faqSlice";
import { FaqItem } from "../../redux/reducers/faqSlice.d";
import { Link, useNavigate } from "react-router-dom";
import MainWrapper from "../../containers/MainWrapper/MainWrapper";
import ContentBox from "../../components/ContentBox/ContentBox";
import TitleLine from "../../components/TitleLine/TitleLine";
import { ReactComponent as CloseIcon } from "../../images/close-round-icon.svg";
import Button from "../../components/Button/Button";
import { useAppDispatch } from "../../redux/selectors";

const QUESTIONS_COUNT = 3;

const FAQPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { faqList, faqSearchList, status, searchStatus } = useSelector(
    (state: RootState) => state.faq
  );

  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState<Array<FaqItem>>([]);

  const onSearchHandler = (text: string) => {
    setSearchValue(text);

    if (text.trim().length > 2) {
      dispatch(fetchFaqSearchList(text));
    } else {
      setSearchResults([]);
    }
  };

  useEffect(() => {
    if (searchStatus === "succeded") setSearchResults(faqSearchList);
  }, [searchStatus, faqSearchList]);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchFaqList({}));
    }
  }, [status, dispatch]);

  return (
    <MainWrapper>
      <BlockContainer>
        <div className={styles.blockContainer}>
          <ContentBox className={styles.block}>
            <TitleLine className={styles.title}>
              FAQ - Fragen und Antworten
            </TitleLine>

            <Button
              type="icon"
              icon={CloseIcon}
              className={styles.closeBtn}
              clickHandler={() => navigate("/home")}
            />

            <div className={styles.content}>
              <div className={styles.searchContainer}>
                <h3>Wie können wir dir helfen?</h3>

                <SearchFaqBar
                  results={searchResults}
                  keyword={searchValue}
                  changeHandler={onSearchHandler}
                />

                <p className={styles.description}>
                  Du kannst auch die Themen unten nach Antworten durchsuchen.
                </p>
              </div>

              <div className={styles.categoriesContainer}>
                {/* <div className={styles.title}>Themen</div> */}

                <div className={styles.categoriesItems}>
                  {faqList &&
                    faqList.map((faq: FaqItem) => (
                      <div key={faq._id} className={styles.categoryItem}>
                        <Link
                          to={`/faq/${faq._id}`}
                          className={`h3 ${styles.categoryTitle}`}
                        >
                          {faq.category}
                        </Link>

                        <div className={styles.categoryQuestions}>
                          {faq.articles &&
                            faq.articles
                              .slice(0, QUESTIONS_COUNT)
                              .map((item, i) => (
                                <div key={i} className={styles.question}>
                                  <Link
                                    className={styles.readMore}
                                    to={`/faq/question/${item._id}`}
                                  >
                                    {item.question}
                                  </Link>
                                </div>
                              ))}
                        </div>

                        {faq.articles &&
                          faq.articles.length > QUESTIONS_COUNT && (
                            <Link
                              className={styles.allQuestions}
                              to={`/faq/${faq._id}`}
                            >
                              Alle Fragen ansehen
                            </Link>
                          )}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </ContentBox>
        </div>
      </BlockContainer>
    </MainWrapper>
  );
};

export default FAQPage;

import React from "react";
import { NumericFormat } from "react-number-format";

const FormattedNumber: React.FC<{ value: number }> = ({ value }) => {
  return (
    <NumericFormat
      value={value}
      displayType={"text"}
      thousandSeparator={"."}
      decimalSeparator={","}
    />
  );
};

export default FormattedNumber;

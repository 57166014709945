import InputAdressAutocomplete from "../InputAdressAutocomplete/InputAdressAutocomplete";

const FormikInputAddress = ({
  field,
  form,
  onChange,
  ...props
}: {
  field: any;
  form: any;
  onChange: any;
  onSuggestionChange: any;
}) => {
  return (
    <InputAdressAutocomplete
      {...field}
      {...props}
      onInputChange={(value: string) => form.setFieldValue(field.name, value)}
      onSuggestionChange={onChange}
    />
  );
};

export default FormikInputAddress;

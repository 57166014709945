import React from "react";
import styles from "./TitleLine.module.css";
interface Props {
  className?: string;
  children: React.ReactNode;
}

const TitleLine: React.FC<Props> = ({ className = "", children }) => {
  return (
    <h2 className={`${styles.h2} ${className}`}>
      <div className={styles.child}>{children}</div>
      <div className={styles.line}></div>
    </h2>
  );
};

export default TitleLine;

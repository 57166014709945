import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { client } from "../../utils/api";
import { RootState, store } from "../../redux/store";
import LoaderComponent from "../../components/LoaderComponent/LoaderComponent";
import { ROUTE } from "../../variables_config";
import styles from "./PrivateInterceptorWrapper.module.css";

const addBearerToken = (req: any) => {
  const token = store.getState().authentication.token;
  if (token) {
    req.headers.Authorization = `Bearer ${token}`;
  }
  return req;
};

const checkAuth = (res: any) => {
  return res;
};

const checkAuthCatch = async (error: any) => {
  if (
    401 === error.response.status &&
    window.location.pathname !== "/" &&
    window.location.pathname !== `${ROUTE}${ROUTE === "/" ? "" : "/"}login`
  ) {
    console.log(`client.interceptors.response ${error.response.status}`);
    window.location.href = `${window.location.origin}${ROUTE}${
      ROUTE === "/" ? "" : "/"
    }login`;
  } else {
    return Promise.reject(error);
  }
};

const PrivateInterceptorWrapper: React.FC<any> = ({ children }) => {
  const [added, setAdded] = useState(false);
  const { token } = useSelector((state: RootState) => state.authentication);

  useEffect(() => {
    const requestInterceptor = client.interceptors.request.use(addBearerToken);
    const responseInterceptor = client.interceptors.response.use(
      checkAuth,
      checkAuthCatch
    );
    setAdded(true);

    return () => {
      client.interceptors.request.eject(requestInterceptor);
      client.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  if (!added || !token) {
    return (
      <div className={styles.wrapper}>
        <LoaderComponent />
      </div>
    );
  }

  return children;
};
export default PrivateInterceptorWrapper;

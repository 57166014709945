import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APICall } from "../../utils/api";
import {
  CustomerAccount,
  ProfileState,
  ProfileValuesToSave,
} from "./profileSlice.d";

const apiVersion = "1.0.0";

const initialState: ProfileState = {
  profileData: {
    roles: ["customer"],
    _id: "",
    confirmed: false,
    username: "",
    email: "",
    lastName: "",
    firstName: "",
    salutation: "",
    gender: "",
    dateBirth: "",
    city: "",
    postalCode: "",
    userCode: "",
    iban: "",
    phone: "",
    accountHolderName: "",
    twoWayAuthEmail: { active: false, isDefault: false },
    twoWayAuthSMS: { active: false, isDefault: false },
    twoWayAuthGoogle: { active: false, isDefault: false },
    extras: {},
  },
  gQRcode: "",
  status: "idle",
  error: null,
};

export const fetchProfileData = createAsyncThunk(
  "profile/profileData",
  async ({ woDots }: { woDots: boolean }, { rejectWithValue }) => {
    return await APICall(
      "authservice.user",
      "get-current-profile",
      rejectWithValue,
      {
        woDots,
        ver: apiVersion,
      }
    );
  }
);

export const fetchProfileSchema = createAsyncThunk(
  "profile/Schema",
  async (_, { rejectWithValue }) => {
    return await APICall("authservice.user", "schema", rejectWithValue, {
      ver: apiVersion,
    });
  }
);

export const saveProfileData = createAsyncThunk(
  "profile/saveProfileData",
  async ({ values }: { values: ProfileValuesToSave }, { rejectWithValue }) => {
    return await APICall(
      "authservice.user",
      "update-current-profile",
      rejectWithValue,
      {
        ...values,
        extras: {
          house: values.house,
          street: values.street,
        },
        ver: apiVersion,
      }
    );
  }
);

export const fetchGoogleSetup = createAsyncThunk(
  "profile/fetchGoogleSetup",
  async (setup: boolean, { rejectWithValue, getState }) => {
    return await APICall("authservice.user", "google-setup", rejectWithValue, {
      setup,
    });
  }
);

export const fetchGoogleSetupConfirm = createAsyncThunk(
  "profile/fetchGoogleSetupConfirm",
  async (
    { code, isDefault }: { code: string; isDefault: boolean },
    { rejectWithValue }
  ) => {
    return await APICall(
      "authservice.user",
      "google-setup-confirm",
      rejectWithValue,
      {
        code,
        isDefault,
      }
    );
  }
);

export const fetchUpdateCustomerAccount = createAsyncThunk(
  "profile/fetchUpdateCustomerAccount",
  async ({ iban, accountHolderName }: CustomerAccount, { rejectWithValue }) => {
    return await APICall(
      "authservice.user",
      "update-current-profile-iban",
      rejectWithValue,
      {
        iban,
        accountHolderName,
      }
    );
  }
);

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    clearError: (state, action) => {
      state.error = null;
    },
    clearStatus: (state, action) => {
      state.status = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchProfileData.fulfilled as any, (state, action) => {
      state.profileData = action.payload;
      state.error = null;
      state.status = "updated";
    });

    builder.addCase(fetchProfileData.rejected as any, (state, action) => {
      if (action?.payload?.message) state.error = action.payload.message;
      else state.error = "Etwas schiefgegangen";

      state.status = "error";
    });

    builder.addCase(saveProfileData.fulfilled as any, (state, action) => {
      state.profileData = action.payload;
      state.error = null;
      state.status = "saved";
    });

    builder.addCase(saveProfileData.rejected as any, (state, action) => {
      if (action?.payload?.message) state.error = action.payload.message;
      else state.error = "Etwas schiefgegangen";

      state.status = "error";
    });

    builder.addCase(fetchGoogleSetup.fulfilled as any, (state, action) => {
      if (action.payload.qrcode) {
        state.gQRcode = action.payload.qrcode;
      } else {
        state.gQRcode = "";
      }
      state.profileData.twoWayAuthGoogle = action.payload.twoWayAuthGoogle;

      state.error = null;
      state.status = "gsucceded";
    });

    builder.addCase(fetchGoogleSetup.rejected as any, (state, action) => {
      state.status = "error";

      if (action.payload?.message) {
        state.error = action.payload.message;
      } else {
        state.error = "Etwas schiefgegangen";
      }
    });

    builder.addCase(
      fetchGoogleSetupConfirm.fulfilled as any,
      (state, action) => {
        if (action.payload.success) {
          state.profileData.twoWayAuthGoogle = action.payload.twoWayAuthGoogle;
          state.gQRcode = "";
          state.error = null;
          state.status = "gconfirmed";
        } else {
          state.profileData.twoWayAuthGoogle = {
            ...state.profileData.twoWayAuthGoogle,
            active: false,
          };
          state.gQRcode = "";
          state.error = "Code is not mathing";
          state.status = "error";
        }
      }
    );

    builder.addCase(
      fetchGoogleSetupConfirm.rejected as any,
      (state, action) => {
        state.status = "error";

        if (action.payload?.message) {
          state.error = action.payload.message;
        } else {
          state.error = "Etwas schiefgegangen";
        }
      }
    );

    builder.addCase(
      fetchUpdateCustomerAccount.fulfilled as any,
      (state, action) => {
        if (action.payload.iban) {
          state.profileData.accountHolderName =
            action.payload.accountHolderName;
          state.profileData.iban = action.payload.iban;
          state.error = null;
          state.status = "succeded";
        } else {
          state.error = "Etwas schiefgegange";
          state.status = "error";
        }
      }
    );

    builder.addCase(
      fetchUpdateCustomerAccount.rejected as any,
      (state, action) => {
        state.status = "error";

        if (action.payload?.message) {
          state.error = action.payload.message;
        } else {
          state.error = "Etwas schiefgegangen";
        }
      }
    );
  },
});

export const { clearError, clearStatus } = profileSlice.actions;

export default profileSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APICall } from "../../utils/api";
import { PackagesState } from "./packagesSlice.d";

const apiVersion = "1.0.0";

const initialState: PackagesState = {
  packages: [],
  boughtPackages: [],
  categories: null,
  categoriesPackages: null,
  selectedCategory: "",
  perPage: 9,
  startRecord: 0,
  totalActive: 0,
  totalBought: 0,
  totalRecords: 0,
  date: null,
  status: "idle",
  boughtPackagesStatus: "idle",
  error: null,
};

export const fetchPackagesData = createAsyncThunk(
  "packages/PackagesData",
  async (
    { perPage, tag }: { perPage: number; tag?: string },
    { rejectWithValue }
  ) => {
    return await APICall("backend.commerce", "getall", rejectWithValue, {
      perPage,
      tag,
      woBought: true,
      ver: apiVersion,
    });
  }
);

export const fetchBoughtPackagesData = createAsyncThunk(
  "packages/BoughtPackagesData",
  async (_, { rejectWithValue }) => {
    return await APICall("backend.commerce", "getall", rejectWithValue, {
      bought: true,
      perPage: 100,
      ver: apiVersion,
    });
  }
);

export const fetchBoughtAdditionalPackagesData = createAsyncThunk(
  "packages/BoughtAdditionalPackagesData",
  async (_, { rejectWithValue }) => {
    return await APICall("backend.commerce", "getall", rejectWithValue, {
      bought: true,
      addToPerPage: true,
      perPage: 3,
      perRow: 3,
      ver: apiVersion,
    });
  }
);

export const fetchLoadMorePackagesData = createAsyncThunk(
  "packages/fetchLoadMorePackagesData",
  async (
    {
      perPage,
      tag,
      startRecord,
    }: { perPage: number; tag: string; startRecord: number },
    { rejectWithValue }
  ) => {
    return await APICall("backend.commerce", "getall", rejectWithValue, {
      perPage,
      startRecord,
      tag,
      woBought: true,
      ver: apiVersion,
    });
  }
);

export const fetchCategories = createAsyncThunk(
  "packages/fetchCategories",
  async (
    {
      idBank,
      perPage,
      startRecord,
    }: { idBank?: string; perPage?: number; startRecord?: number },
    { rejectWithValue }
  ) => {
    return await APICall(
      "backend.dicts.packagecategory",
      "get-categories",
      rejectWithValue,
      {
        perPage,
        startRecord,
        ver: apiVersion,
      }
    );
  }
);

export const fetchCategoriesWithPackages = createAsyncThunk(
  "packages/fetchCategoriesWithPackages",
  async (
    { perPage, startRecord }: { perPage?: number; startRecord?: number },
    { rejectWithValue }
  ) => {
    return await APICall(
      "backend.dicts.packagecategory",
      "getall",
      rejectWithValue,
      {
        perPage,
        startRecord,
        ver: apiVersion,
      }
    );
  }
);

export const packagesSlice = createSlice({
  name: "packages",
  initialState,
  reducers: {
    selectCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    changePackageActiveStatus: (state, action) => {
      const currPackage = state.packages.find(
        (item, i) => item.idPackage === action.payload.currentIdPackage
      );

      if (currPackage)
        currPackage.active = action.payload.isCurrentPackageActive;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchPackagesData.fulfilled as any, (state, action) => {
      state.packages = action.payload.data;
      state.startRecord = action.payload.startRecord;
      state.totalActive = action.payload.totalActive;
      state.totalBought = action.payload.totalBought;
      state.totalRecords = action.payload.totalRecords;
      state.error = null;
    });

    builder.addCase(fetchPackagesData.rejected as any, (state, action) => {
      if (action?.payload?.message) state.error = action.payload.message;
      else state.error = "Etwas schiefgegangen";
    });

    builder.addCase(
      fetchBoughtPackagesData.fulfilled as any,
      (state, action) => {
        state.boughtPackages = action.payload.data;
        //state.startRecord = action.payload.startRecord;
        state.totalActive = action.payload.totalActive;
        state.totalBought = action.payload.totalBought;
        state.date = action.payload?.date;
        //state.totalRecords = action.payload.totalRecords;
        state.error = null;
      }
    );

    builder.addCase(
      fetchBoughtPackagesData.rejected as any,
      (state, action) => {
        if (action?.payload?.message) state.error = action.payload.message;
        else state.error = "Etwas schiefgegangen";
      }
    );

    builder.addCase(
      fetchBoughtAdditionalPackagesData.fulfilled as any,
      (state, action) => {
        state.packages = action.payload.packages;
        state.startRecord = action.payload.startRecord;
        state.totalActive = action.payload.totalActive;
        state.totalBought = action.payload.totalBought;
        state.totalRecords = action.payload.totalRecords;
        state.error = null;
      }
    );

    builder.addCase(
      fetchBoughtAdditionalPackagesData.rejected as any,
      (state, action) => {
        if (action?.payload?.message) state.error = action.payload.message;
        else state.error = "Etwas schiefgegangen";
      }
    );

    builder.addCase(
      fetchLoadMorePackagesData.fulfilled as any,
      (state, action) => {
        console.log("fetchLoadMorePackagesData", action);
        state.packages = [...state.packages, ...action.payload.packages];
        state.startRecord = action.payload.startRecord;
        state.totalActive = action.payload.totalActive;
        state.totalBought = action.payload.totalBought;
        state.totalRecords = action.payload.totalRecords;
        state.error = null;
      }
    );

    builder.addCase(
      fetchLoadMorePackagesData.rejected as any,
      (state, action) => {
        if (action?.payload?.message) state.error = action.payload.message;
        else state.error = "Etwas schiefgegangen";
      }
    );

    builder.addCase(fetchCategories.fulfilled as any, (state, action) => {
      state.categories = action.payload.data;
      state.error = null;
    });

    builder.addCase(fetchCategories.rejected as any, (state, action) => {
      if (action?.payload?.message) state.error = action.payload.message;
      else state.error = "Etwas schiefgegangen";
    });

    builder.addCase(
      fetchCategoriesWithPackages.fulfilled as any,
      (state, action) => {
        state.categoriesPackages = action.payload.data;
        state.error = null;
      }
    );

    builder.addCase(
      fetchCategoriesWithPackages.rejected as any,
      (state, action) => {
        if (action?.payload?.message) state.error = action.payload.message;
        else state.error = "Etwas schiefgegangen";
      }
    );
  },
});

export const { selectCategory, changePackageActiveStatus } =
  packagesSlice.actions;

export default packagesSlice.reducer;

import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { getModalRootElement } from "../../utils";
import useDisableBodyScroll from "../../utils/useDisableBodyScroll";
import { ModalProps } from "./ModalWrapper.d";
import styles from "./ModalWrapper.module.css";

const modalRoot = getModalRootElement();

const ModalWrapper: React.FC<ModalProps> = ({
  isShowing,
  closeHandler,
  children,
}) => {
  const el = useRef(document.createElement("div"));

  useDisableBodyScroll(isShowing);

  useEffect(() => {
    const current = el.current;
    modalRoot && modalRoot.appendChild(current);

    return () => {
      modalRoot && modalRoot.removeChild(current);
    };
  }, []);

  return ReactDOM.createPortal(
    <div
      data-testid="modal-wrapper"
      className={`${styles.modal} ${isShowing ? styles.show : ""}`}
      {...(closeHandler ? { onClick: closeHandler } : null)}
      aria-modal
      aria-hidden
      tabIndex={-1}
      role="dialog"
    >
      {children}
    </div>,
    el.current
  );
};

export default ModalWrapper;

import React, { useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import Checkbox from "../../components/Checkbox/Checkbox";
import CookiesModal from "../../components/CookiesModal/CookiesModal";
import styles from "./CheckCookies.module.css";
import { useLocation } from "react-router-dom";
import { useCookieConsent } from "../../utils/useCookieConsent";

const CheckCookies = () => {
  const location = useLocation();

  const { consent, acceptCookies } = useCookieConsent();
  const [isShowCookiesModal, setIsShowCookiesModal] = useState(false);
  const [isMoreSettingsStep, setIsMoreSettingsStep] = useState(false);

  const [preferencesCookie, setPreferencesCookie] = useState(false);
  const [statisticsCookie, setStatisticsCookie] = useState(false);
  const [marketingCookie, setMarketingCookie] = useState(false);

  const acceptAllCookiesHandler = () => {
    acceptCookies({
      necessary: true,
      preferences: true,
      statistics: true,
      marketing: true,
    });

    setIsShowCookiesModal(false);
  };

  const moreSettingsStepHandler = () => {
    setIsMoreSettingsStep(prev => !prev);
  };

  const saveSettingsHandler = () => {
    acceptCookies({
      necessary: true,
      preferences: preferencesCookie,
      statistics: statisticsCookie,
      marketing: marketingCookie,
    });

    setIsShowCookiesModal(false);
  };

  const toggleCheckBoxPreferences = () => {
    setPreferencesCookie(prev => !prev);
  };

  const toggleCheckBoxStatistics = () => {
    setStatisticsCookie(prev => !prev);
  };

  const toggleCheckBoxMarketing = () => {
    setMarketingCookie(prev => !prev);
  };

  useEffect(() => {
    if (
      !isShowCookiesModal &&
      !consent.necessary &&
      !consent.preferences &&
      !consent.statistics &&
      !consent.marketing
    ) {
      setIsShowCookiesModal(true);
    }
  }, [consent, isShowCookiesModal]);

  if (location.pathname === "/infobuildpage") return null;

  return (
    <CookiesModal show={isShowCookiesModal}>
      {!isMoreSettingsStep ? (
        <div className={styles.step}>
          <div className={styles.stepContent}>
            <h3>Wir verwenden Cookies</h3>
            <p className={styles.mainP}>
              Wir verwenden verschiedene Arten von Cookies, um den bestmöglichen
              Service zu bieten. Zu weiteren Details und
              Widerspruchsmöglichkeiten geht es hier in unseren{" "}
              <a
                href={`${window.location.origin}/storage/assets/files/Datenschutz.pdf`}
                target="_blank"
                rel="noreferrer"
              >
                Datenschutzhinweisen
              </a>
              .
            </p>
          </div>

          <div className={styles.btnsContainer}>
            <button
              className={styles.settingsBtn}
              type="button"
              onClick={moreSettingsStepHandler}
            >
              Cookie-Einstellungen
            </button>

            <Button
              variant="primary-office"
              type="button"
              text="Alle akzeptieren"
              clickHandler={() => acceptAllCookiesHandler()}
            />
          </div>
        </div>
      ) : (
        <div className={styles.step}>
          <h3 className="h3">Cookie-Hinweis</h3>
          <p className={styles.mainP}>
            Wir verwenden Cookies, um die Leistung unserer Website zu
            analysieren, Inhalte zu verbessern und anzupassen und für
            Werbezwecke. Um mehr über die von uns verwendeten spezifischen
            Cookies zu erfahren, wofür sie verwendet werden und wie lange sie
            dauern, klicken Sie bitte auf den Link „Cookies-Details“ am Ende
            jeder Cookie-Kategorie.
          </p>

          <div className={styles.btnsContainer}>
            <Button
              variant="primary-office"
              type="button"
              text="Alle akzeptieren"
              clickHandler={() => acceptAllCookiesHandler()}
            />
          </div>

          <div className={styles.checkContainer}>
            <Checkbox
              id="necessaryToggle"
              checked={true}
              disabled={true}
              toggleCheck={() => {}}
            >
              <h3 className={styles.h3}>Notwendig (immer aktiv)</h3>
              <span>
                Mit diesen Cookies können Sie sich auf der Website bewegen und
                wesentliche Funktionen nutzen, wie den Zugriff auf sichere
                Bereiche der Website und die Identifizierung als eingeloggt.
                Diese Cookies sammeln keine Informationen über Sie, die für
                Marketingzwecke verwendet werden könnten oder sich daran
                erinnern, wo Sie sich befinden im Internet gewesen. Da diese
                Cookies für das korrekte Funktionieren unserer Website
                erforderlich sind, können Sie ihre Verwendung von diesem
                Cookie-Verwaltungstool aus nicht steuern.
              </span>
            </Checkbox>

            <Checkbox
              id="preferencesToggle"
              checked={preferencesCookie}
              toggleCheck={toggleCheckBoxPreferences}
            >
              <h3 className={styles.h3}>Erweiterte Funktionalität</h3>
              <span>
                Diese Cookies ermöglichen es unseren Websites, sich von Ihnen
                getroffene Entscheidungen (wie Ihren Benutzernamen, Ihre Sprache
                oder die Region, in der Sie sich befinden) zu merken und
                verbesserte, persönlichere Funktionen bereitzustellen. Diese
                Cookies können auch verwendet werden, um Änderungen zu
                speichern, die Sie an Textgröße, Schriftarten und anderen Teilen
                von Webseiten vorgenommen haben, die Sie anpassen können. Sie
                können auch verwendet werden, um von Ihnen angeforderte Dienste
                bereitzustellen, wie z. B. das Ansehen eines Videos oder das
                Kommentieren eines Blogs.
              </span>
            </Checkbox>

            <Checkbox
              id="trackingToggle"
              checked={statisticsCookie}
              toggleCheck={toggleCheckBoxStatistics}
            >
              <h3 className={styles.h3}>Verfolgung</h3>
              <span>
                Diese Cookies sammeln Informationen darüber, wie Besucher unsere
                Websites nutzen, einschließlich Details der Website woher der
                Besucher kommt (z. B. verweisende Domains, Suchmaschinen,
                Marketingkampagnen), Seiten angesehen, der Site-Pfad des
                Besuchers, welche Inhalte Besucher anklicken, welche Produkte
                Besucher interessiert sind und kaufen und wie oft ein Besucher
                unsere Website insgesamt besucht hat.
              </span>
            </Checkbox>

            <Checkbox
              id="targetingToggle"
              checked={marketingCookie}
              toggleCheck={toggleCheckBoxMarketing}
            >
              <h3 className={styles.h3}>Ausrichtung</h3>
              <span>
                Diese Cookies werden verwendet, um Informationen über Ihre
                Surfgewohnheiten zu sammeln, um Werbung anzuzeigen, die für Sie
                und Ihre Interessen relevanter ist. Die Personalisierung von
                Anzeigen kann auf Daten basieren, die über Ihre Nutzung unserer
                Dienste gesammelt werden (z. B. welche Seiten Sie besuchen) und
                anderen Daten, die Ihnen von der Anzeigenplattform zugeordnet
                werden. Dazu gehören Datenpunkte wie Interessen, Alter,
                Geschlecht und ähnliche statistische Merkmale, die aus dem
                Surfen im Internet oder aus Marketingdaten abgeleitet werden,
                die ein Werbetreibender über Sie hält. Weitere Informationen zu
                Werbung finden Sie in unseren Datenschutz- und Cookie-Hinweisen.
              </span>
            </Checkbox>
          </div>
          <div className={styles.btnsContainer}>
            <button
              className={styles.settingsBtn}
              type="button"
              onClick={moreSettingsStepHandler}
            >
              Back
            </button>

            <Button
              variant="primary-office"
              type="button"
              text="Einstellungen speichern"
              clickHandler={() => saveSettingsHandler()}
            />
          </div>
        </div>
      )}
    </CookiesModal>
  );
};

export default CheckCookies;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APICall } from "../../utils/api";
import { DownloadsState } from "./downloadsSlice.d";

const apiVersion = "1.0.0";

const initialState: DownloadsState = {
  categories: null,
};

export const fetchCatigories = createAsyncThunk(
  "downloads/Catigories",
  async (_, { rejectWithValue }) => {
    return await APICall("backend.links", "getall", rejectWithValue, {
      ver: apiVersion,
    });
  }
);

export const downloadsSlice = createSlice({
  name: "downloads",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchCatigories.fulfilled as any, (state, action) => {
      state.categories = action.payload;
    });
  },
});

export default downloadsSlice.reducer;

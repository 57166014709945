import React, { useRef } from "react";
import ConfirmActionModal from "../../components/ConfirmActionModal/ConfirmActionModal";
import MainWrapper from "../../containers/MainWrapper/MainWrapper";
import { fetchCashbackCreateUser } from "../../redux/reducers/authenticationSlice";
import useModal from "../../utils/useModal";
import BlockFour from "./components/BlockFour";
import BlockOne from "./components/BlockOne";
import BlockThree from "./components/BlockThree";
import BlockTwo from "./components/BlockTwo";
import styles from "./HomePage.module.css";
import { IS_RVN_BRAND } from "../../variables_config";
import { useAppDispatch } from "../../redux/selectors";

const HomePage: React.FC = () => {
  const dispatch = useAppDispatch();

  const { isShowing, toggle } = useModal();

  const categoriesRef = useRef<HTMLDivElement>(null);

  const executeScroll = () =>
    null !== categoriesRef.current
      ? categoriesRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        })
      : null;

  const scrollToCategoriesHandler = () => {
    executeScroll();
  };

  const clickActivateHandler = () => {
    toggle();
    dispatch(fetchCashbackCreateUser());
  };

  return (
    <MainWrapper>
      <BlockOne styles={styles} />
      {!IS_RVN_BRAND ? (
        <BlockTwo styles={styles} scrollTo={scrollToCategoriesHandler} />
      ) : null}
      <BlockThree styles={styles} />
      {!IS_RVN_BRAND ? (
        <BlockFour styles={styles} scrollRef={categoriesRef} />
      ) : null}

      <ConfirmActionModal
        showModal={isShowing}
        title={"Bitte bestätige Deine Aktivieren"}
        description={"Bitte bestätige Deine Eingabe"}
        confirmBtnText={"Bestätigung"}
        rejectBtnText={"Zurück zur Hause"}
        confirmHandler={() => {
          clickActivateHandler();
        }}
        rejectHandler={toggle}
      />
    </MainWrapper>
  );
};

export default HomePage;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APICall } from "../../utils/api";
import { RequestCategoriesState } from "./requestCategoriesSlice.d";

const initialState: RequestCategoriesState = {
  requestCategoriesList: [],
  perPage: 20,
  error: null,
  status: "idle",
};

export const fetchRequestCategoriesList = createAsyncThunk(
  "requestcategories/fetchRequestCategoriesList",
  async (
    { perPage, isForPackage }: { perPage?: number; isForPackage?: boolean },
    { rejectWithValue }
  ) => {
    return await APICall(
      "infoservice.requestcategories",
      "getall",
      rejectWithValue,
      {
        ...(perPage ? { perPage } : {}),
        ...(isForPackage ? { isForPackage } : {}),
      }
    );
  }
);

export const requestCategoriesSlice = createSlice({
  name: "requestCategories",
  initialState,
  reducers: {
    clearError: (state, action) => {
      state.error = null;
    },
    changeStatus: (state, action) => {
      state.status = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(
      fetchRequestCategoriesList.fulfilled as any,
      (state, action) => {
        state.requestCategoriesList = action.payload.data;
        state.perPage = action.payload.perPage;

        state.error = null;
        state.status = "succeded";
      }
    );

    builder.addCase(
      fetchRequestCategoriesList.rejected as any,
      (state, action) => {
        if (action?.payload?.message) {
          state.error = action.payload.message;
        } else {
          state.error = action.error;
        }

        state.status = "error";
      }
    );
  },
});

export const { clearError, changeStatus } = requestCategoriesSlice.actions;

export default requestCategoriesSlice.reducer;

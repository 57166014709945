import React, { memo } from "react";
import BlockContainer from "../../../components/BlockContainer/BlockContainer";
import ContentBox from "../../../components/ContentBox/ContentBox";
import LongButton from "../../../components/LongButton/LongButton";
import BankLogo from "../../../components/BankLogo/BankLogo";
import { BlockProps } from "../PackagePage.d";
import { ReactComponent as OrderIcon } from "../../../images/order-icon.svg";
import { ReactComponent as SchutzIcon } from "../../../images/schutz-icon.svg";

const BlockOne: React.FC<BlockProps> = ({ letterData, styles }) => {
  return (
    <BlockContainer>
      <div className={styles.blockOneContainer}>
        <ContentBox className={styles.blockOne}>
          <div className={styles.bankLogoContainer}>
            <BankLogo />
          </div>

          <h1>{letterData?.name}</h1>

          <p className={styles.textBlock}>
            Immer zahlungsfähig bleiben mit dem {letterData?.name}: Damit du bei
            Arbeitslosigkeit oder längerer Krankheit deinen
            Zahlungsverpflichtungen nachkommen kannst.
          </p>

          <p className={styles.textBlock}>
            Hier siehst du deinen aktuellen Schutz und kannst nach deinen
            Wünschen das Schutz-Niveau anpassen.
          </p>

          <div className={styles.btnContainer}>
            <LongButton
              text="Vertragsunterlagen einsehen"
              icon={OrderIcon}
              href="/downloads"
            />
          </div>
          <div className={styles.schutzIconContainer}>
            <SchutzIcon className={styles.schutzIcon} />
          </div>
        </ContentBox>
      </div>
    </BlockContainer>
  );
};

export default memo(BlockOne);

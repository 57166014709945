import React from "react";
import { AdditionalFormProtocolProps } from "./AdditionalFormProtocol.d";
import styles from "./AdditionalFormProtocol.module.css";
import QuestionsList from "./QuestionsList";

const AdditionalFormProtocol: React.FC<AdditionalFormProtocolProps> = ({
  protocolData,
  answers,
  isShowError,
  updateAnswer,
}) => {
  return (
    <div className={styles.questionConent}>
      <QuestionsList
        questions={protocolData.questions}
        answers={answers}
        updateAnswer={updateAnswer}
      />

      {isShowError && Object.keys(answers).length ? (
        <div className={styles.errorTip}>
          Bitte beantworten Sie alle Fragen oder verweigern Sie das Protokoll
        </div>
      ) : null}
    </div>
  );
};

export default AdditionalFormProtocol;

import Cookies from "js-cookie";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import CalcProductDefault from "../../components/CalcProductDefault/CalcProductDefault";
import CalcProductDefaultFixed from "../../components/CalcProductDefaultFixed/CalcProductDefaultFixed";
import CalcProductElectronic from "../../components/CalcProductElectronic/CalcProductElectronic";
import CalcProductPortalAccess from "../../components/CalcProductPortalAccess/CalcProduct";
import DefaultModal from "../../components/DefaultModal/DefaultModal";
import LoaderComponent from "../../components/LoaderComponent/LoaderComponent";
import Modal from "../../components/Modal/Modal";
import ProductModalContent from "../../components/ProductModalContent/ProductModalContent";
import {
  fetchCreateAnounLetter,
  fetchRecalcLetter,
} from "../../redux/reducers/calculatorSlice";
import {
  Product,
  ServiceToCalc,
  TariffDataItem,
} from "../../redux/reducers/calculatorSlice.d";
import { calcSelector, useAppDispatch } from "../../redux/selectors";
import { BIC } from "../../variables_config";
import MandatoryModalContainer from "../MandatoryModalContainer/MandatoryModalContainer";
import RichTextEditorHtml from "../RichTextEditorHtml/RichTextEditorHtml";
import {
  CalculatorProps,
  InitMandatoryModalState,
  InitModalState,
  MandatoryDataRecalcProps,
} from "./Calculator.d";
import styles from "./Calculator.module.css";
import { getFormulaParamById, sortProductsByOrder } from "./calculatorHelper";

const initModalState = {
  idService: "",
  name: "",
  popupTitle: "",
  popupBody: "",
  bottomImg: "",
  rightImg: "",
} as InitModalState;

const initMandatoryModalState = {
  idProduct: "",
  productName: "",
  isActive: false,
  valueToCalculate: 0,
  mandatoryData: [],
} as InitMandatoryModalState;

const Calculator: React.FC<CalculatorProps> = ({
  packageIdProp,
  createDraft,
}) => {
  const dispatch = useAppDispatch();

  const { letterData, status, isCalculating } = useSelector(calcSelector);

  const idPackage = packageIdProp || letterData.idPackage;

  const [modalContent, setModalContent] = useState(initModalState);
  const [mandatoryModal, setMandatoryModal] = useState(initMandatoryModalState);

  const closeModalHandler = () => {
    setModalContent(initModalState);
  };

  const closeMandatoryModalHandler = () => {
    setMandatoryModal(initMandatoryModalState);
  };

  const changeServiceValueToCalc = useCallback(
    (productId: string) => (value: number) => {
      const products: Array<ServiceToCalc> = [
        {
          idProduct: productId,
          valueToCalculate: value,
        },
      ];

      dispatch(
        fetchRecalcLetter({
          idPackage,
          products,
          createDraft,
        })
      );
    },
    [createDraft, dispatch, idPackage]
  );

  // Send recalc API call after ToggleSwitch onChange event
  const changeServiceIsActive = useCallback(
    (productId: string) => (value: boolean) => {
      const products: Array<ServiceToCalc> = [
        {
          idProduct: productId,
          isActive: value,
        },
      ];

      dispatch(
        fetchRecalcLetter({
          idPackage,
          products,
          createDraft,
        })
      );
    },
    [createDraft, dispatch, idPackage]
  );

  // Send recalc API call on mandatory data save
  const saveMandatoryDataRecalc = useCallback(
    ({
      idProduct,
      isActive,
      valueToCalculate,
      mandatoryDataRequest,
      values,
    }: MandatoryDataRecalcProps) => {
      const tariffData: Array<TariffDataItem> = Object.keys(values).map(
        (idParam: string) => {
          const formulaParam = getFormulaParamById(
            idParam,
            mandatoryDataRequest
          );

          return {
            idParam,
            dataType: formulaParam?.type,
            ...values[idParam],
          } as TariffDataItem;
        }
      );

      const products: Array<ServiceToCalc> = [
        {
          idProduct,
          isActive,
          valueToCalculate,
          tariffData,
        },
      ];

      dispatch(
        fetchRecalcLetter({
          idPackage,
          products,
          createDraft,
        })
      );
    },
    [createDraft, dispatch, idPackage]
  );

  const portalAccessProduct = useMemo(() => {
    if (letterData?.products) {
      return letterData?.products?.find(
        (product: Product) => product.calcMethod === 9
      );
    }

    return undefined;
  }, [letterData]);

  const getProductComponent = useCallback(
    (service: Product) => {
      let component =
        service.calcMethod === 1 ? (
          <CalcProductDefaultFixed
            key={service.idProduct}
            product={service}
            paymentTitle={`Kosten ${letterData.paymentModalityTitle}`}
            changeServiceIsActive={changeServiceIsActive}
            setModalContent={setModalContent}
          />
        ) : service.calcMethod === 8 ? (
          <CalcProductElectronic
            key={service.idProduct}
            product={service}
            changeServiceIsActive={changeServiceIsActive}
            setModalContent={setModalContent}
            saveMandatoryDataRecalc={saveMandatoryDataRecalc}
          />
        ) : service.calcMethod === 9 ? (
          <CalcProductPortalAccess
            key={service.idProduct}
            product={service}
            setModalContent={setModalContent}
          />
        ) : (
          <CalcProductDefault
            key={service.idProduct}
            product={service}
            paymentTitle={`Kosten ${letterData.paymentModalityTitle}`}
            changeServiceIsActive={changeServiceIsActive}
            changeServiceValueToCalc={changeServiceValueToCalc}
            setModalContent={setModalContent}
            setMandatoryModal={setMandatoryModal}
            saveMandatoryDataRecalc={saveMandatoryDataRecalc}
          />
        );

      return component;
    },
    [
      changeServiceIsActive,
      changeServiceValueToCalc,
      letterData,
      saveMandatoryDataRecalc,
    ]
  );

  useEffect(() => {
    if (!packageIdProp) {
      dispatch(fetchCreateAnounLetter({ BIC }));
    }
  }, [packageIdProp, dispatch]);

  useEffect(() => {
    if (status === "succeded" && letterData.idPackage) {
      Cookies.set("idPackage", letterData.idPackage);
    }
  }, [status, letterData.idPackage, dispatch]);

  if (status === "succeded" && !letterData.idPackage) {
    return null;
  }

  if (status === "pending" && !idPackage) {
    return <LoaderComponent />;
  }

  return (
    <div className={styles.container}>
      {isCalculating && <div className={styles.overlay} />}
      {portalAccessProduct && getProductComponent(portalAccessProduct)}

      {letterData.hasOwnProperty("products") &&
        sortProductsByOrder(letterData?.products)?.map(
          (product: Product) =>
            product.calcMethod !== 9 && getProductComponent(product)
        )}

      <DefaultModal
        showModal={modalContent.idService !== ""}
        title={modalContent.name}
        closeHandler={closeModalHandler}
      >
        <ProductModalContent
          // title={modalContent.popupTitle}
          text={<RichTextEditorHtml editorValue={modalContent.popupBody} />}
          bottomText={modalContent.bottomText}
          bottomImg={modalContent.bottomImg}
          bottomAlt={modalContent.name}
          rightImg={modalContent.rightImg}
          rightAlt={modalContent.name}
        />
      </DefaultModal>

      {mandatoryModal.idProduct !== "" && (
        <Modal
          key={`modal_mandatory_${mandatoryModal.idProduct}`}
          show={mandatoryModal.idProduct !== ""}
          closeHandler={closeMandatoryModalHandler}
          title={"Add mandatory data"}
          size="small"
        >
          <MandatoryModalContainer
            idProduct={mandatoryModal.idProduct}
            mandatoryData={mandatoryModal.mandatoryData}
            saveMandatoryData={values => {
              saveMandatoryDataRecalc({
                idProduct: mandatoryModal.idProduct,
                isActive: mandatoryModal.isActive,
                valueToCalculate: mandatoryModal.valueToCalculate,
                mandatoryDataRequest: mandatoryModal.mandatoryData,
                values,
              });

              closeMandatoryModalHandler();
            }}
          />
        </Modal>
      )}
    </div>
  );
};

export default Calculator;

import React from "react";
import ConfirmActionModalContent from "../ConfirmActionModalContent/ConfirmActionModalContent";
import styles from "./ConfirmActionModal.module.css";
import { ConfirmActionModalProps } from "./ConfirmActionModal.d";
import DefaultModal from "../DefaultModal/DefaultModal";

const ConfirmActionModal: React.FC<ConfirmActionModalProps> = ({
  showModal,
  title,
  description,
  confirmBtnText,
  rejectBtnText,
  confirmHandler,
  rejectHandler,
}) => {
  return (
    <DefaultModal
      showModal={showModal}
      title={title}
      closeHandler={rejectHandler}
      className={styles.modalConfirmContent}
    >
      <ConfirmActionModalContent
        confirmBtnText={confirmBtnText}
        rejectBtnText={rejectBtnText}
        confirmHandler={confirmHandler}
        closeHandler={rejectHandler}
      >
        <div data-testid="actionFormModal" className={styles.description}>
          {description}
        </div>
      </ConfirmActionModalContent>
    </DefaultModal>
  );
};

export default ConfirmActionModal;

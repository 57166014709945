import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ConfirmationCodeModal from "../../components/ConfirmationCodeModal/ConfirmationCodeModal";
import { fetchConfirmChangeEmail } from "../../redux/reducers/authenticationSlice";
import { authSelector, useAppDispatch } from "../../redux/selectors";

const EmailChangeCodeWrapper = () => {
  const dispatch = useAppDispatch();

  const { waitConfirmEmailChange } = useSelector(authSelector);

  const [confirmCode, setConfirmCode] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const onClickConfirmEmail = () => {
    dispatch(fetchConfirmChangeEmail({ confirmCode }));
  };

  useEffect(() => {
    setShowConfirmModal(!!waitConfirmEmailChange);
  }, [waitConfirmEmailChange, dispatch]);

  return (
    <ConfirmationCodeModal
      title={"Email change confirmation"}
      showModal={showConfirmModal}
      submitDisabled={!confirmCode}
      onInputChange={setConfirmCode}
      closeModal={() => setShowConfirmModal(false)}
      onSubmit={onClickConfirmEmail}
    />
  );
};

export default EmailChangeCodeWrapper;

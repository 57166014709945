import React, { useCallback, useMemo } from "react";
//import Radiobox from "../../components/Radiobox/Radiobox";
import { Product } from "../../redux/reducers/calculatorSlice.d";
import { MandatoryDataRecalcProps } from "../Calculator/Calculator.d";
import {
  FORMULA_MONTH_TAB,
  getFormulaItem,
  getTarifItemById,
  sortFormulaMonthes,
} from "../Calculator/calculatorHelper";

interface ProductItemMonthesProps {
  product: Product;
  styles: any;
  saveMandatoryDataRecalc?: (props: MandatoryDataRecalcProps) => void;
}

const ProductItemMonthes: React.FC<ProductItemMonthesProps> = ({
  product,
  styles,
  saveMandatoryDataRecalc,
}) => {
  const formulaItemMonthTab = useMemo(
    () => getFormulaItem(product.mandatoryDataRequest || [], FORMULA_MONTH_TAB),
    [product.mandatoryDataRequest]
  );

  const tarifDataItem = useMemo(
    () => getTarifItemById(product.mandatoryData, formulaItemMonthTab?.idParam),
    [product.mandatoryData, formulaItemMonthTab]
  );

  const sortedMonthes = useMemo(
    () =>
      formulaItemMonthTab?.options
        ? sortFormulaMonthes(formulaItemMonthTab?.options)
        : [],
    [formulaItemMonthTab?.options]
  );

  const onMonthChange = useCallback(
    (radioValue: string) => {
      if (formulaItemMonthTab?.idParam) {
        saveMandatoryDataRecalc &&
          saveMandatoryDataRecalc({
            idProduct: product.idProduct,
            isActive: product.isActive,
            valueToCalculate: product.valueToCalculate,
            mandatoryDataRequest: product.mandatoryDataRequest,
            values: {
              [formulaItemMonthTab.idParam]: {
                value: tarifDataItem?.value,
                months: radioValue,
              },
            },
          });
      }
    },
    [
      product.idProduct,
      product.isActive,
      product.mandatoryDataRequest,
      product.valueToCalculate,
      tarifDataItem,
      formulaItemMonthTab,
      saveMandatoryDataRecalc,
    ]
  );
  return formulaItemMonthTab ? (
    <select
      value={tarifDataItem?.months || ""}
      onChange={e => onMonthChange(e.target.value)}
      className={styles.mandarotyMonthesSelect}
      title={`${sortedMonthes
        .map((opt: string) => opt.replace("m", ""))
        .join("/")} Monate`}
      {...(!product.isActive ? { disabled: true } : {})}
    >
      {sortedMonthes.map((opt: string) => (
        <option
          key={opt}
          value={opt}
          label={`${opt.replace("m", "")} M.`}
        >{`${opt.replace("m", "")} M.`}</option>
      ))}
    </select>
  ) : null;
  // return formulaItemMonthTab ? (
  //   <div className={styles.radioMonthesContainer}>
  //     <div className={styles.priceHint}>Leistung</div>
  //     <div className={styles.radioMonthes}>
  //       {sortFormulaMonthes(formulaItemMonthTab?.options).map((opt: string) => {
  //         const radioId = `monthes_${opt}_${formulaItemMonthTab?.idParam}`;
  //         const radioName = `monthes_${formulaItemMonthTab?.idParam}`;
  //         const isRadioChecked = !!(
  //           getTarifItemById(
  //             product.mandatoryData,
  //             formulaItemMonthTab?.idParam
  //           )?.months === opt
  //         );
  //         return (
  //           <Radiobox
  //             key={radioId}
  //             id={radioId}
  //             name={radioName}
  //             checked={isRadioChecked}
  //             className={styles.radioLabel}
  //             textClassName={styles.radioText}
  //             disabled={!product.isActive}
  //             toggleCheck={() => {
  //               onRadioMonthChange(opt);
  //             }}
  //           >
  //             <div className={styles.radioContent}>
  //               <span>
  //                 <b>{opt.replace("m", "")}</b>&nbsp;Monate
  //               </span>
  //             </div>
  //           </Radiobox>
  //         );
  //       })}
  //     </div>
  //   </div>
  // ) : null;
};

export default ProductItemMonthes;

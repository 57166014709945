import MainWrapper from "../../containers/MainWrapper/MainWrapper";
import BlockContainer from "../../components/BlockContainer/BlockContainer";
import Table from "../../components/Table/Table";
import Td from "../../components/Td/Td";
import Tr from "../../components/Tr/Tr";
import styles from "./InfoBuildPage.module.css";

type InfoItem = {
  label: string;
  value: string | number | undefined;
};

const BuildData: InfoItem[] = [
  {
    label: "Bitbucket build number",
    value: process.env.REACT_APP_BITBUCKET_BUILD_NUMBER,
  },
  {
    label: "Bitbucket commit",
    value: process.env.REACT_APP_BITBUCKET_COMMIT,
  },

  {
    label: "Bitbucket branch",
    value: process.env.REACT_APP_BITBUCKET_BRANCH,
  },

  {
    label: "Bitbucket date",
    value: process.env.REACT_APP_BITBUCKET_DATE,
  },
];

const InfoBuildPage = () => {
  return (
    <MainWrapper>
      <BlockContainer>
        <div className={styles.tableWrapper}>
          <h2 className={styles.greeting}>Build Information</h2>

          <Table customClass={styles.table}>
            {BuildData.map((item: InfoItem) => (
              <Tr key={item.label}>
                <Td customClass={`${styles.cell} ${styles.cellTitle} `}>
                  {item.label}
                </Td>
                <Td customClass={styles.cell}>{item.value}</Td>
              </Tr>
            ))}
          </Table>
        </div>
      </BlockContainer>
    </MainWrapper>
  );
};

export default InfoBuildPage;

import React from "react";
import Radiobox from "../Radiobox/Radiobox";
import {
  AnswersListProps,
  QuestionsListProps,
} from "./AdditionalFormProtocol.d";
import styles from "./AdditionalFormProtocol.module.css";
import { excludedInnerAnswers, getSelectedAnswer } from "./helper";

const AnswersList: React.FC<AnswersListProps> = ({
  questionKey,
  answersList,
  selectedAnswers,
  thenComponent,
  updateAnswer,
}) => {
  const ThenComponent: React.FC<QuestionsListProps> = thenComponent;
  return (
    <>
      {answersList.map((answer: any, j: number) => (
        <div
          key={`answer_${questionKey}_${j}`}
          className={styles.answerItemContainer}
        >
          <div className={styles.answerItem}>
            <Radiobox
              id={`questionAnswer_${questionKey}_${answer.value}`}
              name={`questionAnswer_${questionKey}_${answer.value}`}
              checked={getSelectedAnswer(
                selectedAnswers,
                questionKey,
                answer.value
              )}
              className={styles.defaultRadio}
              toggleCheck={() => {
                // For multiple selection
                // let newValues = selectedAnswers[questionKey] || [];
                // if (checked) {
                //   newValues = [...newValues, answerJ.value];
                // } else {
                //   const index = newValues.findIndex(answerJ.value);
                //   if (index !== -1) {
                //     newValues.splice(index, 1);
                //   }
                // }

                updateAnswer({
                  ...excludedInnerAnswers(selectedAnswers, `${questionKey}.`),
                  [questionKey]: [answer.value],
                });
              }}
            >
              <span>{answer.text}</span>
            </Radiobox>
          </div>

          {getSelectedAnswer(selectedAnswers, questionKey, answer.value) ? (
            answer.then?.questions ? (
              <div className={styles.innerQuestions}>
                <ThenComponent
                  questions={answer.then?.questions}
                  answers={selectedAnswers}
                  updateAnswer={updateAnswer}
                />
              </div>
            ) : answer.then ? (
              <div className={styles.answerText}>{answer.then}</div>
            ) : null
          ) : null}
        </div>
      ))}
    </>
  );
};

export default AnswersList;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APICall } from "../../utils/api";
import {
  BuyPackageState,
  EmployerFormErrorValues,
  FormPackageValues,
} from "./buyPackageSlice.d";
import { AdditionalInfoAnswer } from "./calculatorSlice.d";

const initialState: BuyPackageState = {
  status: "idle",
  error: null,
  paymentChecks: {
    withdrawalCheck: false,
    accrvidn: "",
    ready: false,
  },
  waitingPayment: false,
  isNeedTan: false,
  isAbortNeedTan: false,
  employerFormValues: null,
  employerFormErrors: null,
  repeatRequest: false,
};

export const fetchBuyPackage = createAsyncThunk(
  "buyPackage/fetchBuyPackage",
  async (data: FormPackageValues, { rejectWithValue }) => {
    return await APICall("backend.commerce", "buy", rejectWithValue, data);
  }
);

export const fetchConfirmTanPackage = createAsyncThunk(
  "buyPackage/fetchConfirmTanPackage",
  async (
    {
      idPackage,
      confirmCode,
      clearTan,
      additionalInfoAns,
    }: {
      idPackage: string;
      confirmCode: string;
      clearTan?: boolean;
      additionalInfoAns?: Array<AdditionalInfoAnswer>;
    },
    { rejectWithValue }
  ) => {
    return await APICall("backend.commerce", "buy", rejectWithValue, {
      idPackage,
      ...(clearTan ? { clearTan } : { confirmCode, additionalInfoAns }),
    });
  }
);

export const fetchDiscontinuePackage = createAsyncThunk(
  "buyPackage/fetchDiscontinuePackage",
  async (packageId: string, { rejectWithValue }) => {
    return await APICall("backend.commerce", "discontinue", rejectWithValue, {
      idPackage: packageId,
      ver: "1.0.0",
    });
  }
);

export const fetchCheckoutWithdrawalCheck = createAsyncThunk(
  "checkout/UserWithdrawalCheck",
  async (
    { value, accrvidn }: { value: boolean; accrvidn: string },
    { rejectWithValue }
  ) => {
    return await APICall(
      "authservice.acceptions",
      "set-accept",
      rejectWithValue,
      {
        service: "lounge_creditor_checkout",
        idElem: "withdrawalCheck",
        value,
        accrvidn,
      }
    );
  }
);

export const buyPackageSlice = createSlice({
  name: "buyPackage",
  initialState,
  reducers: {
    clearError: (state, action) => {
      state.error = null;
    },
    resetStatus: (state, action) => {
      state.status = action.payload;
    },
    resetState: state => {
      state.status = initialState.status;
      state.error = initialState.error;
      state.paymentChecks = initialState.paymentChecks;
      state.waitingPayment = initialState.waitingPayment;
      state.isNeedTan = initialState.isNeedTan;
      state.isAbortNeedTan = initialState.isAbortNeedTan;
      state.employerFormValues = initialState.employerFormValues;
      state.employerFormErrors = initialState.employerFormErrors;
      state.repeatRequest = initialState.repeatRequest;
    },
    resetEmployerFormError: (state, action) => {
      if (
        state.employerFormErrors &&
        action.payload in state.employerFormErrors
      ) {
        delete state.employerFormErrors[
          action.payload as keyof EmployerFormErrorValues
        ];
      }
    },
    resetRepeatRequest: state => {
      state.repeatRequest = initialState.repeatRequest;
    },
    updateEmployerFormValues: (state, action) => {
      state.employerFormValues = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchBuyPackage.fulfilled as any, (state, action) => {
      if ("needTan" in action.payload) {
        state.isAbortNeedTan = initialState.isAbortNeedTan;
        state.isNeedTan = action.payload.needTan;
      }

      if ("idPackage" in action.payload) {
        state.status = "succeded";
      }

      if ("pending" in action.payload) {
        state.waitingPayment = action.payload.pending;
        state.repeatRequest = action.payload.pending;
      } else {
        state.waitingPayment = false;
      }

      state.error = null;
    });

    builder.addCase(fetchBuyPackage.pending as any, state => {
      state.status = "pending";
      state.waitingPayment = true;
      state.employerFormErrors = initialState.employerFormErrors;
      state.error = null;
    });

    builder.addCase(fetchBuyPackage.rejected as any, (state, action) => {
      if (action?.payload?.message) {
        state.error = action.payload.message;
      } else {
        state.error = "Etwas schiefgegangen";
      }

      state.status = "error";
      state.waitingPayment = false;

      const actionErrors = action.payload?.err;

      if ("needTan" in actionErrors) {
        state.isAbortNeedTan = initialState.isAbortNeedTan;
        state.isNeedTan = actionErrors.needTan;
      }

      if ("fields" in actionErrors) {
        state.employerFormErrors = actionErrors.fields;
      }
    });

    builder.addCase(
      fetchConfirmTanPackage.fulfilled as any,
      (state, action) => {
        if ("needTan" in action.payload) {
          state.isNeedTan = action.payload.needTan;
        }

        if ("idPackage" in action.payload) {
          //state.success = action.payload.success;
          state.status = "succeded";
        }

        if ("success" in action.payload) {
          state.isAbortNeedTan = initialState.isAbortNeedTan;
          state.isNeedTan = initialState.isNeedTan;
          state.repeatRequest = initialState.repeatRequest;
          state.status = "cleared";
        }

        if ("pending" in action.payload) {
          state.waitingPayment = action.payload.pending;
          state.repeatRequest = action.payload.pending;
        } else {
          state.waitingPayment = false;
        }

        state.error = null;
      }
    );

    builder.addCase(fetchConfirmTanPackage.pending as any, state => {
      state.status = "pending";
      state.waitingPayment = true;
      //state.employerFormErrors = initialState.employerFormErrors;
    });

    builder.addCase(fetchConfirmTanPackage.rejected as any, (state, action) => {
      if (action?.payload?.message) {
        state.error = action.payload.message;
      } else {
        state.error = "Etwas schiefgegangen";
      }

      state.status = "error";
      state.waitingPayment = false;

      const actionErrors = action.payload?.err;

      if ("stop" in actionErrors) {
        state.isAbortNeedTan = actionErrors.stop;
        state.isNeedTan = !actionErrors.stop;
      }

      if ("fields" in actionErrors) {
        state.employerFormErrors = actionErrors.fields;
      }
    });

    builder.addCase(
      fetchDiscontinuePackage.fulfilled as any,
      (state, action) => {
        if (action.payload.success) {
          state.status = "returned";
          state.error = null;
        } else {
          state.status = "error";
          state.error = "Etwas schiefgegangen";
        }
      }
    );

    builder.addCase(
      fetchDiscontinuePackage.rejected as any,
      (state, action) => {
        if (action?.payload?.message) {
          state.error = action.payload.message;
        } else {
          state.error = "Etwas schiefgegangen";
        }

        state.status = "error";
      }
    );

    builder.addCase(
      fetchCheckoutWithdrawalCheck.fulfilled as any,
      (state, action) => {
        state.paymentChecks.withdrawalCheck = action.payload.withdrawalCheck;
        state.paymentChecks.accrvidn = action.payload.accrvidn;
        state.paymentChecks.ready = action.payload.ready;
      }
    );
  },
});

export const {
  clearError,
  resetStatus,
  resetState,
  resetEmployerFormError,
  resetRepeatRequest,
  updateEmployerFormValues,
} = buyPackageSlice.actions;

export default buyPackageSlice.reducer;

import axios, { AxiosError } from "axios";
import { API_SERVER } from "../variables_config";

export const client = axios.create({
  baseURL: API_SERVER,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

export const APICall = async (
  service: string,
  command: string,
  reject: (data: any) => void,
  data?: any,
  config?: any
) => {
  console.log(`API "${service}/${command}" called. Waiting for response..`);

  const response = await client
    .post(
      `/api/request/`,
      {
        service,
        command,
        data,
      },
      { ...(config ? config : {}) }
    )
    .then(response => {
      console.info(
        `Service "${service}/${command}" responded:\n`,
        response.data
      );

      return response.data;
    })
    .catch((error: Error | AxiosError) => {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.error(
            `Service "${service}" with command "${command}" thrown\n`,
            error.response.data
          );

          return reject(error.response.data);
        } else if (error.request) {
          console.log("Request", error.request);
          return reject(error.request);
        } else {
          console.log("Error", error.message);
          return reject(error);
        }
      } else {
        console.log("Stock error", error);
        return reject(error);
      }
    });

  return response;
};

/*
export const APICall1 = async (
  service: string,
  command: string,
  reject: (data: any) => void, data?: any
) => {

  console.log(`API "${service}/${command}" called. Waiting for response..`);

  try {
    const response = await client.post(`/api/request/`, {
      service,
      command,
      data: data
    });

    console.info(`Service "${service}/${command}" responded:\n`, response.data);
    return response.data;
  }
  catch (error) {

    const e = JSON.parse(JSON.stringify(error));

    console.error(`Service "${service}" with command "${command}" thrown\n`, e.response.data)
    return reject(e.response.data);
  }
}*/

import styles from "./ModalContainer.module.css";
import React, { useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { ModalProps } from "./ModalContainer.d";
import ContentBox from "../ContentBox/ContentBox";

const ModalContainer: React.FC<ModalProps> = ({
  isShowing,
  className = "",
  children,
}) => {
  const nodeRef = useRef(null);

  return (
    <CSSTransition
      in={isShowing}
      unmountOnExit
      timeout={{ enter: 0, exit: 300 }}
      nodeRef={nodeRef}
      classNames={{
        enterDone: styles.enterDone,
        exitDone: styles.exit,
      }}
    >
      <div
        className={`${styles.modalContainer} ${className}`}
        onClick={e => e.stopPropagation()}
        ref={nodeRef}
        data-testid={"modalWrapper"}
      >
        <ContentBox className={styles.block} data-testid={"modalContent"}>
          {children}
        </ContentBox>
      </div>
    </CSSTransition>
  );
};

export default ModalContainer;

import { AdditionalInfoAnswer } from "./../../redux/reducers/calculatorSlice.d";
import { AdditionalInfoItem } from "../../redux/reducers/calculatorSlice.d";
import { QuestionAnswer } from "./Checkout.d";
import { OtherPerson } from "../../redux/reducers/checkoutSlice.d";

export const getQuestionAnswerByValue = (
  answers: Array<QuestionAnswer> | undefined,
  value: number | string
) => {
  return (
    answers && answers.find((answer: QuestionAnswer) => answer.value === value)
  );
};

export const getAdditionalInfoItem = (
  additionalInfo: Array<AdditionalInfoItem> | undefined,
  type: string
) => {
  return (
    additionalInfo &&
    additionalInfo.find(
      (infoItem: AdditionalInfoItem) => infoItem.type === type
    )
  );
};

export const getAdditionalInfoAnsItem = (
  additionalInfoAns: Array<AdditionalInfoAnswer> | undefined,
  type: string
) => {
  return (
    additionalInfoAns &&
    additionalInfoAns.find(
      (infoAns: AdditionalInfoAnswer) => infoAns.type === type
    )
  );
};

export const getOptionTextByValue = (
  options: Array<{ title: string; value: number }> | undefined,
  value: number | string
) => {
  return (
    (options &&
      options.find(
        (option: { title: string; value: number }) => option.value === value
      )?.title) ||
    ""
  );
};

export const compareAdditionalPersons = (
  oldPersons: Array<OtherPerson>,
  newPersons: Array<OtherPerson>
) => {
  if (oldPersons.length !== newPersons.length) {
    return true;
  }

  for (let i = 0; i < oldPersons.length; i++) {
    if (
      oldPersons[i].firstName !== newPersons[i].firstName ||
      oldPersons[i].lastName !== newPersons[i].lastName ||
      oldPersons[i].dateBirth !== newPersons[i].dateBirth
    ) {
      return true;
    }
  }

  return false;
};

export const hidePhoneNumber = (phone: string) => {
  if (phone.length === 0) {
    return "";
  }

  const shownLastDigitsQty = 4;
  const countryCode =
    phone.match(/^(0049|[+]\d{2}|\([0]\d{2}\)|[0]\d{2})/)?.[0] || "";
  const areaCode =
    phone.substring(countryCode.length).match(/(\(\d{2,3}\)|\d{2,3})/)?.[0] ||
    "";
  const shownDigits = phone.substring(phone.length - shownLastDigitsQty);

  const hiddenDigitsLength =
    phone.length - countryCode.length - areaCode.length - shownDigits.length;

  return `${countryCode}${areaCode}${"*".repeat(
    hiddenDigitsLength
  )}${shownDigits}`;
};

import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
//import AdditionalFormDocuments from "../../../components/AdditionalFormDocuments/AdditionalFormDocuments";
import AdditionalFormEmployer from "../../../components/AdditionalFormEmployer/AdditionalFormEmployer";
import AdditionalFormPersonDeath from "../../../components/AdditionalFormPersonDeath/AdditionalFormPersonDeath";
import AdditionalFormProtocol from "../../../components/AdditionalFormProtocol/AdditionalFormProtocol";
import { AdditionalProtocolAnswers } from "../../../components/AdditionalFormProtocol/AdditionalFormProtocol.d";
import { isFilledAnswers } from "../../../components/AdditionalFormProtocol/helper";
import Button from "../../../components/Button/Button";
import Checkbox from "../../../components/Checkbox/Checkbox";
import ContentBox from "../../../components/ContentBox/ContentBox";
import FormikIbanInput from "../../../components/FormikIbanInput/FormikIbanInput";
import InputField from "../../../components/InputField/InputField";
import Radiobox from "../../../components/Radiobox/Radiobox";
import TitleLine from "../../../components/TitleLine/TitleLine";
import UpdateStateFormik from "../../../components/UpdateStateFormik/UpdateStateFormik";
import { ReactComponent as CreditCard } from "../../../images/credit-card-icon.svg";
//import { ReactComponent as GiftIcon } from "../../../images/gift-icon.svg";
import { API_SERVER } from "../../../variables_config";
import {
  clearError,
  fetchBuyPackage,
  fetchCheckoutWithdrawalCheck,
  resetEmployerFormError,
  updateEmployerFormValues,
} from "../../../redux/reducers/buyPackageSlice";
import {
  FormPackageValues,
  PersonDeathFormValues,
} from "../../../redux/reducers/buyPackageSlice.d";
import { fetchAdditionalRecalcLetter } from "../../../redux/reducers/calculatorSlice";
import { AdditionalInfoAnswer } from "../../../redux/reducers/calculatorSlice.d";
import {
  authSelector,
  buyPackageSelector,
  calcSelector,
} from "../../../redux/selectors";
import { numberToDECurrency } from "../../../utils";
import {
  convertFileToBase64,
  validateIBANField,
} from "../../../utils/formHelper";
import { QuestionAnswer } from "../Checkout.d";
import {
  getAdditionalInfoAnsItem,
  getAdditionalInfoItem,
  getOptionTextByValue,
  getQuestionAnswerByValue,
} from "../helper";
import ConfirmationCodeWrapper from "./ConfirmationCodeWrapper/ConfirmationCodeWrapper";
import { useAppDispatch } from "../../../redux/selectors";
import { Link } from "react-router-dom";

interface CheckoutFormProps {
  styles: any;
  packageId?: string;
}

const initCheckoutValues: FormPackageValues = {
  idPackage: "",
  accountHolderName: "",
  iban: "",
  //voucher: "",
  additionalInfoEmployer: {
    firmName: "",
    street: "",
    house: "",
    postalCode: "",
    city: "",
  },
  additionalInfoPerson: {
    value: "",
    documentFront: "",
    documentBack: "",
  },
};

const defaultCheckboxState = 0;

const CheckoutForm: React.FC<CheckoutFormProps> = ({ styles, packageId }) => {
  const dispatch = useAppDispatch();

  const {
    letterData: {
      //defaultValue,
      isFirstBuy,
      idPackage,
      mergedAVB,
      mergedIPID,
      mergedConsumerInfo,
      mergedLeaflet,
      mergedNoConfident,
      mergedAdditionalInfo,
      mergedAdditionalInfoAns,
      forms: additionalFormsData,
      parentPackage: parentPackageId,
    },
    isCalculating,
    status: packageStatus,
  } = useSelector(calcSelector);
  const {
    paymentChecks,
    //success: paymentSuccess,
    employerFormErrors,
    status,
  } = useSelector(buyPackageSelector);
  const { user: loginUser } = useSelector(authSelector);

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const [updateCheckoutValues, setUpdateCheckoutValues] = useState({
    ...initCheckoutValues,
    idPackage,
    iban: loginUser?.iban || "",
    accountHolderName:
      loginUser?.accountHolderName ||
      `${loginUser?.firstName} ${loginUser?.lastName}` ||
      "",
    //amount: defaultValue,
  });

  const [firedCheckboxValue, setFiredCheckboxValue] =
    useState(defaultCheckboxState);
  const [uploadDocsCheckboxValue /*, setUploadDocsCheckboxValue*/] =
    useState(defaultCheckboxState);
  const [gapCheckboxValue, setGapCheckboxValue] =
    useState(defaultCheckboxState);

  const [protocolAnswers, setProtocolAnswers] =
    useState<AdditionalProtocolAnswers>({});

  const additionalInfoProtocol = useMemo(
    () => getAdditionalInfoItem(mergedAdditionalInfo, "consultingProtocol"),
    [mergedAdditionalInfo]
  );

  const additionalInfoEmployer = useMemo(
    () => getAdditionalInfoItem(mergedAdditionalInfo, "employer"),
    [mergedAdditionalInfo]
  );

  const employerAnswerData = useMemo(
    () =>
      getQuestionAnswerByValue(
        additionalFormsData?.employer?.answers,
        firedCheckboxValue
      ),
    [firedCheckboxValue, additionalFormsData?.employer?.answers]
  );

  const additionalInfoPerson = useMemo(
    () => getAdditionalInfoItem(mergedAdditionalInfo, "persondeath"),
    [mergedAdditionalInfo]
  );

  const additionalInfoGap = useMemo(
    () => getAdditionalInfoItem(mergedAdditionalInfo, "gap"),
    [mergedAdditionalInfo]
  );

  const getGapNumbers = useCallback(() => {
    const result = {
      min: 0,
      middleMin: 0,
      middleMax: 0,
      max: 0,
    };

    if (additionalInfoGap?.valueToCalculate) {
      const value = additionalInfoGap?.valueToCalculate * 4;

      result.min = value - 400;
      result.middleMin = result.min + 1;
      result.middleMax = value;
      result.max = result.middleMax + 1;
    }

    return result;
  }, [additionalInfoGap?.valueToCalculate]);

  const onSubmit = async (values: FormPackageValues) => {
    const sendValues: FormPackageValues = {
      iban: values.iban,
      accountHolderName: values.accountHolderName,
      idPackage: idPackage,
      //amount: defaultValue,
      //...(voucher ? { voucher } : {}),
    };

    if (
      additionalInfoEmployer ||
      additionalInfoPerson ||
      additionalInfoGap ||
      additionalInfoProtocol
    ) {
      const additionalInfoAns: Array<AdditionalInfoAnswer> = [];

      if (additionalInfoPerson) {
        const uploadDocsAnswerData = getQuestionAnswerByValue(
          additionalFormsData?.persondeath.answers,
          uploadDocsCheckboxValue
        );

        const personOptionText = getOptionTextByValue(
          additionalFormsData?.persondeath.options,
          values.additionalInfoPerson?.value || ""
        );

        const formDocsValues = {
          ...(uploadDocsAnswerData?.then === "form"
            ? {
                form: {
                  documentFront: await convertFileToBase64(
                    values.additionalInfoPerson?.documentFront
                  ),
                  documentBack: await convertFileToBase64(
                    values.additionalInfoPerson?.documentBack
                  ),
                },
              }
            : null),
        };

        additionalInfoAns.push({
          infoName: additionalInfoPerson.infoName,
          type: additionalInfoPerson.type,
          value: {
            value: values.additionalInfoPerson?.value,
            text: personOptionText,
            deactivateProduct: uploadDocsAnswerData?.deactivateProduct,
            ...(values.additionalInfoPerson?.value ===
            additionalFormsData?.persondeath.showPersonFormOnValue
              ? {
                  form: {
                    ...(formDocsValues ? formDocsValues.form : {}),
                    otherPersons: values?.additionalInfoPerson?.otherPersons,
                  },
                }
              : { ...(formDocsValues ? formDocsValues : {}) }),
          },
        });
      }

      if (additionalInfoEmployer) {
        additionalInfoAns.push({
          infoName: additionalInfoEmployer.infoName,
          type: additionalInfoEmployer.type,
          value: {
            value: firedCheckboxValue,
            deactivateProduct: employerAnswerData?.deactivateProduct,
            ...(employerAnswerData?.then === "form"
              ? { form: values.additionalInfoEmployer }
              : {}),
          },
        });
      }

      if (additionalInfoGap) {
        const gapAnswerData = getQuestionAnswerByValue(
          additionalFormsData?.gap.answers,
          gapCheckboxValue
        );

        additionalInfoAns.push({
          infoName: additionalInfoGap.infoName,
          type: additionalInfoGap.type,
          value: {
            value: gapCheckboxValue,
            gap: getGapNumbers(),
            deactivateProduct: gapAnswerData?.deactivateProduct,
          },
        });
      }

      if (additionalInfoProtocol) {
        additionalInfoAns.push({
          infoName: additionalInfoProtocol.infoName,
          type: additionalInfoProtocol.type,
          value: {
            value: protocolAnswers,
          },
        });
      }

      sendValues.additionalInfoAns = additionalInfoAns;
    }

    dispatch(clearError(null));
    dispatch(fetchBuyPackage(sendValues));
  };

  const additionalInfoRecalcPerson = useCallback(
    (values: PersonDeathFormValues) => {
      if (!additionalInfoPerson) {
        return;
      }

      const additionalInfoAns: Array<AdditionalInfoAnswer> = [];

      const personOptionText = getOptionTextByValue(
        additionalFormsData?.persondeath.options,
        values?.value || ""
      );

      additionalInfoAns.push({
        infoName: additionalInfoPerson.infoName,
        type: additionalInfoPerson.type,
        value: {
          value: values?.value,
          text: personOptionText,
          ...(values?.value ===
          additionalFormsData?.persondeath.showPersonFormOnValue
            ? {
                form: {
                  otherPersons: values?.otherPersons,
                },
              }
            : {}),
        },
      });

      dispatch(
        fetchAdditionalRecalcLetter({
          idPackage,
          createDraft: false,
          additionalInfoAns,
        }) as any
      );
    },
    [additionalInfoPerson, additionalFormsData, dispatch, idPackage]
  );

  const additionalInfoRecalcEmployer = useCallback(
    (value: any) => {
      if (!additionalInfoEmployer) {
        return;
      }

      const additionalInfoAns: Array<AdditionalInfoAnswer> = [];

      additionalInfoAns.push({
        infoName: additionalInfoEmployer.infoName,
        type: additionalInfoEmployer.type,
        value: {
          value,
          deactivateProduct: getQuestionAnswerByValue(
            additionalFormsData?.employer.answers,
            value
          )?.deactivateProduct,
          ...(employerAnswerData?.then === "form"
            ? { form: updateCheckoutValues.additionalInfoEmployer }
            : {}),
        },
      });

      setProtocolAnswers({});

      dispatch(
        fetchAdditionalRecalcLetter({
          idPackage,
          createDraft: false,
          additionalInfoAns,
        }) as any
      );
    },
    [
      updateCheckoutValues.additionalInfoEmployer,
      employerAnswerData,
      additionalInfoEmployer,
      idPackage,
      additionalFormsData,
      dispatch,
    ]
  );

  const additionalInfoRecalcGap = useCallback(
    (value: any) => {
      if (!additionalInfoGap) {
        return;
      }

      const additionalInfoAns: Array<AdditionalInfoAnswer> = [];

      additionalInfoAns.push({
        infoName: additionalInfoGap.infoName,
        type: additionalInfoGap.type,
        value: {
          value,
          gap: getGapNumbers(),
          deactivateProduct: getQuestionAnswerByValue(
            additionalFormsData?.gap.answers,
            value
          )?.deactivateProduct,
        },
      });

      setProtocolAnswers({});

      dispatch(
        fetchAdditionalRecalcLetter({
          idPackage,
          createDraft: false,
          additionalInfoAns,
        }) as any
      );
    },
    [additionalInfoGap, idPackage, additionalFormsData, dispatch, getGapNumbers]
  );

  const additionalInfoRecalcProtocol = useCallback(
    (value: any) => {
      if (!additionalInfoProtocol) {
        return;
      }

      const additionalInfoAns: Array<AdditionalInfoAnswer> = [];

      additionalInfoAns.push({
        infoName: additionalInfoProtocol.infoName,
        type: additionalInfoProtocol.type,
        value: {
          value,
        },
      });

      dispatch(
        fetchAdditionalRecalcLetter({
          idPackage,
          createDraft: false,
          additionalInfoAns,
        }) as any
      );
    },
    [additionalInfoProtocol, idPackage, dispatch]
  );

  const isAdditionalInfoEmployerFilled = useCallback(() => {
    if (
      (additionalInfoEmployer?.mandatoryType === 1 ||
        additionalInfoEmployer?.mandatoryType === 2) &&
      firedCheckboxValue === 0
    ) {
      return false;
    }

    return true;
  }, [firedCheckboxValue, additionalInfoEmployer]);

  const isAdditionalInfoPersonRequired = useMemo(
    () =>
      additionalInfoPerson?.mandatoryType === 1 ||
      additionalInfoPerson?.mandatoryType === 2,
    [additionalInfoPerson]
  );

  const isAdditionalInfoPersonFilled = useCallback(() => {
    if (
      (isAdditionalInfoPersonRequired &&
        !updateCheckoutValues.additionalInfoPerson?.value) ||
      (updateCheckoutValues.additionalInfoPerson?.value ===
        additionalFormsData?.persondeath?.showPersonFormOnValue &&
        !updateCheckoutValues.additionalInfoPerson?.otherPersons
          ?.length) /* && uploadDocsCheckboxValue === 0*/
    ) {
      return false;
    }

    return true;
  }, [
    additionalFormsData?.persondeath,
    isAdditionalInfoPersonRequired,
    updateCheckoutValues.additionalInfoPerson,
  ]);

  const isAdditionalInfoGapRequired = useMemo(
    () =>
      additionalInfoGap?.mandatoryType === 1 ||
      additionalInfoGap?.mandatoryType === 2,
    [additionalInfoGap]
  );

  const isAdditionalInfoGapFilled = useCallback(() => {
    if (isAdditionalInfoGapRequired && gapCheckboxValue === 0) {
      return false;
    }

    return true;
  }, [gapCheckboxValue, isAdditionalInfoGapRequired]);

  const isAdditionalInfoProtocolFilled = useMemo(
    () =>
      isFilledAnswers(
        additionalFormsData?.consultingProtocol?.questions,
        protocolAnswers
      ),
    [additionalFormsData?.consultingProtocol?.questions, protocolAnswers]
  );

  const resetEmployerRequestError = (key: string) => {
    dispatch(resetEmployerFormError(key));
  };

  const toggleTermsConditionsCheckBox = (value: boolean) => {
    dispatch(
      fetchCheckoutWithdrawalCheck({ value, accrvidn: paymentChecks.accrvidn })
    );
  };

  useEffect(() => {
    if (additionalInfoPerson) {
      const personAnswerItem = getAdditionalInfoAnsItem(
        mergedAdditionalInfoAns,
        "persondeath"
      );

      if (personAnswerItem) {
        setUpdateCheckoutValues(prev => ({
          ...prev,
          additionalInfoPerson: {
            ...prev.additionalInfoPerson,
            value: personAnswerItem.value.value,
            ...(personAnswerItem.value.form ? personAnswerItem.value.form : {}),
          },
        }));

        // if (
        //   !personAnswerItem.value.form ||
        //   !personAnswerItem.value.form.documentFront
        // ) {
        //   setUploadDocsCheckboxValue(1);
        // }
      }
    }
  }, [additionalInfoPerson, mergedAdditionalInfoAns]);

  useEffect(() => {
    if (additionalInfoEmployer) {
      const employerAnswerItem = getAdditionalInfoAnsItem(
        mergedAdditionalInfoAns,
        "employer"
      );

      if (employerAnswerItem) {
        setFiredCheckboxValue(employerAnswerItem.value.value);
        setUpdateCheckoutValues(prev => ({
          ...prev,
          additionalInfoEmployer:
            employerAnswerItem.value.form ||
            initCheckoutValues.additionalInfoEmployer,
        }));
      } else {
        setFiredCheckboxValue(defaultCheckboxState);
      }
    }
  }, [additionalInfoEmployer, mergedAdditionalInfoAns]);

  useEffect(() => {
    if (additionalInfoGap) {
      const gapAnswerItem = getAdditionalInfoAnsItem(
        mergedAdditionalInfoAns,
        "gap"
      );

      if (gapAnswerItem) {
        setGapCheckboxValue(gapAnswerItem.value.value);
      } else {
        setGapCheckboxValue(defaultCheckboxState);
      }
    }
  }, [additionalInfoGap, mergedAdditionalInfoAns]);

  useEffect(() => {
    if (additionalInfoProtocol && packageStatus === "succeded") {
      const protocolAnswersItem = getAdditionalInfoAnsItem(
        mergedAdditionalInfoAns,
        "consultingProtocol"
      );

      if (protocolAnswersItem) {
        setProtocolAnswers(protocolAnswersItem.value.value);
      }
    }
  }, [additionalInfoProtocol, mergedAdditionalInfoAns, packageStatus]);

  useEffect(() => {
    if (employerAnswerData?.then === "form") {
      dispatch(
        updateEmployerFormValues(updateCheckoutValues.additionalInfoEmployer)
      );
    }
  }, [
    employerAnswerData,
    updateCheckoutValues.additionalInfoEmployer,
    dispatch,
  ]);

  useEffect(() => {
    if (isCalculating) {
      setProtocolAnswers({});
    }
  }, [isCalculating]);

  useEffect(() => {
    if (status === "pending") {
      setIsSubmitDisabled(true);
      const id = setTimeout(() => {
        setIsSubmitDisabled(false);

        return () => clearTimeout(id);
      }, 2500);
    }
  }, [status]);

  useEffect(() => {
    const personAnswerItem = getAdditionalInfoAnsItem(
      mergedAdditionalInfoAns,
      "persondeath"
    );

    if (
      personAnswerItem?.value?.form?.otherPersons &&
      updateCheckoutValues.additionalInfoPerson &&
      updateCheckoutValues.additionalInfoPerson?.value ===
        additionalFormsData?.persondeath?.showPersonFormOnValue &&
      personAnswerItem?.value?.form?.otherPersons?.length !==
        updateCheckoutValues.additionalInfoPerson?.otherPersons?.length
    ) {
      additionalInfoRecalcPerson(updateCheckoutValues?.additionalInfoPerson);
    }
  }, [
    mergedAdditionalInfoAns,
    updateCheckoutValues.additionalInfoPerson,
    additionalInfoRecalcPerson,
    additionalFormsData?.persondeath,
  ]);

  return (
    <>
      <Formik
        key={`formik_${uploadDocsCheckboxValue}_${gapCheckboxValue}_${firedCheckboxValue}`}
        {...{ initialValues: updateCheckoutValues, onSubmit }}
        //enableReinitialize={true}
      >
        {({ values, errors, touched, isValid, setFieldValue, handleBlur }) => (
          <Form className={styles.form} noValidate>
            {additionalInfoPerson && additionalFormsData?.persondeath ? (
              <ContentBox
                className={`${styles.additionalBlock} ${styles.widthZIndex}`}
              >
                <TitleLine className={styles.title}>
                  {additionalFormsData?.persondeath.title}
                </TitleLine>

                <div className={styles.questionContainer}>
                  <div className={styles.questionText}>
                    {additionalFormsData?.persondeath.question}
                  </div>
                  <div className={styles.questionContent}>
                    <AdditionalFormPersonDeath
                      options={additionalFormsData?.persondeath.options}
                      fields={additionalFormsData?.persondeath.fields}
                      values={values?.additionalInfoPerson}
                      errors={errors?.additionalInfoPerson}
                      touched={touched?.additionalInfoPerson}
                      isPersonRequired={isAdditionalInfoPersonRequired}
                      showPersonFormOnValue={
                        additionalFormsData?.persondeath.showPersonFormOnValue
                      }
                      setFieldValue={setFieldValue}
                      onFieldBlur={(e: any) => {
                        handleBlur(e);
                        values?.additionalInfoPerson &&
                          additionalInfoRecalcPerson(
                            values?.additionalInfoPerson
                          );
                      }}
                      sendRecalc={additionalInfoRecalcPerson}
                    />
                  </div>
                </div>

                {/* <div className={styles.questionContainer}>
                <div className={styles.questionText}>
                  {additionalFormsData?.persondeath.description}
                </div>
                <div className={styles.questionContent}>
                  <div
                    className={`${styles.answersContainer} ${styles.answersColumnContainer}`}
                  >
                    {additionalFormsData?.persondeath.answers.map(
                      (answer: QuestionAnswer) => (
                        <Radiobox
                          key={answer.value}
                          id={`isUploadDocs${answer.value}`}
                          name="isUploadDocs"
                          className={styles.defaultRadio}
                          checked={uploadDocsCheckboxValue === answer.value}
                          toggleCheck={(value: boolean) => {
                            if (value) {
                              if (answer.then !== "form") {
                                setUpdateCheckoutValues((prev: any) => ({
                                  ...prev,
                                  additionalInfoPerson: {
                                    ...prev.additionalInfoPerson,
                                    documentFront:
                                      initCheckoutValues.additionalInfoPerson
                                        ?.documentFront,
                                    documentBack:
                                      initCheckoutValues.additionalInfoPerson
                                        ?.documentBack,
                                  },
                                }));
                              }

                              setUploadDocsCheckboxValue(answer.value);
                            }
                          }}
                        >
                          <span>{answer.title}</span>
                        </Radiobox>
                      )
                    )}
                  </div>

                  {getQuestionAnswerByValue(
                    additionalFormsData?.persondeath.answers,
                    uploadDocsCheckboxValue
                  )?.then === "form" ? (
                    <div className={styles.questionContainer}>
                      <div className={styles.questionText}>
                        {additionalFormsData?.persondeath.formTitle}
                      </div>
                      <div className={styles.questionContent}>
                        <AdditionalFormDocuments
                          fields={additionalFormsData?.persondeath.fields}
                          errors={errors?.additionalInfoPerson}
                          touched={touched?.additionalInfoPerson}
                        />
                      </div>
                      <div className={styles.questionDesc}>
                        {additionalFormsData?.persondeath.formDescription}
                      </div>
                    </div>
                  ) : (
                    <div className={styles.questionContainer}>
                      <div className={styles.questionText}>
                        {
                          getQuestionAnswerByValue(
                            additionalFormsData?.persondeath.answers,
                            uploadDocsCheckboxValue
                          )?.then
                        }
                      </div>
                    </div>
                  )}
                </div>
              </div> */}
              </ContentBox>
            ) : null}

            {(additionalInfoGap && additionalFormsData?.gap) ||
            (additionalInfoEmployer && additionalFormsData?.employer) ? (
              <ContentBox className={styles.additionalBlock}>
                <TitleLine className={styles.title}>
                  {additionalFormsData?.employer.title}
                </TitleLine>

                {additionalInfoEmployer && additionalFormsData?.employer ? (
                  <>
                    <div className={styles.questionContainer}>
                      <div className={styles.questionText}>
                        {additionalFormsData?.employer.question}
                      </div>
                      <div className={styles.questionContent}>
                        <div className={styles.answersContainer}>
                          {additionalFormsData?.employer.answers.map(
                            (answer: QuestionAnswer) => (
                              <Radiobox
                                key={answer.value}
                                id={`isFired${answer.value}`}
                                name="isFired"
                                className={styles.defaultRadio}
                                checked={firedCheckboxValue === answer.value}
                                toggleCheck={(value: boolean) => {
                                  if (value) {
                                    if (answer.then !== "form") {
                                      setUpdateCheckoutValues(prev => ({
                                        ...prev,
                                        additionalInfoEmployer:
                                          initCheckoutValues.additionalInfoEmployer,
                                      }));
                                    }

                                    additionalInfoRecalcEmployer(answer.value);
                                    setFiredCheckboxValue(answer.value);
                                  }
                                }}
                              >
                                <span>{answer.title}</span>
                              </Radiobox>
                            )
                          )}
                        </div>
                      </div>
                    </div>

                    {getQuestionAnswerByValue(
                      additionalFormsData?.employer.answers,
                      firedCheckboxValue
                    )?.then === "form" ? (
                      <div className={styles.questionContainer}>
                        <div className={styles.questionText}>
                          {additionalFormsData?.employer.formTitle}
                        </div>
                        <div className={styles.questionContent}>
                          <AdditionalFormEmployer
                            fields={additionalFormsData?.employer.fields}
                            errors={errors}
                            touched={touched}
                            requestErrors={employerFormErrors}
                            setFieldValue={setFieldValue}
                            resetRequestError={resetEmployerRequestError}
                            onFieldBlur={(e: any) => {
                              handleBlur(e);
                              additionalInfoRecalcEmployer(firedCheckboxValue);
                            }}
                          />
                        </div>
                        <div className={styles.questionDesc}>
                          {additionalFormsData?.employer.formDescription}
                        </div>
                      </div>
                    ) : (
                      <div className={styles.questionContainer}>
                        <div className={styles.questionText}>
                          {
                            getQuestionAnswerByValue(
                              additionalFormsData?.employer.answers,
                              firedCheckboxValue
                            )?.then
                          }
                        </div>
                      </div>
                    )}
                  </>
                ) : null}

                {additionalInfoGap && additionalFormsData?.gap ? (
                  <>
                    <div className={styles.questionContainer}>
                      <div className={styles.questionText}>
                        {additionalFormsData?.gap.question}
                      </div>
                      <div className={styles.questionContent}>
                        <div
                          className={`${styles.answersContainer} ${styles.answersColumnContainer}`}
                        >
                          {additionalFormsData?.gap.answers.map(
                            (answer: QuestionAnswer) => (
                              <Radiobox
                                key={answer.value}
                                id={`gapCheckbox${answer.value}`}
                                name="gapCheckbox"
                                className={styles.defaultRadio}
                                checked={gapCheckboxValue === answer.value}
                                toggleCheck={(value: boolean) => {
                                  if (value) {
                                    additionalInfoRecalcGap(answer.value);
                                    setGapCheckboxValue(answer.value);
                                  }
                                }}
                              >
                                {answer.value === 1 && (
                                  <span>
                                    bei{" "}
                                    {numberToDECurrency(
                                      getGapNumbers().min,
                                      true
                                    )}{" "}
                                    oder darunter
                                  </span>
                                )}

                                {answer.value === 2 && (
                                  <span>
                                    zwischen{" "}
                                    {numberToDECurrency(
                                      getGapNumbers().middleMin,
                                      true
                                    )}{" "}
                                    und{" "}
                                    {numberToDECurrency(
                                      getGapNumbers().middleMax,
                                      true
                                    )}
                                  </span>
                                )}

                                {answer.value === 3 && (
                                  <span>
                                    über{" "}
                                    {numberToDECurrency(
                                      getGapNumbers().max,
                                      true
                                    )}
                                  </span>
                                )}
                              </Radiobox>
                            )
                          )}
                        </div>
                      </div>
                    </div>

                    <div className={styles.questionContainer}>
                      <div className={styles.questionText}>
                        {
                          getQuestionAnswerByValue(
                            additionalFormsData?.gap.answers,
                            gapCheckboxValue
                          )?.then
                        }
                      </div>
                    </div>
                  </>
                ) : null}
              </ContentBox>
            ) : null}

            {additionalInfoProtocol &&
            additionalFormsData?.consultingProtocol ? (
              <ContentBox className={styles.additionalBlock}>
                <TitleLine className={styles.title}>
                  {additionalFormsData?.consultingProtocol.title}
                </TitleLine>

                <div className={styles.questionContainer}>
                  <AdditionalFormProtocol
                    key={`protocol_${JSON.stringify(protocolAnswers)}`}
                    protocolData={additionalFormsData?.consultingProtocol}
                    answers={protocolAnswers}
                    isShowError={!isAdditionalInfoProtocolFilled}
                    updateAnswer={answer => {
                      setProtocolAnswers(answer);
                      additionalInfoRecalcProtocol(answer);
                    }}
                  />
                </div>
              </ContentBox>
            ) : null}

            <ContentBox className={styles.block}>
              <TitleLine>Deine Zahlungsdaten</TitleLine>

              <div>
                <div className={styles.field}>
                  <InputField
                    id="iban"
                    name="iban"
                    type="text"
                    placeholder="IBAN"
                    labelText="IBAN (22-stellig)"
                    value={values.iban}
                    hasErrors={
                      errors.hasOwnProperty("iban") &&
                      touched.hasOwnProperty("iban")
                    }
                    validateHandler={validateIBANField}
                    icon={CreditCard}
                    component={FormikIbanInput}
                  />
                </div>

                {/* <div className={styles.voucherContainer}>
                <h3 className={styles.voucherH3}>
                  Du hast einen Gutschein den du einlösen möchtest?
                  <br />
                  Dann gib hier deinen Gutschein-Code ein:
                </h3>

                <div className={styles.voucher}>
                  <div className={styles.field}>
                    <InputField
                      id="voucher"
                      name="voucher"
                      type="text"
                      containerClass={styles.voucherInput}
                      placeholder="123456"
                      hasErrors={
                        errors.hasOwnProperty("voucher") &&
                        touched.hasOwnProperty("voucher")
                      }
                      icon={GiftIcon}
                    />
                  </div>
                </div>
              </div> */}

                <Checkbox
                  id="withdrawalCheck"
                  checked={paymentChecks.withdrawalCheck}
                  toggleCheck={toggleTermsConditionsCheckBox}
                  className={styles.checkbox}
                >
                  Ich habe die
                  {mergedAVB ? (
                    <>
                      {" "}
                      <a
                        href={`${API_SERVER}storage/assets/images/${mergedAVB}`}
                        target="_blank"
                        rel="nofollow noreferrer"
                        className={styles.link}
                      >
                        Allgemeinen Versicherungsbedingungen
                      </a>
                    </>
                  ) : (
                    <>
                      {" "}
                      <Link
                        to="/agb"
                        target="_blank"
                        rel="nofollow noreferrer"
                        className={styles.link}
                      >
                        Allgemeine Geschäftsbedingungen
                      </Link>
                    </>
                  )}
                  {mergedNoConfident ? (
                    <>
                      {", die "}
                      <a
                        href={`${API_SERVER}storage/assets/images/${mergedNoConfident}`}
                        target="_blank"
                        rel="nofollow noreferrer"
                        className={styles.link}
                      >
                        Einwilligungs - und
                        Schweigepflichtentbindungserklärungen
                      </a>
                    </>
                  ) : null}
                  {mergedIPID ? (
                    <>
                      {", die "}
                      <a
                        href={`${API_SERVER}storage/assets/images/${mergedIPID}`}
                        target="_blank"
                        rel="nofollow noreferrer"
                        className={styles.link}
                      >
                        Produkt-Informationsblätter
                      </a>
                    </>
                  ) : null}
                  {mergedLeaflet ? (
                    <>
                      {", die "}
                      <a
                        href={`${API_SERVER}storage/assets/images/${mergedLeaflet}`}
                        target="_blank"
                        rel="nofollow noreferrer"
                        className={styles.link}
                      >
                        Merkblatt-Datenverarbeitung
                      </a>
                    </>
                  ) : null}
                  {mergedConsumerInfo ? (
                    <>
                      {", die "}
                      <a
                        href={`${API_SERVER}storage/assets/images/${mergedConsumerInfo}`}
                        target="_blank"
                        rel="nofollow noreferrer"
                        className={styles.link}
                      >
                        Verbraucherinfo
                      </a>
                    </>
                  ) : null}{" "}
                  und die Belehrung zum Widerrufsrecht zustimmend zur Kenntnis
                  genommen.*
                </Checkbox>
              </div>
            </ContentBox>

            <div className={styles.btnContainer}>
              <Button
                type="button"
                text="Abbrechen"
                href={`/packages/${parentPackageId || packageId}`}
                className={styles.btn}
              />

              <Button
                type="submit"
                variant="secondary"
                text="Jetzt buchen"
                disabled={
                  status === "pending" ||
                  isSubmitDisabled ||
                  !isValid ||
                  !paymentChecks.ready ||
                  !!(
                    employerFormErrors && Object.keys(employerFormErrors).length
                  ) ||
                  !isAdditionalInfoEmployerFilled() ||
                  !isAdditionalInfoPersonFilled() ||
                  !isAdditionalInfoGapFilled() ||
                  !isAdditionalInfoProtocolFilled
                }
                className={styles.btn}
              />
            </div>

            <UpdateStateFormik onChange={setUpdateCheckoutValues} />
          </Form>
        )}
      </Formik>

      <ConfirmationCodeWrapper
        isShowEmployerForm={!!employerFormErrors}
        employerFormData={additionalFormsData?.employer}
      />
    </>
  );
};

export default CheckoutForm;

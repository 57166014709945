import React, { useCallback, useMemo, useState } from "react";
import styles from "./PackagesSlider.module.css";
import LongButton from "../LongButton/LongButton";
import useWindowDimensions from "../../utils/useWindowDimentions";
import { ReactComponent as OrderIcon } from "../assets/images/order-icon.svg";
import { ReactComponent as ConfigIcon } from "../assets/images/config-icon.svg";
import { ReactComponent as FileDockIcon } from "../assets/images/file-dock-icon.svg";
import { ReactComponent as WalletIcon } from "../assets/images/wallet-icon.svg";
import { ReactComponent as ArrowFilledIcon } from "../assets/images/arrow-filled-right.svg";
import SlidePackageItem from "../SlidePackageItem/SlidePackageItem";
import { SliderProps } from "./PackagesSlider.d";

const slideWidthDesktop = 256;
const slideWidthMob = 162;
const defaultSlide = 1;
const visibleQty = 3;
const mobFrom = 1044;

const PackagesSlider: React.FC<SliderProps> = ({
  packages,
  hideWalletBtn,
  disableWalletBtn,
  getWalletPassHandler,
}) => {
  const { width } = useWindowDimensions();
  const childsLength = useMemo(() => packages.length, [packages]);
  const shouldTransform = useMemo(
    () =>
      !(
        width > slideWidthMob * visibleQty + 36 * 2 &&
        childsLength <= visibleQty
      ),
    [childsLength, width]
  );

  const [active, setActive] = useState(
    childsLength > defaultSlide ? defaultSlide : 0
  );
  const [touchPosition, setTouchPosition] = useState(null);

  const prevHandler = () => {
    if (active > 0) {
      setActive(prev => prev - 1);
    }
  };

  const nextHandler = () => {
    if (active < childsLength - 1) {
      setActive(prev => prev + 1);
    }
  };

  const handleTouchStart = (e: any) => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = (e: any) => {
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 5) {
      nextHandler();
    }

    if (diff < -5) {
      prevHandler();
    }

    setTouchPosition(null);
  };

  const dotClickHandler = (index: number) => {
    setActive(index);
  };

  const percentageDesktop = useCallback(
    (slideWidth: number) => {
      if (active === 0 || childsLength <= visibleQty) {
        return "0";
      }

      if (active > childsLength - visibleQty) {
        return -slideWidth * (childsLength - visibleQty) + "px";
      }

      return -slideWidth * (active - 1) + "px";
    },
    [active, childsLength]
  );

  const percentageMobile = useMemo(() => {
    if (!shouldTransform) {
      return "0";
    }

    return (
      (width > mobFrom ? mobFrom : width) / 2 -
      slideWidthMob / 2 -
      slideWidthMob * active +
      "px"
    );
  }, [shouldTransform, width, active]);

  const transform = useMemo(() => {
    const slideWidth =
      width > 768 && width <= mobFrom ? slideWidthMob : slideWidthDesktop;

    return width > 768 ? percentageDesktop(slideWidth) : percentageMobile;
  }, [percentageDesktop, percentageMobile, width]);

  return (
    <div className={styles.sliderContainer} data-testid="package-slider">
      <div className={styles.sliderContent}>
        <div className={styles.bulletsContainer}>
          {packages.map((child: any, index: number) => (
            <div
              data-testid={`dot-${index}`}
              key={index}
              className={`${styles.bullet} ${
                active === index ? styles.activeBullet : ""
              }`}
              onClick={() => dotClickHandler(index)}
            />
          ))}
        </div>
        <div
          className={`${styles.sliderArrows} ${
            active === 0 ? styles.sliderArrowsDisabled : " "
          }`}
        >
          <div
            data-testid="prev-button"
            className={styles.prev}
            {...(active > 0 ? { onClick: prevHandler } : {})}
          >
            <ArrowFilledIcon className={styles.arrow} />
          </div>
        </div>

        <div
          className={styles.slidesContainer}
          style={
            !shouldTransform
              ? {
                  justifyContent: "center",
                }
              : {}
          }
        >
          <div
            className={styles.slides}
            style={{ transform: `translateX(${transform})` }}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
          >
            {packages.map((item: any, index: number) => (
              <SlidePackageItem
                testId={`package-${index}-${
                  index === active ? "active" : "notActive"
                }`}
                key={item.idPackage}
                name={item.name}
                image={item.image}
                {...(index === active
                  ? { isActive: true }
                  : { onClick: () => dotClickHandler(index) })}
              />
            ))}
          </div>
        </div>

        <div
          data-testid="next-button"
          className={`${styles.sliderArrows} ${styles.sliderArrowsNext} ${
            active === childsLength - 1 ? styles.sliderArrowsDisabled : " "
          }`}
        >
          <div {...(active < childsLength - 1 ? { onClick: nextHandler } : {})}>
            <ArrowFilledIcon className={styles.arrow} />
          </div>
        </div>
      </div>

      <div className={styles.slideDescription}>
        <div className={styles.btnContainer}>
          <LongButton
            text="Vertragsunterlagen einsehen"
            icon={OrderIcon}
            href="/downloads"
            className={styles.btn}
          />

          <LongButton
            text="Jetzt konfigurieren"
            icon={ConfigIcon}
            href={`/packages/${packages[active].idPackage}`}
            className={styles.btn}
          />

          <LongButton
            text="Leistungsfall melden"
            icon={FileDockIcon}
            href={`/service-contact/${packages[active].idPackage}`}
            className={styles.btn}
          />
          {!hideWalletBtn && (
            <LongButton
              text="In deiner Wallet speichern"
              icon={WalletIcon}
              className={styles.btn}
              disabled={!disableWalletBtn}
              clickHandler={() =>
                getWalletPassHandler(packages[active].idPackage)
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PackagesSlider;

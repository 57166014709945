import { Form, Formik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Params, useNavigate, useParams } from "react-router-dom";
import BlockContainer from "../../components/BlockContainer/BlockContainer";
import Button from "../../components/Button/Button";
import ContentBox from "../../components/ContentBox/ContentBox";
import FormikReactSelect from "../../components/FormikReactSelect/FormikReactSelect";
import InputField from "../../components/InputField/InputField";
import LoaderComponent from "../../components/LoaderComponent/LoaderComponent";
import TitleLine from "../../components/TitleLine/TitleLine";
import MainWrapper from "../../containers/MainWrapper/MainWrapper";
import { ReactComponent as CloseIcon } from "../../images/close-round-icon.svg";
import { ReactComponent as PhoneIcon } from "../../images/phone-icon.svg";
import { fetchCalcLetterData } from "../../redux/reducers/calculatorSlice";
import { Product } from "../../redux/reducers/calculatorSlice.d";
import {
  changeStatus,
  clearError,
  fetchSendMessagePackageSupport,
} from "../../redux/reducers/mailSupportSlice";
import { MailSupportValues } from "../../redux/reducers/mailSupportSlice.d";
import {
  changeStatus as changeRequestCategoriesStatus,
  fetchRequestCategoriesList,
} from "../../redux/reducers/requestCategoriesSlice";
import {
  authSelector,
  calcSelector,
  mailSupportSelector,
  requestCategoriesSelector,
} from "../../redux/selectors";
import { successMessage } from "../../utils";
import { validateRequiredField } from "../../utils/formHelper";
import styles from "./SchadenMeldenPage.module.css";
import { useAppDispatch } from "../../redux/selectors";

const initialValues: MailSupportValues = {
  firstName: "",
  lastName: "",
  from: "",
  cc: "",
  requestCategory: "",
  packageName: "",
  idProduct: "",
  body: "",
};

const SchadenMeldenPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { packageId }: Readonly<Params<string>> = useParams();

  const { letterData } = useSelector(calcSelector);
  const { user } = useSelector(authSelector);
  const { requestCategoriesList, status: requestCategoriesStatus } =
    useSelector(requestCategoriesSelector);
  const { status } = useSelector(mailSupportSelector);

  const [formValues, setFormValues] = useState(initialValues);

  const getSelectedCategory = (categories: any, categoryId: string) => {
    return categories.find((category: any) => category._id === categoryId);
  };

  const onSubmit = (
    values: MailSupportValues,
    { resetForm }: { resetForm: () => void }
  ) => {
    let sendValues = { ...values };

    const categoryId = sendValues.requestCategory.value;
    let selectedCategory = getSelectedCategory(
      requestCategoriesList,
      categoryId
    );

    if (!selectedCategory) {
      requestCategoriesList.some(category => {
        selectedCategory = getSelectedCategory(
          category.subcategories,
          categoryId
        );

        if (selectedCategory) {
          return true;
        }

        return false;
      });
    }

    if (selectedCategory) {
      sendValues.requestCategory = selectedCategory?.name;
      sendValues.cc = selectedCategory?.email;
    }

    const product =
      letterData?.products &&
      letterData?.products?.find(p => p.idProduct === values.idProduct);

    sendValues.packageName = `${values.packageName}/${product?.contractNum}`;
    delete sendValues.idProduct;

    dispatch(clearError(null));
    resetForm();
    dispatch(fetchSendMessagePackageSupport({ values: sendValues }));
  };

  const requestCategoriesOptions = () => {
    if (requestCategoriesList.length === 0) {
      return [];
    }

    return requestCategoriesList.map(category => ({
      label: category.name,
      ...(category.subcategories.length > 0
        ? {
            options: category.subcategories.map(subcategory => ({
              value: subcategory._id,
              label: subcategory.name,
            })),
          }
        : { value: category._id }),
    }));
  };

  const productsOptions = useCallback(() => {
    if (!letterData.products.length) {
      return null;
    }

    return letterData.products.map((product: Product, i: number) => (
      <option key={i} value={product.idProduct} label={product.name}>
        {product.name}
      </option>
    ));
  }, [letterData.products]);

  useEffect(() => {
    if (packageId) {
      dispatch(fetchCalcLetterData({ letterId: packageId }));
    }
  }, [dispatch, packageId]);

  useEffect(() => {
    if (user && letterData.idPackage) {
      setFormValues({
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        from: user.username || "",
        cc: "",
        requestCategory: "",
        packageName: letterData.packageNum || "",
        idProduct: "",
        body: "",
      });
    }
  }, [user, letterData, dispatch]);

  useEffect(() => {
    if (requestCategoriesStatus === "idle") {
      dispatch(
        fetchRequestCategoriesList({ perPage: 999, isForPackage: true })
      );
    }
  }, [dispatch, requestCategoriesStatus]);

  useEffect(() => {
    if (status === "succeded") {
      dispatch(changeStatus("idle"));
      successMessage(`Nachricht gesendet`);

      setTimeout(() => navigate("/"), 2000);
    }
  }, [status, navigate, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(changeRequestCategoriesStatus("idle"));
    };
  }, [dispatch]);

  if (!letterData.idPackage) {
    return <LoaderComponent />;
  }

  return (
    <MainWrapper>
      <BlockContainer>
        <div className={styles.blockContainer}>
          <ContentBox className={styles.block}>
            <TitleLine className={styles.title}>
              Unser Kontakt - Dein Anliegen
            </TitleLine>

            <Button
              type="icon"
              icon={CloseIcon}
              className={styles.closeBtn}
              clickHandler={() => navigate("/home")}
            />

            <div className={styles.phoneBlock}>
              <h3>Du möchtest uns direkt erreichen?</h3>
              <h3 className={styles.phoneContainer}>
                <span>Wir sind für dich da.</span>
                <a href="tel:+4920549899965" className={styles.phoneA}>
                  <PhoneIcon className={styles.phoneIcon} />
                  +49 2054 989 99 65
                </a>
              </h3>
            </div>

            <div className={styles.requiredInfo}>
              Die mit * gekennzeichneten Daten sind Pflichtfelder
            </div>

            <Formik
              {...{ initialValues: formValues, onSubmit }}
              enableReinitialize
            >
              {({ errors, touched }) => (
                <Form className={styles.form} noValidate>
                  <InputField
                    id="firstName"
                    name="firstName"
                    type="text"
                    placeholder=""
                    labelText="Vorname"
                    hasErrors={
                      errors.hasOwnProperty("firstName") &&
                      touched.hasOwnProperty("firstName")
                    }
                    containerClass={styles.formGroupInline}
                    labelClass={styles.label}
                    inputClass={styles.input}
                  />

                  <InputField
                    id="lastName"
                    name="lastName"
                    type="text"
                    placeholder=""
                    labelText="Nachname"
                    hasErrors={
                      errors.hasOwnProperty("lastName") &&
                      touched.hasOwnProperty("lastName")
                    }
                    containerClass={styles.formGroupInline}
                    labelClass={styles.label}
                    inputClass={styles.input}
                  />

                  <InputField
                    id="from"
                    name="from"
                    type="text"
                    placeholder=""
                    labelText="Deine E-Mail-Adresse, an die wir dir unsere Antwort schicken können"
                    hasErrors={
                      errors.hasOwnProperty("from") &&
                      touched.hasOwnProperty("from")
                    }
                    validateHandler={validateRequiredField}
                    containerClass={`${styles.formGroupInline} ${styles.inputAlignTop}`}
                    labelClass={styles.label}
                    inputClass={styles.input}
                  />

                  <InputField
                    id="requestCategory"
                    name="requestCategory"
                    type="text"
                    placeholder="Auswählen..."
                    labelText="Bitte wähle eine Rubrik, die zu deinem Anliegen passt"
                    component={FormikReactSelect}
                    options={requestCategoriesOptions()}
                    hasErrors={
                      errors.hasOwnProperty("requestCategory") &&
                      touched.hasOwnProperty("requestCategory")
                    }
                    validateHandler={validateRequiredField}
                    containerClass={`${styles.formGroupInline} ${styles.inputAlignTop}`}
                    labelClass={styles.label}
                    inputClass={styles.input}
                  />

                  <InputField
                    id="packageName"
                    name="packageName"
                    type="text"
                    placeholder=""
                    labelText="Deine Mehrwert-Paket Nummer (falls zur Hand)"
                    hasErrors={
                      errors.hasOwnProperty("packageName") &&
                      touched.hasOwnProperty("packageName")
                    }
                    validateHandler={validateRequiredField}
                    containerClass={`${styles.formGroupInline} ${styles.inputAlignTop}`}
                    labelClass={styles.label}
                    inputClass={styles.input}
                  />

                  <InputField
                    id="idProduct"
                    name="idProduct"
                    type="select"
                    placeholder=""
                    labelText="Welcher Mehrwert-Paket-Baustein ist von Deinem Leistungsfall betroffen?"
                    hasErrors={
                      errors.hasOwnProperty("idProduct") &&
                      touched.hasOwnProperty("idProduct")
                    }
                    validateHandler={validateRequiredField}
                    containerClass={`${styles.formGroupInline} ${styles.inputAlignTop}`}
                    labelClass={styles.label}
                    inputClass={styles.input}
                  >
                    <option value="" label="Auswählen...">
                      Auswählen...
                    </option>
                    {productsOptions()}
                  </InputField>

                  <InputField
                    id="body"
                    name="body"
                    type="textarea"
                    placeholder="Deine Eingabe"
                    labelText="Bitte beschreibe hier dein Anliegen oder deine Frage"
                    rows="6"
                    hasErrors={
                      errors.hasOwnProperty("body") &&
                      touched.hasOwnProperty("body")
                    }
                    validateHandler={validateRequiredField}
                    containerClass={`${styles.formGroupInline} ${styles.inputAlignTop}`}
                    labelClass={styles.label}
                    inputClass={styles.input}
                  />

                  <div className={styles.btnContainer}>
                    <Button type="submit" variant="secondary" text="Absenden" />
                  </div>
                </Form>
              )}
            </Formik>
          </ContentBox>
        </div>
      </BlockContainer>
    </MainWrapper>
  );
};

export default SchadenMeldenPage;

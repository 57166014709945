import React from "react";
import { TableProps } from "./Table.d";
import styles from "./Table.module.css";

const Table: React.FC<TableProps> = ({
  customContainerClass = "",
  customClass = "",
  ref,
  children,
}) => {
  return (
    <div
      className={`${styles.responsiveTable} ${customContainerClass}`}
      {...(ref ? { ref } : null)}
    >
      <table className={`${styles.table} ${customClass}`}>{children}</table>
    </div>
  );
};

export default Table;

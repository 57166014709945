import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APICall } from "../../utils/api";
import { FaqItemState } from "./faqSlice.d";

const initialState: FaqItemState = {
  faqItem: {
    _id: "",
    category: "",
    articles: [],
  },
  status: "idle",
  error: null,
};

export const fetchFaqItem = createAsyncThunk(
  "faq/fetchFaqItem",
  async (faqId: string, { rejectWithValue }) => {
    return await APICall("infoservice.faq", "getone", rejectWithValue, {
      idFaq: faqId,
    });
  }
);

export const faqItemSlice = createSlice({
  name: "faqItem",
  initialState,
  reducers: {
    changeStatus: (state, action) => {
      state.status = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchFaqItem.fulfilled as any, (state, action) => {
      state.faqItem = action.payload;
      state.error = null;
      state.status = "succeded";
    });

    builder.addCase(fetchFaqItem.rejected as any, (state, action) => {
      if (action?.payload?.message) state.error = action.payload.message;
      else state.error = "Etwas schiefgegangen";

      state.status = "error";
    });
  },
});

export const { changeStatus } = faqItemSlice.actions;

export default faqItemSlice.reducer;

import React from "react";
import styles from "./InfoBubble.module.css";
import { ReactComponent as ArrowIcon } from "../assets/images/arrow.svg";

interface Props {
  text: React.ReactNode;
  customClass?: string;
}

const InfoBubble: React.FC<Props> = ({ text, customClass = "" }) => {
  return (
    <div className={`${styles.bubble} ${customClass}`}>
      {text}
      <ArrowIcon className={styles.arrowIcon} />
    </div>
  );
};

export default InfoBubble;

import { configureStore } from "@reduxjs/toolkit";
import calculatorReducer from "./reducers/calculatorSlice";
import authenticationReducer from "./reducers/authenticationSlice";
import downloadsReducer from "./reducers/downloadsSlice";
import checkoutReducer from "./reducers/checkoutSlice";
import cashbackReducer from "./reducers/cashbackSlice";
import finapiReducer from "./reducers/finapiSlice";
import packagesReducer from "./reducers/packagesSlice";
import packageReducer from "./reducers/packageSlice";
import profileReducer from "./reducers/profileSlice";
import buyPackageReducer from "./reducers/buyPackageSlice";
import faqReducer from "./reducers/faqSlice";
import faqItemReducer from "./reducers/faqItemSlice";
import faqArticleReducer from "./reducers/faqArticleSlice";
import themeStylesReducer from "./reducers/themeStylesSlice";
import requestCategoriesReducer from "./reducers/requestCategoriesSlice";
import mailSupportReducer from "./reducers/mailSupportSlice";
import bankReducer from "./reducers/bankSlice";
import addressReducer from "./reducers/addressSlice";

import errorCatchingReducer from "./reducers/errorCatchingSlice";

export const getStore = () =>
  configureStore({
    reducer: {
      calculator: calculatorReducer,
      authentication: authenticationReducer,
      downloads: downloadsReducer,
      checkout: checkoutReducer,
      cashback: cashbackReducer,
      finapi: finapiReducer,
      packages: packagesReducer,
      package: packageReducer,
      profile: profileReducer,
      buyPackage: buyPackageReducer,
      faq: faqReducer,
      faqItem: faqItemReducer,
      faqArticle: faqArticleReducer,
      themeStyles: themeStylesReducer,
      requestCategories: requestCategoriesReducer,
      mailSupport: mailSupportReducer,
      bank: bankReducer,
      address: addressReducer,

      //Should always be at the end of this list
      errorCatching: errorCatchingReducer,
    },
  });

export const store = getStore();
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppStore = ReturnType<typeof getStore>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

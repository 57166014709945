import styles from "./RangeNumbersSimple.module.css";
import { ReactComponent as RangeDownIcon } from "../assets/images/remove-icon.svg";
import { ReactComponent as RangeUpIcon } from "../assets/images/add-ring-icon.svg";
import { useCallback, useMemo } from "react";
import FormattedNumber from "../FormattedNumber/FormattedNumber";

export type RangeNumbersSimpleProps = {
  min: number;
  max: number;
  current: number;
  step: number;
  active: boolean;
  arrowClass?: string;
  changeHandler: (value: number) => void;
  setScrollStyle?: (value: string) => void;
};

const RangeNumbersSimple: React.FC<RangeNumbersSimpleProps> = ({
  min,
  max,
  current,
  step,
  active,
  arrowClass = "",
  changeHandler,
  setScrollStyle,
}) => {
  const upNumber = useMemo(
    () => (current + step <= max ? current + step : 0),
    [current, max, step]
  );

  const downNumber = useMemo(
    () => (current - step >= min ? current - step : 0),
    [current, min, step]
  );

  const clickHandlerUp = useCallback(() => {
    if (active && upNumber !== 0 && current <= max) {
      changeHandler(upNumber);
      setScrollStyle && setScrollStyle("transformToRight");
    }
  }, [changeHandler, active, current, max, upNumber, setScrollStyle]);

  const clickHandlerDown = useCallback(() => {
    if (active && downNumber !== 0 && current >= min) {
      changeHandler(downNumber);
      setScrollStyle && setScrollStyle("transformToLeft");
    }
  }, [changeHandler, active, current, min, downNumber, setScrollStyle]);

  return (
    <div
      className={`${styles.rangeNumbers} ${!active ? styles.notActive : ""}`}
      data-testid={"rangeNumbersSimple"}
    >
      <div className={styles.currentNumber} data-testid="currentNumber">
        <span>
          <FormattedNumber value={current} /> €
        </span>
      </div>

      <div
        data-testid="arrow-prev"
        className={`${styles.prev} ${arrowClass} ${
          current === min ? styles.disabled : ""
        }`}
        {...(current === min ? null : { onClick: clickHandlerDown })}
      >
        <RangeDownIcon className={styles.arrowDownIcon} />
      </div>

      <div
        data-testid="arrow-next"
        className={`${styles.next} ${arrowClass} ${
          current === max ? styles.disabled : ""
        }`}
        {...(current === max ? null : { onClick: clickHandlerUp })}
      >
        <RangeUpIcon className={styles.arrowDownIcon} />
      </div>
    </div>
  );
};

export default RangeNumbersSimple;

import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import TitleLine from "../../components/TitleLine/TitleLine";
import ContentBox from "../../components/ContentBox/ContentBox";
import BlockContainer from "../../components/BlockContainer/BlockContainer";
import Attachment from "../../components/Attachment/Attachment";
import Button from "../../components/Button/Button";
import LoaderComponent from "../../components/LoaderComponent/LoaderComponent";
import MainWrapper from "../../containers/MainWrapper/MainWrapper";
import { downloadsSelector, useAppDispatch } from "../../redux/selectors";
import { API_SERVER, IS_RVN_BRAND } from "../../variables_config";
import { fetchCatigories } from "../../redux/reducers/downloadsSlice";
import { DownloadLink } from "../../redux/reducers/downloadsSlice.d";
import styles from "./Downloads.module.css";
import { ReactComponent as DownloadIcon } from "../../images/export-icon.svg";
import { ReactComponent as CloseIcon } from "../../images/close-round-icon.svg";

const getFormatedDate = (date: Date) => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
};

const Downloads = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { categories } = useSelector(downloadsSelector);

  useEffect(() => {
    dispatch(fetchCatigories());
  }, [dispatch]);

  return (
    <MainWrapper>
      <BlockContainer>
        <div className={styles.blockContainer}>
          <div className={styles.titleContainer}>
            <TitleLine className={styles.title}>Downloads</TitleLine>

            <Button
              type="icon"
              icon={CloseIcon}
              className={styles.closeBtn}
              clickHandler={() => navigate("/")}
            />
          </div>

          <h3 className={styles.subTitle}>
            Hier findest du alle wichtigen Informationen zu deinen{" "}
            {IS_RVN_BRAND ? "R+V-CashProtect" : "Schutzbriefen"}:
          </h3>

          {!categories ? (
            <div className={styles.noContent}>
              <LoaderComponent />
            </div>
          ) : categories.length ? (
            categories.map((item, i) => (
              <ContentBox key={i + 1} className={styles.linksContainer}>
                <h3 className={styles.linkTitle}>{item.category}</h3>

                <ul className={styles.linksUl}>
                  {item.links.map((link: DownloadLink, j: number) => (
                    <li key={j + 1}>
                      {link?.secure ? (
                        <Attachment
                          link={`${API_SERVER}upload/download/${link.link}`}
                          fileName={`${link.title}${
                            link?.createdAt
                              ? format(
                                  getFormatedDate(new Date(link?.createdAt)),
                                  "dd.MM.yyyy HH:mm"
                                )
                              : ""
                          }.pdf`}
                          className={styles.link}
                        >
                          <DownloadIcon className={styles.icon} />
                          <div className={styles.linkContent}>
                            {link.title}{" "}
                            {link?.createdAt
                              ? format(
                                  getFormatedDate(new Date(link?.createdAt)),
                                  "dd.MM.yyyy HH:mm"
                                )
                              : null}
                          </div>
                        </Attachment>
                      ) : (
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={`${API_SERVER}${link.link}`}
                          className={styles.link}
                        >
                          <DownloadIcon className={styles.icon} />
                          <div className={styles.linkContent}>{link.title}</div>
                        </a>
                      )}
                    </li>
                  ))}
                </ul>
              </ContentBox>
            ))
          ) : (
            <div>
              <h3>No documents</h3>
            </div>
          )}
        </div>
      </BlockContainer>
    </MainWrapper>
  );
};

export default Downloads;

import {
  AdditionalProtocolAnswers,
  AdditionalProtocolAnswer,
} from "./AdditionalFormProtocol.d";

export const getSelectedAnswer = (
  answers: AdditionalProtocolAnswers,
  key: string,
  value: number
) => {
  if (key in answers) {
    return answers[key].includes(value);
  }

  return false;
};

export const excludedInnerAnswers = (
  answers: AdditionalProtocolAnswers,
  excludeKeyPart: string
) => {
  const excluded = Object.keys(answers)
    .filter(key => !key.includes(excludeKeyPart, 0))
    .reduce((acc, currKey) => ({ ...acc, [currKey]: answers[currKey] }), {});

  return excluded;
};

export const hasUnfilledAnswers = (questions: any, answersKeys: string[]) => {
  return Object.keys(questions).some(key => !answersKeys.includes(key));
};

export const isFilledAnswers = (
  questions: any,
  selectedAnswers: AdditionalProtocolAnswers
): boolean => {
  if (!questions) {
    return true;
  }

  const selectedAnswersKeys = Object.keys(selectedAnswers);
  const isUnfilled = hasUnfilledAnswers(questions, selectedAnswersKeys);

  if (isUnfilled) {
    return false;
  }

  const selectedQuestionsKeys = Object.keys(questions).filter(key =>
    selectedAnswersKeys.includes(key)
  );

  const isAllFilled = selectedQuestionsKeys.every(key => {
    const selectedAnswer = questions[key].answers.find(
      (answer: AdditionalProtocolAnswer) =>
        selectedAnswers[key].includes(answer.value)
    );

    if (selectedAnswer && selectedAnswer.then?.questions) {
      return isFilledAnswers(selectedAnswer.then?.questions, selectedAnswers);
    }

    return true;
  });

  return isAllFilled;
};

import React from "react";
import { Form, Formik } from "formik";
import Button from "../../../components/Button/Button";
import InputField from "../../../components/InputField/InputField";
import { validateEmail } from "../../../utils/formHelper";
import {
  clearError,
  fetchUserEmailChangeSendCode,
} from "../../../redux/reducers/authenticationSlice";
import { EmailFormValues } from "../../../redux/reducers/profileSlice.d";
import { EmailFormComponentProps } from "../ProfilePage.d";
import { useAppDispatch } from "../../../redux/selectors";

const initialEmailValues: EmailFormValues = {
  email: "",
};

const EmailFormComponent: React.FC<EmailFormComponentProps> = ({
  currentEmail,
  styles,
}) => {
  const dispatch = useAppDispatch();

  const onSubmit = (
    values: EmailFormValues,
    { resetForm }: { resetForm: () => void }
  ) => {
    resetForm();
    dispatch(clearError(null));
    dispatch(fetchUserEmailChangeSendCode(values));
  };

  return (
    <Formik
      {...{
        initialValues: initialEmailValues,
        onSubmit,
      }}
      enableReinitialize
    >
      {({ errors, touched }) => (
        <Form className={styles.form} noValidate>
          <div className={styles.requiredInfo}>
            Die mit * gekennzeichneten Daten sind Pflichtfelder
          </div>
          <div className={styles.formPaddingBottom}>
            <InputField
              id="email"
              name="email"
              type="text"
              placeholder={currentEmail}
              labelText="Deine E-Mail Adresse"
              hasErrors={
                errors.hasOwnProperty("email") &&
                touched.hasOwnProperty("email")
              }
              validateHandler={validateEmail}
              containerClass={styles.formGroupInline}
              labelClass={styles.label}
              inputClass={styles.input}
            />
          </div>

          <div className={styles.btnContainer}>
            <Button type="submit" variant="secondary" text="Speichern" />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EmailFormComponent;

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import MainWrapper from "../../containers/MainWrapper/MainWrapper";
import BlockContainer from "../../components/BlockContainer/BlockContainer";
import styles from "./DatenschutzPage.module.css";

const DatenschutzPage = () => {
  return (
    <MainWrapper>
      <BlockContainer fixed={true}>
        <div className={styles.block}>
          <p>Allgemeine Schutzbrief Service GmbH</p>
          <h1 className={styles.h1}>Datenschutzhinweise</h1>

          <p>
            Diese Datenschutzhinweise klären Dich über die Art, den Umfang und
            Zweck der Verarbeitung von personenbezogenen Daten innerhalb unseres
            Onlineangebotes “Cash & Protect Portal” und der mit ihm verbundenen
            Webseiten, Funktionen und Inhalte sowie externen Onlinepräsenzen,
            wie z. B. unser Social Media Profile auf (nachfolgend gemeinsam
            bezeichnet als „Onlineangebot“) auf.
          </p>

          <p>
            Die Verarbeitung personenbezogener Daten, beispielsweise des Namens,
            der Anschrift, E-Mail-Adresse oder Telefonnummer einer betroffenen
            Person, erfolgt stets im Einklang mit der
            Datenschutz-Grundverordnung und in Übereinstimmung mit den für die
            Allgemeine Schutzbrief Service GmbH (ASSG) geltenden
            landesspezifischen Datenschutzbestimmungen. Mittels dieser
            Datenschutzerklärung möchte unser Unternehmen die Öffentlichkeit
            über Art, Umfang und Zweck der von uns erhobenen, genutzten und
            verarbeiteten personenbezogenen Daten informieren. Ferner werden
            betroffene Personen mittels dieser Datenschutzerklärung über die
            ihnen zustehenden Rechte aufgeklärt.
          </p>

          <p>
            Die ASSG hat als für die Verarbeitung Verantwortlicher zahlreiche
            technische und organisatorische Maßnahmen umgesetzt, um einen
            möglichst lückenlosen Schutz der über diese Internetseite
            verarbeiteten personenbezogenen Daten sicherzustellen. Dennoch
            können internetbasierte Datenübertragungen grundsätzlich
            Sicherheitslücken aufweisen, sodass ein absoluter Schutz nicht
            gewährleistet werden kann. Aus diesem Grund steht es jeder
            betroffenen Person frei, personenbezogene Daten auch auf
            alternativen Wegen, beispielsweise telefonisch, an uns zu
            übermitteln.
          </p>

          <h2 className={styles.h2}>Inhaltsübersicht</h2>

          <Accordion allowZeroExpanded={true}>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  1. Begriffsbestimmungen
                </AccordionItemButton>
              </AccordionItemHeading>

              <AccordionItemPanel>
                <p>
                  Die Datenschutzerklärung der ASSG beruht auf den
                  Begrifflichkeiten, die durch den Europäischen Richtlinien- und
                  Verordnungsgeber beim Erlass der Datenschutz-Grundverordnung
                  (DS-GVO) verwendet wurden. Unsere Datenschutzerklärung soll
                  sowohl für die Öffentlichkeit als auch für unsere Kunden und
                  Geschäftspartner einfach lesbar und verständlich sein. Um dies
                  zu gewährleisten, möchten wir vorab die verwendeten
                  Begrifflichkeiten erläutern. Wir verwenden in dieser
                  Datenschutzerklärung unter anderem die folgenden Begriffe:
                </p>

                <h4 className={styles.h4}>Personenbezogene Daten</h4>

                <p>
                  Personenbezogene Daten sind alle Informationen, die sich auf
                  eine identifizierte oder identifizierbare natürliche Person
                  (im Folgenden „betroffene Person“) beziehen. Als
                  identifizierbar wird eine natürliche Person angesehen, die
                  direkt oder indirekt, insbesondere mittels Zuordnung zu einer
                  Kennung wie einem Namen, zu einer Kennnummer, zu
                  Standortdaten, zu einer Online-Kennung oder zu einem oder
                  mehreren besonderen Merkmalen, die Ausdruck der physischen,
                  physiologischen, genetischen, psychischen, wirtschaftlichen,
                  kulturellen oder sozialen Identität dieser natürlichen Person
                  sind, identifiziert werden kann.
                </p>

                <h4 className={styles.h4}>Betroffene Person</h4>

                <p>
                  Betroffene Person ist jede identifizierte oder
                  identifizierbare natürliche Person, deren personenbezogene
                  Daten, von dem für die Verarbeitung Verantwortlichen
                  verarbeitet werden.
                </p>

                <p>
                  Verarbeitung ist jeder mit oder ohne Hilfe automatisierter
                  Verfahren ausgeführte Vorgang oder jede solche Vorgangsweise
                  im Zusammenhang mit personenbezogenen Daten wie das Erheben,
                  das Erfassen, die Organisation, das Ordnen, die Speicherung,
                  die Anpassung oder Veränderung, das Auslesen, das Abfragen,
                  die Verwendung, die Offenlegung durch Übermittlung,
                  Verbreitung oder eine andere Form der Bereitstellung, den
                  Abgleich oder die Verknüpfung, die Einschränkung, das Löschen
                  oder die Vernichtung.
                </p>

                <h4 className={styles.h4}>Einschränkung der Verarbeitung</h4>

                <p>
                  Einschränkung der Verarbeitung ist die Markierung
                  gespeicherter personenbezogener Daten mit dem Ziel, ihre
                  künftige Verarbeitung einzuschränken.
                </p>

                <p>
                  Profiling ist jede Art der automatisierten Verarbeitung
                  personbezogener Daten, die darin besteht, dass diese
                  personenbezogenen Daten verwendet werden, um bestimmte
                  persönliche Aspekte, die sich auf eine natürliche Person
                  beziehen, zu bewerten, insbesondere, um Aspekte bezüglich
                  Arbeitsleistung, wirtschaftlicher Lage, Gesundheit,
                  persönlicher Vorlieben, Interessen, Zuverlässigkeit,
                  Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen
                  Person zu analysieren oder vorherzusagen.
                </p>

                <h4 className={styles.h4}>Pseudonymisierung</h4>

                <p>
                  Pseudonymisierung ist die Verarbeitung personenbezogener Daten
                  in einer Weise, auf welche die personenbezogenen Daten ohne
                  Hinzuziehung zusätzlicher Informationen nicht mehr einer
                  spezifischen betroffenen Person zugeordnet werden können,
                  sofern diese zusätzlichen Informationen gesondert aufbewahrt
                  werden und technischen und organisatorischen Maßnahmen
                  unterliegen, die gewährleisten, dass die personenbezogenen
                  Daten nicht einer identifizierten oder identifizierbaren
                  natürlichen Person zugewiesen werden.
                </p>

                <h4 className={styles.h4}>
                  Verantwortlicher oder für die Verarbeitung Verantwortlicher
                </h4>

                <p>
                  Verantwortlicher oder für die Verarbeitung Verantwortlicher
                  ist die natürliche oder juristische Person, Behörde,
                  Einrichtung oder andere Stelle, die allein oder gemeinsam mit
                  anderen über die Zwecke und Mittel der Verarbeitung von
                  personenbezogenen Daten entscheidet. Sind die Zwecke und
                  Mittel dieser Verarbeitung durch das Unionsrecht oder das
                  Recht der Mitgliedstaaten vorgegeben, so kann der
                  Verantwortliche beziehungsweise können die bestimmten
                  Kriterien seiner Benennung nach dem Unionsrecht oder dem Recht
                  der Mitgliedstaaten vorgesehen werden.
                </p>

                <h4 className={styles.h4}>Auftragsverarbeiter</h4>

                <p>
                  Auftragsverarbeiter ist eine natürliche oder juristische
                  Person, Behörde, Einrichtung oder andere Stelle, die
                  personenbezogene Daten im Auftrag des Verantwortlichen
                  verarbeitet.
                </p>

                <p>
                  Empfänger ist eine natürliche oder juristische Person,
                  Behörde, Einrichtung oder andere Stelle, der personenbezogene
                  Daten offengelegt werden, unabhängig davon, ob es sich bei ihr
                  um einen Dritten handelt oder nicht. Behörden, die im Rahmen
                  eines bestimmten Untersuchungsauftrags nach dem Unionsrecht
                  oder dem Recht der Mitgliedstaaten möglicherweise
                  personen­bezogene Daten erhalten, gelten jedoch nicht als
                  Empfänger.
                </p>

                <h4 className={styles.h4}>Dritter</h4>

                <p>
                  Dritter ist eine natürliche oder juristische Person, Behörde,
                  Einrichtung oder andere Stelle außer der betroffenen Person,
                  dem Verantwortlichen, dem Auftragsverarbeiter und den
                  Personen, die unter der unmittelbaren Verantwortung des
                  Verantwortlichen oder des Auftragsverarbeiters befugt sind,
                  die personenbezogenen Daten zu verarbeiten.
                </p>

                <p>
                  Einwilligung ist jede von der betroffenen Person freiwillig
                  für den bestimmten Fall in informierter Weise und
                  unmissverständlich abgegebene Willensbekundung in Form einer
                  Erklärung oder einer sonstigen eindeutigen bestätigenden
                  Handlung, mit der die betroffene Person zu verstehen gibt,
                  dass sie mit der Verarbeitung der sie betreffenden
                  personenbezogenen Daten einverstanden ist.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  2. Name und Anschrift des für die Verarbeitung
                  Verantwortlichen
                </AccordionItemButton>
              </AccordionItemHeading>

              <AccordionItemPanel>
                <p>
                  Verantwortlicher im Sinne der Datenschutz-Grundverordnung
                  (DSGVO), sonstiger in den Mitgliedstaaten der Europäischen
                  Union geltenden Datenschutzgesetze und anderer Bestimmungen
                  mit datenschutzrechtlichem Charakter ist die:
                </p>
                <p>
                  Allgemeine Schutzbrief Service GmbH
                  <br />
                  Kemmannsweg 13
                  <br />
                  45219 Essen
                  <br />
                  Deutschland
                </p>
                <p>
                  E-Mail: info@allgemeine-schutzbrief.de
                  <br />
                  Website: www.allgemeine-schutzbrief.de
                </p>
                <p>
                  E-Mail des Datenschutzbeauftragten:
                  <br />
                  datenschutz@allgemeine-schutzbrief.de
                </p>
                <p>
                  Jede betroffene Person kann sich jederzeit bei allen Fragen
                  und Anregungen zum Datenschutz direkt an unseren
                  Datenschutzbeauftragten wenden.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>3. Cookies</AccordionItemButton>
              </AccordionItemHeading>

              <AccordionItemPanel>
                <p>
                  Die Internetseiten der ASSG verwenden Cookies. Cookies sind
                  Textdateien, welche über einen Internetbrowser auf einem
                  Computersystem abgelegt und gespeichert werden.
                </p>
                <p>
                  Zahlreiche Internetseiten und Server verwenden Cookies. Viele
                  Cookies enthalten eine sogenannte Cookie-ID. Eine Cookie-ID
                  ist eine eindeutige Kennung des Cookies. Sie besteht aus einer
                  Zeichenfolge, durch welche Internetseiten und Server dem
                  konkreten Internetbrowser zugeordnet werden können, in dem das
                  Cookie gespeichert wurde. Dies ermöglicht es den besuchten
                  Internetseiten und Servern, den individuellen Browser der
                  betroffenen Person von anderen Internetbrowsern, die andere
                  Cookies enthalten, zu unterscheiden. Ein bestimmter
                  Internetbrowser kann über die eindeutige Cookie-ID
                  wiedererkannt und identifiziert werden.
                </p>
                <p>
                  Durch den Einsatz von Cookies kann die ASSG den Nutzern dieser
                  Internetseite nutzerfreundlichere Services bereitstellen, die
                  ohne die Cookie-Setzung nicht möglich wären.
                </p>
                <p>
                  Mittels eines Cookies können die Informationen und Angebote
                  auf unserer Internetseite im Sinne des Benutzers optimiert
                  werden. Cookies ermöglichen uns, wie bereits erwähnt, die
                  Benutzer unserer Internetseite wiederzuerkennen. Zweck dieser
                  Wiedererkennung ist es, den Nutzern die Verwendung unserer
                  Internetseite zu erleichtern. Der Benutzer einer
                  Internetseite, die Cookies verwendet, muss beispielsweise
                  nicht bei jedem Besuch der Internetseite erneut seine
                  Zugangsdaten eingeben, weil dies von der Internetseite und dem
                  auf dem Computersystem des Benutzers abgelegten Cookie
                  übernommen wird. Ein weiteres Beispiel ist das Cookie eines
                  Warenkorbes im Online-Shop. Der Online-Shop merkt sich die
                  Artikel, die ein Kunde in den virtuellen Warenkorb gelegt hat,
                  über ein Cookie.
                </p>
                <p>
                  Die betroffene Person kann die Setzung von Cookies durch
                  unsere Internetseite jederzeit mittels einer entsprechenden
                  Einstellung des genutzten Internetbrowsers verhindern und
                  damit der Setzung von Cookies dauerhaft widersprechen. Ferner
                  können bereits gesetzte Cookies jederzeit über einen
                  Internetbrowser oder andere Softwareprogramme gelöscht werden.
                  Dies ist in allen gängigen Internetbrowsern möglich.
                  Deaktiviert die betroffene Person die Setzung von Cookies in
                  dem genutzten Internetbrowser, sind unter Umständen nicht alle
                  Funktionen unserer Internetseite vollumfänglich nutzbar.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  4. Erfassung von allgemeinen Daten und Informationen
                </AccordionItemButton>
              </AccordionItemHeading>

              <AccordionItemPanel>
                <p>
                  Die Internetseite der ASSG erfasst mit jedem Aufruf der
                  Internetseite durch eine betroffene Person oder ein
                  automatisiertes System eine Reihe von allgemeinen Daten und
                  Informationen. Diese allgemeinen Daten und Informationen
                  werden in den Logfiles des Servers gespeichert. Erfasst werden
                  können die (1) verwendeten Browsertypen und Versionen, (2) das
                  vom zugreifenden System verwendete Betriebssystem, (3) die
                  Internetseite, von welcher ein zugreifendes System auf unsere
                  Internetseite gelangt (sogenannte Referrer), (4) die
                  Unterwebseiten, welche über ein zugreifendes System auf
                  unserer Internetseite angesteuert werden, (5) das Datum und
                  die Uhrzeit eines Zugriffs auf die Internetseite, (6) eine
                  Internet-Protokoll-Adresse (IP-Adresse), (7) der
                  Internet-Service-Provider des zugreifenden Systems und (8)
                  sonstige ähnliche Daten und Informationen, die der
                  Gefahrenabwehr im Falle von Angriffen auf unsere
                  informationstechnologischen Systeme dienen.
                </p>
                <p>
                  Bei der Nutzung dieser allgemeinen Daten und Informationen
                  zieht die ASSG keine Rückschlüsse auf die betroffene Person.
                  Diese Informationen werden vielmehr benötigt, um (1) die
                  Inhalte unserer Internetseite korrekt auszuliefern, (2) die
                  Inhalte unserer Internetseite sowie die Werbung für diese zu
                  optimieren, (3) die dauerhafte Funktionsfähigkeit unserer
                  informationstechnologischen Systeme und der Technik unserer
                  Internetseite zu gewährleisten sowie (4) um
                  Strafverfolgungsbehörden im Falle eines Cyberangriffes die zur
                  Strafverfolgung notwendigen Informationen bereitzustellen.
                </p>
                <p>
                  Diese anonym erhobenen Daten und Informationen werden durch
                  die ASSG daher einerseits statistisch und ferner mit dem Ziel
                  ausgewertet, den Datenschutz und die Datensicherheit in
                  unserem Unternehmen zu erhöhen, um letztlich ein optimales
                  Schutzniveau für die von uns verarbeiteten personenbezogenen
                  Daten sicherzustellen. Die anonymen Daten der Server-Logfiles
                  werden getrennt von allen durch eine betroffene Person
                  angegebenen personenbezogenen Daten gespeichert.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  5. Registrierung auf unserer Internetseite
                </AccordionItemButton>
              </AccordionItemHeading>

              <AccordionItemPanel>
                <p>
                  Die betroffene Person hat die Möglichkeit, sich auf der
                  Internetseite des für die Verarbeitung Verantwortlichen unter
                  Angabe von personenbezogenen Daten zu registrieren. Welche
                  personenbezogenen Daten dabei an den für die Verarbeitung
                  Verantwortlichen übermittelt werden, ergibt sich aus der
                  jeweiligen Eingabemaske, die für die Registrierung verwendet
                  wird. Die von der betroffenen Person eingegebenen
                  personenbezogenen Daten werden ausschließlich für die interne
                  Verwendung bei dem für die Verarbeitung Verantwortlichen und
                  für eigene Zwecke erhoben und gespeichert. Der für die
                  Verarbeitung Verantwortliche kann die Weitergabe an einen oder
                  mehrere Auftragsverarbeiter, beispielsweise einen
                  Paketdienstleister, veranlassen, der die personenbezogenen
                  Daten ebenfalls ausschließlich für eine interne Verwendung,
                  die dem für die Verarbeitung Verantwortlichen zuzurechnen ist,
                  nutzt.
                </p>
                <p>
                  Durch eine Registrierung auf der Internetseite des für die
                  Verarbeitung Verantwortlichen wird ferner die vom
                  Internet-Service-Provider (ISP) der betroffenen Person
                  vergebene IP-Adresse, das Datum sowie die Uhrzeit der
                  Registrierung gespeichert. Die Speicherung dieser Daten
                  erfolgt vor dem Hintergrund, dass nur so der Missbrauch
                  unserer Dienste verhindert werden kann und diese Daten im
                  Bedarfsfall ermöglichen, begangene Straftaten aufzuklären.
                  Insofern ist die Speicherung dieser Daten zur Absicherung des
                  für die Verarbeitung Verantwortlichen erforderlich. Eine
                  Weitergabe dieser Daten an Dritte erfolgt grundsätzlich nicht,
                  sofern keine gesetzliche Pflicht zur Weitergabe besteht oder
                  die Weitergabe der Strafverfolgung dient.
                </p>
                <p>
                  Die Registrierung der betroffenen Person unter freiwilliger
                  Angabe personenbezogener Daten dient dem für die Verarbeitung
                  Verantwortlichen dazu, der betroffenen Person Inhalte oder
                  Leistungen anzubieten, die aufgrund der Natur der Sache nur
                  registrierten Benutzern angeboten werden können. Registrierten
                  Personen steht die Möglichkeit frei, die bei der Registrierung
                  angegebenen personenbezogenen Daten jederzeit abzuändern oder
                  vollständig aus dem Datenbestand des für die Verarbeitung
                  Verantwortlichen löschen zu lassen.
                </p>
                <p>
                  Der für die Verarbeitung Verantwortliche erteilt jeder
                  betroffenen Person jederzeit auf Anfrage Auskunft darüber,
                  welche personenbezogenen Daten über die betroffene Person
                  gespeichert sind. Ferner berichtigt oder löscht der für die
                  Verarbeitung Verantwortliche personenbezogene Daten auf Wunsch
                  oder Hinweis der betroffenen Person, soweit dem keine
                  gesetzlichen Aufbewahrungspflichten entgegenstehen. Die
                  Gesamtheit der Mitarbeiter des für die Verarbeitung
                  Verantwortlichen stehen der betroffenen Person in diesem
                  Zusammenhang als Ansprechpartner zur Verfügung.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  6. Weitergabe von persönlichen Daten
                </AccordionItemButton>
              </AccordionItemHeading>

              <AccordionItemPanel>
                <p>
                  Im Rahmen der Teilnahme am Cashback-Programm werden
                  persönliche Daten ohne gesonderte Einwilligung nur insoweit
                  verwendet, wie es für die Erbringung der Dienstleistung
                  notwendig ist.
                </p>
                <p>
                  Zur Abwicklung von Auszahlungen der Cashback-Beträge werden
                  Daten an jene Bank weitergegeben, die von der ASSG mit der
                  Auszahlung beauftrag wird.
                </p>
                <p>
                  Im Falle einer Reklamation oder Nachbuchungsanfrage werden im
                  Einzelfall auf das Verlangen des Betroffenen persönliche Daten
                  zum Zwecke der Kontrolle des Einkaufs und Cashback-Erfassung
                  an die jeweiligen Affiliate-Netzwerke, Marketing-Agenturen und
                  Online-Shop-Betreiber weitergegeben.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  7. Abonnement unseres Newsletters
                </AccordionItemButton>
              </AccordionItemHeading>

              <AccordionItemPanel>
                <p>
                  Auf der Internetseite der ASSG wird den Benutzern die
                  Möglichkeit eingeräumt, den Newsletter unseres Unternehmens zu
                  abonnieren. Welche personenbezogenen Daten bei der Bestellung
                  des Newsletters, an den für die Verarbeitung Verantwortlichen
                  übermittelt werden, ergibt sich aus der hierzu verwendeten
                  Eingabemaske.
                </p>
                <p>
                  Die ASSG informiert ihre Kunden und Geschäftspartner in
                  regelmäßigen Abständen im Wege eines Newsletters über Angebote
                  des Unternehmens. Der Newsletter unseres Unternehmens kann von
                  der betroffenen Person grundsätzlich nur dann empfangen
                  werden, wenn (1) die betroffene Person über eine gültige
                  E-Mail-Adresse verfügt und (2) die betroffene Person sich für
                  den Newsletter Versand registriert. An die von einer
                  betroffenen Person erstmalig für den Newsletter Versand
                  eingetragene E-Mail-Adresse wird aus rechtlichen Gründen eine
                  Bestätigungsmail im Double-Opt-In-Verfahren versendet. Diese
                  Bestätigungsmail dient der Überprüfung, ob der Inhaber der
                  E-Mail-Adresse als betroffene Person den Empfang des
                  Newsletters autorisiert hat.
                </p>
                <p>
                  Bei der Anmeldung zum Newsletter speichern wir ferner die vom
                  Internet-Service-Provider (ISP) vergebene IP-Adresse des von
                  der betroffenen Person zum Zeitpunkt der Anmeldung verwendeten
                  Computersystems sowie das Datum und die Uhrzeit der Anmeldung.
                  Die Erhebung dieser Daten ist erforderlich, um den (möglichen)
                  Missbrauch der E-Mail-Adresse einer betroffenen Person zu
                  einem späteren Zeitpunkt nachvollziehen zu können und dient
                  deshalb der rechtlichen Absicherung des für die Verarbeitung
                  Verantwortlichen.
                </p>
                <p>
                  Die im Rahmen einer Anmeldung zum Newsletter erhobenen
                  personenbezogenen Daten werden ausschließlich zum Versand
                  unseres Newsletters verwendet. Ferner könnten Abonnenten des
                  Newsletters per E-Mail informiert werden, sofern dies für den
                  Betrieb des Newsletter-Dienstes oder eine diesbezügliche
                  Registrierung erforderlich ist, wie dies im Falle von
                  Änderungen am Newsletter-Angebot oder bei der Veränderung der
                  technischen Gegebenheiten der Fall sein könnte. Es erfolgt
                  keine Weitergabe der im Rahmen des Newsletter-Dienstes
                  erhobenen personebezogenen Daten an Dritte. Das Abonnement
                  unseres Newsletters kann durch die betroffene Person jederzeit
                  gekündigt werden. Die Einwilligung in die Speicherung
                  personenbezogener Daten, die die betroffene Person uns für den
                  Newsletter Versand erteilt hat, kann jederzeit widerrufen
                  werden. Zum Zwecke des Widerrufs der Einwilligung findet sich
                  in jedem Newsletter ein entsprechender Link. Ferner besteht
                  die Möglichkeit, sich jederzeit auch direkt auf der
                  Internetseite des für die Verarbeitung Verantwortlichen vom
                  Newsletter Versand abzumelden oder dies dem für die
                  Verarbeitung Verantwortlichen auf andere Weise mitzuteilen.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  8. Newsletter-Tracking
                </AccordionItemButton>
              </AccordionItemHeading>

              <AccordionItemPanel>
                <p>
                  Die Newsletter der ASSG enthalten sogenannte Zählpixel. Ein
                  Zählpixel ist eine Miniaturgrafik, die in solche E-Mails
                  eingebettet wird, welche im HTML-Format versendet werden, um
                  eine Logdatei-Aufzeichnung und eine Logdatei-Analyse zu
                  ermöglichen. Dadurch kann eine statistische Auswertung des
                  Erfolges oder Misserfolges von Online-Marketing-Kampagnen
                  durchgeführt werden. Anhand des eingebetteten Zählpixels kann
                  die ASSG erkennen, ob und wann eine E-Mail von einer
                  betroffenen Person geöffnet wurde und welche in der E-Mail
                  befindlichen Links von der betroffenen Person aufgerufen
                  wurden.
                </p>
                <p>
                  Solche über die in den Newslettern enthaltenen Zählpixel
                  erhobenen personenbezogenen Daten, werden von dem für die
                  Verarbeitung Verantwortlichen gespeichert und ausgewertet, um
                  den Newsletter Versand zu optimieren und den Inhalt
                  zukünftiger Newsletter noch besser den Interessen der
                  betroffenen Person anzupassen. Diese personenbezogenen Daten
                  werden nicht an Dritte weitergegeben. Betroffene Personen sind
                  jederzeit berechtigt, die diesbezügliche gesonderte, über das
                  Double-Opt-In-Verfahren abgegebene Einwilligungserklärung zu
                  widerrufen. Nach einem Widerruf werden diese personenbezogenen
                  Daten von dem für die Verarbeitung Verantwortlichen gelöscht.
                  Eine Abmeldung vom Erhalt des Newsletters deutet die ASSG
                  automatisch als Widerruf.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  9. Kontaktmöglichkeit über die Internetseite
                </AccordionItemButton>
              </AccordionItemHeading>

              <AccordionItemPanel>
                <p>
                  Die Internetseite der ASSG enthält aufgrund von gesetzlichen
                  Vorschriften Angaben, die eine schnelle elektronische
                  Kontaktaufnahme zu unserem Unternehmen sowie eine unmittelbare
                  Kommunikation mit uns ermöglichen, was ebenfalls eine
                  allgemeine Adresse der sogenannten elektronischen Post
                  (E-Mail-Adresse) umfasst. Sofern eine betroffene Person per
                  E-Mail oder über ein Kontaktformular den Kontakt mit dem für
                  die Verarbeitung Verantwortlichen aufnimmt, werden die von der
                  betroffenen Person übermittelten personenbezogenen Daten
                  automatisch gespeichert. Solche auf freiwilliger Basis von
                  einer betroffenen Person an den für die Verarbeitung
                  Verantwortlichen übermittelten personenbezogenen Daten werden
                  für Zwecke der Bearbeitung oder der Kontaktaufnahme zur
                  betroffenen Person gespeichert. Es erfolgt keine Weitergabe
                  dieser personenbezogenen Daten an Dritte.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  10. Routinemäßige Löschung und Sperrung von personenbezogenen
                  Daten
                </AccordionItemButton>
              </AccordionItemHeading>

              <AccordionItemPanel>
                <p>
                  Der für die Verarbeitung Verantwortliche verarbeitet und
                  speichert personenbezogene Daten der betroffenen Person nur
                  für den Zeitraum, der zur Erreichung des Speicherungszwecks
                  erforderlich ist oder sofern dies durch den Europäischen
                  Richtlinien- und Verordnungsgeber oder einen anderen
                  Gesetzgeber in Gesetzen oder Vorschriften, welchen der für die
                  Verarbeitung Verantwortliche unterliegt, vorgesehen wurde.
                </p>
                <p>
                  Entfällt der Speicherungszweck oder läuft eine vom
                  Europäischen Richtlinien- und Verordnungsgeber oder einem
                  anderen zuständigen Gesetzgeber vorgeschriebene Speicherfrist
                  ab, werden die personenbezogenen Daten routinemäßig und
                  entsprechend den gesetzlichen Vorschriften gesperrt oder
                  gelöscht.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  11. Rechte der betroffenen Person
                </AccordionItemButton>
              </AccordionItemHeading>

              <AccordionItemPanel>
                <h4 className={styles.h4}>a. Recht auf Bestätigung</h4>

                <p>
                  Jede betroffene Person hat das vom Europäischen Richtlinien-
                  und Verordnungsgeber eingeräumte Recht, von dem für die
                  Verarbeitung Verantwortlichen eine Bestätigung darüber zu
                  verlangen, ob sie betreffende personenbezogene Daten
                  verarbeitet werden. Möchte eine betroffene Person dieses
                  Bestätigungsrecht in Anspruch nehmen, kann sie sich hierzu
                  jederzeit an einen Mitarbeiter des für die Verarbeitung
                  Verantwortlichen wenden.
                </p>

                <h4 className={styles.h4}>b. Recht auf Auskunft</h4>

                <p>
                  Jede von der Verarbeitung personenbezogener Daten betroffene
                  Person hat das vom Europäischen Richtlinien- und
                  Verordnungsgeber gewährte Recht, jederzeit von dem für die
                  Verarbeitung Verantwortlichen unentgeltliche Auskunft über die
                  zu seiner Person gespeicherten personenbezogenen Daten und
                  eine Kopie dieser Auskunft zu erhalten. Ferner hat der
                  Europäische Richtlinien- und Verordnungsgeber der betroffenen
                  Person Auskunft über folgende Informationen zugestanden:
                </p>

                <ul>
                  <li>die Verarbeitungszwecke</li>
                  <li>
                    die Kategorien personenbezogener Daten, die verarbeitet
                    werden
                  </li>
                  <li>
                    die Empfänger oder Kategorien von Empfängern, gegenüber
                    denen die personenbezogenen Daten offengelegt worden sind
                    oder noch offengelegt werden, insbesondere bei Empfängern in
                    Drittländern oder bei internationalen Organisationen
                  </li>
                  <li>
                    falls möglich die geplante Dauer, für die die
                    personenbezogenen Daten gespeichert werden, oder, falls dies
                    nicht möglich ist, die Kriterien für die Festlegung dieser
                    Dauer
                  </li>
                  <li>
                    das Bestehen eines Rechts auf Berichtigung oder Löschung der
                    sie betreffenden personenbezogenen Daten oder auf
                    Einschränkung der Verarbeitung durch den Verantwortlichen
                    oder eines Widerspruchsrechts gegen diese Verarbeitung
                  </li>
                  <li>
                    das Bestehen eines Beschwerderechts bei einer
                    Aufsichtsbehörde
                  </li>
                  <li>
                    wenn die personenbezogenen Daten nicht bei der betroffenen
                    Person erhoben werden: Alle verfügbaren Informationen über
                    die Herkunft der Daten
                  </li>
                  <li>
                    das Bestehen einer automatisierten Entscheidungsfindung
                    einschließlich Profiling gemäß Artikel 22 Abs.1 und 4 DSGVO
                    und — zumindest in diesen Fällen — aussagekräftige
                    Informationen über die involvierte Logik sowie die Tragweite
                    und die angestrebten Auswirkungen einer derartigen
                    Verarbeitung für die betroffene Person
                  </li>
                  <li>
                    Ferner steht der betroffenen Person ein Auskunftsrecht
                    darüber zu, ob personenbezogene Daten an ein Drittland oder
                    an eine internationale Organisation übermittelt wurden.
                    Sofern dies der Fall ist, so steht der betroffenen Person im
                    Übrigen das Recht zu, Auskunft über die geeigneten Garantien
                    im Zusammenhang mit der Übermittlung zu erhalten.
                  </li>
                </ul>

                <p>
                  Möchte eine betroffene Person dieses Auskunftsrecht in
                  Anspruch nehmen, kann sie sich hierzu jederzeit an einen
                  Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.
                </p>

                <h4 className={styles.h4}>c. Recht auf Berichtigung</h4>

                <p>
                  Jede von der Verarbeitung personenbezogener Daten betroffene
                  Person hat das vom Europäischen Richtlinien- und
                  Verordnungsgeber gewährte Recht, die unverzügliche
                  Berichtigung sie betreffender unrichtiger personenbezogener
                  Daten zu verlangen. Ferner steht der betroffenen Person das
                  Recht zu, unter Berücksichtigung der Zwecke der Verarbeitung,
                  die Vervollständigung unvollständiger personenbezogener Daten
                  — auch mittels einer ergänzenden Erklärung — zu verlangen.
                </p>
                <p>
                  Möchte eine betroffene Person dieses Berichtigungsrecht in
                  Anspruch nehmen, kann sie sich hierzu jederzeit an einen
                  Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.
                </p>

                <h4 className={styles.h4}>
                  d. Recht auf Löschung (Recht auf Vergessenwerden)
                </h4>

                <p>
                  Jede von der Verarbeitung personenbezogener Daten betroffene
                  Person hat das vom Europäischen Richtlinien- und
                  Verordnungsgeber gewährte Recht, von dem Verantwortlichen zu
                  verlangen, dass die sie betreffenden personenbezogenen Daten
                  unverzüglich gelöscht werden, sofern einer der folgenden
                  Gründe zutrifft und soweit die Verarbeitung nicht erforderlich
                  ist:
                </p>

                <ul>
                  <li>
                    Die personenbezogenen Daten wurden für solche Zwecke erhoben
                    oder auf sonstige Weise verarbeitet, für welche sie nicht
                    mehr notwendig sind.
                  </li>
                  <li>
                    Die betroffene Person widerruft ihre Einwilligung, auf die
                    sich die Verarbeitung gemäß Art. 6 Abs. 1 Buchstabe a DSGVO
                    oder Art. 9 Abs. 2 Buchstabe a DSGVO stützte und es fehlt an
                    einer anderweitigen Rechtsgrundlage für die Verarbeitung.
                  </li>
                  <li>
                    Die betroffene Person legt gemäß Art. 21 Abs. 1 DSGVO
                    Widerspruch gegen die Verarbeitung ein und es liegen keine
                    vorrangigen berechtigten Gründe für die Verarbeitung vor,
                    oder die betroffene Person legt gemäß Art. 21 Abs. 2 DSGVO
                    Widerspruch gegen die Verarbeitung ein.
                  </li>
                  <li>
                    Die personenbezogenen Daten wurden unrechtmäßig verarbeitet.
                  </li>
                  <li>
                    Die Löschung der personenbezogenen Daten ist zur Erfüllung
                    einer rechtlichen Verpflichtung nach dem Unionsrecht oder
                    dem Recht der Mitgliedstaaten erforderlich, dem der
                    Verantwortliche unterliegt.
                  </li>
                  <li>
                    Die personenbezogenen Daten wurden in Bezug auf angebotene
                    Dienste der Informationsgesellschaft gemäß Art. 8 Abs. 1
                    DSGVO erhoben.
                  </li>
                  <li>
                    Sofern einer der oben genannten Gründe zutrifft und eine
                    betroffene Person die Löschung von personenbezogenen Daten,
                    die bei der ASSG gespeichert sind, veranlassen möchte, kann
                    sie sich hierzu jederzeit an einen Mitarbeiter des für die
                    Verarbeitung Verantwortlichen wenden. Der Mitarbeiter der
                    ASSG wird veranlassen, dass dem Löschverlangen unverzüglich
                    nachgekommen wird.
                  </li>
                </ul>

                <p>
                  Wurden die personenbezogenen Daten von der ASSG öffentlich
                  gemacht und ist unser Unternehmen als Verantwortlicher gemäß
                  Art. 17 Abs. 1 DSGVO zur Löschung der personenbezogenen Daten
                  verpflichtet, so trifft die ASSG unter Berücksichtigung der
                  verfügbaren Technologie und der Implementierungskosten
                  angemessene Maßnahmen, auch technischer Art, um andere für die
                  Datenverarbeitung Verantwortliche, welche die veröffentlichten
                  personenbezogenen Daten verarbeiten, darüber in Kenntnis zu
                  setzen, dass die betroffene Person von diesen anderen für die
                  Datenverarbeitung Verantwortlichen die Löschung sämtlicher
                  Links zu diesen personenbezogenen Daten oder von Kopien oder
                  Replikationen dieser personenbezogenen Daten verlangt hat,
                  soweit die Verarbeitung nicht erforderlich ist. Die ASSG wird
                  im Einzelfall das Notwendige veranlassen.
                </p>

                <h4 className={styles.h4}>
                  e. Recht auf Einschränkung der Verarbeitung
                </h4>

                <p>
                  Jede von der Verarbeitung personenbezogener Daten betroffene
                  Person hat das vom Europäischen Richtlinien- und
                  Verordnungsgeber gewährte Recht, von dem Verantwortlichen die
                  Einschränkung der Verarbeitung zu verlangen, wenn eine der
                  folgenden Voraussetzungen gegeben ist:
                </p>

                <ul>
                  <li>
                    Die Richtigkeit der personenbezogenen Daten wird von der
                    betroffenen Person bestritten, und zwar für eine Dauer, die
                    es dem Verantwortlichen ermöglicht, die Richtigkeit der
                    personenbezogenen Daten zu überprüfen.
                  </li>
                  <li>
                    Die Verarbeitung ist unrechtmäßig, die betroffene Person
                    lehnt die Löschung der personenbezogenen Daten ab und
                    verlangt stattdessen die Einschränkung der Nutzung der
                    personenbezogenen Daten.
                  </li>
                  <li>
                    Der Verantwortliche benötigt die personenbezogenen Daten für
                    die Zwecke der Verarbeitung nicht länger, die betroffene
                    Person benötigt sie jedoch zur Geltendmachung, Ausübung oder
                    Verteidigung von Rechtsansprüchen.
                  </li>
                  <li>
                    Die betroffene Person hat Widerspruch gegen die Verarbeitung
                    gem. Art. 21 Abs. 1 DSGVO eingelegt und es steht noch nicht
                    fest, ob die berechtigten Gründe des Verantwortlichen
                    gegenüber denen der betroffenen Person überwiegen.
                  </li>
                  <li>
                    Sofern eine der oben genannten Voraussetzungen gegeben ist
                    und eine betroffene Person die Einschränkung von
                    personenbezogenen Daten, die bei der ASSG gespeichert sind,
                    verlangen möchte, kann sie sich hierzu jederzeit an einen
                    Mitarbeiter des für die Verarbeitung Verantwortlichen
                    wenden. Die ASSG wird die Einschränkung der Verarbeitung
                    veranlassen.
                  </li>
                </ul>

                <p>
                  Zur Ausübung des Rechts auf Widerspruch kann sich die
                  betroffene Person direkt jeden Mitarbeiter der ASSG oder einen
                  anderen Mitarbeiter wenden. Der betroffenen Person steht es
                  ferner frei, im Zusammenhang mit der Nutzung von Diensten der
                  Informationsgesellschaft, ungeachtet der Richtlinie
                  2002/58/EG, ihr Widerspruchsrecht mittels automatisierter
                  Verfahren auszuüben, bei denen technische Spezifikationen
                  verwendet werden.
                </p>

                <h4 className={styles.h4}>
                  h. Automatisierte Entscheidungen im Einzelfall einschließlich
                  Profiling
                </h4>

                <p>
                  Jede von der Verarbeitung personenbezogener Daten betroffene
                  Person hat das vom Europäischen Richtlinien- und
                  Verordnungsgeber gewährte Recht, nicht einer ausschließlich
                  auf einer automatisierten Verarbeitung — einschließlich
                  Profiling — beruhenden Entscheidung unterworfen zu werden, die
                  ihr gegenüber rechtliche Wirkung entfaltet oder sie in
                  ähnlicher Weise erheblich beeinträchtigt, sofern die
                  Entscheidung (1) nicht für den Abschluss oder die Erfüllung
                  eines Vertrags zwischen der betroffenen Person und dem
                  Verantwortlichen erforderlich ist, oder (2) aufgrund von
                  Rechtsvorschriften der Union oder der Mitgliedstaaten, denen
                  der Verantwortliche unterliegt, zulässig ist und diese
                  Rechtsvorschriften angemessene Maßnahmen zur Wahrung der
                  Rechte und Freiheiten sowie der berechtigten Interessen der
                  betroffenen Person enthalten oder (3) mit ausdrücklicher
                  Einwilligung der betroffenen Person erfolgt.
                </p>
                <p>
                  Ist die Entscheidung (1) für den Abschluss oder die Erfüllung
                  eines Vertrags zwischen der betroffenen Person und dem
                  Verantwortlichen erforderlich oder (2) erfolgt sie mit
                  ausdrücklicher Einwilligung der betroffenen Person, trifft die
                  ASSG angemessene Maßnahmen, um die Rechte und Freiheiten sowie
                  die berechtigten Interessen der betroffenen Person zu wahren,
                  wozu mindestens das Recht auf Erwirkung des Eingreifens einer
                  Person seitens des Verantwortlichen, auf Darlegung des eigenen
                  Standpunkts und auf Anfechtung der Entscheidung gehört.
                </p>
                <p>
                  Möchte die betroffene Person Rechte mit Bezug auf
                  automatisierte Entscheidungen geltend machen, kann sie sich
                  hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung
                  Verantwortlichen wenden.
                </p>

                <h4 className={styles.h4}>
                  i. Recht auf Widerruf einer datenschutzrechtlichen
                  Einwilligung
                </h4>

                <p>
                  Jede von der Verarbeitung personenbezogener Daten betroffene
                  Person hat das vom Europäischen Richtlinien- und
                  Verordnungsgeber gewährte Recht, eine Einwilligung zur
                  Verarbeitung personenbezogener Daten jederzeit zu widerrufen.
                </p>
                <p>
                  Möchte die betroffene Person ihr Recht auf Widerruf einer
                  Einwilligung geltend machen, kann sie sich hierzu jederzeit an
                  einen Mitarbeiter des für die Verarbeitung Verantwortlichen
                  wenden.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  12. Datenschutzbestimmungen zu Einsatz und Verwendung von
                  Google Analytics (mit Anonymisierungsfunktion)
                </AccordionItemButton>
              </AccordionItemHeading>

              <AccordionItemPanel>
                <p>
                  Der für die Verarbeitung Verantwortliche hat auf dieser
                  Internetseite die Komponente Google Analytics (mit
                  Anonymisierungsfunktion) integriert. Google Analytics ist ein
                  Web-Analyse-Dienst. Web-Analyse ist die Erhebung, Sammlung und
                  Auswertung von Daten über das Verhalten von Besuchern von
                  Internetseiten. Ein Web-Analyse-Dienst erfasst unter anderem
                  Daten darüber, von welcher Internetseite eine betroffene
                  Person auf eine Internetseite gekommen ist (sogenannte
                  Referrer), auf welche Unterseiten der Internetseite
                  zugegriffen oder wie oft und für welche Verweildauer eine
                  Unterseite betrachtet wurde. Eine Web-Analyse wird überwiegend
                  zur Optimierung einer Internetseite und zur
                  Kosten-Nutzen-Analyse von Internetwerbung eingesetzt.
                </p>
                <p>
                  Betreibergesellschaft der Google-Analytics-Komponente ist die
                  Google Inc., 1600 Amphitheatre Pkwy, Mountain View, CA
                  94043-1351, USA.
                </p>
                <p>
                  Der für die Verarbeitung Verantwortliche verwendet für die
                  Web-Analyse über Google Analytics den Zusatz
                  "_gat._anonymizeIp". Mittels dieses Zusatzes wird die
                  IP-Adresse des Internetanschlusses der betroffenen Person von
                  Google gekürzt und anonymisiert, wenn der Zugriff auf unsere
                  Internetseiten aus einem Mitgliedstaat der Europäischen Union
                  oder aus einem anderen Vertragsstaat des Abkommens über den
                  Europäischen Wirtschaftsraum erfolgt.
                </p>
                <p>
                  Der Zweck der Google-Analytics-Komponente ist die Analyse der
                  Besucherströme auf unserer Internetseite. Google nutzt die
                  gewonnenen Daten und Informationen unter anderem dazu, die
                  Nutzung unserer Internetseite auszuwerten, um für uns
                  Online-Reports, welche die Aktivitäten auf unseren
                  Internetseiten aufzeigen, zusammenzustellen und um weitere mit
                  der Nutzung unserer Internetseite in Verbindung stehende
                  Dienstleistungen zu erbringen.
                </p>
                <p>
                  Google Analytics setzt ein Cookie auf dem
                  informationstechnologischen System der betroffenen Person. Was
                  Cookies sind, wurde oben bereits erläutert. Mit Setzung des
                  Cookies wird Google eine Analyse der Benutzung unserer
                  Internetseite ermöglicht. Durch jeden Aufruf einer der
                  Einzelseiten dieser Internetseite, die durch den für die
                  Verarbeitung Verantwortlichen betrieben wird und auf welcher
                  eine Google-Analytics-Komponente integriert wurde, wird der
                  Internetbrowser auf dem informationstechnologischen System der
                  betroffenen Person automatisch durch die jeweilige
                  Google-Analytics-Komponente veranlasst, Daten zum Zwecke der
                  Online-Analyse an Google zu übermitteln. Im Rahmen dieses
                  technischen Verfahrens erhält Google Kenntnis über
                  personenbezogene Daten, wie der IP-Adresse der betroffenen
                  Person, die Google unter anderem dazu dienen, die Herkunft der
                  Besucher und Klicks nachzuvollziehen und in der Folge
                  Provisionsabrechnungen zu ermöglichen.
                </p>
                <p>
                  Mittels des Cookies werden personenbezogene Informationen,
                  beispielsweise die Zugriffszeit, der Ort, von welchem ein
                  Zugriff ausging und die Häufigkeit der Besuche unserer
                  Internetseite durch die betroffene Person, gespeichert. Bei
                  jedem Besuch unserer Internetseiten werden diese
                  personenbezogenen Daten, einschließlich der IP-Adresse des von
                  der betroffenen Person genutzten Internetanschlusses, an
                  Google in den Vereinigten Staaten von Amerika übertragen.
                  Diese personenbezogenen Daten werden durch Google in den
                  Vereinigten Staaten von Amerika gespeichert. Google gibt diese
                  über das technische Verfahren erhobenen personenbezogenen
                  Daten unter Umständen an Dritte weiter.
                </p>
                <p>
                  Die betroffene Person kann die Setzung von Cookies durch
                  unsere Internetseite, wie oben bereits dargestellt, jederzeit
                  mittels einer entsprechenden Einstellung des genutzten
                  Internetbrowsers verhindern und damit der Setzung von Cookies
                  dauerhaft widersprechen. Eine solche Einstellung des genutzten
                  Internetbrowsers würde auch verhindern, dass Google ein Cookie
                  auf dem informationstechnologischen System der betroffenen
                  Person setzt. Zudem kann ein von Google Analytics bereits
                  gesetztes Cookie jederzeit über den Internetbrowser oder
                  andere Softwareprogramme gelöscht werden.
                </p>
                <p>
                  Ferner besteht für die betroffene Person die Möglichkeit,
                  einer Erfassung der durch Google Analytics erzeugten, auf eine
                  Nutzung dieser Internetseite bezogenen Daten sowie der
                  Verarbeitung dieser Daten durch Google zu widersprechen und
                  eine solche zu verhindern. Hierzu muss die betroffene Person
                  ein Browser-Add-On unter dem Link
                  tools.google.com/dlpage/gaoptout herunterladen und
                </p>
                <p>
                  installieren. Dieses Browser-Add-On teilt Google Analytics
                  über JavaScript mit, dass keine Daten und Informationen zu den
                  Besuchen von Internetseiten an Google Analytics übermittelt
                  werden dürfen. Die Installation des Browser-Add-Ons wird von
                  Google als Widerspruch gewertet. Wird das
                  informationstechnologische System der betroffenen Person zu
                  einem späteren Zeitpunkt gelöscht, formatiert oder neu
                  installiert, muss durch die betroffene Person eine erneute
                  Installation des Browser-Add-Ons erfolgen, um Google Analytics
                  zu deaktivieren. Sofern das Browser-Add-On durch die
                  betroffene Person oder einer anderen Person, die ihrem
                  Machtbereich zuzurechnen ist, deinstalliert oder deaktiviert
                  wird, besteht die Möglichkeit der Neuinstallation oder der
                  erneuten Aktivierung des Browser-Add-Ons.
                </p>
                <p>
                  Weitere Informationen und die geltenden
                  Datenschutzbestimmungen von Google können unter
                  www.google.de/intl/de/policies/privacy/ und unter
                  www.google.com/analytics/terms/de.html abgerufen werden.
                  Google Analytics wird unter diesem Link
                  www.google.com/intl/de_de/analytics/ genauer erläutert.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  13. Verwendung von Affiliate-Marketing-Programmen
                </AccordionItemButton>
              </AccordionItemHeading>

              <AccordionItemPanel>
                <p>
                  Die Internetseiten der ASSG setzen branchenübliche
                  Trackingmaßnahmen im Rahmen des Affiliate-Marketings ein. Es
                  ist für uns erforderlich, dass wir nachverfolgen können, ob
                  Nutzer, die sich für bei uns verfügbaren Angebote
                  interessieren, diese anschließend auf den Internetseiten
                  unserer Partner wahrnehmen.
                </p>
                <p>
                  Hierzu werden die Affiliate-Links um definierte Werte ergänzt,
                  die als Bestandteil des Links oder anderweitig, z. B. in einem
                  Cookie, gesetzt werden können. Zu den Werten gehören
                  insbesondere die Ausgangswebseite (Referrer), Zeitpunkt,
                  unsere Kennung als Betreiber der Webseite, eine Kennung des
                  jeweiligen Angebotes, eine Kennung des Nutzers, als auch
                  Tracking-spezifische Werte wie z. B. eine Kennung des
                  eingesetzten Werbemittels.
                </p>
                <p>
                  Bei der von uns verwendeten Kennungen der Nutzer handelt es
                  sich ausschließlich um Pseudonyme. D. h. die Kennungen selbst
                  enthalten keine personenbezogenen Daten wie Benutzernamen oder
                  E-Mail-Adressen. Die Kennung versetzt uns nur in die Lage zu
                  erfassen, ob derselbe Nutzer, der bei uns auf einen Link
                  geklickt oder sich für ein Angebot interessiert hat, dieses
                  anschließend bei unserem Partner wahrgenommen hat. Die Kennung
                  ist allerdings insoweit personenbezogen, dass bei uns wie auch
                  bei unseren Partnern weitere Nutzungsdaten zu dieser
                  vorliegen. Nur so kann uns unser Partner mitteilen, ob das
                  Angebot wahrgenommen worden ist und wir das Cashback zahlen
                  können.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  14. Rechtsgrundlage der Verarbeitung
                </AccordionItemButton>
              </AccordionItemHeading>

              <AccordionItemPanel>
                <p>
                  Art. 6 I lit. a DSGVO dient unserem Unternehmen als
                  Rechtsgrundlage für Verarbeitungsvorgänge, bei denen wir eine
                  Einwilligung für einen bestimmten Verarbeitungszweck einholen.
                  Ist die Verarbeitung personenbezogener Daten zur Erfüllung
                  eines Vertrags, dessen Vertragspartei die betroffene Person
                  ist, erforderlich, wie dies beispielsweise bei
                  Verarbeitungsvorgängen der Fall ist, die für eine Lieferung
                  von Waren oder die Erbringung einer sonstigen Leistung oder
                  Gegenleistung notwendig sind, so beruht die Verarbeitung auf
                  Art. 6 I lit. b DSGVO. Gleiches gilt für solche
                  Verarbeitungsvorgänge die zur Durchführung vorvertraglicher
                  Maßnahmen erforderlich sind, etwa in Fällen von Anfragen zu
                  unseren Produkten oder Leistungen. Unterliegt unser
                  Unternehmen einer rechtlichen Verpflichtung, durch welche eine
                  Verarbeitung von personenbezogenen Daten erforderlich wird,
                  wie beispielsweise zur Erfüllung steuerlicher Pflichten, so
                  basiert die Verarbeitung auf Art. 6 I lit. c DSGVO. In
                  seltenen Fällen könnte die Verarbeitung von personenbezogenen
                  Daten erforderlich werden, um lebenswichtige Interessen der
                  betroffenen Person oder einer anderen natürlichen Person zu
                  schützen. Dies wäre beispielsweise der Fall, wenn ein Besucher
                  in unserem Betrieb verletzt werden würde und daraufhin sein
                  Name, sein Alter, seine Krankenkassendaten oder sonstige
                  lebenswichtige Informationen an einen Arzt, ein Krankenhaus
                  oder sonstige Dritte weitergegeben werden müssten. Dann würde
                  die Verarbeitung auf Art. 6 I lit. d DSGVO beruhen.
                </p>
                <p>
                  Letztlich könnten Verarbeitungsvorgänge auf Art. 6 I lit. f
                  DSGVO beruhen. Auf dieser Rechtsgrundlage basieren
                  Verarbeitungsvorgänge, die von keiner der vorgenannten
                  Rechtsgrundlagen erfasst werden, wenn die Verarbeitung zur
                  Wahrung eines berechtigten Interesses unseres Unternehmens
                  oder eines Dritten erforderlich ist, sofern die Interessen,
                  Grundrechte und Grundfreiheiten des Betroffenen nicht
                  überwiegen. Solche Verarbeitungsvorgänge sind uns insbesondere
                  deshalb gestattet, weil sie durch den Europäischen Gesetzgeber
                  besonders erwähnt wurden. Er vertrat insoweit die Auffassung,
                  dass ein berechtigtes Interesse anzunehmen sein könnte, wenn
                  die betroffene Person ein Kunde des Verantwortlichen ist
                  (Erwägungsgrund 47 Satz 2 DSGVO).
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  15. Berechtigte Interessen an der Verarbeitung, die von dem
                  Verantwortlichen oder einem Dritten verfolgt werden
                </AccordionItemButton>
              </AccordionItemHeading>

              <AccordionItemPanel>
                <p>
                  Basiert die Verarbeitung personenbezogener Daten auf Artikel 6
                  I lit. f DSGVO ist unser berechtigtes Interesse die
                  Durchführung unserer Geschäftstätigkeit zugunsten des
                  Wohlergehens all unserer Mitarbeiter und unserer
                  Anteilseigner.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  16. Dauer, für die die personenbezogenen Daten gespeichert
                  werden
                </AccordionItemButton>
              </AccordionItemHeading>

              <AccordionItemPanel>
                <p>
                  Das Kriterium für die Dauer der Speicherung von
                  personenbezogenen Daten ist die jeweilige gesetzliche
                  Aufbewahrungsfrist. Nach Ablauf der Frist werden die
                  entsprechenden Daten routinemäßig gelöscht, sofern sie nicht
                  mehr zur Vertragserfüllung oder Vertragsanbahnung erforderlich
                  sind.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  17. Gesetzliche oder vertragliche Vorschriften zur
                  Bereitstellung der personenbezogenen Daten; Erforderlichkeit
                  für den Vertragsabschluss; Verpflichtung der betroffenen
                  Person, die personenbezogenen Daten bereitzustellen; mögliche
                  Folgen der Nichtbereitstellung
                </AccordionItemButton>
              </AccordionItemHeading>

              <AccordionItemPanel>
                <p>
                  Wir klären Sie darüber auf, dass die Bereitstellung
                  personenbezogener Daten zum Teil gesetzlich vorgeschrieben ist
                  (z. B. Steuervorschriften) oder sich auch aus vertraglichen
                  Regelungen (z. B. Angaben zum Vertragspartner) ergeben kann.
                  Mitunter kann es zu einem Vertragsschluss erforderlich sein,
                  dass eine betroffene Person uns personenbezogene Daten zur
                  Verfügung stellt, die in der Folge durch uns verarbeitet
                  werden müssen. Die betroffene Person ist beispielsweise
                  verpflichtet uns personenbezogene Daten bereitzustellen, wenn
                  unser Unternehmen mit ihr einen Vertrag abschließt. Eine
                  Nichtbereitstellung der personenbezogenen Daten hätte zur
                  Folge, dass der Vertrag mit dem Betroffenen nicht geschlossen
                  werden könnte. Vor einer Bereitstellung personenbezogener
                  Daten durch den Betroffenen muss sich der Betroffene an einen
                  unserer Mitarbeiter wenden. Unser Mitarbeiter klärt den
                  Betroffenen einzelfallbezogen darüber auf, ob die
                  Bereitstellung der personenbezogenen Daten gesetzlich oder
                  vertraglich vorgeschrieben oder für den Vertragsabschluss
                  erforderlich ist, ob eine Verpflichtung besteht, die
                  personenbezogenen Daten bereitzustellen und welche Folgen die
                  Nichtbereitstellung der personenbezogenen Daten hätte.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  18. Bestehen einer automatisierten Entscheidungsfindung
                </AccordionItemButton>
              </AccordionItemHeading>

              <AccordionItemPanel>
                <p>
                  Als verantwortungsbewusstes Unternehmen verzichten wir auf
                  eine automatische Entscheidungsfindung oder ein Profiling.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>

          <p className={styles.textRight}>Stand: 11/2021</p>
        </div>
      </BlockContainer>
    </MainWrapper>
  );
};

export default DatenschutzPage;

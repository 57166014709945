import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Checkbox from "../../../components/Checkbox/Checkbox";
import Radiobox from "../../../components/Radiobox/Radiobox";
import TitleLine from "../../../components/TitleLine/TitleLine";
import DefaultModal from "../../../components/DefaultModal/DefaultModal";
import ModalBody from "../../../components/ModalBody/ModalBody";
import SetupGoogleAuth from "../../../containers/SetupGoogleAuth/SetupGoogleAuth";
import { ReactComponent as InfoIcon } from "../../../images/info-icon.svg";
import { profileSelector, useAppDispatch } from "../../../redux/selectors";
import {
  fetchGoogleSetup,
  fetchGoogleSetupConfirm,
} from "../../../redux/reducers/profileSlice";
import {
  AuthText,
  AuthTypes,
  TwoWayAuthSettingsComponentProps,
} from "../ProfilePage.d";
import useModal from "../../../utils/useModal";

const authText: AuthText = {
  twoWayAuthEmail: "E-Mail",
  twoWayAuthSMS: "SMS",
  twoWayAuthGoogle: "Google Authenticator",
};

const TwoWayAuthSettingsComponent: React.FC<
  TwoWayAuthSettingsComponentProps
> = ({ authTypes, styles, isDisableSMS, setAuthTypes }) => {
  const dispatch = useAppDispatch();
  const { isShowing, toggle } = useModal();
  const { isShowing: isGoogleAuthInfoShowing, toggle: toggleGoogleAuthInfo } =
    useModal();
  const { profileData, status, gQRcode } = useSelector(profileSelector);

  const onSubmitGAuthCode = (code: string) => {
    dispatch(
      fetchGoogleSetupConfirm({
        code,
        isDefault: !isAnyDefaultType(authTypes, "twoWayAuthGoogle"),
      })
    );
  };

  const isAnyDefaultType = (types: AuthTypes, excludeKey: string): boolean => {
    return Object.keys(types).some(
      (key: string) => key !== excludeKey && types[key].isDefault
    );
  };

  const nextDefaultType = (
    types: AuthTypes,
    excludeKey: string
  ): string | undefined => {
    return Object.keys(types).find(
      (key: string) => key !== excludeKey && types[key].active
    );
  };

  const changeTypeState = (
    key: string,
    active: boolean,
    isDefault?: boolean
  ) => {
    setAuthTypes((prev: AuthTypes) => ({
      ...prev,
      [key]: {
        ...prev[key],
        active: active,
        ...(isDefault !== undefined ? { isDefault } : null),
      },
    }));
  };

  const checkboxTypeHandler = (
    authTypes: AuthTypes,
    currentKey: string,
    value: boolean
  ) => {
    if (!value && authTypes[currentKey].isDefault) {
      const typeName = nextDefaultType(authTypes, currentKey);

      if (typeName) {
        changeTypeState(typeName, authTypes[typeName].active, true);
      }
    }

    changeTypeState(
      currentKey,
      value,
      value && !isAnyDefaultType(authTypes, currentKey)
    );
  };

  const setDefaultTypeHandler = (authTypes: AuthTypes, currentKey: string) => {
    if (currentKey === "twoWayAuthGoogle" && !authTypes[currentKey].active) {
      dispatch(fetchGoogleSetup(true));
    }

    setAuthTypes((prev: AuthTypes) => ({
      ...Object.keys(authTypes).reduce(
        (acc, type: keyof AuthTypes) => ({
          ...acc,
          [type]: {
            ...authTypes[type],
            isDefault: false,
          },
        }),
        {}
      ),
      [currentKey]: {
        ...prev[currentKey],
        isDefault: true,
        active: true,
      },
    }));
  };

  const toggleActiveTypeHandler = (key: string, value: boolean) => {
    if (key === "twoWayAuthGoogle") {
      dispatch(fetchGoogleSetup(value));
    }

    checkboxTypeHandler(authTypes, key, value);
  };

  useEffect(() => {
    if (status === "gconfirmed") {
      changeTypeState("twoWayAuthGoogle", profileData.twoWayAuthGoogle.active);
    }
  }, [dispatch, status, profileData.twoWayAuthGoogle]);

  return (
    <div className={styles.checkboxesConatiner}>
      <TitleLine className={styles.title}>
        <span className={styles.authOneLine}>
          <span>Zwei-Wege-Authentifizierung</span>
          <InfoIcon
            className={styles.infoIcon}
            onClick={toggle}
            // {...{
            //   "data-tip": true,
            //   "data-for": "twowayauth_hint",
            //   "data-tip-disable": false,
            //   "data-event": "click focus",
            // }}
          />
        </span>
      </TitleLine>

      <div className={styles.twowayTitles}>
        <div className={`h3 ${styles.twowayDefaultItem}`}>Standard</div>
        <div className={`h3 ${styles.twowayActiveItem}`}>Aktiv</div>
        <div></div>
      </div>

      {Object.keys(authTypes).map((key: string, index: number) => (
        <div key={index} className={styles.checkboxAuthTypeConatiner}>
          <div className={`h3  ${styles.titleIconWrapper}`}>
            <span>
              Authentifizierungs-Code per {authText[key as keyof AuthText]}{" "}
              erhalten
            </span>{" "}
            {key === "twoWayAuthGoogle" && (
              <InfoIcon
                className={styles.infoIconGoogle}
                onClick={toggleGoogleAuthInfo}
                // {...{
                //   "data-tip": true,
                //   "data-for": "twowayauth_hint",
                //   "data-tip-disable": false,
                //   "data-event": "click focus",
                // }}
              />
            )}
            {/* {authTypes[key].active && authTypes[key].isDefault && (
              <span className={styles.defaultLabel}>standard</span>
            )} */}
          </div>

          <div className={styles.twowayDefaultItems}>
            <div className={styles.twowayDefaultItem}>
              <Radiobox
                id={`authDefault_${key}`}
                name={`authDefault_${key}`}
                checked={authTypes[key].isDefault}
                className={styles.twowayDefaultItemCheckbox}
                {...(key === "twoWayAuthSMS" ? { disabled: isDisableSMS } : {})}
                toggleCheck={(value: boolean) =>
                  setDefaultTypeHandler(authTypes, key)
                }
              />
            </div>

            <div
              className={`${styles.twowayDefaultItem} ${styles.twowayActiveItem}`}
            >
              <Checkbox
                id={`authActive_${key}`}
                checked={authTypes[key].active}
                className={styles.twowayDefaultItemCheckbox}
                {...(key === "twoWayAuthGoogle"
                  ? { waitCheck: gQRcode !== "" }
                  : {})}
                {...(key === "twoWayAuthSMS" ? { disabled: isDisableSMS } : {})}
                toggleCheck={(value: boolean) =>
                  toggleActiveTypeHandler(key, value)
                }
              />
            </div>
          </div>
        </div>
      ))}

      {gQRcode && (
        <SetupGoogleAuth QRcode={gQRcode} onSubmit={onSubmitGAuthCode} />
      )}

      <DefaultModal
        showModal={isShowing}
        closeHandler={toggle}
        className={styles.twowayAuthModal}
        title="Zwei-Wege-Authentifizierung"
      >
        <ModalBody>
          <h3 className={styles.authTooltipH3}>
            Bestätigung in zwei Schritten aktivieren
          </h3>

          <p className={styles.authTooltipP}>
            Mit der Bestätigung in zwei Schritten können Sie Ihr Konto für den
            Fall, dass Unbefugte an Ihr Passwort gelangen, zusätzlich absichern.
            Nachdem Sie die Bestätigung in zwei Schritten eingerichtet haben,
            erfolgt die Anmeldung in Ihrem Konto in zwei Schritten. Dabei
            verwenden Sie:
          </p>

          <ul className={styles.authTooltipUl}>
            <li>Etwas, das Sie wissen, zum Beispiel Ihr Passwort</li>
            <li>Etwas, das Sie haben, zum Beispiel Ihr Smartphone</li>
          </ul>

          <h3 className={styles.authTooltipH3}>
            Identität in einem zweiten Schritt bestätigen
          </h3>

          <p className={styles.authTooltipP}>
            Nachdem Sie die Bestätigung in zwei Schritten aktiviert haben,
            müssen Sie bei der Anmeldung einen zweiten Schritt ausführen, um
            Ihre Identität zu bestätigen. Zum Schutz Ihres Kontos werden Sie von
            uns aufgefordert, einen zweiten Schritt auszuführen.
          </p>
        </ModalBody>
      </DefaultModal>

      <DefaultModal
        showModal={isGoogleAuthInfoShowing}
        closeHandler={toggleGoogleAuthInfo}
        className={styles.twowayAuthModal}
        title="Google Authentifizierung"
      >
        <ModalBody>
          <h3 className={styles.authTooltipH3}>Hinweis:</h3>
          <p className={styles.authTooltipP}>
            Wurde eine Google Authentifizierung bereits hinzugefügt und dann
            entfernt, müssen dann der Eintrag im Google Authenticator App auch
            entfernt werden.
            <br /> Sie sollen dann den QR-Code erneut mit Google Authenticator
            App scannen, anstatt alte Code aus Google Authenticator APP
            benutzen.
          </p>
        </ModalBody>
      </DefaultModal>
    </div>
  );
};

export default TwoWayAuthSettingsComponent;

import React from "react";
import BlockContainer from "../../../components/BlockContainer/BlockContainer";
import ContentBox from "../../../components/ContentBox/ContentBox";
import InfoBubble from "../../../components/InfoBubble/InfoBubble";
import Li from "../../../components/Li/Li";
import LongButton from "../../../components/LongButton/LongButton";
import { ReactComponent as UserIcon } from "../../../images/user-icon.svg";
import { BlockProps } from "../LandingPage.d";
import { IS_RVN_BRAND } from "../../../variables_config";

const BlockOne: React.FC<BlockProps> = ({ styles }) => {
  return (
    <BlockContainer bgClass={styles.blockOneBg}>
      <div className={styles.blockOneContainer}>
        <ContentBox className={styles.blockOne}>
          <h1>
            {IS_RVN_BRAND ? "R+V-CashProtect Portal" : "Cash & Protect Portal"}
          </h1>
          <h3>So geht Versicherung heute:</h3>

          <div className={styles.itemsContainer}>
            <p className={styles.textBlock}>
              Hol dir jetzt den Schutz, den du für deine Lebenssituation
              brauchst. Spare darüber hinaus beim online-shoppen bares Geld!
            </p>

            <ul className={styles.ulBlock}>
              <Li>Passt.</Li>
              <Li>Passt sich an.</Li>
              <Li>Passt einfach ins Leben.</Li>
            </ul>
          </div>

          <div className={styles.btnContainer}>
            <LongButton
              text="Jetzt zum Mitgliederbereich"
              icon={UserIcon}
              href="/login"
            />
          </div>

          {!IS_RVN_BRAND ? (
            <InfoBubble
              customClass={styles.bubbleTop}
              text="bis zu 25% Cashback auf deine Einkäufe"
            />
          ) : null}
        </ContentBox>
      </div>
    </BlockContainer>
  );
};

export default BlockOne;

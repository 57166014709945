import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APICall } from "../../utils/api";

export interface Bank {
  _id: string;
  name: string;
  BIC: string;
  address: string;
  bdcBank: string;
  bdcBic: string;
  bdcIban: string;
  bdcPlace: string;
  bdcPaymentMethod?: string;
  bdcPaymentEmail?: string;
  bdcDocumentTemplate?: string;
  brokerRegisterNum: string;
  contactPerson: string;
  contactPersonEmail: string;
  contactPersonTel: string;
  house: string;
  city: string;
  personalVatNum: string;
  registerNum: string;
  registerPlace: string;
  street: string;
  zip: string;
  logo: string;
  icon: string;
  contactEmail?: string;
  contactPhone?: string;
  contactSocialFB?: string;
  contactSocialLinkedin?: string;
  createPkPass?: boolean;
}

export interface BankState {
  bankData: Bank | null;
  status: string;
}

const initialState: BankState = {
  bankData: null,
  status: "idle",
};

export const fetchGetBank = createAsyncThunk(
  "bank/GetBank",
  async (
    { BIC, idBank }: { BIC?: string; idBank?: string },
    { rejectWithValue }
  ) => {
    return await APICall("backend.dicts.banks", "get-bank", rejectWithValue, {
      ...(BIC ? { BIC } : {}),
      ...(idBank ? { idBank } : {}),
    });
  }
);

export const bankSlice = createSlice({
  name: "bank",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchGetBank.fulfilled as any, (state, action) => {
      state.bankData = action.payload;
      state.status = "succeded";
    });

    builder.addCase(fetchGetBank.rejected as any, (state, action) => {
      state.bankData = initialState.bankData;
      state.status = "error";
    });
  },
});

export default bankSlice.reducer;

import React from "react";
import Main from "../../components/Main/Main";
import MainFooterShort from "../../components/MainFooterShort/MainFooter";
import MainHeader from "../../components/MainHeader/MainHeader";
import { LinkItem } from "../../components/MainHeader/MainHeader.d";
import { MainWrapperProps } from "./MainWrapper.d";
import { ReactComponent as QuestionIcon } from "../../images/question-icon.svg";
import { ReactComponent as UserIcon } from "../../images/user-circle-icon.svg";
import { ReactComponent as MessageIcon } from "../../images/message-icon.svg";
import { ReactComponent as GiftIcon } from "../../images/gift-icon.svg";
import { IS_CASHBACK } from "../../variables_config";

const headerLinks: Array<LinkItem> = [
  { href: "/faq", title: "FAQ und Hilfe", icon: QuestionIcon },
  { href: "/profile", title: "Meine Daten", icon: UserIcon },
  { href: "/service-contact", title: "Kontakt", icon: MessageIcon },
];

if (IS_CASHBACK) {
  headerLinks.push({ href: "/cashback", title: "Cashback", icon: GiftIcon });
}

const MainWrapper: React.FC<MainWrapperProps> = ({
  hideFooter,
  showLoader,
  children,
}) => {
  return (
    <>
      <MainHeader links={headerLinks} />
      <Main showLoader={showLoader}>{children}</Main>
      {!hideFooter ? <MainFooterShort /> : null}
    </>
  );
};

export default MainWrapper;

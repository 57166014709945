import React from "react";
import styles from "./ModalBody.module.css";

const ModalBody: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div data-testid="modal-body" className={styles.modalBody}>
      {children}
    </div>
  );
};

export default ModalBody;

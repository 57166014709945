import React, { memo } from "react";
import BlockContainer from "../../../components/BlockContainer/BlockContainer";
import ContentBox from "../../../components/ContentBox/ContentBox";
import LongButton from "../../../components/LongButton/LongButton";
import { BlockProps } from "../PackagePage.d";
import { ReactComponent as BagIcon } from "../../../images/bag-icon.svg";
import TitleLine from "../../../components/TitleLine/TitleLine";
import ReactPlayer from "react-player";
import play from "../../../images/play-icon.svg";
import videoPreview from "../video-preview.jpg";
import Li from "../../../components/Li/Li";
import { IS_CASHBACK, IS_RVN_BRAND } from "../../../variables_config";

const BlockFour: React.FC<BlockProps> = ({ letterData, styles }) => {
  return (
    <BlockContainer>
      <div className={styles.blockFourContainer}>
        <div className={styles.twoDivs}>
          <div className={styles.half}>
            <TitleLine>
              {!IS_RVN_BRAND
                ? `Dein Mehrwert-Paket kurz erklärt – ${letterData?.name}`
                : "Dein R+V-CashProtect kurz erklärt"}
            </TitleLine>
          </div>
          <div className={styles.half}></div>
        </div>

        <ContentBox className={styles.blockFour}>
          <ul className={styles.ulBlock}>
            <Li className={styles.li}>
              Absicherung bei unverschuldeter Arbeitslosigkeit
            </Li>
            <Li className={styles.li}>
              Absicherung bei längerer Arbeitsunfähigkeit
            </Li>
            <Li className={styles.li}>Assistance-Leistungen bei Jobverlust</Li>
            <Li className={styles.li}>Assistance-Leistung bei Krankheit</Li>
          </ul>

          <div className={styles.videoContainer}>
            <ReactPlayer
              url="https://cdn.valu-x.com/video/vx2-h264_1080_best.mp4"
              playIcon={
                <span className={styles.playIcon}>
                  <img className={styles.playBut} src={play} alt="play" />
                </span>
              }
              light={videoPreview}
              controls={true}
              playing={true}
              width="100%"
              height="100%"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
              }}
            />
          </div>
          {IS_CASHBACK && (
            <div className={styles.btnContainer}>
              <LongButton
                text="Weitere Angebote ansehen"
                icon={BagIcon}
                href="/cashback"
              />
            </div>
          )}
        </ContentBox>
      </div>
    </BlockContainer>
  );
};

export default memo(BlockFour);

import React from "react";
import ModalContainer from "../ModalContainer/ModalContainer";
import styles from "./DefaultModal.module.css";
import { DefaultModalProps } from "./DefaultModal.d";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import ModalHeader from "../ModalHeader/ModalHeader";

const DefaultModal: React.FC<DefaultModalProps> = ({
  showModal,
  title,
  children,
  className = "",
  closeHandler,
}) => {
  return (
    <ModalWrapper
      isShowing={showModal}
      closeHandler={closeHandler}
      data-testid={"modalWrapper"}
    >
      <ModalContainer
        isShowing={showModal}
        className={`${styles.modalContent} ${className}`}
      >
        <ModalHeader title={title} closeHandler={closeHandler} />
        {children}
      </ModalContainer>
    </ModalWrapper>
  );
};

export default DefaultModal;

export const PACKAGE_LAYOUT_WIREFRAME = "t";
export const PACKAGE_LAYOUT_ONE = "1";
export const PACKAGE_LAYOUT_TWO = "2";
export const PACKAGE_LAYOUT_THREE = "3";
export const PACKAGE_LAYOUT_FOUR = "4";
export const PACKAGE_LAYOUT_FIVE = "5";
export const PACKAGE_LAYOUT_SIX = "6";
export const PACKAGE_LAYOUT_SEVEN = "7";
export const PACKAGE_LAYOUT_EIGHT = "8";

export const SYSTEM_ID = process.env.REACT_APP_SYSTEM_ID || "";
export const APP_BRANDED = process.env.REACT_APP_BRANDED || "";
export const IS_RVN_BRAND = process.env.REACT_APP_BRANDED === "ruv" || false;
export const API_SERVER: string =
  process.env.REACT_APP_API_SERVER || `${window.location.host}/`;
export const BIC: string = process.env.REACT_APP_BIC || "";
export const ROUTE: string = process.env.REACT_APP_ROUTE || "/";
export const INSTANCE: string = process.env.REACT_APP_INSTANCE || "";
export const IS_CASHBACK: boolean =
  process.env.REACT_APP_BRANDED === "ruv"
    ? false
    : process.env.REACT_APP_CASHBACK === "true" || false;
